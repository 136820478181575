import app from "/src/app/app.module";


app.controller('quote-details',
    function ($scope, vxUtils, settings, $location, $filter, enums, $customer, $products) {
        $scope.cust = {};
        $scope.savetoserver = true;
        $scope.offerte = {};
        $scope.actionBarCpi = {};
        $scope.customerCpi = {};
        $scope.adressCpi = {};
        $scope.pageID = vxUtils.generateQuickGuid();

        $scope.init = function (object) {
            $scope.offerte = object;

            $customer.getCustomer(function (result) {
                $scope.cust = result;
                $scope.customerCpi.cust = $scope.cust;
                $scope.adressCpi.adress = $scope.cust;
                $scope.actionBarCpi.mailobject = $scope.offerte;
            }, object.idClient);

            $products.getProductsByPaymentItem(function (result) {
                $scope.products = result;
            }, object.idofferte);

        };

        $scope.locationCpi = {
            updateAdress: function (data) {

            },
            adress: {adress: 'vondelweg 16', city: 'Rotterdam', country: 'Nederland'}
        };

        ///////////actionbar/////////////
        $scope.actionitems = [
            {label: vxUtils.loc('DELETE'), href: "", icon: "fa fa-trash-o fa-2x"},
            {label: vxUtils.loc('SEND_EMAIL'), href: "/src/panel/mail-dialog.html", icon: "fa fa-envelope fa-2x"},
            {label: vxUtils.loc('NEW_NOTE'), href: "/src/panel/paperclip-dialog.html", icon: "fa fa-paperclip fa-2x"}
        ];

        $scope.actionBarCpi = {};

        $scope.scrollMethod = function ($event, $delta, $deltaX, $deltaY) {
            vxUtils.scrollHorzontal($event, $delta, $deltaX, $deltaY, $scope.pageID);
        };
    });