'use strict';

var Change = function (event) {
    var vxUtils = window.vxUtils;
    this.dbo = event;

    this.FullName = function () {
        var prep = this.dbo['ctd_preposition'];
        if (prep !== "" && prep !== null)
            prep = " " + prep;
        else {
            prep ='';
        }

        return this.dbo['ctd_surname'] + prep + " " + this.dbo['ctd_lastname'];

    };

    this.Name = function () {

        return this.dbo['ctd_surname'];

    };


    this.ID = function () {
        return this.dbo['id'];
    };

    this.UserID = function () {
        return this.dbo['id_user'];
    };


    this.Icon = function () {
        switch (this.ActionType()) {
            case 'Created':
                return 'glyphicon glyphicon-open';
                break;
            case 'Added':
                return 'ionicons ion-android-add-circle';
                break;
            case 'Removed':
                return 'ionicons ion-android-cancel';
                break;
            case 'Updated':
                return 'ionicons ion-android-create';
                break;
            case 'Deleted':
                return 'fa fa-trash-o';
                break;
        }
    };

    this.Action = function (object) {
        switch (this.ActionType()) {
            case 'Created':
                return vxUtils.loc('HAS') + object + vxUtils.loc('ACTION_ADDED');
                break;
            case 'Added':
                return vxUtils.loc('HAS') + object + vxUtils.loc('ACTION_ADDED')
                    + vxUtils.loc('TO_A');
                break;
            case 'Deleted':
                return vxUtils.loc('HAS') + object + vxUtils.loc('ACTION_DELETED');
                break;
            case 'Updated':
                return vxUtils.loc('HAS') + object + vxUtils.loc('ACTION_CHANGED');
                break;
            case 'Removed':
                return vxUtils.loc('HAS') + vxUtils.loc('ACTION_DELETED') + object + ' ' + vxUtils.loc("FROM") + ' ';
                break;
        }
    };

    this.convertToType = function ($id) {
        var $actiontype = '';
        switch ($id) {
            case 'Note':
                $actiontype = vxUtils.loc('NOTE');
                break;
            case 'Product':
                $actiontype = vxUtils.loc('PRODUCT');
                break;
            case 'Job':
                $actiontype = vxUtils.loc('JOB');
                break;
            case 'Partykind':
                $actiontype = vxUtils.loc('KINDOFPARTY');
                break;
            case 'Customer':
                $actiontype = vxUtils.loc('RELATION');
                break;
            case 'User':
                $actiontype = vxUtils.loc('COLLEAGUE');
                break;
            case 'Availability':
                $actiontype = vxUtils.loc('AVAILABILITY');
                break;
            case 'Worknote':
                $actiontype = vxUtils.loc('WORKNOTE');
                break;
            case 'PaymentItem':
                $actiontype = vxUtils.loc('PAYMENT');
                break;
            case 'Depot':
                $actiontype = vxUtils.loc('DEPOT');
                break;
            case 'Company':
                $actiontype = vxUtils.loc('COMPANY');
                break;
            case 'Location':
                $actiontype = vxUtils.loc('LOCATION');
                break;
        }

        return $actiontype;
    }


    this.ActionType = function () {
        return this.dbo['actiontype'];
    };

    this.ObjectName = function () {
        return this.dbo['objectname'];
    };
    this.Message = function () {
        var target = this.dbo['targettype'];
        var object = this.dbo['objecttype'];

        var output = '';

        if (target === object) {
            output = this.Action(this.convertToType(target).toLowerCase());
        } else {
            output = this.Action(this.convertToType(object).toLowerCase()) +
                this.convertToType(target).toLowerCase();
        }

        return output;
    };

    this.Time = function () {
        return parseInt(this.dbo['creationdate'] + "000");
    };


    this.name = this.Name();
    this.id = this.ID();
    this.message = this.Message();
    this.actiontype = this.ActionType();
    this.time = this.Time();
    this.action = this.Action();
    this.icon = this.Icon();
    this.type = 'change'
    this.objectname = this.ObjectName();

};
export default Change;
//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];