import app from "/src/app/app.module";

var api = function($resource, settings, vox) {

    var postSearch = function(callback, searchstring,searchtypes) {
        vox.post(settings.BASE_API_URL + 'v1/search/' + searchstring,
            searchtypes,  callback);
    };
  

    return {
        search: postSearch
    };
};

app.factory("$search", api);