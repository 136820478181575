import appModule from "./app.module";

appModule.factory('msgBus', function ($rootScope) {
    var msgBus = {};
    msgBus.emitMsg = function (msg, data) {
        data = data || {};
        $rootScope.$emit(msg, data);
    };
    msgBus.onMsg = function (msg, func, scope) {
        var unbind = $rootScope.$on(msg, func);
        if (scope) {
            scope.$on('$destroy', unbind);
        }
    };
    return msgBus;
});

appModule.service('enums', function () {
    this.ADMINISTRATOR = 0;
    this.USER = 1;
    this.LOGGED_USER = "LOGGED_USER";
});


appModule.service('mailevents', function () {
    this.JOBDECLINED = "jobdeclinedempl";
    this.JOBCHANGED = "jobchangedempl";
    this.JOBACCEPTED = "jobacceptedempl";
});