import app from "/src/app/app.module";
import HourType from "../../models/hour-type";


app.controller('hourtypes',
    function ($scope, $timeout, msgBus, $api, $job,
              $filter, $focus, sweet, vxUtils, toastr) {
        var vm = this;

        vm._onlyNumbers = /^\d+$/;


        vm.subjectPlaceholder = vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.items = [];
        vm.selected = {content: ''};
        $job.allKindOfParties(function (result) {
            vm.kindofjobs = result;
        });
        vm.add = function () {
            var m = {
                label: vm.title
            };

            vm.title = '';
            vm.items.splice(0, 0, m);
            vm.selected = m;
            $focus('focusSubject');
            vm.save(true);
        };

        vm.delete = function () {
            sweet.show({
                title: vxUtils.loc('CONFIRMDELETION'),
                text: "Type verwijderen? Weet u het zeker?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: vxUtils.loc('YES'),
                closeOnConfirm: true
            }, function () {
                $api.del($api.adress.hourtypes + '/' + vm.selected.id, function () {
                    var i = $filter('removeWith')(vm.items, {id: vm.selected.id});
                    // vm.items.splice(i, 1);
                    vm.items = i;
                    vm.selected = vm.items[vm.items.length - 1];
                });
            });


        };

        vm.changed = function () {
            var found = $filter('filter')(vm.kindofjobs,
                {id_partykind: vm.selected.job_type_id}, true);
            if (found.length >0)
                vm.kindofparty = found[0].type;
            else
                vm.kindofparty = null;
        };

        vm.save = function (isNew) {
            if (vm.kindofparty) {
                angular.forEach(vm.kindofjobs, function (value, key) {
                    if (value.type === vm.kindofparty)
                        vm.selected.job_type_id = value.id_partykind;
                });
            }               
            else {
                vm.selected.job_type_id = 0;
            }
            if (!isNew)
                vm.selected.calcAmount();

            $api.post($api.adress.hourtypes, function (result) {

                if (isNew) {
                    // vm.selected.id = parseInt(result);
                    vm.getItems();
                    // var i = $filter('filter')(vm.items, {id: result});
                    //
                    // vm.selected = i;

                    //    vm.items.splice(0, 0, vm.selected);
                }

                toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('TYPE')+vxUtils.loc('ACTION_SAVED'));
            }, vm.selected);

        };

        vm.getItems = function () {
            var items = [];
            $api.get($api.adress.hourtypes,
                function (data) {
                    if (angular.isArray(data)) {
                        items = data.concat(items)
                    } else
                        items.push(data);

                    vm.items = items;

                    if (vm.items.length > 0) {
                        vm.selected = vm.items[0];
                        vm.kindofparty = $filter('filter')(vm.kindofjobs,
                            {id_partykind: vm.selected.job_type_id}, true)[0].type;
                    }
                }, HourType);
        };

        vm.getItems();
    })
;