/**
 * Created by Alex Knijf on 3-5-2015.
 */
'use strict';

var Worknote = function (worknote) {

    if (worknote === undefined) {
        return;
    }

    this.dbo = worknote;

    var prefix = "ctd";

    this.WorknoteID = function () {
        var value = parseInt(this.dbo['idTimechart']);
        return isNaN(value) ? 0 : value;
    };

    this.UserID = function () {
        return parseInt(this.dbo['id_user']);
    };

    this.JobCrewID = function () {
        return parseInt(this.dbo['idJobcrew']);
    };

    this.JobID = function () {
        return parseInt(this.dbo['id_job'] || this.dbo['idJob']);
    };

    this.GetHours = function () {
        var value = (this.dbo['hours'] || this.dbo['timechart_hours']);
        return isNaN(value) ? 0 : parseFloat(value).toFixed(2);
    };

    this.GetAmountJobs = function () {
        return parseFloat(this.dbo['worked_jobs']);
    };

    this.GetTravelKm = function () {
        var value = parseFloat(this.dbo['travelkms'] || this.dbo['timechart_travelkms']);
        return isNaN(value) ? 0 : value;
    };

    this.GetTravelTime = function () {
        var value = parseFloat(this.dbo['traveltime'] || this.dbo['timechart_traveltime']);
        return isNaN(value) ? 0 : value;
    };

    this.GetExpenses = function () {
        return parseFloat(this.dbo['expenses'] || this.dbo['timechart_expenses']);
    };

    this.GetJobcrewCreationDate = function () {
        return parseInt(this.dbo['creationdate'] + "000");
    };
    this.GetJobcrewResponseDate = function () {
        return parseInt(this.dbo['responsedate'] + "000");
    };
    this.WorkNoteResponseDate = function () {
        var value = parseInt(this.dbo['created'] + "000");
        return isNaN(value) ? 0 : value;

    };

    this.Status = function () {
        return parseInt(this.dbo['status']);
    };

    this.City = function () {
        return this.dbo['city'];
    };

    this.Country = function () {
        return this.dbo['country'];
    };
    this.JobTitle = function () {
        return this.dbo['jobtitle'];
    };

    this.StartDate = function () {
        return this.dbo['startdate'] === null ? '-' : parseInt(this.dbo['startdate'] + "000");
    };

    this.EndDate = function () {
        return this.dbo['enddate'] === null ? '-' : parseInt(this.dbo['enddate'] + "000");
    };

    this.Entrance = function () {
        return this.dbo['entrance'] === null ? '-' : parseInt(this.dbo['entrance'] + "000");
    };


    //ICONS
    this.FilledIcon = function () {

        var val = "fa fa-building-o";

        switch (this.Filled()) {
            case true:
                val = "fa fa-check-square";
                break;
            case false:
                val = "fa fa-square-o";
        }
        return val;
    };

    this.JobNumber = function () {
        var value = parseInt(this.dbo['jobnummer']);
        return isNaN(value) ? 0 : value;
    };

    this.Payout = function () {
        var value = (parseInt(this.dbo['vox_hour_rates_amount'])/100*this.hours);
        return isNaN(value) ? 0 : value;
    };


    this.Name = function () {

        var prep = this.dbo['ctd_preposition'];
        if (prep !== "" && prep !== null)
            prep = " " + prep;
        else {
            prep ='';
        }

        return this.dbo['ctd_surname'] + prep + " " + this.dbo['ctd_lastname'];

    };
    this.Filled = function () {
        return this.dbo['filled'] === 'true';
    };

    this.KindOfJob = function () {
        return parseInt(this.dbo['id_partykind']);
    };

    this.Remarks = function () {
        return this.dbo['remarks'];
    };

    this.type = 'worknote'


    //TAB interface
    this.tabID = this.UserID();
    this.tabDescription = this.Name();

    // this.customericon = this.CustomerTypIcon();
    this.id_user = this.UserID();
    this.idTimechart = this.WorknoteID();
    //JobCrew

    this.filled = this.Filled();
    this.idjobcrew = this.JobCrewID();
    this.idjob = this.JobID();
    this.hours = this.GetHours();
    this.remarks = this.Remarks();
    this.amountjobs = this.GetAmountJobs();
    this.traveltime = this.GetTravelTime();
    this.travelkm = this.GetTravelKm();
    this.expenses = this.GetExpenses();
    this.status = this.Status();
    this.creationdate = this.GetJobcrewCreationDate();
    this.responsedate = this.GetJobcrewResponseDate();
    this.createdWorknote = this.WorkNoteResponseDate();
    this.jobtitle = this.JobTitle();
    this.jobnumber = this.JobNumber();
    this.entrance = this.Entrance();
    this.startdate = this.StartDate();
    this.enddate = this.EndDate();
    this.partykind = this.KindOfJob();
    this.filledIcon = this.FilledIcon();
    this.payout = this.Payout();
};

export default Worknote;

//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];