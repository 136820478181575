'use strict';

var Product = function (product) {

    if (product === undefined) {
        return;
    }

    this.dbo = product;

    this.SubTotal = function () {

    };
    /*   this.Details = function () {
     var detail = {
     idproductdetail: this.dbo['idproductdetail'],
     idProductgroup: this.dbo['idproductgroup'],
     productname: this.dbo['productname']
     };
     return detail;
     };
     */

    this.IdProductDetail = function () {
        return parseInt(this.dbo['idproductdetail'] || '0');
    };

    this.TaxDetails = function () {
        var tax = {
            id_taxes: parseInt(this.dbo['id_taxes'] || '0'),
            name: this.dbo['name'] || 'none',
            rate: parseInt(this.dbo['rate'] || '0')
        };
        return tax;
    };

    this.Price = function () {
        return parseFloat(this.dbo['productprice'] || '0');
    };

    this.Quantity = function () {
        return parseInt(this.dbo['productquantity'] || '0');
    };

    this.Tax = function () {
        return parseInt(this.dbo['id_tax_rate'] || this.dbo['id_taxes'] || '0');
    };

    this.IdProduct = function () {
        return parseInt(this.dbo['idproducts'] || '0');
    };

    this.IdOfferte = function () {
        return parseInt(this.dbo['id_offerte'] || '0');
    };
    this.IdProductGroup = function () {
        return parseInt(this.dbo['idproductgroup'] || '0');
    };
    /**
     * @return {number}
     */
    this.ProductNumber = function () {
        return parseInt(this.dbo['productnumber'] || '0');
    };

    /**
     * @return {number}
     */
    this.ProductGroupNumber = function () {
        return parseInt(this.dbo['productgroupstartnr'] || '0');
    };

    this.Description = function () {
        return this.dbo['productdescr'];
    };

    this.ProductGroupName = function () {
        return this.dbo['productgroupname'];
    };

    this.ProductName = function () {
        return this.dbo['productname'];
    };

    this.ProductGroupDescription = function () {
        return this.dbo['productgroupdesrc'];
    };

    /**
     * @return {string}
     */
    this.ShowInCalcIcon = function () {
        var show = parseInt(this.dbo['showincalc']);
        return show === 1 ? 'fa fa-check-square green fa-05x' : 'fa fa-square-o fa-05x';
    };

    /**
     * @return {boolean}
     */
    this.ShowInCalc = function () {
        var show = parseInt(this.dbo['showincalc']);
        return show === 1;
    };

    //TAB interface
    this.tabID = this.IdProductDetail();
    this.tabDescription = this.ProductName();

    this.idofferte = this.IdOfferte();
    this.productquantity = this.Quantity();
    this.productprice = this.Price();
    //  this.details = this.Details();
    this.idproduct = this.IdProduct();
    this.tax = this.TaxDetails();
    this.taxid = this.Tax();

    this.idproductdetail = this.IdProductDetail();
    this.idproductgroup = this.IdProductGroup();
    this.productnumber = this.ProductNumber();
    this.productgroupnumber = this.ProductGroupNumber();
    this.productdescr = this.Description();
    this.productgroupname = this.ProductGroupName();
    this.productgroupdescription = this.ProductGroupDescription();
    this.showincalcicon = this.ShowInCalcIcon();
    this.showincalc = this.ShowInCalc();
    this.productname = this.ProductName();
    this.fullnumber = {
        productgroupnumber: this.ProductGroupNumber(),
        productnumber: this.ProductNumber()
    };
};

export default Product;

//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];