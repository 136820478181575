'use strict';

var PaymentItem = function (item) {

    if (item === undefined) {
        return;
    }

    this.dbo = item;

    this.IsCompany = this.dbo['companyname'] !== undefined;


    this.ClientNumber = function () {
        return parseInt(this.dbo['idClient'] || this.dbo['id_client']);
    };

    //properties
    this.OfferteID = function () {
        return parseInt(this.dbo['idofferte']);
    };

    this.IdUser = function () {
        return parseInt(this.dbo['iduser']);
    };

    this.JobTitle = function () {
        return this.dbo['jobtitle'];
    };

    this.ClientName = function () {
        if (this.dbo['invoice_companyname'] !== '')
            return this.dbo['invoice_companyname'];
        else {
            var prep = this.dbo['invoice_middlename'];
            if (prep !== "" && prep !== null)
                prep = " " + prep;
            else {
                prep = '';
            }

            return this.dbo['invoice_firstname'] + prep + " " + this.dbo['invoice_lastname'];
        }
    };

    this.UserName = function () {
        if (this.IsCompany)
            return this.dbo['companyname'];
        else {
            var prep = this.dbo['user_middlename'];
            if (prep !== "")
                prep = " " + prep;

            return this.dbo['user_firstname'] + prep + " " + this.dbo['user_lastname'];
        }
    };

    this.DueDate = function () {
        return this.dbo['experation_date'] === null ? '-' : parseInt(this.dbo['experation_date'] + "000");
    };


    this.CreationDate = function () {
        return this.dbo['creationdate'] === null ? '-' : parseInt(this.dbo['creationdate'] + "000");
    };

    this.SendDate = function () {
        return this.dbo['send_date'] === null ? '-' : parseInt(this.dbo['send_date'] + "000");
    };

    this.Status = function () {
        return this.dbo['state'];
    };

    this.IdOrderFK = function () {
        return this.dbo['id_order_FK'];
    };

    //Icons

    this.StatusIcon = function () {
        const val = {
            [this.status]: {
                'declined': 'fa fa-times-circle-o red',
                'accepted': 'glyphicon glyphicon-ok-circle green',
                'notsend': 'glyphicon glyphicon-pencil'
            }[this.status]
        };
        return val;
    };


    /**
     * Returns the appropriate icon for a payment type based on its value
     * @param {string} type - The value of the payment type (either 'quote' or 'invoice')
     * @returns {string} The CSS class for the payment type icon
     */
    this.PaymentTypeIcon = function (type) {
        // Default value for the icon if the type is not recognized
        let val = "fa fa-asterisk fa-05x not-important";

        // Switch statement to determine the appropriate icon based on the payment type
        switch (type) {
            case "quote":
                val = "fa fa-clock-o fa-05x";
                break;
            case "invoice":
                val = "fa fa-eur fa-05x";
                break;
        }

        return val;
    };


    /**
     * IDealTypeIcon - Return fa flag-checkered or flag-o based on hasIdeal value
     * @returns {string} val - fa classname
     */
    this.IDealTypeIcon = function () {

        var val = "fa fa-asterisk fa-05x not-important";
        switch (this.hasIdeal) {
            case true:
                val = "fa fa-flag-checkered fa-05x ";
                break;
            case false:
                val = "fa fa-flag-o fa-05x";
                break;
        }

        return val;
    };

    /**
     * Checks the status of the payment and returns the appropriate icon
     * @returns {string} val - The CSS class for the payed status icon
     */
    this.IsPayedIcon = function () {
        let val = "fa fa-asterisk fa-05x not-important"; // Default icon
        // Switch statement to determine the appropriate icon based on the payment status
        switch (this.status) {
            case 'new':
                val = "fa fa-money fa-05x ";
                break;
            case 'send':
                val = "fa fa fa-paper-plane green fa-05x";
                break;
            case 'payment':
                val = "fa fa-money green fa-05x";
                break;
            case 'overdue':
                val = "fa fa-money red fa-05x";
                break;
        }

        return val;
    };

    this.OfferteName = function () {
        return this.dbo['offertename'];
    };

    this.Amount = function () {
        return parseFloat(this.dbo['amount']);
    };

    this.Type = function () {
        return this.dbo['type'];
    };
    const prefix = 'invoice';


    this.ID = function () {
        return this.dbo['idinvoiceadresses'];
    };
    this.InvoiceNumber = function () {
        return this.dbo['invoicenr'];
    };

    //properties
    this.Companyname = function () {
        return this.dbo[prefix + '_companyname'];
    };


    this.Adress = function () {
        return `${this.dbo[prefix + '_street']} ${this.dbo[prefix + '_number']}`
    };

    this.Street = function () {
        return this.dbo[prefix + '_street'];
    };

    this.StreetNumber = function () {
        return this.dbo[prefix + '_number'];
    };

    this.AreaCode = function () {
        return this.dbo[prefix + '_areacode'];
    };

    this.City = function () {
        return this.dbo[prefix + '_city'];
    };

    this.State = function () {
        return this.dbo[prefix + '_state'];
    };

    this.Country = function () {
        return this.dbo[prefix + '_country'];
    };

    this.Email = function () {
        return this.dbo[prefix + '_email'];
    };

    this.Telephone = function () {
        var n = "1";
        return this.dbo[prefix + '_telephone' + n];
    };
    this.Telephone2 = function () {
        var n = "2";
        return this.dbo[prefix + '_telephone' + n];
    };

    this.GetFirstName = function () {
        if (this.IsCompany)
            return null;
        else {
            return this.dbo[prefix + '_surname'];
        }
    };

    this.GetLastName = function () {
        if (this.IsCompany)
            return null;
        else {
            var prep = this.dbo[prefix + '_preposition'];
            if (prep !== null && prep !== "")
                prep = prep + " ";
            else prep = '';

            return prep + this.dbo[prefix + '_lastname'];
        }
    };

    this.Preposition = function () {
        return this.dbo[prefix + '_preposition'];
    };


    this.JobID = function () {
        return this.dbo['idjob'] || this.dbo['idJob'] || this.dbo['jobid'];
    };

    this.JobNumber = function () {
        return this.dbo['jobnummer'];
    };

    this.idPayment = this.OfferteID();

    //PaymentAdress
    this.id = this.ID();
    this.idinvoiceadress = this.ID();
    this.idUser = this.IdUser();
    this.firstname = this.GetFirstName();
    this.prepostition = this.Preposition();
    this.lastname = this.GetLastName();
    this.adress = this.Adress();
    this.street = this.Street();
    this.streetnumber = this.StreetNumber();
    this.areacode = this.AreaCode();
    this.city = this.City();
    this.state = this.State();
    this.country = this.Country();
    this.email = this.Email();
    this.telephone = this.Telephone();
    this.telephone2 = this.Telephone2();
    this.companyname = this.Companyname();

    //TAB interface
    this.tabID = this.OfferteID();
    this.tabDescription = this.ClientName();

    //Client
    this.amount = this.Amount();
    this.status = this.Status();
    this.offertename = this.OfferteName();
    this.jobtitle = this.JobTitle();
    this.idofferte = this.OfferteID();
    this.idClient = this.ClientNumber();
    this.clientname = this.ClientName();
    this.username = this.UserName();
    this.duedate = this.DueDate();
    this.senddate = this.SendDate();
    this.creationdate = this.CreationDate();
    this.statusIcon = this.StatusIcon();

    this.idjob = this.JobID();
    this.id_order_FK = this.IdOrderFK();
    this.jobnumber = this.JobNumber();
    this.type = this.Type();
    this.paymentstatusIcon = this.IsPayedIcon();
    this.paymentypeicon = this.PaymentTypeIcon();

    this.hasIdeal = this.dbo['vox_payments_id'] !== null;
    this.hasIdealIcon = this.IDealTypeIcon();
    this.transactionid = this.dbo['vox_payments_id_transaction'];
    this.invoicenr = this.InvoiceNumber();
    this.toString = function () {
        var sFormat = "Offerte: {0} aangemaakt op {1} {2} ";
        var result = bob.string.formatString(sFormat, this.idofferte, new Date(this.creationdate).toLocaleDateString(), this.jobtitle !== null ? " voor klus " + this.jobnumber + ": " + this.jobtitle : "");
        return result;
    };
};

export default PaymentItem;

//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];