'use strict';

import {toUpper} from "lodash/string";

class OrderStatus {
    constructor(orderStatus) {
        if (orderStatus === undefined) {
            return;
        }

        this.dbo = orderStatus;
        const prefix = '';

        this.id = this.dbo[`${prefix}id`];
        this.name = this.dbo[`${prefix}name`];
        this.label = this.getLabel(this.name);
    }

    /**
     * Get the translation label
     * @param {string} name the label
     * @returns {string}
     */
    getLabel(name) {
        return toUpper(name);
    }
}

export default OrderStatus;
