import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        FOUND_AFTER_FILTER: 'gevonden, na filteren',
        SUM_AMOUNT:"Som bedrag"
    });

    const enTranslations = {
        FOUND_AFTER_FILTER: 'found, when filtered',
        SUM_AMOUNT: 'Sum amount'
    };

    $translateProvider.translations('en-us', enTranslations);
    $translateProvider.translations('en-gb', enTranslations);


    $translateProvider.preferredLanguage('nl-nl');
}]);