import app from "/src/app/app.module";
import moment from "moment";


app.controller('payments', function ($scope, settings, $location,
                                     msgBus,vxUtils,
                                     $payment, $filter, msgBusActions,
                                     enums, selectionFilters, $locale) {

    msgBus.onMsg(msgBusActions.overviewReload, function (sender, mssg) {
        if (mssg === 'payment')
            $scope.getItems($scope.lastdate);
    }, $scope);

    ////////OVERVIEW/////
    $scope.overviewCpi = {
        type: 'payment_item',
        openItemPage: "/src/pages/payments/payment-details.html",
        openItemIcon: "fa fa-eur",
        tabItems: $scope.openItems,
        filters: selectionFilters.paymentTypes,
        date: {startDate: moment().add(-29,'days'), endDate: moment()},
        dateChanged: function (newDate) {
            $scope.lastdate = newDate;
            $scope.getItems(newDate);
        },
        selectionChanged: function (data) {

            var fields = ['type','status'];
            $scope.overviewCpi.items = selectionFilters.filterBySelection(
                $scope.overviewCpi.filters,
                $scope.overviewCpi.all,
                fields
            );

            $scope.overviewCpi.reload();
        }
    };

    $scope.columns = [
        {title: '#', field: 'idofferte', visible: true, sortable: 'idofferte', style: {'width': '65px'}},
        {title: vxUtils.loc("JOBNUMBER"), field: 'jobnumber', visible: true, sortable: 'jobnumber', style: {'width': '65px'}},
        {
            title: vxUtils.loc("PAYMENT_KIND"), field: 'type',
            visible: true, filter: "paymentType", style: {'width': '50px'}
        },
        {
            title: '', altfield: 'type',
            altfilter: 'paymentType', field: 'paymentypeicon', visible: true, icon: true,
            style: {'width': '10px'},
            hover: function (item, column) {
                var val = '';
                if (item.type) {
                    column.tooltipobj = $filter('paymentType')(item.type);
                    column.tooltipopen = true;
                } else {
                    column.tooltipobj = val;

                }
            }, hastooltip: true,
        }, {
            title: '',
            name: vxUtils.loc("PAYMENTSTATE"),
            hover: function (item, column) {
                var val = '';
                if (item.status) {
                    column.tooltipobj = $filter('invoiceType')(item.status);
                    column.tooltipopen = true;
                } else {
                    column.tooltipobj = val;

                }
            },
            altfield: 'status',
            altfilter: 'invoiceType',
            field: 'paymentstatusIcon',
            hastooltip: true,
            visible: true, icon: true, style: {'width': '10px'}
        }, {
            title: '',
            name: vxUtils.loc('HAS_IDEAL'),
            hover: function (item, column) {
                var val = '';

                column.tooltipobj = item.hasIdeal ? vxUtils.loc('HAS_IDEAL') : vxUtils.loc('NO_IDEAL');
                column.tooltipopen = true;

            },
            altfield: 'hasIdeal',
            field: 'hasIdealIcon',
            hastooltip: true,
            visible: true, icon: true, style: {'width': '10px'}
        },

        {
            title: vxUtils.loc('CREATIONDATE'), field: 'creationdate', visible: true,
            filter: "date", args: $locale.DATETIME_FORMATS.shortDate, style: {'width': '100px'}
        },
        {title: vxUtils.loc('JOBTITLE'), field: 'jobtitle', visible: true},
        {title: vxUtils.loc('CUSTOMER_NAME'), field: 'clientname', visible: true},
        {title:  vxUtils.loc('AMOUNT'), field: 'amount', visible: true, filter: "currency", args: settings.currency},
        {title: vxUtils.loc('TRANSACTIONID'), field: 'transactionid', visible: true},
        {
            title:  vxUtils.loc('SENDDATE'),
            field: 'senddate',
            visible: true,
            filter: "date",
            args:  $locale.DATETIME_FORMATS.shortDate,
            style: {'width': '100px'}
        },
        {
            title: vxUtils.loc('DUEDATE'),
            field: 'duedate',
            visible: true,
            filter: "date",
            args:  $locale.DATETIME_FORMATS.shortDate,
            style: {'width': '100px'}
        }

    ];

    $scope.headerButtons = [
        {
            tooltip:  vxUtils.loc('EXPORT_TO_EXCEL'),
            icon: 'fa fa-file-excel-o',
            action: function () {
                var name = moment($scope.overviewCpi.date.startDate).format( $locale.DATETIME_FORMATS.shortDate) + ' - ' +
                    moment($scope.overviewCpi.date.endDate).format( $locale.DATETIME_FORMATS.shortDate);
                $scope.overviewCpi.export('Offertes en facturen ' + name, $scope.columns, $scope.overviewCpi.items);
            }
        },
        {
            tooltip:  vxUtils.loc('REFRESH'),
            icon: 'fa fa-refresh',
            action: function () {
                $scope.getItems($scope.lastdate);
            }
        }
    ];


    $scope.getItems = function (date) {
        $scope.lastdate = date;
        $payment.getAllPaymentsBetween(function (results) {
            $scope.overviewCpi.all = results;
            $scope.overviewCpi.selectionChanged(null);
        }, 0, date.startDate, date.endDate);
    };

    //  $scope.getItems();
    ////////OVERVIEW/////
});