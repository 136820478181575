class Location {
    constructor(location) {
        if (location === undefined) {
            return; // This effectively creates an incomplete object, consider throwing an error instead
        }
        this.dbo = location;
        this.prefix = 'loc';

        // Assigning the properties using the methods of the class
        this.id =this.ID();
        this.country = this.Country();
        this.areacode = this.AreaCode();
        this.streetnumber = this.StreetNumber();
        this.street = this.Street();
        this.state = this.State();
        this.city = this.City();
        this.adress=this.Address();
    }

    Address() {
        return `${this.dbo[this.prefix + '_street']} ${this.dbo[this.prefix + '_number']}`;
    }

    Street() {
        return this.dbo[this.prefix + '_street'];
    }

    StreetNumber() {
        return this.dbo[this.prefix + '_number'];
    }

    AreaCode() {
        return this.dbo[this.prefix + '_areacode'];
    }

    City() {
        return this.dbo[this.prefix + '_city'];
    }

    State() {
        return this.dbo[this.prefix + '_state'];
    }

    Country() {
        return this.dbo[this.prefix + '_country'];
    }

    ID() {
        return this.dbo['idLocation'];
    }

    /**
     *
     * @param wizard
     * @returns {Location}
     */
    static parseWizard(wizard) {
        if (!wizard) {
            throw new Error("Wizard object is required.");
        }

        return new Location({
            loc_street: wizard.street,
            loc_number: wizard.number,
            loc_areacode: wizard.areacode,
            loc_city: wizard.city,
            loc_state: wizard.state,
            loc_country: wizard.country,
        });
    }


}

export default Location;
