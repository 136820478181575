'use strict';

var MergeItem = function () {

    //this.name = null;
    // this.id_merge_item = 0;
    // this.field = null;
    //  this.merge_item = null;

    this.name = "";
    this.value = "";
    this.type = "";
};

export default MergeItem;