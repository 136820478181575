import app from "/src/app/app.module";
import Depot from "../models/depot";

var api = function ($resource, settings, $http, $filter, vox, $localStorage) {

    var convertToObj = function (values) {
        var temp = [];
        angular.forEach(values, function (value, key) {
            var obj = new Depot(value, $filter);
            //angular.extend(obj, value);
            this.push(obj);
        }, temp);
        return temp;
    };

    var getAllDepots = function (callback, force) {
        if ($localStorage.allDepots !== undefined && !force) {
            var out = $localStorage.allDepots;

                out = convertToObj(out);

            if (callback)
                callback(out);
        }
        vox.get(settings.BASE_API_URL + 'v1/depot/all', function (result) {
            var out = result;

                out = convertToObj(out);

            $localStorage.allDepots = result;
            if (callback)
                callback(out);
        });
    };

    var deleteDepot = function (callback, id) {
        vox.delete(settings.BASE_API_URL + 'v1/depot/' + id, callback);
    };

    var getDepot = function (callback, id) {
        vox.get(settings.BASE_API_URL + 'v1/depot/' + id,
            function (data, status, headers, config) {
                var out = convertToObj(data);
                callback(out[0]);
            });
    };

    var postDepot = function (callback, depot) {
        vox.post(settings.BASE_API_URL + 'v1/depot', depot,
            function (data, status, headers, config) {
                var out = convertToObj(data);
                callback(out[0]);
            });
    };

    return {
        getAllDepots: getAllDepots,
        deleteDepot: deleteDepot,
        getDepot: getDepot,
        postDepot: postDepot
    };
};

app.factory("$depot", api);