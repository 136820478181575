import app from "/src/app/app.module";

app.controller('availabiltypanel', function ($scope, vxUtils) {
    var vm = this;
    vm.pageHash = vxUtils.findObjectOnParents($scope, 'PageHash');
    vm.init = function (data, filter) {

    }


})
;