

var User = function (user) {

    if (user === undefined) {
        return;
    }

    this.dbo = user;

    var prefix = "ctd";

    this.UserID = function () {
        var result = parseInt(this.dbo['idUser']);
        if (isNaN(result)) {
            result = parseInt(this.dbo['id_user']);
        }
        return result;
    };

    this.JobCrewID = function () {
        return parseInt(this.dbo['idJobcrew']);
    };

    this.JobID = function () {
        return parseInt(this.dbo['id_job']);
    };
    this.GetHourRate = function () {
        return parseInt(this.dbo['hour_type_id']);
    };

    this.DetailID = function () {
        return parseInt(this.dbo['idContactdetail']);
    }


    //properties
    this.Name = function () {

        var prep = this.dbo['ctd_preposition'];
        if (prep !== "" && prep !== null)
            prep = " " + prep;
        else {
            prep ='';
        }

        return this.dbo['ctd_surname'] + prep + " " + this.dbo['ctd_lastname'];

    };

    this.Adress = function () {
        return `${this.dbo[prefix + '_street']} ${this.dbo[prefix + '_number']}`
    };

    this.Street = function () {
        return this.dbo[prefix + '_street'];
    };

    this.StreetNumber = function () {
        return this.dbo[prefix + '_number'];
    };

    this.AreaCode = function () {
        return this.dbo[prefix + '_areacode'];
    };

    this.City = function () {
        return this.dbo[prefix + '_city'];
    };

    this.State = function () {
        return this.dbo[prefix + '_state'];
    };

    this.Country = function () {
        return this.dbo[prefix + '_country'];
    };

    this.Email = function () {
        return this.dbo[prefix + '_email'];
    };

    this.Telephone1 = function () {
        return this.dbo[prefix + '_telephone1'];
    };
    this.Telephone2 = function () {
        return this.dbo[prefix + '_telephone2'];
    };

    this.Picture = function () {
        return this.dbo['picture'];
    };

    this.GetFirstName = function () {
        return this.dbo['ctd_surname'];
    };

    this.GetLastName = function () {
        var prep = this.dbo['ctd_preposition'];
        if (prep !== "" && prep !== null)
            prep = prep + " ";
        else {
            prep ='';
        }
        return prep + this.dbo['ctd_lastname'];
    };

    this.Type = function () {
        return this.dbo['type'];
    };

    this.GetHours = function () {
        var value = (parseFloat(this.dbo['hours'])).toFixed(2);
        return isNaN(value) ? 0 : value;
    };

    this.GetAmountJobs = function () {
        return parseFloat(this.dbo['worked_jobs']);
    };

    this.GetTravelKm = function () {
        var value = parseFloat(this.dbo['travelkms']);
        return isNaN(value) ? 0 : value;
    };

    this.GetTravelTime = function () {
        var value = Math.ceil(parseFloat(this.dbo['traveltime']));
        return isNaN(value) ? 0 : value;
    };

    this.GetExpenses = function () {
        return parseFloat(this.dbo['expenses']);
    };

    this.IsDisabled = function () {
        return parseInt(this.dbo['isdisabled']);
    };

    this.GetBirthday = function () {
        var value = parseInt(this.dbo['ctd_birthdate'] + "000");
        return isNaN(value) ? 0 : value;
    };

    this.EntranceTime = function () {
        var value = parseInt(this.dbo['entrancetime'] + "000");
        return isNaN(value) ? 0 : value;
    };
    this.StartTime = function () {
        var value = parseInt(this.dbo['starttime'] + "000");
        return isNaN(value) ? 0 : value;
    };
    this.EndTime = function () {
        var value = parseInt(this.dbo['endtime'] + "000");
        return isNaN(value) ? 0 : value;
    };

    this.Payout = function () {
        var value = (parseInt(this.dbo['vox_hour_rates_amount'])/100*this.hours);
        return isNaN(value) ? 0 : value;
    };

    this.GetDateOfHire = function () {
        return parseInt(this.dbo['hired_date'] + "000");
    };

    this.GetJobcrewCreationDate = function () {
        return parseInt(this.dbo['creationdate'] + "000");
    };
    this.GetJobcrewResponseDate = function () {
        return parseInt(this.dbo['responsedate'] + "000");
    };

    this.Status = function () {
        return parseInt(this.dbo['status']);
    };

    this.Experience = function () {
        return parseInt(this.dbo['experience']);
    };

    //ICONS
    this.CustomerTypIcon = function () {

        var val = "fa fa-building-o";

        switch (this.IsCompany) {
            case true:
                val = "fa fa-building-o";
                break;
            case false:
                val = "glyphicon glyphicon-user";
        }
        return val;
    };


    this.Preposition = function () {
        return this.dbo['ctd_preposition'];
    };

    this.GroupName = function () {
        return this.dbo['groupname'];
    };

    this.UserLevel = function () {
        return parseInt(this.dbo['user_level']);
    };

    this.GroupID = function () {
        return parseInt(this.dbo['id_group']);
    };

    this.Car = function () {
        return parseInt(this.dbo['car']);
    };

    this.License = function () {
        return parseInt(this.dbo['license']);
    };

    this.HasEventsToday = function () {
        return parseInt(this.dbo['hasEventsToday']);
    };

    this.OtherTime =function(){
      return parseInt(this.dbo['othertime'])
    };

    this.UpdateProfilePicture = function (){
      return  `/api/assets/picture/${this.idUser}?q=${Date.now()}`
    }

    //TAB interface
    this.tabID = this.UserID();
    this.tabDescription = this.Name();

    //Itemtemplate
    this.type = this.Type();

    this.hasevents = this.HasEventsToday();

    //Client
    this.idUser = this.UserID();
    this.iddetail = this.DetailID();
    this.isdisabled = this.IsDisabled();
    this.customericon = this.CustomerTypIcon();
    this.id_user = this.UserID();
    this.idGroup = this.GroupID();
    //JobCrew

    this.idjobcrew = this.JobCrewID();
    this.idjob = this.JobID();
    this.creationdate = this.GetJobcrewCreationDate();
    this.responsedate = this.GetJobcrewResponseDate();
    this.entrancetime = this.EntranceTime();
    this.starttime = this.StartTime();
    this.endtime = this.EndTime();
    this.othertime = this.OtherTime();
    this.hour_rate_id = this.GetHourRate();

    //Contactdata
    this.firstname = this.GetFirstName();
    this.prepostition = this.Preposition();
    this.lastname = this.GetLastName();
    this.adress = this.Adress();
    this.street = this.Street();
    this.streetnumber = this.StreetNumber();
    this.areacode = this.AreaCode();
    this.city = this.City();
    this.state = this.State();
    this.country = this.Country();
    this.email = this.Email();
    this.telephone1 = this.Telephone1();
    this.telephone2 = this.Telephone2();
    this.events = [];
    this.name = this.Name();
    this.birthday = this.GetBirthday();
    this.hireddate = this.GetDateOfHire();
    this.picture = this.Picture();
    this.status = this.Status();
    this.experience = this.Experience();
    this.groupname = this.GroupName();
    this.hours = this.GetHours();
    this.amountjobs = this.GetAmountJobs();
    this.traveltime = this.GetTravelTime();
    this.travelkm = this.GetTravelKm();
    this.expenses = this.GetExpenses();
    this.level = this.UserLevel();
    this.car = this.Car();
    this.license = this.License();

    this.payout =this.Payout();

    this.userPictureUrl = `/api/assets/picture/${this.idUser}`
};

export default User;

//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];