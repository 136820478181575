import MergeItem from "../models/merge-item";

var voxponents = angular.module('Voxponents', [])

voxponents.directive('wavesEffect', function () {
    return {
        restrict: 'C', // This directive can be used as a class
        link: function (scope, elements, attrs) {

            const element = elements[0];
            if (!element)
                return;
            // Add the required class
            //element.addClass('waves-effect');

            // Initiate event listeners

            // Touch events (for mobile devices)
            if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
                elements.on('touchstart', function (event) {
                    window.Waves.show(event.originalEvent || event, element);
                });
                elements.on('touchend', function (event) {
                    window.Waves.hide(event.originalEvent || event, element);
                });
            }

            // Mouse events (for desktop)
            elements.on('mousedown', function (event) {
                window.Waves.show(event, element);
            });
            elements.on('mouseup', function (event) {
                window.Waves.hide(event, element);
            });
            elements.on('mouseleave', function (event) {
                window.Waves.hide(event, element);
            });

            // Clean up event listeners when the directive is destroyed
            scope.$on('$destroy', function () {
                elements.off('touchstart touchend mousedown mouseup mouseleave');
            });
        }
    };
});

voxponents.directive('vxFloatInput', function () {


    return {
        restrict: 'E',
        // declare the directive scope as private (and empty)
        scope: {
            text: '=',
            label: '@',
            type: '@'
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/directives/vxFloatInput/vxFloatInput.html',
        link: function (scope, iElement, iAttrs) {


        }
    };


});
voxponents.directive('vxFloatTextArea', function () {


    return {
        restrict: 'E',
        // declare the directive scope as private (and empty)
        scope: {
            text: '=',
            label: '@',
            type: '@'
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/directives/vxFloatInput/vxFloatTextArea.html',
        link: function (scope, iElement, iAttrs) {


        }
    };


});
voxponents.directive('setSize', function ($window, $timeout) {
    return {
        restrict: 'A',
        scope: {
            setSize: '&'
        },
        link: function (scope, elem, attr) {
            var doWork = function () {
                var timer = $timeout(function () {
                    var obj = scope.setSize();
                    elem.css(obj);
                }, 100);

                scope.$on(
                    "$destroy",
                    function (event) {
                        $timeout.cancel(timer);
                    }
                );
            };

            scope.$watch(function () {
                return $window.innerHeight;
            }, function (value) {
                doWork();
            });
            doWork();
        }
    }
});
voxponents.directive('vxTimeLine', function ($filter, msgBusActions, $users, $customer,
                                             vxUtils, msgBus, toastr, $email, $locale) {
    return {
        restrict: 'E',
        // declare the directive scope as private (and empty)
        scope: {
            events: '=',
            panelsize: '=',
            headsize: '=',
            cpi: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/directives/vxTimeLine/vxTimeLine.html',
        link: function (scope, iElement, iAttrs) {
            scope.ic = scope.cpi || {};
            scope.time = iAttrs.date !== undefined;
            scope.none = iAttrs.readonly !== undefined;

            scope.deleteTooltip = $filter('translate')('DELETE');

            scope.resize = function () {
                var i = (scope.panelsize.height - scope.headsize.height - 95);
                msgBus.emitMsg("UpdateScroll", {});
                return i;
            };


            scope.delete = function (event) {
                switch (event.type) {
                    case 'note':
                    case 'incoming':
                    case 'outgoing':
                    case 'note_quote':
                    case 'note_cancellation':
                    case 'note_payment':
                    case 'planned':
                        $customer.deleteEvent(function (result) {
                            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('NOTE') + vxUtils.loc('ACTION_DELETED'));

                        }, event.id, event.type);
                        break;
                    case 'email':
                        $email.deleteEmail(event.id, function (result) {
                            toastr.success(vxUtils.loc('SUCCES'), vxUtils('EMAIL_ATTACHMENTS') + vxUtils.loc('ACTION_DELETED'));
                        }, function (result) {
                            toastr.error(vxUtils.loc('UNSUCCESFULL'), vxUtils('EMAIL_ATTACHMENTS') + vxUtils.loc('NOT') + vxUtils.loc('ACTION_DELETED'));
                        });
                        break;
                    case "availability":
                        $users.deleteUserEvent(event.id, function (result) {
                            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('AVAILABILITY') + vxUtils.loc('ACTION_DELETED'));
                        }, function (result) {
                            toastr.error(vxUtils.loc('UNSUCCESFULL'), vxUtils.loc('AVAILABILITY') + vxUtils.loc('NOT') + vxUtils.loc('ACTION_DELETED'));
                        });
                        break;
                }
                angular.forEach(scope.events, function (value, key) {
                        if (event.idjob) {
                            event.id = event.idjob;
                        }
                        if (value.idjob) {
                            value.id = value.idjob;
                        }

                        if (event.id === value.id && event.id !== undefined && value.id !== undefined) {
                            console.log('Really deleting because ' + event.id + ' == ' + value.id + ' key: ' + key, scope.events);
                            scope.events.splice(key, 1);
                        }
                    }
                );
                scope.$apply();
            };
            scope.ic.delete = scope.delete;

            scope.openJob = function (event) {
                msgBus.emitMsg(msgBusActions.shellOpenTabByType, {
                    type: 'job', id: event.jobnumber
                });
            };

            scope.ic.refresh = function () {
                scope.ic.selectionChanged();
            };

            scope.ic.selectionChanged = function () {
                if (scope.ic.fields === undefined) {
                    scope.ic.fields = [{
                        field: 'status', expression: function (value) {
                            return {
                                type: value.state
                            };
                        }
                    }];
                }
                var items = [];
                var basket = scope.ic.all;
                angular.forEach(scope.ic.fields, function (v, k) {
                    var res = $filter('filter')(scope.ic.filters,
                        {ticked: true, field: v.field});


                    angular.forEach(res, function (value, key) {
                        var jobs = $filter('filter')(basket,
                            v.expression(value), true);

                        angular.forEach(jobs, function (valuea, keya) {
                            items.push(valuea);
                        })

                    });
                    basket = items;
                    items = [];
                });
                scope.ic.items = $filter('orderBy')(basket, 'time', true);
                msgBus.emitMsg('UpdateScroll', {});

            }
        }
    };


});
voxponents.directive('vxInputLabel', function () {


    return {
        restrict: 'E',
        // declare the directive scope as private (and empty)
        scope: {
            text: '=',
            label: '@',
            type: '@'

        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/directives/vxInputLabel/vxInputLabel.html',
        link: function (scope, iElement, iAttrs) {

            scope.editmode = false;
            scope.show = function (parm) {
                console.log(parm);
            };

        }
    };


});
voxponents.directive('focusOn', function () {
    return function (scope, elem, attr) {
        scope.$on('focusOn', function (e, name) {
            if (name === attr.focusOn) {
                elem[0].focus();
            }
        });
    };
});
voxponents.factory('$focus', function ($rootScope, $timeout) {
    return function (name) {
        var timer = $timeout(function () {
            $rootScope.$broadcast('focusOn', name);
        });
        $rootScope.$on(
            "$destroy",
            function (event) {
                $timeout.cancel(timer);
            }
        );
    }
});
voxponents.directive('offClick', ['$document', function ($document) {

    function targetInFilter(target, filter) {
        if (!target || !filter) return false;
        var elms = angular.element(filter);
        var elmsLen = elms.length;
        for (var i = 0; i < elmsLen; ++i)
            if (elms[i].contains(target)) return true;
        return false;
    }

    return {
        restrict: 'A',
        scope: {
            offClick: '&',
            offClickIf: '&'
        },
        link: function (scope, elm, attr) {

            if (attr.offClickIf) {
                scope.$watch(scope.offClickIf, function (newVal, oldVal) {
                        if (newVal && !oldVal) {
                            $document.on('click', handler);
                        } else if (!newVal) {
                            $document.off('click', handler);
                        }
                    }
                );
            } else {
                $document.on('click', handler);
            }

            function handler(event) {
                var target = event.target || event.srcElement;
                if (!(elm[0].contains(target) || targetInFilter(target, attr.offClickFilter))) {
                    scope.$apply(scope.offClick());
                }
            }
        }
    };
}]);
voxponents.directive('vxWizard', function (vxUtils, msgBus, toastr) {


    return {
        restrict: 'E',
        // declare the directive scope as private (and empty)
        scope: {
            steps: '=',
            hash: '='

        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/directives/vxWizard/vxWizard.html',
        link: function (scope, iElement, iAttrs) {
            var currentstep = 0;
            scope.wizard = {};
            scope.hash = vxUtils.generateQuickGuid();


            msgBus.onMsg('valForm', function (e, r) {
                var pagehash = vxUtils.findObjectOnParents(r.scope, 'hash');
                if (pagehash === scope.hash)
                    scope.selectedstep.valid = r.isValid;
            }, scope);

            scope.checkIfValid = function () {
                console.log(scope.hash);
                var e = $("#" + scope.hash, iElement);
                var foundScope = e.scope();
                var childScope = foundScope.$$childHead;
                var form = typeof childScope.currentForm == 'function' ? childScope.currentForm() : undefined;

                if (form === undefined) {
                    return true;
                }

                console.log(form.$invalid);
                return !form.$invalid;
            };

            scope.selectedChanged = function (step, index) {

                if (!step.valid && index > 0 && !scope.steps[index - 1].valid) {
                    toastr.warning(vxUtils.loc('FILLFORMCORRECT'), vxUtils.loc('UNSUCCESFULL'));
                    return;
                }

                if (scope.selectedstep !== undefined)
                    scope.steps[scope.steps.indexOf(scope.selectedstep)].active = false;

                step.active = true;
                scope.selectedstep = step;
            };

            scope.nextStep = function () {
                // angular.element('#wizardContent').scope().invalid;

                if (!scope.checkIfValid()) {
                    alert("niet valide");
                    return;
                }
                var index = 0;
                if (scope.selectedstep !== undefined)
                    index = scope.steps.indexOf(scope.selectedstep) + 1;
                scope.selectedstep.active = false;
                scope.selectedstep = scope.steps[index];
                scope.selectedstep.active = true;
            };

            scope.previousStep = function () {
                var index = 0;
                if (scope.selectedstep !== undefined)
                    index = scope.steps.indexOf(scope.selectedstep) - 1;

                scope.selectedstep.active = false;
                scope.selectedstep = scope.steps[index];
                scope.selectedstep.active = true;
            };

            scope.finish = function () {
                if (!scope.checkIfValid()) {
                    alert("niet valide");
                    return;
                }
                scope.$parent.finish(scope.wizard);
            };

            setTimeout(function () {
                scope.selectedstep = scope.steps[currentstep];
                scope.selectedstep.active = true;
                //  $scope.openItems.push($scope.menuitems[3]);
            }, 100);
        }
    };

});

voxponents.directive('valBubble', function (formHelper) {
    return {
        require: 'ngModel',
        restrict: "A",
        link: function (scope, element, attr, ctrl) {

            if (!attr.name) {
                throw "valBubble must be set on an input element that has a 'name' attribute";
            }

            var currentForm = formHelper.getCurrentForm(scope);
            if (!currentForm || !currentForm.$name)
                throw "valBubble requires that a name is assigned to the ng-form containing the validated input";

            //watch the current form's validation for the current field name
            scope.$watch(currentForm.$name + "." + ctrl.$name + ".$valid", function (isValid, lastValue) {
                if (isValid != undefined) {
                    //emit an event upwards
                    scope.$emit("valBubble", {
                        isValid: isValid,       // if the field is valid
                        element: element,       // the element that the validation applies to
                        expression: this.exp,   // the expression that was watched to check validity
                        scope: scope,           // the current scope
                        ctrl: ctrl              // the current controller
                    });
                }
            });
        }
    };
});
voxponents.directive('vxCollapseToggle', function (msgBus, $parse) {
    return {
        restrict: "A",
        link: function (scope, element, attrs, ctrl) {
            var vxCollapse = scope.$eval(attrs.vxCollapseToggle);


            element.on('click', function (event) {
                msgBus.emitMsg('collapse', vxCollapse);

            });

        }
    }
});
voxponents.directive('vxCollapse', function (msgBus, $timeout) {
    return {
        restrict: "A",
        link: function (scope, element, attrs, ctrl) {
            var vxCollapse = scope.$eval(attrs.vxCollapse);
            var vxHeight = scope.$eval(attrs.vxHeight);
            var originalHeight = element.height();
            var open = true;

            // element.css({'height': vxHeight});

            var collapse = function () {
                var newHeight = open ? vxHeight : originalHeight;

                element.velocity({height: newHeight}, {
                    complete: function () {
                        open = !open;
                    }
                }, 'ease');
            };

            msgBus.onMsg('collapse', function (m, e) {
                if (e !== vxCollapse)
                    return;

                collapse();
            }, scope);

            $timeout(function () {
                originalHeight = element.height();
                collapse();
            }, 1000);
        }
    }
});

voxponents.directive('valForm', function (formHelper) {
    return {
        require: '',
        restrict: "A",
        link: function (scope, element, attr, ctrl) {

            if (!attr.name) {
                throw "valForm must be set on an form element that has a 'name' attribute";
            }
            var formname = attr.name;
            var currentForm = scope[formname];
            if (!currentForm || !currentForm.$name)
                throw "valBubble requires that a name is assigned to the ng-form containing the validated input";
            var self = this;
            //watch the current form's validation for the current field name
            scope.$watch(currentForm.$name + ".$valid", (isValid, lastValue) => {
                if (isValid != undefined) {
                    //emit an event upwards
                    scope.$emit("valForm", {
                        isValid: isValid,       // if the field is valid
                        element: element,       // the element that the validation applies to
                        expression: self.exp,   // the expression that was watched to check validity
                        scope: scope,           // the current scope
                        ctrl: ctrl,
                        form: currentForm         // the current controller
                    });
                }
            });
        }
    };
});

voxponents.factory('formHelper', function () {
    return {
        getCurrentForm: function (scope) {
            var form = null;
            var requiredFormProps = ["$error", "$name", "$dirty", "$pristine", "$valid", "$invalid", "$addControl", "$removeControl", "$setValidity", "$setDirty"];
            for (var p in scope) {
                if (_.isObject(scope[p]) && !_.isFunction(scope[p]) && !_.isArray(scope[p]) && p.substr(0, 1) != "$") {
                    var props = _.keys(scope[p]);
                    if (props.length < requiredFormProps.length) continue;
                    if (_.every(requiredFormProps, function (item) {
                        return _.contains(props, item);
                    })) {
                        form = scope[p];
                        break;
                    }
                }
            }
            return form;
        }
    };
});


voxponents.directive('elSize', ['$parse', function ($parse) {
    return function (scope, elem, attrs) {
        var fn = $parse(attrs.elSize);

        scope.$watch(function () {
            return {width: elem.width(), height: elem.height()};
        }, function (size) {
            fn.assign(scope, size);
        }, true);

    }
}]);

voxponents.service("vxMailMerge", function (vxMailMergeLists, $filter, vxUtils,settings) {

    var fillTemplateFromObject = function (object, text) {
        var decoded = he.decode(text);
        var templateVars = decoded.match(/\$\{\"[^\"]+\"\}/g);

        if (templateVars == null)
            return decoded;

        for (var i = 0; i < templateVars.length; i++) {
            var prop = templateVars[i].replace('${"', '').replace('"}', '');
            var variableData = $filter('filter')(object, {name: prop});
            decoded = decoded.replace(templateVars[i], variableData.length > 0 ? variableData[0].value : "");
        }

        return decoded;
    };

    var getMailMergeItems = function () {

        var list = [
            {merge_item: "invSurname", name: "INV_FIRSTNAME"},
            {merge_item: "invPreposition", name: "INV_PREPOSITION"},
            {merge_item: "invLastname", name: "INV_LASTNAME"},
            {merge_item: "invStreet", name: "INV_STREET"},
            {merge_item: "invNumber", name: "INV_STREETNUMBER"},
            {merge_item: "invAreacode", name: "INV_AREACODE"},
            {merge_item: "invState", name: "INV_STATE"},
            {merge_item: "invCity", name: "INV_CITY"},
            {merge_item: "invCountry", name: "INV_COUNTRY"},
            {merge_item: "invCompanyName", name: "INV_COMPANYNAME"},

            {merge_item: "usrSurname", name: "USR_FIRSTNAME"},
            {merge_item: "usrPreposition", name: "USR_PREPOSITION"},
            {merge_item: "usrLastname", name: "USR_LASTNAME"},
            {merge_item: "usrStreet", name: "USR_STREET"},
            {merge_item: "usrNumber", name: "USR_STREETNUMBER"},
            {merge_item: "usrAreacode", name: "USR_AREACODE"},
            {merge_item: "usrCity", name: "USR_CITY"},
            {merge_item: "usrState", name: "USR_STATE"},
            {merge_item: "usrCountry", name: "USR_COUNTRY"},
            {merge_item: "usrEmail", name: "USR_EMAIL"},
            {merge_item: "usrTel", name: "USR_TELEPHONE"},
            {merge_item: "usrBirthday", name: "USR_BIRTHDAY"},
            {merge_item: "usrEmploymentDate", name: "USR_DATEOFHIRE"},
            {merge_item: "usrPassResetUrl", name: "USR_PASSRESETURL"},

            {merge_item: "jobDate", name: "JOB_STARTDATE"},
            {merge_item: "jobDateLong", name: "JOB_STARTDATE_LONG"},
            {merge_item: "jobTime", name: "JOB_TIME"},
            {merge_item: "jobTitle", name: "JOB_TITLE"},
            {merge_item: "jobNumber", name: "JOB_NUMBER"},
            {merge_item: "jobStatus", name: "JOB_STATE"},
            {merge_item: "jobKind", name: "JOB_KINDOFPARTY"},
            {merge_item: "jobGuestCount", name: "JOB_AMOUNTGUESTS"},
            {merge_item: "jobEmplCount", name: "JOB_AMOUNTEMPLOYEES"},
            {merge_item: "jobDescription", name: "JOB_INTERNALREMARKS"},
            {merge_item: "jobRemarks", name: "JOB_EXTERNALREMARKS"},

            {merge_item: "locLocation", name: "LOC_LOCATION"},
            {merge_item: "locStreet", name: "LOC_STREET"},
            {merge_item: "locNumber", name: "LOC_STREETNUMBER"},
            {merge_item: "locAreacode", name: "LOC_AREACODE"},
            {merge_item: "locCity", name: "LOC_CITY"},
            {merge_item: "locState", name: "LOC_STATE"},
            {merge_item: "locCountry", name: "LOC_COUNTRY"},

            {merge_item: "ctdSurname", name: "CTD_FIRSTNAME"},
            {merge_item: "ctdPreposition", name: "CTD_PREPOSITION"},
            {merge_item: "ctdLastname", name: "CTD_LASTNAME"},
            {merge_item: "ctdStreet", name: "CTD_STREET"},
            {merge_item: "ctdNumber", name: "CTD_STREETNUMBER"},
            {merge_item: "ctdAreacode", name: "CTD_AREACODE"},
            {merge_item: "ctdCity", name: "CTD_CITY"},
            {merge_item: "ctdCountry", name: "CTD_COUNTRY"},
            {merge_item: "ctdState", name: "CTD_STATE"},
            {merge_item: "ctdEmail", name: "CTD_EMAIL"},
            {merge_item: "ctdTel", name: "CTD_TEL"},
            {merge_item: "ctdCompanyName", name: "CTD_COMPANYNAME"},
            {merge_item: "ctdKVK", name: "CTD_KVK"},
            {merge_item: "ctdBTW", name: "CTD_BTW"},

            //  {merge_item: "paymentJobId", name: "PAYMENT_JOBID"},
            {merge_item: "paymentNumber", name: "PAYMENT_NR"},
            {merge_item: "paymentSendDate", name: "PAYMENT_SENDDATE"},
            {merge_item: "paymentCreateDate", name: "PAYMENT_CREATIONDATE"},
            {merge_item: "paymentDueDate", name: "PAYMENT_DUEDATE"},
            {merge_item: "paymentState", name: "PAYMENT_STATUS"},
            {merge_item: "paymentType", name: "PAYMENT_TYPE"},

        ];

        for (var i = 0; i < list.length; i++) {
            list[i].merge_item = '${"' + list[i].merge_item + '"}';
            list[i].name = vxUtils.loc(list[i].name);
        }
        return list;

    };
    //"Reiskosten Oesterman|€|2,25|x|300|=|€|500,00"
    var createProductList = function (products) {
        var output = [];

        var width = new MergeItem();
        width.value = "150|15|60|15|60|15|15|70";
        width.type = "table-width";
        output.push(width);
        var total = 0;
        angular.forEach(products, function (v, key) {
            var item = new MergeItem();
            item.type = "table-row";
            var sFormat = `{0}|${settings.currency}|{1}|x|{2}|=|${settings.currency}|{3}`;
            var subtotal = v.productquantity * v.productprice;
            item.value = bob.string.formatString(sFormat,
                v.productname,
                $filter('currency')(v.productprice, settings.currency).replace(settings.currency, ''),
                v.productquantity,
                $filter('currency')(subtotal, settings.currency).replace(settings.currency, '')
            );
            total += subtotal;
            output.push(item);
        });

        var item = new MergeItem();
        item.type = "table-total";
        var sFormat = `||||${vxUtils.loc('TOTAL_WITHOUT_VAT')}|=|${settings.currency}|{0}`;
        item.value = bob.string.formatString(sFormat,
            $filter('currency')(total, settings.currency).replace(settings.currency, '')
        );
        output.push(item);

        return output;
    };

    var createMailMergeList = function (object, type) {
        var list = vxMailMergeLists.getMergeList(type);
        var item = object;
        if (item === undefined)
            return;

        angular.forEach(list, function (value, key) {
            var found = value.value.split(" && ");
            if (found.length > 1) {
                for (var i = 0; i < found.length; i++) {
                    if (i === 0)
                        value.value = '';

                    var result = item[found[i]] ? item[found[i]] : '';

                    if (value.filter !== undefined)
                        result = $filter(value.filter.which)(result, value.filter.params);

                    value.value += result;

                    if (i !== found.length - 1)
                        value.value += value.separator;
                }
            } else {
                var result = item[value.value] ? item[value.value] : '';

                if (value.filter !== undefined)
                    result = $filter(value.filter.which)(result, value.filter.params);

                value.value = result;
            }

        });

        return list;
    };

    return {
        fillTemplateFromObject: fillTemplateFromObject,
        getMailMergeItems: getMailMergeItems,
        createMailMergeList: createMailMergeList,
        createProductList: createProductList
    };
});

voxponents.service("vxMailMergeLists", function ($locale) {

    var getMergeList = function (type) {
        var output = [];

        switch (type) {
            case 'job':
                output = mergeListJob();
                break;
            case 'customer':
                output = mergeListCustomer();
                break;
            case 'user':
                output = mergeListUser();
                break;
            case 'payment':
                output = mergeListPayment();
                break;
        }

        return output;
    };

    var mergeListPayment = function () {
        var output = [
            {name: "invSurname", value: "firstname"},
            {name: "invPreposition", value: "prepostition"},
            {name: "invLastname", value: "lastname"},
            {name: "invStreet", value: "street"},
            {name: "invNumber", value: "streetnumber"},
            {name: "invAreacode", value: "areacode"},
            {name: "invCity", value: "city"},
            {name: "invState", value: "state"},
            {name: "invCountry", value: "country"},
            {name: "invCompanyName", value: "name"}
        ];
        return output;
    };

    var mergeListUser = function () {
        var output = [
            {name: "usrSurname", value: "firstname"},
            {name: "usrPreposition", value: "prepostition"},
            {name: "usrLastname", value: "lastname"},
            {name: "usrStreet", value: "street"},
            {name: "usrNumber", value: "streetnumber"},
            {name: "usrAreacode", value: "areacode"},
            {name: "usrCity", value: "city"},
            {name: "usrState", value: "state"},
            {name: "usrCountry", value: "country"},
            {name: "usrEmail", value: "email"},
            {name: "usrTel", value: "telephone"},
            {name: "usrBirthday", value: "birthday"},
            {name: "usrEmploymentDate", value: "hireddate"},
            {name: "usrFullName", value: "name"}
        ];
        return output;
    };

    var mergeListJob = function () {
        var output = [
            {
                name: "jobDate", value: "startdate",
                filter: {which: 'date', params: 'shortDate'}
            },
            {
                name: "jobDateLong", value: "startdate",
                filter: {which: 'date', params: 'longDate'}
            },
            {
                name: "jobTime", value: "startdate && enddate", separator: ' - ',
                filter: {which: 'date', params: $locale.DATETIME_FORMATS.shortTime}
            },
            {name: "jobTitle", value: "jobtitle"},
            {
                name: "jobEntrance", value: "entrance",
                filter: {which: 'date', params: $locale.DATETIME_FORMATS.shortTime}
            },
            {name: "jobStatus", value: "status"},
            {name: "jobKind", value: "kindofjob"},
            {name: "jobGuestCount", value: "guestcount"},
            {name: "jobEmplCount", value: "crewslots"},
            {name: "jobDescription", value: ""},
            {name: "jobRemarks", value: ""},
            {name: "locLocation", value: "spot"},
            {name: "locStreet", value: "street"},
            {name: "locNumber", value: "streetnumber"},
            {name: "locAreacode", value: "areacode"},
            {name: "locCity", value: "city"},
            {name: "locState", value: "state"},
            {name: "locCountry", value: "country"}
        ];

        return output;
    };
    var mergeListCustomer = function () {
        var output = [
            {name: "ctdSurname", value: "firstname"},
            {name: "ctdPreposition", value: "prepostition"},
            {name: "ctdLastname", value: "lastname"},
            {name: "ctdStreet", value: "street"},
            {name: "ctdNumber", value: "streetnumber"},
            {name: "ctdAreacode", value: "areacode"},
            {name: "ctdCity", value: "city"},
            {name: "ctdCountry", value: "country"},
            {name: "ctdState", value: "state"},
            {name: "ctdEmail", value: "email"},
            {name: "ctdTel", value: "telephone"},
            {name: "ctdCompanyName", value: "name"}
        ];

        return output;
    };
    return {
        getMergeList: getMergeList
    };
});

voxponents.directive('niceScroll', function (msgBus, $window) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            var nicescrolConf = {
                "cursorcolor": "#bdbdbd",
                "background": "#ffffff",
                "cursorwidth": "7px",
                "cursorborder": "none",
                "cursorborderradius": "2px",
                "zindex": 9998,
                "autohidemode": true,
                "bouncescroll": true,
                "mousescrollstep": 20
            };

            var disabled = $window.innerWidth < 750 ? true : false;

            function init() {
                scope.nicescroll = $(element).niceScroll(nicescrolConf);
                scope.nicescroll.onscrollend = function (data) {
                    if (data.end.y >= this.page.maxh) {
                        if (attrs.niceScrollEnd) scope.$evalAsync(attrs.niceScrollEnd);

                    }
                    if (data.end.y <= 0) {
                        // at top
                        if (attrs.niceScrollTopEnd) scope.$evalAsync(attrs.niceScrollTopEnd);
                    }
                };
            }

            angular.element($window).bind('resize', function () {
                if ($window.innerWidth < 750) {
                    if (angular.isDefined(scope.nicescroll) && disabled === false) {
                        scope.nicescroll.remove();
                        scope.nicescroll = undefined;
                        disabled = true;
                    }
                } else {
                    if (!angular.isDefined(scope.nicescroll) && disabled === true) {
                        init();
                        disabled = false;
                    }
                }
            });

            msgBus.onMsg("UpdateScroll", function () {
                if (angular.isDefined(scope.nicescroll)) {
                    scope.nicescroll.resize();
                }
            }, scope);

            scope.$on('$destroy', function () {
                if (angular.isDefined(scope.nicescroll)) {
                    scope.nicescroll.remove();
                    scope.nicescroll = undefined;
                }
            });

            if (!disabled)
                init();
        }
    };
});

voxponents.directive('dragScroll', function (msgBus) {
    return {
        restrict: 'A',
        link: function (scope, element, attribute) {

            var curDown = false,
                curYPos = 0,
                curXPos = 0;
            $(element).mousemove(function (m) {
                if (curDown === true) {
                    $(element).scrollTop($(element).scrollTop() + (curYPos - m.pageY));
                    $(element).scrollLeft($(element).scrollLeft() + (curXPos - m.pageX));
                }
            });

            $(element).mousedown(function (m) {
                curDown = true;
                curYPos = m.pageY;
                curXPos = m.pageX;
            });

            $(element).mouseup(function () {
                curDown = false;
            });

            scope.$on(
                "$destroy",
                function (event) {


                }
            );
        }
    };
});


voxponents.directive(
    "tidyTimeout",
    function ($timeout) {

        // I bind the User Interface events to the $scope.
        function link($scope, element, attributes) {

            // When the timeout is defined, it returns a
            // promise object.
            var timer = $timeout(
                function () {
                    $scope.tidyTimeout();
                },
                2000
            );


            // Let's bind to the resolve/reject handlers of
            // the timer promise so that we can make sure our
            // cancel approach is actually working.
            timer.then(
                function () {

                    console.log("Timer resolved!", Date.now());

                },
                function () {

                    console.log("Timer rejected!", Date.now());

                }
            );


            // When the DOM element is removed from the page,
            // AngularJS will trigger the $destroy event on
            // the scope. This gives us a chance to cancel any
            // pending timer that we may have.
            $scope.$on(
                "$destroy",
                function (event) {

                    $timeout.cancel(timer);

                }
            );

        }

        // Return the directive configuration.
        return ({
            link: link,
            scope: {
                tidyTimeout: '@'
            }
        });

    }
);
voxponents.directive('resizable', function ($window) {
    return function ($scope) {
        $scope.initializeWindowSize = function () {
            $scope.windowHeight = $window.innerHeight;
            return $scope.windowWidth = $window.innerWidth;
        };
        $scope.initializeWindowSize();
        return angular.element($window).bind('resize', function () {
            $scope.initializeWindowSize();
            return $scope.$apply();
        });
    };
});
voxponents.directive('vxRippleToggleButton', function () {


    return {
        restrict: 'E',
        // declare the directive scope as private (and empty)
        scope: {
            toggleCallBack: '=',
            tooltip: '@',
            type: '@'
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/directives/vxRippleToggleButton/vxRippleToggleButton.html',
        link: function (scope, iElement, iAttrs) {


        }
    };


});
voxponents.filter('vxExperience', function ($filter) {
    return function (level) {
        var experiencelabels = [
            {name: $filter('translate')('JUSTSTARTED'), level: 1},
            {name: $filter('translate')('INTERMEDIATE'), level: 2},
            {name: $filter('translate')('ADVANCED'), level: 3},
            {name: $filter('translate')('SENIOR'), level: 4},
            {name: $filter('translate')('EXPERT'), level: 5}
        ];
        return $filter('filter')(experiencelabels, {level: level})[0].name;
    };
});


voxponents.directive("vxSearchPanel", function () {
    return {
        templateUrl: '/src/modules/vxSearchPanel/vxSearchPanel.html',
        scope: {
            item: '=item',
            cpi: '@'
        },
        restrict: 'E',

        link: function (scope, iElement, iAttrs) {

            scope.ic = scope.cpi || {};

            scope.ic.clicked = {active: false};

            scope.ic.items = [{
                type: 'job',
                status: 'Bevestigd',
                jobnumber: 1125,
                jobtitle: 'Van het goed',
                kindofjob: 'Barmitswa'
            }, {type: 'employee', rating: 3, status: 'Annulering', name: 'Robin van der Driessche'}];

        }
    };
});

voxponents.directive('vxList', function (vxUtils, $window, $filter) {
    return {
        restrict: 'EA',
        transclude: false,
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            saveToServer: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxList/vxList.html',
        compile: function (element, attrs, transclude) {
            return {
                pre: function (scope, element, attributes, controller, transcludeFn) {

                    scope.ic = scope.cpi || {};
                    scope.listGUID = vxUtils.generateQuickGuid();

                    scope.selectionChanged = function (item, index) {
                        scope.clicked.active = false;
                        scope.ic.clicked = item;
                        if (!scope.clicked.active)
                            scope.clicked.active = true
                    };

                    scope.dragoverCallback = function (event, index, external, type) {
                        return index > 0;
                    };

                    scope.moved = function (index) {
                        scope.ic.items.splice(index, 1);

                        $(window).trigger('resize');

                    };

                    scope.dropCallback = function (event, index, item, external, type, allowedType) {

                        return scope.ic.dropCallback(event, index, item, external, type, allowedType);

                    };

                    scope.$watch('ic.items.length', function (length) {

                        if (length !== undefined && length == 0) {
                            scope.ic.items.push({type: 'empty'});

                        } else if (length !== undefined && length > 1) {

                            angular.forEach(scope.ic.items, function (value, key) {
                                if (value.type === 'empty') {
                                    scope.ic.items.splice(key, 1);
                                    return;
                                }
                            });


                        }
                        ;

                    });


                }
            };
        }
    };
});
voxponents.service('vxCached', function ($localStorage) {

    this.loaded = function () {

    };
    return this;
});
voxponents.constant('msgBusActions', {
    shellCloseTabByGuid: 'shell.closeTabByGuid',
    shellOpenTabByType: 'shell.openTabByType',
    overviewReload: 'overviewReload',
    calendarReload: 'calendarReload',
    saveEmployee: 'saveEmployee',
    calendarClicked: 'calendarClicked',
    employeeReloadPanel: 'employee.reloadPanel',
    employeeReloadCrewPanel: 'employee.reloadCrewPanel',
    suspend: 'suspend_'
});

export default voxponents;
