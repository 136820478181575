import app from "/src/app/app.module";



app.controller('product-details', function ($scope, vxUtils, $timeout, $filter, enums, $customer, $products) {
    $scope.cust = {};
    $scope.savetoserver = true;
    $scope.init = function (object) {



    };

    $scope.empExpPanelCpi = {
        panels: [
// { label: vxUtils.loc('DELETE'), href: "", icon: "fa fa-trash-o fa-2x", guid: vxUtils.generateQuickGuid(), toggle: false },
{
    label: vxUtils.loc('SEND_EMAIL'), width: 300,
    href: "/src/panel/mail-dialog.html",
    icon: "fa fa-envelope", toggle: true, guid: vxUtils.generateQuickGuid()
},
{
    label: vxUtils.loc('NEW_PHONE_CALL'), width: 300,
    href: "/src/panel/call-dialog.html",
    icon: "fa fa-phone ", toggle: false, guid: vxUtils.generateQuickGuid()
},
{
    label: vxUtils.loc('NEW_NOTE'), width: 300,
    href: "/src/panel/paperclip-dialog.html",
    icon: "fa fa-paperclip ", toggle: false, guid: vxUtils.generateQuickGuid()
}
        ]
    };

    $scope.locationCpi = {
        updateAdress: function (data) { alert('scuuce'); },
        adress: { adress: 'vondelweg 16', city: 'Rotterdam', country: 'Nederland' }
    };

    $scope.closeAll = function () {
        angular.forEach($scope.empExpPanelCpi.panels, function (value, key) {
            if (key > 0) {
                var i = $('#' + value.guid + '_panel');
                i.width(0);
                i.hide();
               
            }
        });
    };

    $scope.test = function ($event, $delta, $deltaX, $deltaY) {

        var scroll = $("#details-content >.row").scrollLeft();
        var i = (scroll - ($delta * 35));
        $("#details-content >.row").scrollLeft(i);
    };

    ///////////actionbar/////////////
    $scope.actionitems = [
      { label: vxUtils.loc('DELETE'), href: "", icon: "fa fa-trash-o fa-2x" },
      { label: vxUtils.loc('SEND_EMAIL'), href: "/src/panel/mail-dialog.html", icon: "fa fa-envelope fa-2x" },
      { label: vxUtils.loc('NEW_PHONE_CALL'), href: "/src/panel/call-dialog.html", icon: "fa fa-phone fa-2x" },
      { label: vxUtils.loc('NEW_NOTE'), href: "/src/panel/paperclip-dialog.html", icon: "fa fa-paperclip fa-2x" }
    ];

    $scope.actionBarCpi = {

    };
   var timer = $timeout(function () {
        $scope.closeAll();
    }, 50);

    $scope.$on(
        "$destroy",
        function (event) {

            $timeout.cancel(timer);

        }
    );
});