import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        QUOTE: 'Offerte',
        INVOICE: 'Factuur',
        INVOICES: 'Facturen',
        PAYMENTS: 'Betalingen',
        UNKOWN: 'Onbekend',
        DUEDATE: 'Verlopen op',
        SENDDATE: 'Verzonden op',
        NOSENDDATE: 'Nog niet verstuurd',
        SEND: 'Verzonden',
        OVERDUE: 'Verlopen',
        PAYED: 'Betaald',
        NEW: 'Nieuw',
        DUEDATE_ON: 'verloopt op',
        NO_OFFERTE: 'Geen offerte gevonden, dit kan een job zijn aangemaakt met de vorige planning',
        CREATE_OFFERTE: 'Maak offerte',
        PAYMENT_NAME: 'Offertenaam',
        AMOUNT: 'Bedrag',
        AMOUNT_PRODUCTS: 'Aantal',
        TRANSACTIONID: 'TransactieID',
        HAS_IDEAL: 'Heeft IDEAL',
        NO_IDEAL: 'Geen IDEAL',
        CREATIONDATE: 'Gemaakt op',
        PAYMENTSTATE: 'Betalingstatus',
        PAYMENT_KIND: 'Type',
        QUOTE_DATA: 'Offerte gegevens',
        JOB_DATA: "Job gegevens",
        SEARCH_CUSTOMER: "Zoek klant",
        ORDER_DATA: "Bestelling gegevens",
        INVOICE_DATA_CUSTOMER: 'Factuurgegevens klant',
        CHECK_DATA: "Controleer data",
        INVOICE_ADDRESS: 'Factuuradres',
        CREATE_INVOICE_NR: 'Maak nr',
        INVOICE_NR: 'Factuurnr',
        PRICE: 'Prijs',
        TOTAL: 'Totaal',
        TOTAL_EX_VAT: 'Totaal ex. BTW',
        VAT: 'BTW',
        TOTAL_VAT: 'Totaal BTW',
        IDEAL: 'IDEAL',
        CREATE_DOCUMENT: 'Maak en vul document',
        PAYMENT_URL: 'Betalings URL',
        EXTRA_REMARKS: 'Extra opmerkingen(Optioneel)',
        ATTACHMENTS: 'Bijlagen',
        COLLAPSE: 'Uitklappen',
        COPY_TO_INVOICE: 'Plak deze link in de factuur',
        PICK_AN_OPTION: 'Kies een optie',
        PAYMENT_UNKOWN: 'Betalingstatus onbekend',
        NEW_PRODUCT: 'Nieuw product',
        NEW_EMPLOYEE: 'Nieuwe werknemer',
        NEW_JOB: 'Nieuwe job',
        NEW_ORDER: 'Nieuwe bestelling',
        NEW_INVOICE: 'Nieuwe factuur',
        NEW_QUOTE: 'Nieuwe offerte',
        RATE:'Percentage',
        TAX_NAME: 'Belasting naam',
        DELIVERY_ADDRESS:'Afleveradres',
        ORDERS:'Bestellingen',
        STATUS: 'Status',
        DELIVERY_DATA_CUSTOMER: 'Leveringsgegevens klant',
    });

    const translations = {
        QUOTE: 'Quote',
        INVOICE: 'Invoice',
        INVOICES: 'Inovices',
        PAYMENTS: 'Payments',
        UNKOWN: 'Unknown',
        DUEDATE: 'Duedate',
        SENDDATE: 'Sentdate',
        NOSENDDATE: 'Not yet sent',
        SEND: 'Sent',
        OVERDUE: 'Overdue',
        PAYED: 'Payed',
        NEW: 'New',
        DUEDATE_ON: 'Duedate on',
        NO_OFFERTE: 'No quote found, this job is maybe created with the previous planning',
        CREATE_OFFERTE: 'Create quote',
        PAYMENT_NAME: 'Quote name',
        AMOUNT: 'Amount',
        AMOUNT_PRODUCTS: 'Amount',
        TRANSACTIONID: 'TransactionID',
        HAS_IDEAL: 'Has IDEAL',
        NO_IDEAL: 'No IDEAL',
        CREATIONDATE: 'Created on',
        PAYMENTSTATE: 'Payment state',
        ORDER_DATA: "Order data",
        PAYMENT_KIND: 'Type',
        QUOTE_DATA: 'Quote data',
        JOB_DATA: "Job data",
        SEARCH_CUSTOMER: "Search customer",
        INVOICE_DATA_CUSTOMER: 'Invoice data customer',
        DELIVERY_DATA_CUSTOMER: 'Delivery customer',
        CHECK_DATA: "Check data",
        INVOICE_ADDRESS: 'Invoice address',
        CREATE_INVOICE_NR: 'Create number',
        INVOICE_NR: 'Invoice number',
        PRICE: 'Price',
        TOTAL: 'Total',
        VAT: 'VAT',
        TOTAL_EX_VAT: 'Total ex. VAT',
        TOTAL_VAT: 'Total VAT',
        IDEAL: 'Internet payment',
        CREATE_DOCUMENT: 'Create document',
        PAYMENT_URL: 'Payment url',
        EXTRA_REMARKS: 'Extra remarks (optional)',
        ATTACHMENTS: 'Attachments',
        COLLAPSE: 'Collapse',
        COPY_TO_INVOICE: 'Copy and paste this link to invoice',
        PICK_AN_OPTION: 'Pick an option',
        PAYMENT_UNKOWN: 'Payment state unknown',
        NEW_PRODUCT: 'New product',
        NEW_EMPLOYEE: 'New employee',
        NEW_ORDER: 'New order',
        NEW_JOB: 'New job',
        NEW_INVOICE: 'New invoice',
        NEW_QUOTE: 'New quote',
        RATE:'Rate',
        TAX_NAME: 'Tax name',
        DELIVERY_ADDRESS:'Delivery address',
        ORDERS:'Orders',
        STATUS: 'State'
    }

    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);


    $translateProvider.preferredLanguage('nl-nl');
}]);