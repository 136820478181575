import app from "/src/app/app.module";


app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        LOGIN: 'Inloggen',
        REMEMBER_ME: 'Onhoud mij',
        SETTINGS: 'Beheer',
        YES: 'Ja',
        NO: 'Nee',
        YESNODIALOG: 'Weet je het zeker?',
        DELETE: 'Verwijderen',
        CLEAR_ALL:'Alles afgehandeld',
        NEW:'Nieuw',
        CALL_PLANNED_CLIENT:'Belafspraak',
        CALL_PLANNED_JOB:'Belafspraak',
        PLANNED_ON:'vandaag om ',
        CALENDAR:'Kalender',
        MY_PROFILE:'Mijn profiel',
        DUTCH:'Nederlands',
        AMERICAN:'Verenigde Staten',
        BRITISH:'Verenigd Koninkrijk',
        JOB_KINDSOFPARTY:'Soort feest beheer'

    });

    const translations = {
        JOB_KINDSOFPARTY:'Job kind management',
        LOGIN: 'Login',
        REMEMBER_ME: 'Remember me',
        SETTINGS: 'Settings',
        YES: 'Yes',
        NO: 'No',
        YESNODIALOG: 'Are you sure?',
        DELETE: 'Delete',
        CLEAR_ALL:'Handled everything',
        NEW:'New',
        CALL_PLANNED_CLIENT:'Plan phone call',
        CALL_PLANNED_JOB:'Plan phone call',
        PLANNED_ON:'today at ',
        CALENDAR:'Calendar',
        MY_PROFILE:'My profile',
        DUTCH:'Dutch',
        AMERICAN:'United States',
        BRITISH:'United Kingdom'

    }

    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);

    $translateProvider.preferredLanguage('nl-nl');
}]);