import app from "/src/app/app.module";


app.controller('customer_details',
    function ($scope, settings, $location, $filter, enums, vxUtils,
              msgBus, $customer, selectionFilters, toastr,tabService) {

        var tab = tabService.getCurrentTab();
        var id = tab.id;

        $scope.cust = {};
        $scope.actionBarCpi = {};
        $scope.customerCpi = {};
        $scope.adressCpi = {};
        $scope.PageHash = vxUtils.generateQuickGuid();

        $scope.timeLineCpi = {

            tabItems: $scope.openItems,
            filters: selectionFilters.eventTypes
        };

        $scope.actionitems = [
            {label: vxUtils.loc('DELETE'), href: "/src/panel/delete-dialog.html", icon: "fa fa-trash-o fa-2x"},
            {label: vxUtils.loc('SEND_EMAIL'), href: "/src/panel/mail-dialog.html", icon: "fa fa-envelope fa-2x"},
            {label: vxUtils.loc('NEW_PHONE_CALL'), href: "/src/panel/call-dialog.html", icon: "fa fa-phone fa-2x"},
            {label: vxUtils.loc('NEW_NOTE'), href: "/src/panel/paperclip-dialog.html", icon: "fa fa-paperclip fa-2x"},
            {
                label:  vxUtils.loc('REFRESH'), click: function () {
                toastr.info($filter('translate')('REFRESHING'), $filter('translate')('BUSY'))
                $customer.getCustomer(function (result) {
                    $scope.init(result);
                }, $scope.cust.ClientNumber());

            }, href: "", icon: "fa fa-refresh fa-2x"
            }

        ];

        $scope.init = function (object) {

            document.title = object.tabID + ': ' + object.tabDescription;
            tab.tabname =  object.tabID + " " + object.tabDescription;
            $scope.cust = object;
            $scope.customerCpi.cust = $scope.cust;
            $scope.adressCpi.adress = $scope.cust;
            $scope.actionBarCpi.mailobject = $scope.cust;
            $scope.actionBarCpi.pageHash = $scope.PageHash;

            $customer.getStats(function (result) {

                $scope.stats = result;
                var s = "[" + result.pastOfJobStatus + "]";
                $scope.pastOfJobStatus = angular.fromJson(s);
                if (result.totalAmountPerJob !== null) {
                    var i = "[{ \"values\": [" + result.totalAmountPerJob + "] }]";
                    $scope.totalAmountPerJob = angular.fromJson(i);
                }

            }, $scope.cust.ClientNumber());

            $customer.getEvents(function (results) {
                $scope.timeLineCpi.all = results;
                $scope.timeLineCpi.selectionChanged();
            }, $scope.cust.ClientNumber(), 'lol');
        };

        $customer.getCustomer(function (result) {
            $scope.init(result);
        }, id);

        $scope.scrollMethod = function ($event, $delta, $deltaX, $deltaY) {
            vxUtils.scrollHorzontal($event, $delta, $deltaX, $deltaY, $scope.PageHash);
        };
    });