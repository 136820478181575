import app from "/src/app/app.module";


app.controller('product-details', function ($scope, vxUtils,$filter, tabService, $products) {

    var tab = tabService.getCurrentTab();
    var id = tab.id;

    $scope.cust = {};
    $scope.savetoserver = true;
    $scope.PageHash = vxUtils.generateQuickGuid();
    $scope.productCpi = {};
    $scope.init = function () {
        $products.getProduct(id, function (item) {
            $scope.productCpi.product = item;
            $scope.product = item;
            var id = $filter('fullproductnumber')(item.fullnumber);
            document.title = id + " " + item.tabDescription;
            tab.tabname = id + " " + item.tabDescription;
        });
    };

    ///////////actionbar/////////////
    $scope.actionitems = [
        {
            label: vxUtils.loc('DELETE'), href: "/src/panel/delete-dialog.html",
            icon: "fa fa-trash-o fa-2x"
        },
        {
            label:  vxUtils.loc('REFRESH'), click: function () {
            $scope.init();

        }, href: "", icon: "fa fa-refresh fa-2x"
        }
    ];

    $scope.actionBarCpi = {};
    $scope.actionBarCpi.pageHash = $scope.PageHash;

    $scope.scrollMethod = function ($event, $delta, $deltaX, $deltaY) {
        vxUtils.scrollHorzontal($event, $delta, $deltaX, $deltaY, $scope.PageHash);
    };
    $scope.init();

});