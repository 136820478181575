import app from "/src/app/app.module";
import Note from "../models/note";

app.controller('callDialog', function ($scope, vxSession, vxUtils, msgBus, toastr, $customer) {
    const vm = this;
    vm.dialogGuid = vxUtils.generateQuickGuid();

    vm.note = new Note();
    vm.note.type = 'incoming';
    vm.note.executiondate = new Date();
    vm.job = vxUtils.findObjectOnParents($scope, 'job');
    vm.cust = vxUtils.findObjectOnParents($scope, 'cust');
    vm.order = vxUtils.findObjectOnParents($scope, 'order');
    vm.timeline = vxUtils.findObjectOnParents($scope, 'timeLineCpi');

    vm.icons = [
        {"value": "incoming", "label": "<i class=\"ionicons ion-android-call green\"></i> " + vxUtils.loc('INCOMING')},
        {"value": "outgoing", "label": "<i class=\"ionicons ion-android-call red\"></i> " + vxUtils.loc('OUTGOING')},
        {
            "value": "planned",
            "label": "<i class=\"ionicons ion-android-calendar yellow\"></i> " + vxUtils.loc('PLANNING')
        }
    ];

    const getBackground = function (type) {
        let result = '';
        switch (type) {
            case 'incoming':
                result = 'green-bg';
                break;
            case 'outgoing':
                result = 'red-bg';
                break;
            case 'planned':
                result = 'yellow-bg';
                break;
        }
        return result;
    };

    vm.saveCall = function () {
        vm.note.idUser = vxSession.user.idUser;

        if (vm.job !== undefined) {
            vm.note.idJob = vm.job.jobid;
        }
        else if (vm.order !== undefined) {
            vm.note.id_order_FK = vm.order.id;
        }

        vm.note.idClient = vm.cust.ClientNumber();

        //if (vm.note.type === 'planned') {
            vm.note.executiondate = Math.round(vm.note.executiondate / 1000);
            vm.note.time = Math.round(Date.now() / 1000);
     //   }
      //  else {
     //       vm.note.time = Math.round(vm.note.executiondate / 1000);
      //  }

        $customer.postNote(function (results) {
            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('NOTIFICATION') + vxUtils.loc('ACTION_ADDED'));
            vm.note.id = results[0];
            vm.note.title = vxSession.user.name;
            vm.note.icon = 'fa fa-phone';
            vm.note.background = getBackground(vm.note.type);
            vm.note.time = Math.round(vm.note.executiondate * 1000);
            vm.note.executiondate =Math.round(vm.note.executiondate * 1000);
            vm.timeline.all.push(vm.note);
            vm.timeline.refresh();
            vm.note = new Note();
            vm.note.type = 'incoming';
        }, vm.note);
    };

});