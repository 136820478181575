
import app from "/src/app/app.module";
import Note from "../models/note";
app.controller('paperclip_dialog', function ($scope, vxSession,
                                             msgBus, $customer, vxUtils, toastr) {
    var vm = this;
    vm.dialogGuid = vxUtils.generateQuickGuid();

    vm.note = new Note();
    vm.job = vxUtils.findObjectOnParents($scope, 'job');
    vm.cust = vxUtils.findObjectOnParents($scope, 'cust');
    vm.timeline = vxUtils.findObjectOnParents($scope, 'timeLineCpi');
    vm.note.type = 'note';

    vm.notelabels = [
        {label: 'Notitie', value: 'note'},
        {label: vxUtils.loc('CANCELREASON'), value: 'cancellation'}
    ];

    var getBackground = function (type) {
        var result = '';
        switch (type) {
            case 'note':
                result = ['vx-blue-bg', 'fa fa-paperclip'];
                break;
            case 'cancellation':
                result = ['red-bg', 'ionicons ion-android-cancel'];
                break;
        }
        return result;
    };

    vm.saveNote = function () {
        vm.note.idUser = vxSession.user.idUser;
        vm.job = vxUtils.findObjectOnParents($scope, 'job');
        vm.cust = vxUtils.findObjectOnParents($scope, 'cust');
        vm.order = vxUtils.findObjectOnParents($scope, 'order');
        vm.timeline = vxUtils.findObjectOnParents($scope, 'timeLineCpi');

        if (vm.job !== -1)
            vm.note.idJob = vm.job.jobid;
        if (vm.cust !== -1)
            vm.note.idClient = vm.cust.ClientNumber();
        if (vm.order !== -1)
            vm.note.id_order_FK = vm.order.id;

        vm.note.time = Math.round(Date.now() / 1000);
        vm.note.executiondate = Math.round(Date.now() / 1000);
        $customer.postNote(function (results) {
            var b = getBackground(vm.note.type);
            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('NOTE') + vxUtils.loc('ACTION_ADDED'));
            vm.note.id = results[0];
            vm.note.icon = b[1];
            vm.note.background = b[0];
            vm.note.time = Math.round(vm.note.time * 1000);
            vm.note.title = vxSession.user.name;

            vm.timeline.all.push(vm.note);
            vm.timeline.refresh();
            vm.note = new Note();
            vm.note.type = 'note';
        }, vm.note);
    };

});