import moment from "moment";
import {export_objects_to_excel} from "../assets/js/Export2Excel";
import * as GeoSearch from "leaflet-geosearch";
import Product from "../models/product";
import {dateToUnixTimestamp, parseUnixToDate, parseUnixToSeconds} from "../shared/utils";
import {toDate} from "../bower_components/moment/src/lib/moment/to-type";

var Voxmodules = angular.module('Voxmodules', []);

Voxmodules.filter('vxUseFilter', function ($filter) {
    return function () {
        var filterName = [].splice.call(arguments, 1, 1)[0];
        return $filter(filterName).apply(null, arguments);
    };
});

Voxmodules.filter('vxCapitalize', function ($filter) {
    return function (input) {
        if (typeof input !== 'string') return '';
        return input.charAt(0).toUpperCase() + input.slice(1);
    };
});

Voxmodules.factory("vxCountries", function (vxUtils) {

    var countries = [
        {name: vxUtils.loc('NETHERLANDS'), iso: 'NL'},
        {name: vxUtils.loc('BELGIUM'), iso: 'BE'},
        {name: vxUtils.loc('FRANCE'), iso: 'FR'},
        {name: vxUtils.loc('GERMANY'), iso: 'DU'},
        {name: vxUtils.loc('LUXEMBOURG'), iso: 'LU'},
        {name: vxUtils.loc('ITALY'), iso: 'IT'}
    ];

    return {
        countries: countries
    };


});

Voxmodules.factory("vxUtils", function (msgBus, $filter) {
    var scrollHorzontal = function ($event, $delta, $deltaX, $deltaY, pageID) {

        if (window.width < 750 || navigator.platform === 'MacIntel' || navigator.platform === 'Macintosh')
            return;

        var scroll = $("#actionwrapper" + pageID).scrollLeft();
        var i = (scroll - ($delta * 35));
        $("#actionwrapper" + pageID).scrollLeft(i);
        msgBus.emitMsg("UpdateScroll", {});
    };

    var writeLine = function (message) {

        console.log(message);
    };

    var findObjectOnParents = function (scope, nameObject, withParent) {

        var parent = scope.$parent;
        var found = parent[nameObject] !== undefined;

        if (!found) {
            var notfound = true;
            while (notfound) {
                parent = parent.$parent;

                if (parent === null) {
                    return -1;
                }

                if (parent[nameObject] === undefined) {

                } else {
                    notfound = false;
                    if (withParent)
                        return {p: parent, o: parent[nameObject]};
                    else
                        return parent[nameObject];
                }
            }
        } else {
            if (withParent)
                return {p: parent, o: parent[nameObject]};
            else
                return parent[nameObject];
        }


    };

    var generateQuickGuid = function () {
        return Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
    };

    var parseTime = function (timeStr, dt) {
        if (!dt) {
            dt = new Date();
        }

        var time = timeStr.match(/(\d+)(?::(\d\d))?\s*(p?)/i);
        if (!time) {
            return NaN;
        }
        var hours = parseInt(time[1], 10);
        if (hours == 12 && !time[3]) {
            hours = 0;
        } else {
            hours += (hours < 12 && time[3]) ? 12 : 0;
        }

        dt.setHours(hours);
        dt.setMinutes(parseInt(time[2], 10) || 0);
        dt.setSeconds(0, 0);
        return dt;
    };

    var loc = function (string, params) {
        return $filter('translate')(string, params);
    };

    var strip_tags = function (input, allowed) {
        allowed = (((allowed || '') + '')
            .toLowerCase()
            .match(/<[a-z][a-z0-9]*>/g) || [])
            .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
            commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
        return input.replace(commentsAndPhpTags, '')
            .replace(tags, function ($0, $1) {
                return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
            });
    }
    var vxUtils = {
        generateQuickGuid: generateQuickGuid,
        parseTime: parseTime,
        findObjectOnParents: findObjectOnParents,
        writeLine: writeLine,
        scrollHorzontal: scrollHorzontal,
        loc: loc,
        strip_tags: strip_tags
    };
    window.vxUtils = vxUtils;
    return vxUtils;
});

Voxmodules.factory("vxForms", function ($filter, $locale) {

    var employee = [
        {
            type: 'multiple',
            columns: 4,
            fields: [
                {
                    property: 'firstname', type: 'text',
                    attr: {autocomplete: 'off', required: true, maxlength: 45},
                    label: $filter('translate')('FIRSTNAME'),
                    msgs: {required: $filter('translate')('REQUIRED')}
                }, {
                    property: 'preposition', type: 'text',
                    attr: {autocomplete: 'off', required: false, maxlength: 45},
                    label: $filter('translate')('PREPOSITION')
                }, {
                    property: 'lastname', type: 'text',
                    attr: {autocomplete: 'off', required: true, maxlength: 45},
                    label: $filter('translate')('LASTNAME'),
                    msgs: {required: $filter('translate')('REQUIRED')}
                }
            ]
        },
        {
            property: 'email', type: 'email',
            attr: {autocomplete: 'off', required: true, maxlength: 80},
            label: $filter('translate')('EMAIL')
        },
        {
            property: 'telephone', type: 'text',
            attr: {
                autocomplete: 'off', required: true, maxlength: 45
            },
            label: $filter('translate')('TELEPHONE')
        },
        {
            property: 'telephone2', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('TELEPHONE2')
        },
        {
            type: 'multiple',
            columns: 6,
            fields: [
                {
                    property: 'street', type: 'text',
                    attr: {autocomplete: 'off', required: false, maxlength: 100},
                    label: $filter('translate')('STREET')
                }, {
                    property: 'streetnumber', type: 'text',
                    attr: {autocomplete: 'off', required: false, maxlength: 100},
                    label: $filter('translate')('STREETNUMBER')
                }
            ]
        },
        {
            property: 'areacode', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 10},
            label: $filter('translate')('AREACODE')
        }, {
            property: 'city', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 60},
            label: $filter('translate')('CITY')
        },
        {
            property: 'country', type: 'typeahead', attr: {autocomplete: 'off', required: true},
            label: $filter('translate')('COUNTRY'),
            typeahead: 'country.name for country in countries',
            msgs: {required: $filter('translate')('REQUIRED')}
        }
    ];

    var productfields = [
        {
            property: 'productname', type: 'text', attr: {autocomplete: 'off', required: false},
            label: $filter('translate')('PRODUCTNAME')
        },
        {
            property: 'productgroup', type: 'select', attr: {autocomplete: 'off', required: true},
            label: $filter('translate')('PRODUCTGROUP'),
            list: 'group.productgroupname for group in wizard.groups',
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'productdescr', type: 'text', attr: {autocomplete: 'off', required: true},
            label: $filter('translate')('PRODUCTDESCRIPTION'),
            msgs: {required: $filter('translate')('REQUIRED')}
        }
    ];
    var location = [
        {
            property: 'spot', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('LOCATION')
        },
        {
            property: 'street', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STREET'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'streetnumber', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STREETNUMBER'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'areacode', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 10},
            label: $filter('translate')('AREACODE'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'city', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('CITY'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'state', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STATE_PROVINCE')
        },
        {
            property: 'country', type: 'typeahead', attr: {autocomplete: 'off', required: true},
            label: $filter('translate')('COUNTRY'),
            typeahead: 'country.name for country in countries',
            msgs: {required: $filter('translate')('REQUIRED')}
        }
    ];

    var invoiceAdress = [
        {
            property: 'companyname', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('COMPANYNAME')
        },
        {
            property: 'firstname', type: 'text', attr: {autocomplete: 'off', required: true, maxlength: 100},
            label: $filter('translate')('FIRSTNAME'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'preposition', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('PREPOSITION')
        },
        {
            property: 'lastname', type: 'text', attr: {autocomplete: 'off', required: true, maxlength: 100},
            label: $filter('translate')('LASTNAME'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'telephone', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('TELEPHONE')
        },
        {
            property: 'telephone2', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('TELEPHONE2')
        },
        {
            property: 'email', type: 'email', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('EMAIL')
        },
        {
            property: 'street', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STREET'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'streetnumber', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STREETNUMBER'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'areacode', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 10},
            label: $filter('translate')('AREACODE'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'city', type: 'text', attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('CITY'),
            msgs: {required: $filter('translate')('REQUIRED')}
        },
        {
            property: 'state', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STATE_PROVINCE')
        },
        {
            property: 'country', type: 'typeahead', attr: {autocomplete: 'off', required: true, maxlength: 100},
            label: $filter('translate')('COUNTRY'),
            typeahead: 'country.name for country in countries',
            msgs: {required: $filter('translate')('REQUIRED')}
        }
    ];

    var jobs = [
        {
            type: 'multiple',
            columns: 6,
            fields: [
                {
                    property: 'jobtitle', type: 'text',
                    attr: {autocomplete: 'off', required: true, maxlength: 100},
                    label: $filter('translate')('JOBTITLE'),
                    msgs: {required: $filter('translate')('REQUIRED')}
                },
                {
                    property: 'state',
                    type: 'select',
                    label: $filter('translate')('STATE'),
                    list: 'state.jobstatusname for state in wizard.jobstatuses',
                    attr: {required: true}
                }
            ]
        },
        {
            type: 'multiple',
            columns: 6,
            fields: [
                {
                    property: 'startdate',
                    type: 'date',
                    dateSettings: {datepickerPopup: $locale.DATETIME_FORMATS.shortDate},
                    attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('STARTDATE'),
                    msgs: {required: $filter('translate')('REQUIRED')}
                },
                {
                    property: 'enddate',
                    type: 'date',
                    attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('ENDDATE'),
                    msgs: {required: $filter('translate')('REQUIRED')}
                }
            ]
        },
        {
            type: 'multiple', columns: 4, fields: [
                {
                    property: 'entrancetime', type: 'time',
                    attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('ENTRANCE'),
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                },
                {
                    property: 'startdate', type: 'time',
                    attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('STARTTIME'),
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                },
                {
                    property: 'enddate', type: 'time',
                    attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('ENDTIME'),
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                }
            ]
        },
        {
            type: 'multiple', columns: 3, fields: [
                {
                    property: 'employees', type: 'number', attr: {autocomplete: 'off', required: true, maxlength: 11},
                    label: $filter('translate')('AMOUNTEMPLOYEES'),
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                },
                {
                    property: 'guests', type: 'number',
                    attr: {autocomplete: 'off', required: true, maxlength: 11},
                    label: $filter('translate')('AMOUNTGUESTS'),
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                }, {
                    property: 'idWarehouse',
                    type: 'select',
                    label: $filter('translate')('DEPOT'),
                    list: 'kind.idWarehouse as kind.name for kind in wizard.allDepots',
                    attr: {required: true},
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                },
                {
                    property: 'kindofparty',
                    type: 'typeahead',
                    label: $filter('translate')('KINDOFPARTY'),
                    typeahead: 'kind.type for kind in wizard.kindofjobs',
                    attr: {required: true},
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                }
            ]
        },
        {
            type: 'multiple', columns: 6, fields: [
                {
                    property: 'remarks', type: 'textarea', attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('INTERNALREMARKS'),
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                },
                {
                    property: 'externalremarks', type: 'textarea',
                    attr: {autocomplete: 'off', required: false},
                    label: $filter('translate')('EXTERNALREMARKS'),
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                }
            ]
        }
    ];
    var order = [
        {
            type: 'multiple',
            columns: 6,
            fields: [
                {
                    property: 'title', type: 'text',
                    attr: {autocomplete: 'off', required: true, maxlength: 100},
                    label: $filter('translate')('TITLE'),
                    msgs: {required: $filter('translate')('REQUIRED')}
                },
                {
                    property: 'orderStatusFK',
                    type: 'select',
                    label: $filter('translate')('STATE'),
                    list: 'state.id as (state.label|translate) for state in wizard.orderStatuses',
                    attr: {required: true}
                }
            ]
        },
        {
            type: 'multiple',
            columns: 6,
            fields: [
                {
                    property: 'orderDate',
                    type: 'date',
                    dateSettings: {datepickerPopup: $locale.DATETIME_FORMATS.shortDate},
                    attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('ORDERDATE'),
                    msgs: {required: $filter('translate')('REQUIRED')}
                },
                {
                    property: 'deliveryDate',
                    type: 'date',
                    dateSettings: {datepickerPopup: $locale.DATETIME_FORMATS.shortDate},
                    attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('DELIVERYDATE'),
                    msgs: {required: $filter('translate')('REQUIRED')}
                },
                {
                    property: 'idWarehouse',
                    type: 'select',
                    label: $filter('translate')('DEPOT'),
                    list: 'kind.idWarehouse as kind.name for kind in wizard.allDepots',
                    attr: {required: true},
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                }
            ]
        },
        {
            type: 'multiple', columns: 12, fields: [
                {
                    property: 'remarks', type: 'textarea', attr: {autocomplete: 'off', required: true},
                    label: $filter('translate')('INTERNALREMARKS'),
                    msgs: {
                        required: $filter('translate')('REQUIRED')
                    }
                }
            ]
        }
    ];

    return {
        product: productfields,
        invoiceAdress: invoiceAdress,
        jobs: jobs,
        order: order,
        location: location,
        employee: employee
    };
});
Voxmodules.directive('vxOverview', function ($products, $filter, $window, msgBus,
                                             ngTableParams, vxUtils, msgBusActions) {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            columns: '=',
            cpi: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxOverview/vxOverview.html',
        link: function (scope, iElement, iAttrs) {
            scope.internalControl = scope.cpi || {};
            scope.internalControl.reload = function () {
                scope.tableParams.reload();
            };

            scope.internalControl.tableid = vxUtils.generateQuickGuid();

            scope.internalControl.export = function (sheetname, columns, data) {
                //export_table_to_excel(scope.internalControl.tableid);
                var result = scope.ConvertToArray(columns, data);
                var colWidth = scope.CalcColWidth(result);
                export_objects_to_excel(sheetname, result, colWidth);
            };

            function isTouchDevice() {
                var msGesture = window.navigator && window.navigator.msPointerEnabled && window.MSGesture;
                return (("ontouchstart" in window) || msGesture || window.DocumentTouch && document instanceof DocumentTouch)
            }

            scope.isTouch = isTouchDevice();

            scope.CalcColWidth = function (data) {
                if (!data || data.length == 0)
                    return [];
                var output = [];
                var ruler = $("#ruler")[0];

                for (var i = 0; i < data[0].length; i++) {
                    var allWidth = [];
                    for (var j = 0; j < data.length; j++) {
                        var v = data[j][i];
                        ruler.innerHTML = v;
                        var w = ruler.offsetWidth;
                        allWidth.push(w);
                    }
                    var result = $filter('max')(allWidth);
                    output.push({wch: result / 8});
                }
                return output;

            };
            scope.ConvertToArray = function (columns, data) {
                var output = [];
                var headers = [];

                angular.forEach(columns, function (value, key) {
                    headers.push(value.title && value.title.length > 0 ? value.title : value.name);
                    if (key === columns.length - 1)
                        output.push(headers);
                });
                var row = [];
                var rowCounter = 0;
                angular.forEach(data, function (obj, num) {
                    angular.forEach(columns, function (value, key) {
                        var item = obj;
                        var field = value.altfield ? value.altfield : value.field;
                        var res = item[field];
                        if (value.value) {
                            res = value.value(item);
                        }
                        if (!value.noFilterOnExport && (value.altfilter || value.filter)) {

                            res = $filter('vxUseFilter')(res, value.altfilter || value.filter, value.args);
                        }
                        if (value.type) {
                            switch (value.type) {
                                case 'number':
                                    res = parseFloat(res);
                                    break;
                                case 'currency':
                                    res = parseFloat(res);
                                    break;
                            }
                        }
                        var val = res === undefined ? '' : res;

                        row.push(val);

                        if (key === columns.length - 1) {
                            output.push(row);
                            row = [];
                        }
                    });
                });

                return output;
            }

            scope.DoISort = function (column) {
                if (column.sortable)
                    return scope.tableParams.sorting(column.field, scope.tableParams.isSortBy(column.field, 'asc') ? 'desc' : 'asc');
                else {
                    return '';
                }
            };
            scope.getInitalSort = function () {
                var result = {};
                var out = $filter('filter')(scope.columns, {initalSort: 'sc'});
                if (out.length > 0) {
                    var t = out[0];
                    var obj = '{"' + t.field + '":"' + t.initalSort + '"}';
                    result = angular.fromJson(obj);
                }
                return result;
            }

            scope.tableParams = new ngTableParams({
                page: 1,            // show first page
                count: 50,          // count per page
                sorting: scope.getInitalSort()
            }, {
                total: 0, // length of data
                getData: function ($defer, params) {
                    // use build-in angular filter
                    if (scope.internalControl.items === undefined)
                        return;

                    var omaFiltered = scope.internalControl.items;

                    var filterdData = omaFiltered;

                    scope.orderedData = params.sorting() ? $filter('orderBy')(filterdData, params.orderBy()) : filterdData;
                    scope.internalControl.items = scope.orderedData;

                    params.total(scope.orderedData.length);
                    // set new data
                    var pages = Math.ceil((scope.orderedData.length / params.count()));
                    if (params.page() > pages)
                        params.page(pages);

                    $defer.resolve(scope.orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));

                    if (params.page() === 0)
                        params.page(1);

                    console.log(params.page());
                }
            });

            scope.opendItem = function (item) {
                scope.currentItem = item;
            };

            scope.internalControl.selection = [];

            scope.clearSelection = function () {
                angular.forEach(scope.internalControl.selection, function (value, key) {
                    value.$selected = false;
                });
                scope.internalControl.selection = [];
            };

            scope.changeSelection = function (item, $event) {
                if ($event.ctrlKey) {
                    item.$selected = !item.$selected;
                    if (item.$selected === true) {
                        scope.internalControl.selection.push(item);
                    } else {
                        var index = scope.internalControl.selection.findIndex(item);
                        scope.internalControl.selection.splice(index, 1);
                    }

                } else {
                    if (scope.currentItem != null) {
                        scope.currentItem.$selected = false;
                    }
                    if (scope.internalControl.selection.length > 0) {
                        scope.clearSelection();
                    }
                    scope.currentItem = item;
                    scope.currentItem.$selected = true;

                }
            };

            scope.openItem = function (item) {

                if (scope.internalControl.canOpen && !scope.internalControl.canOpen())
                    return;

                if (scope.currentItem != null) {
                    scope.currentItem.$selected = false;
                }
                scope.currentItem = item;
                scope.currentItem.$selected = true;
                var type = scope.internalControl.type;

                //var win = window.open('#/' + type + '/' + scope.currentItem.tabID, '_blank');
                //win.focus();

                msgBus.emitMsg(msgBusActions.shellOpenTabByType, {
                    type: type, id: scope.currentItem.tabID
                });

                //var tab = {
                //    tabname: item.tabID + " " + item.tabDescription,
                //    href: scope.internalControl.openItemPage,
                //    icon: scope.internalControl.openItemIcon,
                //    object: item,
                //    hash: vxUtils.generateQuickGuid()
                //};
                //tab.active = true;
                //scope.internalControl.tabItems.push(tab);
            };

        }
    };


});

Voxmodules.service('tabService', function ($filter) {

    var tabs = [];

    var getCurrentTab = function () {
        var tab = $filter('filter')(tabs, {active: true});
        return tab[0];
    };

    return {
        tabs: tabs,
        getCurrentTab: getCurrentTab
    }
});

Voxmodules.service('selectionFilters', function ($filter, vxUtils, $users, $customer, $localStorage) {

        var filterBySelection = function (filters, allItems, fields) {
            var items = [];
            var basket = allItems;
            angular.forEach(fields, function (v, k) {
                var res = $filter('filter')(filters,
                    {ticked: true, field: v});

                var checkobj = angular.fromJson('{"' + v + '": ""}');

                angular.forEach(res, function (value, key) {
                    checkobj[v] = value.state;
                    var i = $filter('filter')(basket,
                        checkobj);

                    angular.forEach(i, function (valuea, keya) {
                        items.push(valuea);
                    })
                });
                basket = items;
                items = [];
            });

            return basket;
        }

        var worknoteTypes = function (actionsOnly) {
            if (angular.isUndefined(actionsOnly))
                actionsOnly = true;

            return [
                {
                    icon: '<i class="fa fa-paper-plane"></i>',
                    name: vxUtils.loc('FILLED'),
                    state: true,
                    field: 'filled',
                    ticked: actionsOnly
                },
                {
                    icon: '<i class="fa fa-calculator"></i>',
                    name: vxUtils.loc('NOTFILLED'),
                    state: false,
                    field: 'filled',
                    ticked: true
                }
            ];
        }

        var customerStates = function (callback, nocache) {

            if ($localStorage.customerStates !== undefined && !nocache) {
                if (callback)
                    callback($localStorage.customerStates);
                return;
            }
            var customerStates = [
                {
                    name: "<strong>" + $filter('translate')('CUSTOMERS') + "</strong>",
                    multiSelectGroup: true
                },
                {
                    icon: '<i class="fa fa-building"></i>',
                    name: $filter('translate')('COMPANY'),
                    state: true,
                    field: 'IsCompany',
                    ticked: true
                },
                {
                    icon: '<i class="glyphicon glyphicon-user"></i>',
                    name: $filter('translate')('INDIVIDUAL'),
                    state: false,
                    field: 'IsCompany',
                    ticked: true
                }, {
                    multiSelectGroup: false
                },
                {
                    icon: '<i class="fa fa-eye"></i>',
                    name: '<strong>' + vxUtils.loc("RELATION") + '</strong>',
                    multiSelectGroup: true
                }
            ];

            $customer.getAllTypes(function (types) {
                angular.forEach(types, function (value, key) {
                    var group = {
                        name: vxUtils.loc(value.client_type_name_org.toUpperCase()),
                        state: value.client_type_name_org,
                        field: 'relationtype',
                        ticked: true
                    };
                    customerStates.push(group);
                }, true);
                customerStates.push({multiSelectGroup: false})
                $localStorage.customerStates = customerStates;
                if (callback) {
                    callback(customerStates);
                }

            });


        };

        var employeeEventTypes = function (actionsOnly) {
            if (angular.isUndefined(actionsOnly))
                actionsOnly = true;

            return [
                {
                    icon: '<i class="fa fa-thumbs-o-up"></i>',
                    name: '<strong>Acceptatie status</strong>',
                    multiSelectGroup: true
                },
                {
                    icon: '<i class="fa fa-check-circle green"></i>',
                    name: $filter('translate')('ACCEPTED'),
                    state: 1,
                    field: 'userjobcrewstatus',
                    ticked: actionsOnly
                },
                {
                    icon: '<i class="fa fa-exclamation-circle yellow"></i>',
                    name: $filter('translate')('AWAITING'),
                    state: 0,
                    field: 'userjobcrewstatus',
                    ticked: true
                },
                {
                    icon: '<i class="fa fa-ban red"></i>',
                    name: $filter('translate')('DECLINED'),
                    state: 2,
                    field: 'userjobcrewstatus',
                    ticked: actionsOnly
                },
                {
                    multiSelectGroup: false
                },
                {
                    icon: '<i class="fa fa-thumb-tack"></i>',
                    name: '<strong>Soort event</strong>',
                    multiSelectGroup: true
                },
                {
                    icon: '<i class="fa fa-map-marker"></i>',
                    name: $filter('translate')('JOBS'),
                    state: 'job',
                    field: 'type',
                    ticked: true
                },
                {
                    icon: '<i class="fa fa-phone green"></i>',
                    name: $filter('translate')('AVAILABILITY'),
                    state: 'availability',
                    field: 'type',
                    ticked: actionsOnly
                },
                {
                    multiSelectGroup: false
                }
            ];
        }

        var jobStates = [
            {
                icon: '<i class="fa fa-map-marker"></i>',
                name: '<strong>Job status</strong>',
                multiSelectGroup: true
            },
            {
                name: $filter('translate')('ACCEPTED'),
                state: 'Bevestigd',
                field: 'status',
                ticked: true
            },
            {
                name: $filter('translate')('OPTION'),
                state: 'Optie',
                field: 'status',
                ticked: true
            },
            {
                name: $filter('translate')('CANCELED'),
                state: 'Annulering',
                field: 'status',
                ticked: false
            },
            {
                multiSelectGroup: false
            },
            {
                icon: '<i class="fa fa-group"></i>',
                name: '<strong>Personeel</strong>',
                multiSelectGroup: true
            },
            {
                name: $filter('translate')('CREWNOTFULL'),
                state: 'toofew',
                field: 'crewstatus',
                ticked: true
            },
            {
                name: $filter('translate')('CREWFULL'),
                state: 'full',
                field: 'crewstatus',
                ticked: true
            },
            {
                name: $filter('translate')('CREWEMPTY'),
                state: 'empty',
                field: 'crewstatus',
                ticked: true
            },
            {
                name: $filter('translate')('CREWOVERBOOKED'),
                state: 'overbooked',
                field: 'crewstatus',
                ticked: true
            },
            {
                multiSelectGroup: false
            },
            {
                icon: '<i class="fa fa-building"></i>',
                name: '<strong>Bedrijf</strong>',
                multiSelectGroup: true
            },
            {
                name: 'Oestercompagnie',
                state: 1,
                field: 'tendant',
                ticked: true
            },
            {
                name: 'Depot Noord',
                state: 2,
                field: 'tendant',
                ticked: true
            },
            {
                multiSelectGroup: false
            }
        ];
        var eventTypes = [
            {
                icon: '<i class="fa fa-map-marker"></i>',
                name: $filter('translate')('JOBS'),
                state: 'job',
                field: 'status',
                ticked: true
            },
            {
                icon: '<i class="fa fa-phone green"></i>',
                name: $filter('translate')('INCOMING'),
                state: 'incoming',
                field: 'status',
                ticked: true
            },
            {
                icon: '<i class="fa fa-phone yellow"></i>',
                name: $filter('translate')('PLANNING'),
                state: 'planned',
                field: 'status',
                ticked: true
            },
            {
                icon: '<i class="fa fa-phone red"></i>',
                name: $filter('translate')('OUTGOING'),
                state: 'outgoing',
                field: 'status',
                ticked: true
            },
            {
                icon: '<i class="fa fa-paperclip"></i>',
                name: $filter('translate')('NOTE'),
                state: 'note',
                field: 'status',
                ticked: true
            },
            {
                icon: '<i class="ionicons ion-android-cancel red"></i>',
                name: $filter('translate')('CANCELREASON'),
                state: 'note_cancellation',
                field: 'status',
                ticked: true
            },
            {
                icon: '<i class="fa fa-envelope"></i>',
                name: $filter('translate')('MAIL'),
                state: 'mail',
                field: 'status',
                ticked: false
            },
        ];
        var paymentTypes = [
            {
                icon: '<i class="fa fa-tag"></i>',
                name: '<strong>Soort</strong>',
                multiSelectGroup: true
            },
            {
                icon: '<i class="fa fa-file-text-o"></i>',
                name: $filter('translate')('QUOTE'),
                state: 'quote',
                field: 'type',
                ticked: true
            },
            {
                icon: '<i class="fa fa-eur"></i>',
                name: $filter('translate')('INVOICE'),
                state: 'invoice',
                field: 'type',
                ticked: true
            },
            {
                multiSelectGroup: false
            },
            {
                icon: '<i class="fa fa-asterisk"></i>',
                name: '<strong>Status</strong>',
                multiSelectGroup: true
            },
            {
                // icon: '<i class="fa fa-file-text-o"></i>',
                name: $filter('translate')('PAYED'),
                state: 'payment',
                field: 'status',
                ticked: true
            },
            {
                // icon: '<i class="fa fa-eur"></i>',
                name: $filter('translate')('NEW'),
                state: 'new',
                field: 'status',
                ticked: true
            },
            {
                // icon: '<i class="fa fa-file-text-o"></i>',
                name: $filter('translate')('OVERDUE'),
                state: 'overdue',
                field: 'status',
                ticked: true
            },
            {
                // icon: '<i class="fa fa-eur"></i>',
                name: $filter('translate')('DECLINED'),
                state: 'declined',
                field: 'status',
                ticked: true
            },
            {
                // icon: '<i class="fa fa-eur"></i>',
                name: $filter('translate')('SEND'),
                state: 'send',
                field: 'status',
                ticked: true
            }, {
                // icon: '<i class="fa fa-eur"></i>',
                name: $filter('translate')('ACCEPTED'),
                state: 'accepted',
                field: 'status',
                ticked: true
            }, {
                // icon: '<i class="fa fa-eur"></i>',
                name: $filter('translate')('UNKOWN'),
                state: 'unkown',
                field: 'status',
                ticked: true
            },
            {
                multiSelectGroup: false
            }
        ];

    var orderStates = [
        {
            icon: '<i class="fa fa-envelope yellow mr-1"></i>',
            name: $filter('translate')('RECEIVED'),
            state: 'received',
            field: 'status',
            ticked: true
        },{
            icon: '<i class="fa fa-check-circle green mr-1"></i>',
            name: $filter('translate')('DELIVERED'),
            state: 'delivered',
            field: 'status',
            ticked: true
        },
        {
            icon: '<i class="fa fa-spinner yellow mr-1"></i>',
            name: $filter('translate')('IN_PROGRESS'),
            state: 'in_progress',
            field: 'status',
            ticked: true
        },{
            icon: '<i class="fa fa-truck blue mr-1"></i>',
            name: $filter('translate')('READY_FOR_DELIVERY'),
            state: 'ready_for_delivery',
            field: 'status',
            ticked: true
        },
        {
            icon: '<i class="fa fa-ban red mr-1"></i>',
            name: $filter('translate')('CANCELED'),
            state: 'canceled',
            field: 'status',
            ticked: false
        }];


        var employeeGroups = function (callback, nocache) {

            if ($localStorage.employeeGroups !== undefined && !nocache) {
                if (callback)
                    callback($localStorage.employeeGroups);
                return;
            }

            var list = [{
                icon: '<i class="fa fa-eye"></i>',
                name: '<strong>' + vxUtils.loc("USERGROUP") + '</strong>',
                multiSelectGroup: true
            }
            ];
            $users.getGroups(function (groups) {
                angular.forEach(groups, function (value, key) {
                    var group = {
                        name: value.groupname,
                        state: value.groupname,
                        field: 'groupname',
                        ticked: true
                    };
                    list.push(group);
                }, true);

                list.push({multiSelectGroup: false});
                list.push({
                    icon: '<i class="fa fa-lock"></i>',
                    name: '<strong>' + vxUtils.loc("USERLEVEL") + '</strong>',
                    multiSelectGroup: true
                });

                $users.getRoles(function (roles) {
                    angular.forEach(roles, function (value, key) {
                        var group = {
                            name: value.role,
                            state: value.level,
                            field: 'level',
                            ticked: true
                        };
                        list.push(group);
                    });

                    list.push({multiSelectGroup: false});
                    $localStorage.employeeGroups = list;
                    if (callback) {
                        callback(list);
                    }
                }, true);
            }, true);

        };

        var employeeGroupsAvail = function (callback, nocache) {

            if ($localStorage.employeeGroupsAvail !== undefined && !nocache) {
                if (callback)
                    callback($localStorage.employeeGroupsAvail);
                return;
            }

            var list = [{
                icon: '<i class="fa fa-eye"></i>',
                name: '<strong>' + vxUtils.loc("USERGROUP") + '</strong>',
                multiSelectGroup: true
            }
            ];
            $users.getGroups(function (groups) {
                angular.forEach(groups, function (value, key) {
                    var group = {
                        name: value.groupname,
                        state: value.idGroup,
                        field: 'idGroup',
                        ticked: value.default_ticked === '1'
                    };
                    list.push(group);


                }, true);

                list.push({multiSelectGroup: false});
                list.push({
                    icon: '<i class="fa fa-lock"></i>',
                    name: '<strong>' + vxUtils.loc("CREW") + '</strong>',
                    multiSelectGroup: true
                });
                list.push(...[{
                    state: 'available',
                    field: 'crew',
                    name: vxUtils.loc('AVAILABLE'),
                    ticked: true
                }, {
                    state: 'occupied',
                    field: 'crew',
                    name: vxUtils.loc('OCCUPIED'),
                    ticked: false
                }, {
                    state: 'planners',
                    field: 'crew',
                    name: vxUtils.loc('PLANNERS'),
                    ticked: false
                }]);


                list.push({multiSelectGroup: false});
                $localStorage.employeeGroupsAvail = list;
                if (callback) {
                    callback(list);
                }

            }, true);

        };

        var customerKinds = function (callback) {

            $customer.getAllCustomerKinds(result => callback(result.map(item => {
                return {
                    name: item.type,
                    state: item.id_customerkind,
                    field: 'customerKindId',
                    ticked: true
                }
            })))
        }

        return {
            jobStates: jobStates,
            orderStates: orderStates,
            eventTypes: eventTypes,
            customerStates: customerStates,
            paymentTypes: paymentTypes,
            employeeGroups: employeeGroups,
            employeeGroupsAvail: employeeGroupsAvail,
            worknoteTypes: worknoteTypes,
            employeeEventTypes: employeeEventTypes,
            filterBySelection: filterBySelection,
            customerKinds:customerKinds
        };
    }
)
;

Voxmodules.directive('vxFilterbar', function (vxUtils, settings) {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            maxlabels: '@',
            filters: '=',
            helperelements: '@'
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxFilterbar/vxFilterbar.html',
        link: function (scope, iElement, iAttrs) {
            scope.ic = scope.cpi || {};
            scope.settings = settings;
            //METHOD CHECKS
            if (scope.ic.filters === undefined)
                console.warn("No filters provided");

            scope.showJobInfo = iAttrs.jobinfo !== undefined;
            scope.showSearch = iAttrs.search !== undefined;
            scope.showInfo = iAttrs.info !== undefined;
            scope.showMultiSelect = iAttrs.mselect !== undefined;
            scope.showPeriod = iAttrs.period !== undefined;
            scope.showFilter = iAttrs.showfilter !== undefined;
            scope.wrap = iAttrs.wrap !== undefined;

            scope.click = function (data) {
                if (data === null)
                    return;

                var res = data['ticked'];
                data['ticked'] = !res;

            };

            scope.$watch('ic.date', function (newDate) {
                //msgBus.emitMsg('vxFilter.dateChanged.id='+, newDate);
                if ((newDate === null || newDate === undefined)
                    || (newDate.startDate === null || newDate.startDate === undefined)
                    || (newDate.endDate === null || newDate.endDate === undefined))
                    return;

                var s = typeof newDate.startDate.unix !== 'function' ? newDate.startDate.getTime() / 1000.0 : newDate.startDate.unix();
                var e = typeof newDate.endDate.unix !== 'function' ? newDate.endDate.getTime() / 1000.0 : newDate.endDate.unix();
                var unix = {
                    startDate: s,
                    endDate: e
                }

                scope.ic.dateChanged(unix);
            }, false);

            var ranges = {};
            ranges[vxUtils.loc('TODAY')] = [moment(), moment()];
            ranges[vxUtils.loc('YESTERDAY')] = [moment().subtract('days', 1), moment().subtract('days', 1)];
            ranges[vxUtils.loc('DAYS_7')] = [moment().subtract('days', 6), moment()];
            ranges[vxUtils.loc('DAYS_30')] = [moment().subtract('days', 29), moment()];
            ranges[vxUtils.loc('THIS_MONTH')] = [moment().startOf('month'), moment().endOf('month')];
            ranges[vxUtils.loc('LAST_MONTH')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract('month', 1).endOf('month')];
            ranges[vxUtils.loc('NEXT_MONTH')] = [moment().add(1, 'month').startOf('month'), moment().subtract('month', 1).endOf('month')];


            // LOCALE SECTIOn
            scope.ic.options = {
                locale: {
                    customRangeLabel: vxUtils.loc('OTHER'),
                    daysOfWeek: vxUtils.loc('DAYS'),
                    monthNames: vxUtils.loc('MONTHS'),
                    format: 'ddd, dd-MM-yyyy',
                    firstDay: 1,
                    applyLabel: vxUtils.loc('SEARCH'),
                    fromLabel: vxUtils.loc('FROM'),
                    toLabel: vxUtils.loc('TILL'),
                    cancelLabel: vxUtils.loc('NEVER_MIND')
                },
                ranges: ranges,
                showWeekNumbers: true,
                showDropdowns: true,
                startDate: moment().subtract('days', 7),
                endDate: moment().add('days', 14),
                separator: ' ' + vxUtils.loc('TILL')
            };

            // scope.ic.date = {startDate: null, endDate: null};

        }
    };


});
Voxmodules.directive('vxActionBar', function (msgBus, $timeout, vxUtils) {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            actionitems: '=',
            cpi: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxActionBar/vxActionBar.html',
        link: function (scope, iElement, iAttrs) {
            scope.internalControl = scope.cpi || {};


            scope.actionSelected = function (action) {
                if (scope.selectedAction) {
                    scope.selectedAction.active = false;
                }
                action.active = true;

                var i = document.getElementById('customerContentPanel');

                scope.checkDialog = function (action) {
                    if (scope.isOpen && scope.lastType !== action.href)
                        $(i).velocity("transition.slideRightOut", {
                            duration: 300,
                            complete: function () {
                                scope.selectedAction = action;
                                scope.$digest();
                                i = document.getElementById('customerContentPanel');
                                $(i).velocity("transition.slideRightIn", {
                                    duration: 300,
                                    complete: function () {

                                    }
                                });
                            }
                        });
                    else if (!scope.isOpen) {
                        scope.selectedAction = action;
                    }
                };

                scope.toggleNav = function (toggle) {
                    var h = scope.internalControl.pageHash;
                    var el = document.getElementById('actionwrapper' + h);
                    if (!toggle) {
                        // Do things on Nav Close
                        $(el).removeClass('show-nav');
                        scope.isOpen = false;
                        if (scope.selectedAction) {
                            scope.selectedAction.active = false;
                        }
                    } else {
                        // Do things on Nav Open
                        $(el).addClass('show-nav');
                        scope.isOpen = true;
                    }
                    var timer = $timeout(function () {
                        msgBus.emitMsg('UpdateScroll', {});
                    }, 400);
                    scope.$on(
                        "$destroy",
                        function (event) {

                            $timeout.cancel(timer);

                        }
                    );

                    //$('#site-wrapper').toggleClass('show-nav');
                };

                scope.checkDialog(action);

                if (scope.isOpen && scope.lastType === action.href ||
                    (scope.isOpen && "" === action.href)) {
                    scope.isOpen = false;
                    scope.toggleNav(false);
                } else if (!scope.isOpen && "" !== action.href) {
                    scope.isOpen = true;
                    scope.toggleNav(true);
                }
                if ("" === action.href) {
                    action.click();
                }


                scope.lastType = action.href;
            };
        }
    };


});
Voxmodules.directive('vxActionBarScroll', function () {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            actionitems: '=',
            cpi: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxActionBar/vxActionBarScroll.html',
        link: function (scope, iElement, iAttrs) {
            scope.internalControl = scope.cpi || {};

            scope.actionSelected = function (action) {
                var i = document.getElementById('customerContentPanel');

                scope.checkDialog = function (action) {
                    if (scope.isOpen && scope.lastType !== action.href)
                        $(i).velocity("transition.slideRightOut", {
                            duration: 300,
                            complete: function () {
                                scope.selectedAction = action;
                                scope.$digest();
                                i = document.getElementById('customerContentPanel');
                                $(i).velocity("transition.slideRightIn", {
                                    duration: 300,
                                    complete: function () {

                                    }
                                });
                            }
                        });
                    else if (!scope.isOpen) {
                        scope.selectedAction = action;
                    }
                };

                scope.toggleNav = function (toggle) {
                    if (!toggle) {
                        // Do things on Nav Close
                        $('#customerContentPanel').velocity("transition.fadeOut", {
                            complete: function () {
                                $('.actionbar-scroll-content').velocity({width: 0}, 'ease');
                            }
                        });

                    } else {
                        // Do things on Nav Open
                        $('.actionbar-scroll-content').velocity({width: 300}, 'ease');
                        $('#customerContentPanel').velocity("transition.fadeIn");
                    }

                    //$('#site-wrapper').toggleClass('show-nav');
                };

                scope.checkDialog(action);

                if (scope.isOpen && scope.lastType === action.href) {
                    scope.isOpen = false;
                    scope.toggleNav(false);
                } else if (!scope.isOpen) {
                    scope.isOpen = true;
                    scope.toggleNav(true);
                }
                scope.lastType = action.href;
            };
        }
    };


});
Voxmodules.directive('vxExtendablePanel', function (vxUtils, $filter) {
    return {
        priority: 1500,
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxExtendablePanel/vxExtendablePanel.html',
        compile: function (element, attrs, transclude) {
            return {
                pre: function (scope, element, attributes, controller, transcludeFn) {
                    scope.internalControl = scope.cpi || {};
                    scope.ignoreSuspention = true;
                    scope.active = {value: ''};
                    scope._pageHash = vxUtils.findObjectOnParents(scope, 'PageHash');
                    scope.internalControl.closeAll = function () {
                        angular.forEach(scope.internalControl.panels, function (value, key) {
                            if (key > 0) {
                                var i = $('#' + value.guid + '_panel');
                                i.width(0);
                                i.hide();
                                value.toggle = false;
                            }
                        });
                    };

                    scope.openNext = function (index, checkIfOpen) {
                        var currentClicked = scope.internalControl.panels[index];
                        var htmlPanel = $('#' + scope.internalControl.panels[index].guid + '_panel');
                        var nextPanel = index + 1 < scope.internalControl.panels.length ? $('#' + scope.internalControl.panels[index + 1].guid + '_panel') : null;
                        var previousPanel = index - 1 > 0 ? scope.internalControl.panels[index - 1] : null;

                        if (index === 0) {
                            htmlPanel.css({'border-bottom-right-radius': '0', 'border-top-right-radius': '0'});
                            if (nextPanel)
                                nextPanel.css({'border-bottom-left-radius': '0', 'border-top-left-radius': '0'});
                        } else {
                            htmlPanel.css({'border-bottom-right-radius': '0', 'border-top-right-radius': '0'});
                            if (nextPanel)
                                nextPanel.css({'border-bottom-left-radius': '0', 'border-top-left-radius': '0'});
                        }

                        if (index !== scope.internalControl.panels.length - 1) {
                            index = index + 1;
                        } else {
                            currentClicked = previousPanel;
                        }

                        if (checkIfOpen && currentClicked.siblingOpen)
                            return;

                        scope.toggleNav(scope.internalControl.panels[index]);

                        if (currentClicked.siblingOpen) {
                            $('#' + currentClicked.guid + '_plus').velocity({rotateZ: "0deg"}, {delay: 410}, 'ease');
                            currentClicked.siblingOpen = false;
                        } else {
                            $('#' + currentClicked.guid + '_plus').velocity({rotateZ: "180deg"}, {delay: 50}, 'ease');
                            currentClicked.siblingOpen = true;
                        }
                    };

                    scope.internalControl.openNext = scope.openNext;

                    scope.toggleNav = function (panel) {
                        var htmlPanel = $('#' + panel.guid + '_panel');
                        var htmlPanelContent = $('#' + panel.guid + '_content');
                        var htmlPanelHeader = $('#' + panel.guid + '_head');
                        htmlPanel.css({'min-width': 0});

                        if (panel.toggle) {
                            // Do things on Nav Close

                            htmlPanelHeader.velocity("transition.slideLeftOut");
                            htmlPanelContent.velocity("transition.slideLeftOut");
                            htmlPanel.velocity({width: 0}, {
                                delay: 500,
                                complete: function () {
                                    htmlPanel.hide();
                                }
                            }, 'ease');

                            panel.toggle = false;

                        } else {
                            // Do things on Nav Open
                            htmlPanel.show();
                            htmlPanelHeader.velocity("transition.slideLeftIn");
                            htmlPanelContent.velocity("transition.slideLeftIn");
                            htmlPanel.velocity({width: panel.width}, {
                                complete: function () {
                                    panel.toggle = true;
                                    if (panel.onOpen)
                                        panel.onOpen();
                                }
                            }, 'ease');
                        }

                        //$('#site-wrapper').toggleClass('show-nav');
                    };

                    scope.openChildren = function (selection) {

                        if (scope.active.value === selection.value)
                            return;

                        scope.active.selected = false;
                        scope.active = selection;

                        var children = $filter('filter')(scope.internalControl.children, {'parent': selection.value})

                        scope.internalControl.panels.splice(1, scope.internalControl.panels.length - 1);

                        angular.forEach(children, function (value, key) {
                            scope.internalControl.panels.push(value);
                        });


                    };

                },
                post: function (scope, element, attributes, controller, transcludeFn) {

                }

            };
        }

    };


});
Voxmodules.directive('vxProducts', function ($products, $filter, $payment, vxSession, settings, toastr) {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            products: '=',
            saveToServer: '=',
            offerteId: '=',
            jobId: '=',
            type:'@'
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxProducts/vxProducts.html',
        link: function (scope, iElement, iAttrs) {

            scope.subTotal = 0;
            scope.totalTaxes = 0;
            scope.settings = settings;

            // Watch the items array for changes
            scope.$watch('products', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    scope.$emit('itemsUpdated', scope.products);
                }
            }, true); // Deep watch the items array

            scope.checkIfValid = function ($data, item) {
                if ($data == null)
                    return 'error';

                return $data.productname == null ? 'error' : true;
            }


            scope.CalcTotals = function () {
                var subtotal = 0;
                var totaltax = 0;
                scope.taxTotalsByRate = {}; // Initialize the object to store taxes by rate

                angular.forEach(scope.products, function (product) {
                    // Calculate the subtotal for each product
                    subtotal += (product.productprice * product.productquantity);

                    // If the product has a tax rate, calculate the tax for this product
                    if (product.tax && product.tax.rate) {
                        var rate = product.tax.rate;
                        var productTax = (product.productprice * product.productquantity) * rate;

                        // Add the tax for this product to the overall total tax
                        totaltax += productTax;

                        // Accumulate tax for each rate in taxTotalsByRate
                        if (!scope.taxTotalsByRate[rate]) {
                            scope.taxTotalsByRate[rate] = 0;
                        }
                        scope.taxTotalsByRate[rate] += productTax;
                    }
                });

                // Set the total taxes and subtotal in the scope
                scope.totalTaxes = totaltax;
                scope.subTotal = subtotal;
            };


            scope.auth = function () {
                return parseInt(vxSession.userRole) > 1;
            };

            $products.allProducts(function (result) {
                scope.foundItems = result;
            });

            $payment.getTaxes(function (result) {
                scope.taxes = result;
                scope.CalcTotals();
            });

            scope.save = function (product) {
                scope.CalcTotals();
                if (typeof product.productname === 'object') {
                    var tempobj = product.productname;

                    product.idproductdetail = tempobj.idproductdetail;
                    product.idproductgroup = tempobj.idproductgroup;
                    product.productnumber = tempobj.productnumber;
                    product.productdescr = tempobj.productdescr;
                    product.productgroupname = tempobj.productgroupname;
                    product.productgroupdescription = tempobj.productgroupdescription;
                    product.showincalcicon = tempobj.showincalcicon;
                    product.showincalc = tempobj.showincalc;
                    product.productname = tempobj.productname;

                    //delete tempobj;
                }

                if (scope.saveToServer) {

                    if (scope.offerteId)
                        product.idofferte = scope.offerteId;

                    if (scope.jobId)
                        product.idjob = scope.jobId;

                    if(scope.type === 'order'){
                        $products.postOrderProduct(function (result) {
                            product.idproduct = new Product(result[0])['idproducts'];
                            toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                        }, product, product.idproduct);
                    } else{
                    $products.postProduct(function (result) {
                        product.idproduct = result[0]['idproducts'];
                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                    }, product, product.idproduct);
                    }
                }
            };


            scope.removeProduct = function (index, product) {
                scope.products.splice(index, 1);
                scope.CalcTotals();

                if (scope.saveToServer) {
                    if(scope.type === 'order'){
                        $products.deleteProductFromOrder(function (result) {
                            toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                        }, product.idproduct);
                    }else{
                    // product.taxid = product.tax.id_taxes;
                    $products.deleteProductFromJob(function (result) {
                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                    }, product.idproduct);
                    }
                }
            };

            // add user
            scope.addRow = function () {
                scope.inserted = {
                    productquantity: 0,
                    productprice: 0.00,
                    taxid: 1,
                    tax: $filter('filter')(scope.taxes, {id: 1})
                };
                scope.products.push(scope.inserted);
            };

            scope.showStatus = function (product) {
                if (scope.taxes === undefined)
                    return '';

                var selected = $filter('filter')(scope.taxes, {id: product.taxid});
                product.tax = selected[0];
                scope.CalcTotals();
                return product.tax.name;
            };

            scope.updateRow = function (data, index, item) {
                if ((data && data.idproductdetail == null) || item.idproductdetail == null)
                    return;

                scope.CalcTotals();
            };

            scope.formatLabel = function (model) {
                if (model === undefined)
                    return;

                return model.productname === undefined ? model : model.productname;
            };


        }
    }
        ;


})
;
Voxmodules.directive('vxPayment', function ($timeout, vxSession, $api,
                                            vxUtils, $payment, $filter, toastr) {
    return {
        restrict: 'EA',
        transclude: false,
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            saveToServer: '=',
            jobId: '@',
            clientId: '@',
            type:'@'
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxPayment/vxPayment.html',
        link: function (scope, element, attrs) {

            scope.ic = scope.cpi || {};
            scope.opened = {};
            scope.ic.minDate = new Date();
            scope.open = function ($event, elementOpened) {
                $event.preventDefault();
                $event.stopPropagation();

                scope.opened[elementOpened] = !scope.opened[elementOpened];
            };

            scope.ic.types = [
                {label: $filter('translate')("QUOTE"), status: 'quote'},
                {label: $filter('translate')("INVOICE"), status: 'invoice'},
            ];

            scope.ic.invoicestatuses = [
                {label: $filter('translate')("DECLINED"), status: 'declined'},
                {label: $filter('translate')("OVERDUE"), status: 'overdue'},
                {label: $filter('translate')("SEND"), status: 'send'},
                {label: $filter('translate')("NEW"), status: 'new'},
                {label: $filter('translate')("ACCEPTED"), status: 'accepted'},
                {label: $filter('translate')("PAYED"), status: 'payment'},
                {label: $filter('translate')("UNKOWN"), status: 'unkown'}
            ];

            scope.checkDate = function (data) {
                return (data > 0 && data < 911100000) || angular.isUndefined(data) || data === null ? false : true;
            };
            //METHOD CHECKS
            if (scope.ic.updateAdress === undefined) {
                scope.ic.updateAdress = function (data) {
                    $payment.postInvoiceAdress(function (result) {

                    }, scope.ic.item);
                };
            }

            if (scope.ic.updateInvoice === undefined) {

                scope.ic.updateInvoice = function (data, arg) {
                    if (!scope.checkDate(scope.ic.item.duedate))
                        return vxUtils.loc('CHECK_DATA');

                    scope.ic.item.idjob = scope.jobId;
                    scope.ic.item.idClient = scope.clientId;
                    scope.oldvalue = scope.ic.item;

                    scope.ic.item.duedate = scope.ic.item.duedate / 1000;
                    scope.ic.item.creationdate = scope.ic.item.creationdate / 1000;
                    scope.ic.item.senddate = scope.ic.item.senddate / 1000;

                    $payment.postPaymentItem(function (result) {

                        scope.ic.item.duedate = scope.oldvalue.duedate * 1000;
                        scope.ic.item.senddate = scope.oldvalue.senddate * 1000;
                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                    }, scope.ic.item);
                };
            }

            scope.ic.generateInvoiceNR = function () {
if(scope.type === 'order'){
    $api.post($api.adress.setPaymentNROrder,
        function (result) {
            scope.ic.item.invoicenr = result;
        }, {idPayment: scope.ic.item.idofferte})
} else {
    $api.post($api.adress.setPaymentNR,
        function (result) {
            scope.ic.item.invoicenr = result;
        }, {idPayment: scope.ic.item.idofferte})
}
            };

            if (scope.ic.createNewOfferte === undefined) {
                scope.ic.createNewOfferte = function () {

                    var p = new PaymentItem();
                    p.idPayment = 0;
                    p.idClient = scope.clientId;
                    p.idjob = scope.jobId;
                    p.idUser = vxSession.user.idUser;
                    p.creationdate = Math.round(new Date().getTime() / 1000);
                    p.duedate = Math.round(new Date().getTime() / 1000);
                    p.type = "quote";
                    p.status = "new";
                    p.senddate = null;
                    p.idinvoiceadress = scope.ic.item.idinvoiceadress;
                    p.fromOldJob = true;
                    $payment.postPaymentItem(function (quote) {
                        $payment.getPaymentItemByJob(function (result) {
                            scope.ic.hideNewData = false;
                            scope.ic.item = result;
                        }, scope.jobId);

                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                    }, p);
                }
            }


        }
    };
});

Voxmodules.directive('vxEmployee', function (vxUtils, $filter, $users, toastr, vxSession) {
    return {
        restrict: 'EA',
        transclude: false,
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            saveToServer: '=',
            jobId: '@'
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxEmployee/vxEmployee.html',
        link: function (scope, element, attrs) {

            scope.ic = scope.cpi || {};
            scope.opened = {};

            scope.hoveringOver = function (value) {
                scope.overStar = value;
                scope.percent = 100 * (value / 5);
                scope.experience = $filter('vxExperience')(value);
            };
            scope.ic.show = function (ele) {
                if (parseInt(vxSession.userRole) === 1) {
                    if (angular.isDefined(ele))
                        ele.hide();
                }
                return false;
            };
            scope.open = function ($event, elementOpened) {
                $event.preventDefault();
                $event.stopPropagation();

                scope.opened[elementOpened] = !scope.opened[elementOpened];
            };

            scope.getPicture = function (urlToPicture) {
                if (urlToPicture == null)
                    return '/src/assets/img/user.svg'
                else {
                    return `/`
                }
            };

            scope.ic.invoicestatuses = [
                {label: $filter('translate')("DECLINED"), status: 'declined'},
                {label: $filter('translate')("OVERDUE"), status: 'overdue'},
                {label: $filter('translate')("SEND"), status: 'send'},
                {label: $filter('translate')("NEW"), status: 'new'},
                {label: $filter('translate')("ACCEPTED"), status: 'accepted'},
                {label: $filter('translate')("PAYED"), status: 'payment'},
                {label: $filter('translate')("UNKOWN"), status: 'unkown'}
            ];

            //METHOD CHECKS
            if (scope.ic.update === undefined) {

                scope.ic.update = function (data, arg) {
                    var oldbday = scope.ic.user.birthday;
                    var oldHday = scope.ic.user.hireddate;
                    $users.postUser(scope.ic.user, function () {
                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                        scope.ic.user.birthday = moment(oldbday).unix() * 1000;
                        scope.ic.user.hireddate = moment(oldHday).unix() * 1000;
                    });

                };
            }

            scope.ic.check = function (e) {
                scope.ic.user[e] = scope.ic.user[e] == 1 ? 0 : 1;
                scope.ic.update();
            };


        }
    };
});
Voxmodules.directive('vxWorknotes', function ($filter, msgBusActions, $customer,
                                              $users, $job, $locale,settings,
                                              vxUtils, msgBus, toastr, $timeout) {

    return {
        restrict: 'E',
        // declare the directive scope as private (and empty)
        scope: {
            panelsize: '=',
            headsize: '=',
            cpi: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxWorknotes/vxWorknotes.html',
        link: function (scope, iElement, iAttrs) {
            scope.ic = scope.cpi || {};
            scope.locale = $locale;
            scope.settings = settings;
            scope.deleteTooltip = $filter('translate')('DELETE');
            scope.isCollapsed = true;
            scope.worknote = {};
            scope.foundJobs = [];
            scope.maxDate = moment().startOf('day').unix() * 1000;

            var list = iElement.find("div[toggle-target='1']");
            var newWorknote = iElement.find("div[toggle-target='2']");

            scope.getJobsForThatDay = function (callback) {

                $users.getUserEvents(function (data) {

                    scope.foundJobs = $filter('filter')(data, {type: 'job'});
                    scope.foundJobs.splice(0, 0, {
                        jobid: 0, toString: function () {
                            return "Geen klus";
                        }
                    });
                    if (callback)
                        callback();
                }, scope.ic.userID, scope.worknote.date / 1000, (scope.worknote.date / 1000) + 86400);
            };

            scope.changeInput = function () {
                var dur = 340;
                if (scope.isCollapsed) {
                    list.velocity("transition.slideUpOut", {
                        duration: dur,
                        complete: function () {
                            list.hide();
                            newWorknote.velocity("transition.slideUpIn", {
                                duration: dur,
                                complete: function () {

                                }
                            }, 'easeout');
                        }
                    }, 'ease');


                    scope.isCollapsed = false;
                } else {

                    newWorknote.velocity("transition.slideUpOut", {
                        duration: dur,
                        complete: function () {
                            newWorknote.hide();
                            list.velocity("transition.slideUpIn", {
                                duration: dur,
                                complete: function () {

                                }
                            }, 'easeout');
                        }
                    }, 'ease');
                    scope.isCollapsed = true;
                }

            };

            scope.saveWorknote = function () {
                var old = angular.copy(scope.worknote);
                old.id_user = scope.ic.userID;
                old.created = moment().unix();
                var hoursWorked = parseFloat(scope.worknote.hours.toString().replace(",", ".")); // Parsed , to . (dutch notation)
                old.hours = hoursWorked * 3600;
                old.traveltime = scope.worknote.traveltime * 3600;
                old.date = moment(scope.worknote.date).unix();
                $users.postWorknote(old, function () {
                    toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('WORKNOTE') + vxUtils.loc('ACTION_ADDED'));
                    scope.changeInput();
                    scope.worknote = {};
                    scope.ic.refresh();
                    var obj = vxUtils.findObjectOnParents(scope, 'worknotesCpi', true);
                    obj.p.worknotesCpi.dateChanged(obj.p.lastDate);
                });


            };

            scope.resize = function () {
                var i = (scope.panelsize.height - scope.headsize.height - 95 - scope.filtersize.height - scope.bottomsize.height);
                msgBus.emitMsg("UpdateScroll", {});
                return i;
            };

            scope.delete = function (event) {
                $customer.deleteEvent(function (result) {
                    toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('NOTE') + vxUtils.loc('ACTION_DELETED'));
                    angular.forEach(scope.events, function (value, key) {
                            if (event.id === value.id) {
                                scope.events.splice(key, 1);
                            }
                        }
                    );
                }, event.id, event.type);
            };

            scope.openJob = function (event) {
                msgBus.emitMsg(msgBusActions.shellOpenTabByType, {
                    type: 'job', id: event.jobnumber
                });
            };

            scope.ic.refresh = function () {
                scope.ic.selectionChanged();
            };

            scope.ic.selectionChanged = function () {

                var res = $filter('filter')(scope.ic.filters,
                    {ticked: true, field: 'filled'});
                var items = [];

                angular.forEach(res, function (value, key) {
                    var jobs = $filter('filter')(scope.ic.all,
                        {filled: value.state});

                    angular.forEach(jobs, function (valuea, keya) {
                        items.push(valuea);
                    })
                });

                scope.ic.items = $filter('orderBy')(items, 'startdate', true);
                msgBus.emitMsg('UpdateScroll', {});
                $timeout(function () {
                    newWorknote.hide();
                }, 250);
            }
        }
    };


});

Voxmodules.directive('vxAvailability', function ($filter, msgBusActions, $customer,
                                                 $users, $job, vxSession, $locale,
                                                 vxUtils, msgBus, toastr) {

    return {
        restrict: 'E',
        // declare the directive scope as private (and empty)
        scope: {
            panelsize: '=',
            headsize: '=',
            cpi: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxAvailability/vxAvailability.html',
        link: function (scope, iElement, iAttrs) {
            scope.ic = scope.cpi || {};
            scope.locale = $locale;
            scope.isCollapsed = false;
            scope.auth = function () {
                return vxSession.auth(2);
            };
            // TODO: translation not here
            scope.recurrenceTypes = [
                {type: 0, label: "Eenmalig"},
                {type: 1, label: "Dagelijks"},
                {type: 2, label: "Wekelijks"},
                {type: 3, label: "Maandelijks"},
                {type: 4, label: "Jaarlijks"}
            ];
            scope.plann = {};
            scope.avail = {
                type: 0,
                start_date: moment().unix() * 1000,
                end_date: moment().unix() * 1000
            };

            var list = iElement.find("div[toggle-target='1']");
            var newWorknote = iElement.find("div[toggle-target='2']");

            $users.getEventTypes(function (result) {
                scope.eventTypes = result;
                scope.avail.id_eventtype = 1 + "";
            });

            scope.getJobsForThatDay = function (callback) {
                $job.getJobsBetween(function (results) {

                    scope.foundJobs = $filter('removeWith')(results, {status: 'Annulering'});
                    if (callback)
                        callback();
                }, scope.plann.date / 1000, (scope.plann.date / 1000) + 86400);
            };

            scope.changeInput = function () {
                var dur = 340;
                if (scope.isCollapsed) {
                    list.velocity("transition.slideUpOut", {
                        duration: dur,
                        complete: function () {
                            list.hide();
                            newWorknote.velocity("transition.slideUpIn", {
                                duration: dur,
                                complete: function () {

                                }
                            }, 'easeout');
                        }
                    }, 'ease');


                    scope.isCollapsed = false;
                } else {

                    newWorknote.velocity("transition.slideUpOut", {
                        duration: dur,
                        complete: function () {
                            newWorknote.hide();
                            list.velocity("transition.slideUpIn", {
                                duration: dur,
                                complete: function () {

                                }
                            }, 'easeout');
                        }
                    }, 'ease');
                    scope.isCollapsed = true;
                }

            };

            msgBus.onMsg(msgBusActions.calendarClicked, function (sender, mssg) {
                    var obj = vxUtils.findObjectOnParents(scope, 'PageHash');

                    if (mssg.id === obj) {
                        scope.ic.isCollapsed = true;

                        $users.getAvailability(function (result) {
                            var old = result[0];
                            var event =
                                {
                                    eventtitle: old.eventtitle,
                                    start_date: old.startdate,
                                    end_date: old.enddate,
                                    period_start: old.startdate,
                                    period_end: old.dbo['period_end'] * 1000,
                                    id_user: old.dbo['id_user'],
                                    type: parseInt(old.dbo['type']),
                                    wholeday: parseInt(old.dbo['wholeday']) === 1,
                                    remarks: old.remarks,
                                    id_eventtype: old.dbo['id_eventtype'] + '',
                                    idEvent: old.id
                                };
                            scope.avail = event;

                        }, mssg.load.id);
                    }

                }, scope
            );

            scope.undoAvail = function () {
                scope.avail = {
                    type: 0,
                    start_date: moment().unix() * 1000,
                    end_date: moment().unix() * 1000,
                    id_eventtype: 1 + ''
                };
            };

            scope.savePlanning = function () {
                var obj = vxUtils.findObjectOnParents(scope, 'empExpPanelCpi', true);
                var id_user = obj.p.user.idUser;
                var user = {status: 0, idUser: id_user};
                $job.postJobcrew(function () {
                        toastr.success(vxUtils.loc('ALLEMPLOYEESUCCEFULLPLANNED'),
                            vxUtils.loc('SUCCES'));
                        scope.foundJobs = [];
                        scope.plann = {
                            date: moment().unix() * 1000
                        };
                        msgBus.emitMsg(msgBusActions.calendarReload, obj.p.PageHash);
                    }, [user], scope.plann.jobid,
                    function (e, g, j, l) {
                        toastr.error(vxUtils.loc('UNSUCCEFULLERRORMESSAGE'), vxUtils.loc('UNSUCCESFULL'));
                    }
                );

            };

            scope.removeAvail = function () {
                var obj = vxUtils.findObjectOnParents(scope, 'empExpPanelCpi', true);
                $users.deleteAvailability(scope.avail.idEvent, function () {
                    toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('AVAILABILITY') + vxUtils.loc('ACTION_DELETED'));
                    msgBus.emitMsg(msgBusActions.calendarReload, obj.p.PageHash);
                    scope.avail = {
                        type: 0,
                        start_date: moment().unix() * 1000,
                        end_date: moment().unix() * 1000,
                        id_eventtype: 1 + ''

                    };

                });
            };

            scope.saveAvail = function () {
                var obj = vxUtils.findObjectOnParents(scope, 'empExpPanelCpi', true);

                var old = angular.copy(scope.avail);
                old.start_date = moment(old.start_date).unix();
                old.end_date = moment(old.end_date).unix();
                old.period_start = moment(old.period_start).unix();
                old.period_end = moment(old.period_end).unix();
                old.id_user = obj.p.user.idUser;

                $users.postAvailability(old, function () {
                    toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('AVAILABILITY') + vxUtils.loc('ACTION_ADDED'));
                    // scope.changeInput();
                    scope.avail = {
                        type: 0,
                        start_date: moment().unix() * 1000,
                        end_date: moment().unix() * 1000,
                        id_eventtype: 1 + ''

                    };
                    msgBus.emitMsg(msgBusActions.calendarReload, obj.p.PageHash);

                    //obj.p.empExpPanelCpi.closeAll();
                    //msgBus.emitMsg("UpdateScroll", {});
                });
            };

            scope.changeInput();
        }
    }
        ;


})
;

Voxmodules.directive('vxAdress', function (Geocoder, $timeout, vxSession, $order,
                                           $users, vxUtils, $job, $customer, toastr, $payment) {
    return {
        restrict: 'EA',
        transclude: false,
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            saveToServer: '=',
            type: '@',
            showPersonalInfo: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxAdress/vxAdress.html',
        compile: function (element, attrs, transclude) {
            return {
                pre: function (scope, element, attributes, controller, transcludeFn) {

                    scope.internalControl = scope.cpi || {};


                    scope.getRoute = function () {
                        var a = scope.internalControl.adress;
                        var url = 'https://maps.google.nl/maps?saddr=Willebrordusstraat,6,Rotterdam&daddr=' + a.country + '+' + a.city + ',' + a.adress + '&hl=nl&ie=UTF8'
                        window.open(url);
                    };

                    scope.openStreetView = function (latitude, longitude) {

                        var mapsUrl = "http://maps.google.com/maps?q=&layer=c&cbll=" + scope.internalControl.center.lat + "," + scope.internalControl.center.lng;
                        window.open(mapsUrl, '_blank');

                    }

                    if (scope.internalControl.updateAdress === undefined) {
                        switch (scope.type) {
                            case 'location':
                                scope.internalControl.updateAdress = function () {
                                    $job.postLocation(function (location) {
                                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                                    }, scope.internalControl.adress);
                                };
                                break;
                            case 'client':
                                scope.internalControl.updateAdress = function () {
                                    $customer.newCustomer(function (location) {
                                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                                    }, scope.internalControl.adress);
                                };
                                break;
                            case 'invoice':
                                scope.internalControl.updateAdress = function () {
                                    $payment.postInvoiceAdress(function (location) {
                                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                                    }, scope.internalControl.adress);
                                };
                                break;
                            case 'employee':
                                scope.internalControl.updateAdress = function () {
                                    $users.postUser(scope.internalControl.adress, function () {
                                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                                    });
                                };
                                break;
                            case 'order':
                                scope.internalControl.updateAdress = function () {
                                    $order.postLocation(scope.internalControl.adress, function () {
                                        toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                                    });
                                };
                                break;
                        }
                    }
                    scope.internalControl.show = function (ele) {
                        if (parseInt(vxSession.userRole) == 1)
                            ele.hide();
                    };
                    scope.countries = ['Nederland', 'België', 'Frankrijk', 'Duitsland', 'Luxemburg'];

                    scope.internalControl.map = {
                        control: {},
                        center: {
                            latitude: 45,
                            longitude: -73
                        },
                        options: {
                            streetViewControl: true,
                            panControl: false,
                            maxZoom: 20,
                            minZoom: 3
                        },
                        zoom: 12,
                        dragging: false,
                        bounds: {},
                        markers: []
                    };

                    scope.$watch('internalControl.adress', function (newValue, oldValue) {
                        if (scope.internalControl.adress !== undefined) {
                            // Assuming you have Leaflet map initialization somewhere like this:
                            // var map = L.map('map').setView([initialLat, initialLng], initialZoom);

                            // check if center has changed, this will stop extra calls to the api
                            if (scope.internalControl.center != null
                                && scope.internalControl.lastCenter != null
                                && scope.internalControl.lastCenter.lat === scope.internalControl.center.lat
                                && scope.internalControl.lastCenter.lng === scope.internalControl.center.lng)
                                return;

                            const address = `${scope.internalControl.adress.country}, ${scope.internalControl.adress.city}, ${scope.internalControl.adress.adress}`;
                            const geocoder = new GeoSearch.OpenStreetMapProvider();

                            geocoder.search({query: address.replace(/undefined/,'')}).then(function (results) {
                                if (results && results[0]) {
                                    const result = results[0];
                                    const latlng = new L.LatLng(result.y, result.x);

                                    // Set the center of the map
                                    scope.internalControl.center = {
                                        lat: result.y,
                                        lng: result.x,
                                        zoom: 15
                                    };

                                    scope.internalControl.lastCenter = scope.internalControl.center;

                                    scope.internalControl.id = vxUtils.generateQuickGuid();
                                    scope.internalControl.defaults = {
                                        attribution: 'Data by &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, under <a href="https://opendatacommons.org/licenses/odbl/">ODbL.</a>'
                                    }


                                    scope.internalControl.markers = {
                                        internal: {
                                            lat: result.y,
                                            lng: result.x
                                        }
                                    }
                                    // // If you need to push the marker to a marker array in your scope
                                    // scope.internalControl.map.markers.push({
                                    //     id: 2,
                                    //     latitude: result.y,
                                    //     longitude: result.x
                                    // });

                                    scope.internalControl.showMap = true;
                                    // You may need to trigger AngularJS's digest cycle if this code isn't run within an AngularJS context
                                    scope.$apply();
                                }
                            }).catch(function (error) {
                                console.error(error);
                            });

                        }
                    }, true);


                }
            };
        }
    };
});
Voxmodules.directive('vxProduct', function ($products, $filter) {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            saveToServer: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxProduct/vxProduct.html',
        link: function (scope, element, attrs) {

            scope.internalControl = scope.cpi || {};

            //METHOD CHECKS
            if (scope.internalControl.update === undefined) {
                console.warn("No update method provided");
                scope.internalControl.update = function (data) {
                    scope.updateProduct();
                }
            }

            $products.allProductGroups(function (result) {
                scope.internalControl.groups = result;
            });

            scope.internalControl.updateProductGroup = function (group) {
                var selected = $filter('filter')(scope.internalControl.groups, {productgroupname: group})[0];
                scope.internalControl.product.idproductgroup = selected.productgroupid;

                $products.lastProductNumber(function (result) {
                    scope.internalControl.product.productnumber = result + 1;
                    //scope.internalControl.product.cleannumber = result + 1;
                    scope.updateProduct(scope.internalControl.product.productnumber, true);
                }, scope.internalControl.product.idproductgroup);
            };
            scope.updateProduct = function (productnumber, changeGroup) {
                $products.postProductDetail(function (result) {
                    scope.internalControl.product = result;
                }, scope.internalControl.product);

            };

        }
    };
});
Voxmodules.directive('vxOrder', function ($customer, $order, $filter, toastr) {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            saveToServer: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxOrder/vxOrder.html',
        link: function (scope, element, attrs) {

            scope.ic = scope.cpi || {};

            $order.getAllStatuses(function (result) {
                scope.ic.orderStatuses = result;
            });

            $customer.getAllCustomerKinds(result => scope.ic.customerKinds = angular.fromJson(result));

            //METHOD CHECKS
            if (scope.ic.update === undefined) {
                scope.ic.update = function ($data) {

                    var type = $filter('filter')(scope.ic.orderStatuses,
                        {id: scope.ic.item.orderStatusFK});
                    scope.ic.item.status = type[0].name;


                    const toSave = Object.assign({},scope.ic.item);

                    toSave.deliveryDate = parseUnixToSeconds(toSave.deliveryDate);
                    toSave.creationDate = parseUnixToSeconds(toSave.creationDate);
                    toSave.orderDate = parseUnixToSeconds(toSave.orderDate);
                    $order.updateOrder(toSave.id,toSave , (result)=>{
                        toastr.success($filter('translate')('UPDATESUCCESFULL'), $filter('translate')('SUCCES'));
                    })

                }
            }




        }
    };
});
Voxmodules.directive('vxCustomer', function ($customer, $filter, toastr) {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            saveToServer: '='
        },
        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxCustomer/vxCustomer.html',
        link: function (scope, element, attrs) {

            scope.ic = scope.cpi || {};

            $customer.getAllTypes(function (result) {
                scope.ic.kindofcustomers = angular.fromJson(result);
            });

            $customer.getAllCustomerKinds(result => scope.ic.customerKinds = angular.fromJson(result));

            //METHOD CHECKS
            if (scope.ic.updateUser === undefined) {
                scope.ic.updateUser = function ($data) {
                    var type = $filter('filter')(scope.ic.kindofcustomers,
                        {client_type_name_org: scope.ic.cust.relationtype});
                    scope.ic.cust.relationId = type[0].id_client_type;

                    var type = $filter('filter')(scope.ic.customerKinds,
                        {id_customerkind: scope.ic.cust.customerKindId});
                    scope.ic.cust.customerKindId = type[0].id_customerkind;
                    scope.ic.cust.customerKind = type[0].type;

                    $customer.newCustomer(function (result) {
                        toastr.success($filter('translate')('CUSTOMERUDATESUCCESFULL'), $filter('translate')('SUCCES'));
                    }, scope.ic.cust);
                }
            }


        }
    };
});

Voxmodules.directive('vxJob', function ($job, sweet, vxUtils, $email, mailevents, $depot, vxSession, $auth) {
    return {
        restrict: 'EA',
        // declare the directive scope as private (and empty)
        scope: {
            cpi: '=',
            saveToServer: '=',
            locale: '='
        },

        // add behaviour to our buttons and use a variable value
        templateUrl: '/src/modules/vxJob/vxJob.html',
        compile: function (element, attrs, transclude) {
            return {
                pre: function (scope, element, attributes, controller, transcludeFn) {


                    scope.ic = scope.cpi || {};
                    scope.ic.show = function (ele) {
                        if (parseInt(vxSession.userRole) == 1)
                            ele.hide();
                    };

                    $auth.getAllCompanies(function(result){
                        scope.ic.allCompanies = result;
                    });

                    $job.allKindOfParties(function (result) {
                        scope.ic.kindofjobs = result;
                    });

                    $depot.getAllDepots(function (result) {
                        scope.ic.alldepots = result;
                    });

                    $job.allJobStatuses(function (result) {
                        scope.ic.jobstatuses = result;
                    });
                    scope.opened = {};

                    scope.sendUpdate = function () {
                        scope.sendEmail(scope.ic.item.jobid, mailevents.JOBCHANGED);
                    };

                    scope.open = function ($event, elementOpened) {
                        $event.preventDefault();
                        $event.stopPropagation();

                        scope.opened[elementOpened] = !scope.opened[elementOpened];
                    };

                    scope.checkDate = function (data) {
                        return (data > 0 && data < 10000) || angular.isUndefined(data) || data === null ? false : true;
                    };
                    scope.checkTime = function (data) {
                        return data === null || data === undefined ? false : true;
                    };
                    //METHOD CHECKS
                    if (scope.ic.update === undefined) {
                        console.warn("No update method provided");
                        scope.ic.update = function (data, type) {


                            var start, end, entrance, startdate, valid;
                            scope.oenddate = scope.ic.item.enddate;
                            scope.ostartdate = scope.ic.item.startdate;
                            scope.oentrance = scope.ic.item.entrance;
                            scope.ojobcreationdate = scope.ic.item.jobcreationdate;
                            var isFloat = function (n) {
                                return n === +n && n !== (n | 0);
                            }

                            var isInteger = function (n) {
                                return n === +n && n === (n | 0);
                            }
                            var UpdateEndTime = function (date) {

                                start = new Date(scope.ic.item.enddate).toDateString();
                                end = (new Date(start).getTime()) + (date.getHours() * 3600000) + (date.getMinutes() * 60000);
                                scope.ic.item.enddate = Math.round(end);
                            };

                            var UpdateStartTime = function (date) {

                                start = new Date(scope.ic.item.startdate).toDateString();
                                start = new Date(start).getTime() + (date.getHours() * 3600000) + (date.getMinutes() * 60000);
                                scope.ostartdate = start;
                                scope.ic.item.startdate = Math.round(start);
                            };
                            var UpdateEntranceTime = function (date) {

                                start = new Date(scope.ic.item.startdate).toDateString();
                                entrance = (new Date(start).getTime()) + (date.getHours() * 3600000) + (date.getMinutes() * 60000);
                                scope.ic.item.entrance = Math.round(entrance);
                            };

                            if (type === 'startdate') {
                                valid = scope.checkDate(data)
                                if (!valid) return 'Controleer datum';

                                var laststart = scope.ic.item.startdate;
                                startdate = (new Date(data).getTime());
                                scope.ic.item.startdate = Math.round(startdate);
                                UpdateStartTime(new Date(laststart));
                                UpdateEndTime(new Date(scope.ic.item.enddate));
                                UpdateEntranceTime(new Date(scope.ic.item.entrance));
                            }
                            if (type === 'enddate') {
                                valid = scope.checkDate(data)
                                if (!valid) return 'Controleer datum';

                                var laststart = scope.ic.item.enddate;
                                startdate = (new Date(data).getTime());
                                scope.ic.item.enddate = Math.round(startdate);
                                UpdateEndTime(new Date(scope.ic.item.enddate));
                            } else if (type === 'starttime') {
                                valid = scope.checkTime(data)
                                if (!valid) return 'Controleer tijd';

                                UpdateStartTime(data);
                            } else if (type === 'endtime') {
                                valid = scope.checkTime(data)
                                if (!valid) return 'Controleer tijd';

                                UpdateEndTime(new Date(scope.ic.item.enddate));
                            } else if (type === 'entrancetime') {
                                valid = scope.checkTime(data)
                                if (!valid) return 'Controleer tijd';

                                UpdateEntranceTime(new Date(scope.ic.item.entrance));
                            }
                            //  if (!valid)return;
                            scope.ic.item.entrance = Math.round(scope.ic.item.entrance / 1000.0);
                            scope.ic.item.startdate = Math.round(scope.ic.item.startdate / 1000.0);
                            scope.ic.item.enddate = Math.round(scope.ic.item.enddate / 1000.0);
                            scope.ic.item.jobcreationdate = Math.round(scope.ic.item.jobcreationdate / 1000.0);

                            //todo cache values
                            angular.forEach(scope.ic.jobstatuses, function (value, key) {
                                if (value.jobstatusname === scope.ic.item.status)
                                    scope.ic.item.idStatus = value.idjobstatuses;
                            });

                            angular.forEach(scope.ic.kindofjobs, function (value, key) {
                                if (value.type === scope.ic.item.kindofjob)
                                    scope.ic.item.idPartykind = value.id_partykind;
                            });
                            scope.lasttype = type;
                            $job.postJob(function (job) {
                                if (_.isNaN(job.entrance))
                                    return;

                                scope.ic.item.entrance = job.entrance;
                                console.log(job.entrance);
                                scope.ic.item.startdate = job.startdate;
                                console.log(job.startdate);
                                scope.ic.item.enddate = job.enddate;
                                console.log(job.enddate);

                                scope.ic.item.jobcreationdate = scope.ojobcreationdate;
                                switch (scope.lasttype) {
                                    case 'jobstatus':
                                        scope.sendEmail(job.idjob);
                                        break;
                                }
                                // $(window).resize();
                                scope.changed = true;
                            }, scope.ic.item);

                            return false;
                        }
                    }

                    scope.sendEmail = function (idJob, mailEvent) {
                        sweet.show({
                            title: 'Confirm',
                            text: vxUtils.loc('SENDMAILJOBSTATUS'),
                            type: 'success',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: vxUtils.loc('YES'),
                            closeOnConfirm: false
                        }, function () {
                            var e = mailevents.JOBCHANGED;
                            if (mailEvent) {
                                e = mailEvent;
                            } else {
                                switch (scope.ic.item.status) {
                                    case "Optie":
                                        e = mailevents.JOBCHANGED;
                                        break;
                                    case "Bevestigd":
                                        e = mailevents.JOBACCEPTED;
                                        break;
                                    case "Annulering":
                                        e = mailevents.JOBDECLINED;
                                        break;
                                }
                            }
                            $email.sendEvent(e, {type: 'job', id: idJob}, function () {
                                scope.changed = false;
                                sweet.show(vxUtils.loc('SUCCES'), vxUtils.loc('SENDMAILSUCCESFULL'), 'success');
                            });
                        });
                    }
                }
            };
        }
    };
});

Voxmodules.directive("vxSearchItem", function (msgBus, sweet, msgBusActions, $timeout,
                                               toastr, $filter, $job, $api, settings,
                                               $users, vxUtils, vxSession, $email, $locale) {
    return {
        template: '<ng-include  src="getTemplateUrl()"/>',
        scope: {
            item: '=item',
            openitem: '='
        },
        restrict: 'E',

        compile: function (element, attrs, transclude) {
            return {
                pre: function (scope, element, attributes, controller, transcludeFn) {
                    scope.timeline = attributes.timeline !== undefined;
                    scope.locale = $locale;
                    scope.settings = settings;
                    function validateTimes(entrancetime, starttime, endtime) {

                        if (!entrancetime && !starttime && !endtime) {
                            return true; // If all times are 0 its a delete 👌
                        }
                        if (!entrancetime || !starttime || !endtime) {
                            return false; // None of the times can be null or 0
                        }

                        if (entrancetime >= starttime) {
                            return false; // entrancetime should be before starttime
                        }

                        if (starttime >= endtime) {
                            return false; // starttime should be before endtime
                        }

                        return true;
                    }


                    //function used on the ng-include to resolve the template
                    scope.getTemplateUrl = function () {
                        if (scope.item.overridetemplate !== undefined)
                            return scope.item.overridetemplate;

                        scope.job = vxUtils.findObjectOnParents(scope, 'job');
                        var root = '/src/itemtemplates/search/';

                        if (scope.item.type === 'worknote')
                            scope.item.editable = moment(scope.item.startdate).isBefore(moment());

                        if (scope.item.type === 'crew') {
                            scope.item.employeestatuses = [{
                                value: 1,
                                label: $filter('translate')('ACCEPTED')
                            }, {
                                value: 0,
                                label: $filter('translate')('AWAITING')
                            }, {
                                value: 2,
                                label: $filter('translate')('DECLINED')
                            }];

                            scope.item.show = function (ele) {
                                if (parseInt(vxSession.userRole) == 1 && !vxSession.sameUser(scope.item.idUser))
                                    ele.hide();
                            };
                        }

                        scope.item.update = function (data, name) {

                            var type = scope.item.type;
                            if (name === 'hourRate')
                                type = 'hourRate';
                            if (name === 'accept')
                                type = 'crew';
                            var obj = vxUtils.findObjectOnParents(scope, 'empExpPanelCpi', true);
                            var objt = vxUtils.findObjectOnParents(scope, 'ic', true);

                            if (data === 'plan') {
                                type = "plan";
                                scope.item.status = scope.item.status > -1 ? -2 : -1;
                                msgBus.emitMsg(msgBusActions.saveEmployee, [scope.item, obj.p.PageHash]);
                            }
                            if (data === 'time')
                                type = 'time';

                            switch (type) {
                                case 'crew':
                                    var uitem = {
                                        status: data.toString() || scope.item.status,
                                        idUser: scope.item.idUser,
                                        idJobcrew: scope.item.idjobcrew,
                                        idJob: scope.item.idjob,
                                        hour_rate_id: scope.item.hour_rate_id,
                                        creationdate: moment(scope.item.creationdate).unix(),
                                        //responsedate: moment(scope.item.responsedate).unix(),
                                        starttime: moment(scope.item.starttime).unix(),
                                        endtime: moment(scope.item.endtime).unix(),
                                        entrancetime: moment(scope.item.entrancetime).unix()
                                    };

                                    $job.updateJobcrew(function (result) {
                                        scope.item.responsedate =  parseUnixToDate(result.responsedate);
                                        toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('SCHEDULE') + vxUtils.loc('ACTION_CHANGED'));
                                        if (data.toString())
                                            scope.item.status = data;

                                        msgBus.emitMsg(msgBusActions.calendarReload, obj.p.PageHash);
                                        if (objt.o.delete)
                                            objt.o.delete(scope.item);
                                    }, uitem);
                                    break;
                                case 'time':
                                    var entrancetime = moment(scope.item.entrancetime).unix();
                                    var starttime = moment(scope.item.starttime).unix();
                                    var endtime = moment(scope.item.endtime).unix();

                                    if (validateTimes(entrancetime, starttime, endtime)) {
                                        var uitem = {
                                            status: scope.item.status,
                                            idUser: scope.item.idUser,
                                            idJobcrew: scope.item.idjobcrew,
                                            idJob: scope.item.idjob,
                                            hour_rate_id: scope.item.hour_rate_id,
                                            creationdate: moment(scope.item.creationdate).unix(),
                                            starttime: starttime,
                                            endtime: endtime,
                                            entrancetime: entrancetime,
                                        };

                                        $job.updateJobcrew(function (result) {
                                            scope.item.responsedate =  parseUnixToDate(result.responsedate);
                                            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('SCHEDULE') + vxUtils.loc('ACTION_CHANGED'));
                                        }, uitem);
                                    } else {
                                        sweet.show({
                                            title: vxUtils.loc('ERROR'),
                                            text: vxUtils.loc('INVALID_TIMES'),
                                            type: 'error',
                                            showCancelButton: false,
                                            confirmButtonColor: '#DD6B55',
                                            confirmButtonText: vxUtils.loc('OKE'),
                                            closeOnConfirm: true
                                        },  ()=> {

                                        });
                                    }

                                    break;
                                case 'hourRate':

                                function getAmount(item) {
                                    var out = '00';
                                    if (parseInt(item._amountcents) < 10 && item._amountcents !== '00')
                                        out = '0' + item._amountcents;

                                    out = ((item._amountwhole) + out);

                                    return out;
                                }

                                    $api.post($api.adress.hourrate,
                                        function (data) {
                                            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('AMOUNT') + vxUtils.loc('ACTION_CHANGED'))
                                        }, {
                                            id: scope.item.id,
                                            amount: getAmount(scope.item)
                                        });
                                    break;
                            }
                        };

                        scope.item.getEvents = function (item) {
                            scope.item.isCollapsed = !scope.item.isCollapsed;

                            if (scope.item.isCollapsed) {
                                $users.getUserEvents(function (results) {
                                        scope.item.events = results;
                                    }, scope.item.idUser, moment(scope.job.entrance).startOf('day').unix(),
                                    moment(scope.job.enddate).add(1, 'days').endOf('day').unix()
                                );
                            }
                        };
                        scope.item.auth = function () {
                            return parseInt(vxSession.userRole) > 1;
                        };

                        scope.item.deleteSeachItem = function (event) {
                            switch (event._type || event.type) {
                                case 'worknote':
                                    sweet.show({
                                        title: vxUtils.loc('CONFIRM'),
                                        text: vxUtils.loc('REMOVEWORKNOTE'),
                                        type: 'success',
                                        showCancelButton: true,
                                        confirmButtonColor: '#DD6B55',
                                        confirmButtonText: vxUtils.loc('YES'),
                                        closeOnConfirm: true
                                    }, function () {
                                        $users.deleteWorknote(event.idTimechart, function () {
                                            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('WORKNOTE') + vxUtils.loc('ACTION_DELETED'));
                                            var obj = vxUtils.findObjectOnParents(scope, 'worknotesCpi', true);
                                            obj.p.worknotesCpi.dateChanged(obj.p.lastDate);
                                        });
                                    });

                                    break;
                                case 'car':
                                    sweet.show({
                                        title: vxUtils.loc('CONFIRM'),
                                        text: vxUtils.loc('REMOVECAR'),
                                        type: 'success',
                                        showCancelButton: true,
                                        confirmButtonColor: '#DD6B55',
                                        confirmButtonText: vxUtils.loc('YES'),
                                        closeOnConfirm: true
                                    }, function () {
                                        $api.del($api.adress.plancar + '/' + event.vox_car_planned_id, function () {
                                            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('CAR') + vxUtils.loc('ACTION_DELETED'));
                                            var obj = vxUtils.findObjectOnParents(scope, 'vm', true);
                                            obj.p.vm.listCpi.items.splice(event, 1);
                                        });
                                    });

                                    break;
                                case 'hourRate':
                                    sweet.show({
                                        title: vxUtils.loc('CONFIRM'),
                                        text: vxUtils.loc('DELETEHOURRATE'),
                                        type: 'success',
                                        showCancelButton: true,
                                        confirmButtonColor: '#DD6B55',
                                        confirmButtonText: vxUtils.loc('YES'),
                                        closeOnConfirm: true
                                    }, function () {
                                        $api.del($api.adress.hourrate + '/' + event.id, function () {
                                            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('HOURRATE') + vxUtils.loc('ACTION_DELETED'));
                                            var obj = vxUtils.findObjectOnParents(scope, 'vm', true);
                                            obj.p.vm.listCpi.items.splice(event, 1);
                                        });
                                    });
                                    break;
                            }
                        }

                        scope.item.openSeachItem = function (event, override) {
                            const type = override==null?event.type:override;

                            switch (type) {
                                case "job":
                                    msgBus.emitMsg(msgBusActions.shellOpenTabByType, {
                                        type: 'job', id: event.jobnumber
                                    });
                                    break;
                                case "order":
                                    msgBus.emitMsg(msgBusActions.shellOpenTabByType, {
                                        type: 'order', id: event.id
                                    });
                                    break;
                                case "attachment":
                                    $email.getAttachment(event.at_guid, event.at_name, event.at_extention,
                                        function (result) {

                                        });
                                    break;
                                case 'worknote':
                                    var obj = vxUtils.findObjectOnParents(scope, 'isCollapsed', true);

                                    obj.p.worknote = event;
                                    obj.p.worknote.date = event.startdate;
                                    obj.p.worknote.hours = parseFloat(event.hours);

                                    obj.p.getJobsForThatDay(function () {
                                        obj.p.worknote.jobid = event.idjob;
                                        obj.p.changeInput();
                                    });


                                    break;
                            }

                        };


                        scope.item.searchID = vxUtils.generateQuickGuid();

                        switch (scope.item._type || scope.item.type) {
                            case 'note':
                            case 'outgoing':
                            case 'incoming':
                            case 'note_quote':
                            case 'note_cancellation':
                            case 'note_payment':
                            case 'change':
                                return root + 'change.tpl.html';
                            case 'planned':
                                return root + 'timeline.tpl.html';
                            case 'car':
                                return root + 'car.tpl.html';
                            case 'email':
                                return root + 'email.tpl.html';
                            case 'availability':
                                return root + 'availability.tpl.html';
                            case 'employee':
                                return root + 'employee.tpl.html';
                            case 'job':
                                return root + 'job.tpl.html';
                            case 'order':
                                return root + 'order.tpl.html';
                            case 'worknote':
                                return root + 'worknote.tpl.html';
                            case 'crew':
                                return root + 'employee.tpl.html';
                            case 'hourRate':
                                return root + 'emp.hour.rate.tpl.html';
                            case 'empty':
                                return root + 'emptylist.tpl.html';

                        }
                    }
                }
            };
        }
    }
})
;

Voxmodules.directive('watchersToggler', ['$parse', '$timeout', function ($parse, $timeout) {
    return {
        restrict: 'EA',
        scope: {
            typeId: '=',
            toggler: '=watchersToggler',
            refreshSuspensionOn: '=refreshHideOn',
            useParent: '&useParent',
        },
        link: function ($scope, element, attrs) {
            var watchers = {
                suspended: false
            };

            $scope.$watch('toggler', function (newToggler, oldToggler) {
                console.log(newToggler);
                if (typeof newToggler == 'boolean') {
                    if (newToggler) {
                        //element.hide(); - with jquery dependency
                        element.css('display', 'none');
                        suspendFromRoot();
                    } else {
                        //element.show(); - with jquery dependency
                        element.css('display', 'block');
                        resumeFromRoot();
                    }
                }
            });

            $scope.$watch('refreshSuspensionOn', function (newVal, oldVal) {
                if (newVal !== oldVal) refreshSuspensionFromRoot()
            }, true);


            function suspendFromRoot() {
                if (!watchers.suspended) {
                    $timeout(function () {
                        suspendWatchers();
                        watchers.suspended = true;
                    })
                }
            }

            function refreshSuspensionFromRoot() {
                if (watchers.suspended) {
                    $timeout(function () {
                        suspendWatchers();
                    })
                }
            }

            function resumeFromRoot() {
                if (watchers.suspended) {
                    $timeout(function () {
                        resumeWatchers();
                        watchers.suspended = false;
                    })
                }
            }

            function suspendWatchers() {
                iterateSiblings($scope, suspendScopeWatchers);
                iterateChildren($scope, suspendScopeWatchers);
            };

            function resumeWatchers() {
                iterateSiblings($scope, resumeScopeWatchers);
                iterateChildren($scope, resumeScopeWatchers);
                $scope.$digest();
            };

            var mockScopeWatch = function (scopeId) {
                return function (watchExp, listener, objectEquality, prettyPrintExpression) {
                    watchers[scopeId].unshift({
                        fn: angular.isFunction(listener) ? listener : angular.noop,
                        last: void 0,
                        get: $parse(watchExp),
                        exp: prettyPrintExpression || watchExp,
                        eq: !!objectEquality
                    })
                }
            }

            function suspendScopeWatchers(scope) {
                if (!watchers[scope.$id]) {
                    if (scope.ignoreSuspention)
                        return;

                    watchers[scope.$id] = scope.$$watchers || [];
                    scope.$$watchers = [];
                    //scope.$$watchersCount = 0;
                    scope.$watch = mockScopeWatch(scope.$id)
                }
            }

            function resumeScopeWatchers(scope) {
                if (watchers[scope.$id]) {
                    scope.$$watchers = watchers[scope.$id];
                    // scope.$$watchersCount = scope.$$watchers.length;
                    if (scope.hasOwnProperty('$watch')) delete scope.$watch;
                    watchers[scope.$id] = false
                }
            }

            function iterateSiblings(scope, operationOnScope) {

                while (!!(scope = scope.$$nextSibling)) {
                    operationOnScope(scope);
                    iterateChildren(scope, operationOnScope);
                }
            }

            function iterateChildren(scope, operationOnScope) {

                while (!!(scope = scope.$$childHead)) {
                    operationOnScope(scope);
                    iterateSiblings(scope, operationOnScope);
                }
            }
        }
    }
}]);

export default Voxmodules;
