import app from "/src/app/app.module";


app.controller('customer_type',
    function ($scope, $timeout, msgBus, $api, $job,
              $filter, $focus, sweet, vxUtils, toastr) {
        const vm = this;

        // Variables to make the controller generic
        const entityName = 'entity'; // Example: 'job kind', 'customer type'
        const apiUrl = $api.adress.customerKinds; // Replace 'entity' with your specific API endpoint
        const entityDisplayName = vxUtils.loc('CUSTOMER_KIND'); // Human-readable name for display purposes
        const entityUniqueIdentifier = 'id_customerkind'; // Unique identifier field for the entity
        vm.entityLabel = 'type'; // Unique identifier field for the entity
        vm.entityDisplayName = entityDisplayName;

        vm.items = [];
        vm.selected = {[vm.entityLabel]: '', usedBy: 0};
        vm.replaceEntity = null;

        vm.add = function () {
            const m = {
                [vm.entityLabel] : vm.newEntity
            };


            vm.newEntity = '';
            vm.selected = m;
            $focus('focusSubject');
            vm.save(true);
        };

        vm.delete = function () {
            if (vm.replaceEntity === null && vm.selected.usedBy > 0) {
                sweet.show({
                    title: vxUtils.loc('NOT_SELECTED'),
                    text: vxUtils.loc('SELECT_A_REPLACE', {entity: entityDisplayName}),
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: vxUtils.loc('OK'),
                    closeOnConfirm: true
                })

            } else {

                sweet.show({
                    title: vxUtils.loc('CONFIRMDELETION'),
                    text: vm.selected.usedBy > 0 ? vxUtils.loc('REMOVE_ENTITY_REPLACE', {
                            entity: entityDisplayName,
                            currentEntity: vm.selected[vm.entityLabel],
                            replaceEntity: vm.replaceEntity[vm.entityLabel]
                        })
                        : vxUtils.loc('REMOVE_ENTITY', {entity: entityDisplayName}),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: vxUtils.loc('YES'),
                    closeOnConfirm: true
                }, function () {
                    $api.post(apiUrl + '/' + vm.selected[entityUniqueIdentifier], function () {
                        var i = $filter('removeWith')(vm.items, {
                            [entityUniqueIdentifier]: vm.replaceEntity && vm.selected.usedBy > 0
                                ? vm.replaceEntity[entityUniqueIdentifier]
                                : vm.selected[entityUniqueIdentifier]
                        }).sort(function (a, b) {
                            return a.usedBy > b.usedBy;
                        });

                        vm.items = i;
                        vm.selected = vm.items[0];
                        vm.replaceEntity = null;
                    }, vm.replaceEntity, function (result, status) {
                        sweet.show({
                            title: vxUtils.loc('ERROR'),
                            text: vxUtils.loc('NOT_ALLOWED_ENTITY_REMOVAL', {entity: entityDisplayName}),
                            type: 'error',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: vxUtils.loc('YES'),
                            closeOnConfirm: true
                        })
                    });
                });

            }
        };

        vm.save = function (isNew) {
            $api.post(apiUrl, function (result) {

                vm.getItems();
                toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('ENTITY_SAVED', {entity: entityDisplayName}));
            }, vm.selected);
        };

        vm.getItems = function () {
            $api.get(apiUrl + '/counted', function (result) {
                vm.items = result.map(function (item) {
                    item.usedBy = parseInt(item.usedBy);
                    return item;
                });

                if (vm.items.length > 0)
                    vm.selected = vm.items[0];
            });

        };

        vm.getItems();
    })
;