'use strict';

var Tendant = function (location) {

    if (location === undefined) {
        return;
    }

    this.dbo = location;
    this.prefix = 'loc';

    this.Name = function () {
        return this.dbo['vox_tendants_name'];
    };

    this.Adress = function () {
        return `${this.dbo[this.prefix + '_street']} ${this.dbo[this.prefix + '_number']}`;
    };

    this.Street = function () {
        return this.dbo[this.prefix + '_street'];
    };

    this.StreetNumber = function () {
        return this.dbo[this.prefix + '_number'];
    };

    this.AreaCode = function () {
        return this.dbo[this.prefix + '_areacode'];
    };

    this.City = function () {
        return this.dbo[this.prefix + '_city'];
    };

    this.State = function () {
        return this.dbo[this.prefix + '_state'];
    };

    this.Country = function () {
        return this.dbo[this.prefix + '_country'];
    };
    this.IDLocation = function () {
        return parseInt(this.dbo['idLocation']);
    };
    this.IDTendant = function () {
        return parseInt(this.dbo['vox_tendants_id']);
    };

    this.Color = function () {
        return this.dbo['vox_tendants_color'] === ""?null:this.dbo['vox_tendants_color'];
    };

    this.HasOrders =function (){
        return this.dbo['vox_tendants_has_orders']==='1';
    }


    this.id = this.IDTendant();
    this.idTendant = this.IDTendant();
    this.idLocation = this.IDLocation();
    this.name = this.Name();
    this.adress = this.Adress();
    this.street = this.Street();
    this.streetnumber = this.StreetNumber();
    this.areacode = this.AreaCode();
    this.city = this.City();
    this.state = this.State();
    this.country = this.Country();
    this.color = this.Color();
    this.hasOrders = this.HasOrders();

};

export default Tendant;