import app from "/src/app/app.module";

app.controller('delete_dialog', function ($scope, vxSession, msgBusActions, sweet,
                                          msgBus, $customer, vxUtils, toastr,
                                          $products, $users, tabService,
                                          $job, $payment) {
    var vm = this;
    vm.dialogGuid = vxUtils.generateQuickGuid();

    vm.job = vxUtils.findObjectOnParents($scope, 'job');
    vm.cust = vxUtils.findObjectOnParents($scope, 'cust');
    vm.payment = vxUtils.findObjectOnParents($scope, 'offerte');
    vm.product = vxUtils.findObjectOnParents($scope, 'product');
    vm.employee = vxUtils.findObjectOnParents($scope, 'user');

    var name = function () {
        if (vm.job != -1 && {} !== vm.job) {
            return vm.job.jobtitle;
        }
        else if (vm.payment != -1 && {} !== vm.payment) {
            return vm.payment.idofferte;
        }
        else if (vm.cust != -1 && {} !== vm.cust) {
            return vm.cust.name;
        }
        else if (vm.product != -1 && {} !== vm.product) {
            return vm.product.productname;
        }
        else if (vm.employee != -1 && {} !== vm.employee) {
            return vm.employee.name;
        }

    };
    //vm.itemname = name();


    vm.closepanel = function () {
        $scope.toggleNav(false);
    };

    vm.deleteitem = function () {
        var succes = function (type) {
            sweet.show(vxUtils.loc('SUCCES'),
                vxUtils.loc('SUCCESFULLDELETED'), 'success');

            var i = tabService.getCurrentTab();
            if (i) {
                msgBus.emitMsg(msgBusActions.overviewReload, type);
                msgBus.emitMsg(msgBusActions.shellCloseTabByGuid, { guid: i.hash });
            }
        };

        if (vm.job !== -1) {
            $job.deleteJob(function () {
                succes('job');
            }, vm.job.jobid);
        }
        else if (vm.payment !== -1) {
            $payment.deletePayment(function () {
                succes('payment');
            }, vm.payment.idofferte);
        }
        else if (vm.product !== -1) {
            $products.deleteProduct(function () {
                succes('product');
            }, vm.product.idproductdetail);
        }
        else if (vm.employee !== -1) {
            $users.deleteUser(vm.employee.idUser, function () {
                succes('employee');
            });
        } else if (vm.cust !== -1) {
            $customer.deleteCustomer(function () {
                succes('customer');
            }, vm.cust.idClient);
        }

    };

});