import app from "/src/app/app.module";
import moment from "moment";
app.controller('add-job-form', function ($scope, vxForms, $job, $depot, $locale, $auth) {
    $scope.locale = $locale;
    $scope.jobFields = vxForms.jobs;

    $scope.options = {
        validation: {
            enabled: true
        },
        layout: {
            type: 'vertical',
            labelSize: 3,
            inputSize: 9
        }
    };

    $scope.today = function () {
        $scope.wizard.expirationDate = new Date();
    };
    $scope.today();


    $depot.getAllDepots(function (result) {
        $scope.wizard.allDepots = result;
    });

    $job.allKindOfParties(function (result) {
        $scope.wizard.kindofjobs = result;
    });

    $job.allJobStatuses(function (result) {
        $scope.wizard.jobstatuses = result;
    });

    $auth.getAllCompanies(function (result) {

        $scope.wizard.companies = result;
        if (result.length > 0) {
            $scope.wizard.selectedTendant = result[0];
        }
    }, true);

    $scope.$watch('wizard.jobData.startdate', function (newValue, oldValue) {
        if (!newValue)
            return;
        var date = moment(newValue);
        var newDate = date.hours(0).minutes(0).seconds(0);
        $scope.minDate = newDate.toDate();
        //$scope.$apply();
    });

});