import app from "/src/app/app.module";


app.controller('invoices', function ($scope, $payment, settings, $locale) {

    ////////OVERVIEW/////
    $scope.overviewCpi = {
        openItemPage: "/src/pages/quotes/quote-details.html",
        openItemIcon: "fa fa-clock-o",
        tabItems: $scope.openItems
    };

    $scope.columns = [
      { title: '#', field: 'idofferte', visible: true },
      { title: 'Status', field: 'statusIcon', visible: true, icon: true },
      { title: 'Verzonden op', field: 'senddate', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate },
      { title: 'Jobnaam', field: 'jobtitle', visible: true },
      { title: 'Klantnaam', field: 'clientname', visible: true },
      { title: 'Bedrag', field: 'amount', visible: true, filter: "currency", args: settings.currency },
      { title: 'Vervaldatum', field: 'duedate', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate }

    ];

    $scope.getItems = function () {
        $payment.getAllInvoices(function (results) {
            $scope.overviewCpi.items = results;
            $scope.overviewCpi.reload();
        });
    };

    $scope.getItems();
    ////////OVERVIEW/////

});