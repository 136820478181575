'use strict';

var Email = function (event) {

    this.dbo = event;

    this.Title = function () {
        return this.dbo['Name'];
    };

    this.ID = function () {
        return this.dbo['ID'];
    };

    this.SecundairID = function () {
        return this.dbo['SecundairID'] ? this.dbo['SecundairID'] : 0;
    };

    this.Icon = function () {
        return 'fa fa-envelope';
    };

    this.Background = function () {
        return 'vx-blue-bg';
    };

    this.From = function () {
        return this.dbo['eFrom'];
    };

    this.To = function () {
        return this.dbo['eTo'];
    };

    this.Subject = function () {
        return this.dbo['Subject'];
    };

    this.Message = function () {
        return this.dbo['Message'];
    };

    this.Type = function () {
        return this.dbo['Type'];
    };

    this.Time = function () {
        return parseInt(this.dbo['Date'] + "000");
    };

    this.Attachments = function () {
        var val =this.dbo['attachments'];
        return angular.fromJson(val);
    }

    this.title = this.Title();
    this.id = this.ID();
    this.secundairid = this.SecundairID();
    this.type = this.Type();
    this.time = this.Time();
    this.message = this.Message();
    this.icon = this.Icon();
    this.background = this.Background();
    this.from = this.From();
    this.to = this.To();
    this.subject = this.Subject();
    this.attachments = this.Attachments();
};
export default Email;
//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];