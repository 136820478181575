'use strict';

var Tax = function (tax) {

    if (tax === undefined)
        return;

    this.dbo = tax;

    this.ID = function () {
        return parseInt(this.dbo['id_taxes']);
    };


    this.Name = function () {
        return this.dbo['name'];
    };

    this.Rate = function () {
        return parseFloat(this.dbo['rate']);
    };
    
    this.id = this.ID();
    this.name = this.Name();
    this.rate = this.Rate();

};

export default Tax;