import app from "/src/app/app.module";

app.controller('new-dialog', function ($scope, settings, enums, msgBus, $customer) {

    $scope.customertype = null;

    $scope.openNewCustomer = function () {
        var temp = {};

        switch ($scope.customertype) {
            case 'individual':
                temp = {
                    isCompany: false,
                    firstname: $scope.firstname,
                    preposition: $scope.preposition,
                    lastname: $scope.lastname
                };
                break;
            case 'company':
                temp = {
                    isCompany: true,
                    companyname: $scope.companyname
                };
                break;
        };

        $customer.newCustomer(function (result) {
           $scope.openCustomer(result);
        }, temp);

        
    };

});