'use strict';
import md5 from 'crypto-js/md5';

var Notification = function (n) {
    if (n === undefined) {
        return;
    }

    this.dbo = n;

    this.Icon = function () {

        var val = "fa fa-exclamation-circle yellow";

        switch (this.dbo['type']) {
            case 'job':
            case 'payment_item':
                val = "fa fa-eur";
                break;
            case 'payment_item':
                val = "fa fa-eur";
                break;
            case 'call_planned_job':
            case 'call_planned_client':
                val = "ionicons ion-android-call yellow";
                break;
        }
        return val;
    };

    this.Time = function () {
        return parseInt(this.dbo['executiondate'] + "000");
    };

    this.Hash = function () {
        return md5(this.dbo['message'] + "," + this.Time() + ',' + this.dbo['id_target']).toString();
    };


    this.id = this.Hash();
    this.targetid = this.dbo['id_target'];
    this.icon = this.Icon();
    this.type = this.dbo['type'];
    this.message = this.dbo['message'];
    this.remarks = this.dbo['remarks'];

    this.idLabel = 0;
    this.timestamp = new Date().getTime();
    // this.creationdate = this.dbo['time'];
    this.idUser = 0;
    this.executiondate = this.Time();
    this.new = true;

    this.tabID =  this.targetid;
    this.tabDescription = this.message;



};


export default Notification;