import {format as formatMoney} from 'accounting-js'

var vxFilter = angular.module('vxFilters', []);
// vxFilter.filter('currency', function () {
//     return function (number, currencyCode) {
//         var currency = {
//                 USD: "$ ",
//                 GBP: "£ ",
//                 AUD: "$ ",
//                 EUR: "€ ",
//                 CAD: "$ ",
//                 MIXED: "~"
//             },
//             thousand, decimal, format, curchar;
//
//         //if (number.charAt(number.length - 3) === ",")
//         //    curchar = ",";
//         //  if (number.charAt(number.length - 3) === ",")
//         //       number[number.length - 3] = ".";
//
//         if ($.inArray(currencyCode, ["USD", "AUD", "CAD", "MIXED"]) >= 0) {
//             thousand = ",";
//             decimal = ".";
//             format = "%s%v";
//         } else {
//             thousand = ".";
//             decimal = ",";
//             format = "%s%v";
//         }
//         ;
//         return formatMoney(number, currency[currencyCode], 2, thousand, decimal, format);
//     };
// });

vxFilter.filter('companyName', function ($filter, $localStorage) {
    return function (input) {
        if (input === undefined)
            return input;

        if (isNaN(input))
            return null;

        var result = $filter('filter')($localStorage.allCompanies, {idTendant: input});

        return result[0].name;
    };


});

vxFilter.filter('depotName', function ($filter, $localStorage) {
    return function (input) {
        if (input === undefined)
            return input;

        if (isNaN(input))
            return null;

        var result = $filter('filter')($localStorage.allDepots, {idWarehouse: input.toString()});

        return result[0].name;
    };


});
vxFilter.filter('groupName', function ($filter, $localStorage) {
    return function (input) {
        if (input === undefined)
            return input;

        if (isNaN(input))
            return null;

        var result = $filter('filter')($localStorage.allUserGroups, {idGroup: parseInt(input)});

        return result[0].groupname;
    };


});
vxFilter.filter('roleName', function ($filter, $localStorage) {
    return function (input) {
        if (input === undefined)
            return input;

        if (isNaN(input))
            return null;

        var result = $filter('filter')($localStorage.allUserRoles, {level: parseInt(input)});

        return result[0].role;
    };


});
vxFilter.filter('kindOfJob', function ($filter, $localStorage) {
    return function (input) {
        if (input === undefined)
            return input;

        if (isNaN(input))
            return null;

        var result = $filter('filter')($localStorage.allJobKinds, {id_partykind: parseInt(input)});

        return result[0] === undefined ? '' : result[0].type;
    };


});
vxFilter.filter('countNewItems', function ($filter) {
    return function (input) {
        if (input === undefined)
            return input;

        var result = $filter('filter')(input, {new: true});

        return result.length;
    };


});
vxFilter.filter('invoiceType', function ($filter) {
    return function (input) {
        if (input === undefined)
            return input;

        var result = input;
        if (input === 'declined')
            result = $filter('translate')("DECLINED");
        else if (input === 'overdue')
            result = $filter('translate')("OVERDUE");
        else if (input === 'send')
            result = $filter('translate')("SEND");
        else if (input === 'new')
            result = $filter('translate')("NEW");
        else if (input === 'accepted')
            result = $filter('translate')("ACCEPTED");
        else if (input === 'payment')
            result = $filter('translate')("PAYED");
        else if (input === null || input === 'unkown')
            result = $filter('translate')("UNKOWN");

        return result;
    };


});
vxFilter.filter('jobcrewStatus', function ($filter) {
    return function (input) {
        if (input === undefined)
            return input;

        var result = parseInt(input);
        if (result === 1)
            result = $filter('translate')("ACCEPTED");
        else if (result === 0)
            result = $filter('translate')("AWAITING");
        else if (result === 2)
            result = $filter('translate')("DECLINED");

        return result;
    };


});vxFilter.filter('emailStatus', function ($filter) {
    return function (input) {
        if (input === undefined)
            return input;

        var result = input;
        if (result === "fa fa-asterisk not-important fa-05x")
            result = $filter('translate')("NOTSEND");
        else if (result ==="ionicons ion-email-unread fa-05x")
            result = $filter('translate')("NOTREAD");
        else if (result === "ionicons ion-email green fa-05x")
            result = $filter('translate')("ISREAD");

        return result;
    };


});vxFilter.filter('crewNames', function ($filter) {
    return function (input,type) {
        if (input === undefined)
            return input;

        var result = input;
        var names = [];
        if (result[type].length === 0) {
            return "Niemand gevonden";
        }
        angular.forEach(result[type], function (value, key) {
            names.push(value.name);
        });

        return names.join();
    };


});
vxFilter.filter('relationType', function ($filter) {
    return function (input) {
        if (input === undefined)
            return input;

        var result = input;
        if (input === 'customer')
            result = $filter('translate')("CUSTOMER");
        else if (input === 'supplier')
            result = $filter('translate')("SUPPLIER");
        else if (input === 'vendor')
            result = $filter('translate')("VENDOR");
        else if (input === 'reseller')
            result = $filter('translate')("RESELLER");
        else if (input === 'partner')
            result = $filter('translate')("PARTNER");
        else if (input === 'agent')
            result = $filter('translate')("AGENT");
        else if (input === 'unkown')
            result = $filter('translate')("UNKOWN");

        return result;
    };


});

vxFilter.filter('paymentType', function ($filter) {
    return function (input) {
        if (input === undefined)
            return input;

        var result = input;
        if (input === 'invoice')
            result = $filter('translate')("INVOICE");
        else if (input === 'quote')
            result = $filter('translate')("QUOTE");
        else if (input === null)
            result = $filter('translate')("UNKOWN");

        return result;
    };


});

vxFilter.filter('fullproductnumber', function ($filter) {
    return function (input) {
        if (input === undefined)
            return input;

        var result = input.productnumber + input.productgroupnumber;

        return result;
    };


});

vxFilter.filter('htmlcompile', ['$compile',
    function ($compile) {
        return function (input) {
            var i = $compile(input);
            return i;
        }
    }]);

vxFilter.filter('html', ['$sce',
    function ($sce) {
        return function (input) {

            if (input === undefined || input === '' || input === 'null' || input === null)
                input = '-';

            var i = $sce.trustAsHtml(input);
            return i;
        }
    }]);


vxFilter.filter('linebreak', function () {
    return function (input) {

        if (input === undefined || input === '' || input === 'null' || input === null) {
            input = '-';
        }

        // Replace both literal newlines and escaped newlines with <br />
        const i = input.replace(/(\r?\n|\\n)/g, '<br />');
        return i;
    }
});



vxFilter.filter('plinebreakplain',
    function () {
        return function (input) {

            if (input === undefined || input === '' || input === 'null' || input === null)
                input = '-';

            input = input.replace(/<style([\s\S]*?)<\/style>/gi, '');
            input = input.replace(/<script([\s\S]*?)<\/script>/gi, '');
            input = input.replace(/<\/div>/ig, '\n');
            input = input.replace(/<\/li>/ig, '\n');
            input = input.replace(/<li>/ig, '  *  ');
            input = input.replace(/<\/ul>/ig, '\n');
            input = input.replace(/<\/p>/ig, '\n');
            input = input.replace(/<br\s*[\/]?>/gi, "\n");
            input = input.replace(/<[^>]+>/ig, '');

            return input;

        }
    });

vxFilter.filter('sum_by_prop', function () {
    return function (items, prop) {
        if (items === undefined)
            return;

        var total = 0;
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (!isNaN(item[prop]))
                total = total + item[prop];
        }
        return total;
    }
});


vxFilter.filter('bytes', function () {
    return function (bytes, precision) {
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
        if (typeof precision === 'undefined') precision = 1;
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
            number = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
    }
});

export default vxFilter;