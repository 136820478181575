import app from "/src/app/app.module";
import './dashboard.html'
import moment from "moment";

app.controller('dashboard', function ($scope, settings, $filter, $job, vxUtils,
                                      $api, $auth,
                                      selectionFilters, tabService, $order) {
    $scope.openItems = tabService.tabs;
    $scope.PageHash = vxUtils.generateQuickGuid();
    $scope.startYear = new Date().getFullYear();
    $scope.dataTotalStatusMonth = [];
    $scope.seriesRevMonth = [];
    $scope.labelsRevMonth = [];
    $scope.dataRevMonth = [];
    $scope.dataTotalRevMonth = [];

    $scope.dataOrdersRevMonth = [];
    $scope.dataOrdersTotalRevMonth = [];
    $scope.labelsOrdersRevMonth = [];

    $scope.years = getYears();
    $scope.year = [];
    $scope.seriestotoalOrdersRevMonth = [
        {status_label:vxUtils.loc('RECEIVED'), status_name: 'received'},
        {status_label:vxUtils.loc('READY_FOR_DELIVERY'), status_name: 'ready_for_delivery'},
        {status_label:vxUtils.loc('IN_PROGRESS'), status_name: 'in_progress'},
        {status_label:vxUtils.loc('DELIVERED'), status_name: 'delivered'},
        {status_label:vxUtils.loc('CANCELED'), status_name: 'canceled'},
    ];

    function getYears() {
        var out = [];

        for (var i = -3; i < 12; i++) {
            out.push($scope.startYear - i);
        }
        return out;
    }

    $scope.init = function () {
        $scope.lastdate = {
            startDate: moment().startOf('day').unix(),
            endDate: moment().add('days', 7).unix()
        };
        $scope.getItems($scope.lastdate);

        $scope.getChartData();
        for (const i of $scope.companies) {
            $api.get($api.adress.stats + '/' + i.id, function (result) {

                $scope.dataTotalStatusMonth[i.id] = [
                    result['totaloption'],
                    result['totalaccepted'],
                    result['totalcanceld']
                ];


            })

            $api.get($api.adress.orderStats + '/' + i.id, function (result) {

                $scope.dataOrdersTotalRevMonth[i.id] =  $scope.seriestotoalOrdersRevMonth
                    .map(x=> {
                        const found = result.find(y=>y.status_name === x.status_name );
                        return found? parseInt(found.order_count) : 0});


            })
        }
    };

    $scope.getData = function (nameChart, companyId) {
        if ('jobs_this_month') {
            return $scope[`dataTotalStatusMonth${companyId}`];
        }
    };

    $scope.getChartData = function () {

        for (const i of $scope.companies) {

            //JOBS
            $api.get($api.adress.charts + '/' + i.id + '/' + $scope.year[i.id], function (result) {
                $scope.seriesRevMonth = [vxUtils.loc('OPTION'), vxUtils.loc('CONFIRMED'), vxUtils.loc('CANCELED')];
                var rev = reorderItems('revenue', $scope.seriesRevMonth, result);
                $scope.labelsRevMonth[i.id] = rev[1];
                $scope.dataRevMonth[i.id] = rev[0];
                var rev = reorderItems('total', $scope.seriesRevMonth, result);
                $scope.dataTotalRevMonth[i.id] = rev[0];
            });

            // ORDERS
            $api.get($api.adress.ordersCharts + '/' + i.id + '/' + $scope.year[i.id], function (result) {
                $scope.seriesOrdersRevMonth =  $scope.seriestotoalOrdersRevMonth.map(x=>x.status_label);
                var rev = reorderItems2('revenue', $scope.seriestotoalOrdersRevMonth, result.revenue);
                $scope.labelsOrdersRevMonth[i.id] = rev[1];
                $scope.dataOrdersRevMonth[i.id] = rev[0];
                var rev = reorderItems2('total', $scope.seriestotoalOrdersRevMonth, result.total);
                $scope.dataOrdersTotalRevMonth[i.id] = rev[0];
                $scope.dataOrdersTotalRevMonthShow[i.id] = rev[1];
            });

        }
    }


    var reorderItems = function (input, series, result) {
        var theone = [];
        var labels = [];
        var items = [];

        if (angular.isArray(result)) {
            theone = result;
        } else {
            for (var i = 1; i < 4; i++) {
                var array = result[input + i];
                theone = theone.concat(array);
            }
        }
        labels = $filter('map')(theone, 'Title');
        labels = $filter('unique')(labels);

        angular.forEach(labels, function (v, k) {
            var item = {};
            var date = '01 ' + v;
            var m = moment(date.replace(' ', '-'), "DD-MM-YYYY"); // todo change date
            item.title = v;
            item.order = m.toDate();
            item.cleanTitle = m.format('MMMM');
            item.series = $filter('filter')(theone, {Title: v});
            items.push(item);
        });
        items = $filter('orderBy')(items, 'order');
        labels = $filter('map')(items, 'cleanTitle');

        var data = [Array.apply(null, Array(labels.length)).map(function () {
            return 0;
        })
            , Array.apply(null, Array(labels.length)).map(function () {
                return 0;
            }),
            Array.apply(null, Array(labels.length)).map(function () {
                return 0;
            })
        ];
        angular.forEach(items, function (v, k) {
            for (var j = 0; j < v.series.length; j++) {
                var index = parseInt(v.series[j].legend) - 1;
                data[index].splice(k, 1, parseFloat(v.series[j].ValuePath));
            }
        });
        var i = [];
        i.push(data);
        i.push(labels);
        return i;
    };

    var reorderItems2 = function(input, series, result) {
        var theone = [];
        var labels = [];
        var items = [];

        if (angular.isArray(result)) {
            theone = result;
        } else {
            for (var i = 1; i < 4; i++) {
                var array = result[input + i];
                theone = theone.concat(array);
            }
        }
        labels = $filter('map')(theone, 'Title');
        labels = $filter('unique')(labels);

        angular.forEach(labels, function(v, k) {
            var item = {};
            var date = '01 ' + v;
            var m = moment(date.replace(' ', '-'), "DD-MM-YYYY");
            item.title = v;
            item.order = m.toDate();
            item.cleanTitle = m.format('MMMM');
            item.series = $filter('filter')(theone, {Title: v});
            items.push(item);
        });

        items = $filter('orderBy')(items, 'order');
        labels = $filter('map')(items, 'cleanTitle');

        let data = series.map(() => Array(labels.length).fill(0));

        angular.forEach(items, function(v, k) {
            for (let j = 0; j < v.series.length; j++) {
                const index = series.findIndex(y => y.status_name === v.series[j].status_name);
                data[index].splice(k, 1, parseFloat(v.series[j].ValuePath));
            }

        });

        var i = [];
        i.push(data);
        i.push(labels);
        return i;
    };


    $scope.onClick = function (points, evt) {
        console.log(points, evt);
    };

    $scope.timeLineCpi = {
        tabItems: $scope.openItems,
        filters: selectionFilters.jobStates,
        fields: [{
            field: 'status', expression: function (value) {
                return {
                    status: value.state
                };
            }
        }, {
            field: 'crewstatus', expression: function (value) {
                return {
                    crewstatus: value.state
                };
            }
        }]
    };

    $scope.timeLineOrderCpi = {
        tabItems: $scope.openItems,
        filters: selectionFilters.orderStates,
        fields: [{
            field: 'status', expression: function (value) {
                return {
                    status: value.state
                };
            }

        }]
    };

    $scope.getItems = function (date) {
        $scope.lastdate = date;
        $job.getJobsBetween(function (results) {
            $scope.timeLineCpi.all = results;
            if ($scope.timeLineCpi.refresh)
                $scope.timeLineCpi.refresh();
            var ids = $filter('map')($scope.timeLineCpi.all, 'jobid');
            $job.getEmailConfirmations(function (data) {
                for (var i = 0; i < $scope.timeLineCpi.all.length; i++) {
                    var item = $scope.timeLineCpi.all[i];
                    var mail = $filter('filter')(data, {
                        vox_mail_referenceID: item.jobid.toString()
                    })[0];
                    if (mail === undefined)
                        continue;

                    item.isread = mail.vox_mail_logging_date_read;
                    item.mail = mail;
                    item.isconfirmed = mail ? true : false;
                    item.isconfirmedicon = item.isConfirmedIcon();
                }

            }, ids);
        }, date.startDate, date.endDate);

        $scope.lastdateOrders = {
            startDate: moment().subtract(14, 'days').unix(),
            endDate: moment().add(1, 'days').unix()
        };

        $order.getAllOrders($scope.lastdateOrders.startDate, $scope.lastdateOrders.endDate, function (results) {
            $scope.timeLineOrderCpi.all = results;
            if ($scope.timeLineOrderCpi.refresh)
                $scope.timeLineOrderCpi.refresh();
        });
    };
    $scope.scrollMethod = function ($event, $delta, $deltaX, $deltaY) {
        vxUtils.scrollHorzontal($event, $delta, $deltaX, $deltaY, $scope.PageHash);
    };

    $auth.getAllCompanies(function (result) {

        $scope.companies = result;

        $scope.companies.forEach(x => {
            $scope.dataTotalStatusMonth[x.id] = []
            $scope.year[x.id] = new Date().getFullYear();
        });

        $scope.init();
    }, true);
});
