import app from "/src/app/app.module";
import moment from "moment";
app.controller('add-quote-form', function ($scope, vxForms, $locale) {

    $scope.locale = $locale;
    $scope.invoiceAdressFields = vxForms.invoiceAdress;

    if ($scope.wizard.customer !== undefined && $scope.wizard.invoiceAdress === undefined) {
        $scope.wizard.invoiceAdress = {};
        if ($scope.wizard.customer.IsCompany) {
            $scope.wizard.invoiceAdress.companyname = $scope.wizard.customer.name;
        } else {
            $scope.wizard.invoiceAdress.firstname = $scope.wizard.customer.firstname;
            $scope.wizard.invoiceAdress.preposition = $scope.wizard.customer.dbo['ctd_preposition'];
            $scope.wizard.invoiceAdress.lastname = $scope.wizard.customer.lastname;
        }

        $scope.wizard.invoiceAdress.telephone = $scope.wizard.customer.telephone;
        $scope.wizard.invoiceAdress.email = $scope.wizard.customer.email;
        $scope.wizard.invoiceAdress.country = $scope.wizard.customer.country;
        $scope.wizard.invoiceAdress.areacode = $scope.wizard.customer.areacode;
        $scope.wizard.invoiceAdress.streetnumber = $scope.wizard.customer.streetnumber;
        $scope.wizard.invoiceAdress.street = $scope.wizard.customer.street;
        $scope.wizard.invoiceAdress.state = $scope.wizard.customer.state;
        $scope.wizard.invoiceAdress.city = $scope.wizard.customer.city;
    }

    $scope.options = {
        validation: {
            enabled: true
        },
        layout: {
            type: 'horizontal',
            labelSize: 3,
            inputSize: 9
        }
    };

    $scope.today = function () {
        $scope.wizard.expirationDate = new Date().getTime();
        $scope.datelimit = moment().subtract(1,'days').hours(0).minutes(0).seconds(0).toDate().getTime();
    };
    $scope.today();


});