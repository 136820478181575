import app from "/src/app/app.module";


import '../factories'
import '../wizardpages'
import '../panel'
import '../pages'

app.controller('appcontroller',
    function ($scope, $rootScope, USER_ROLES, $auth, selectionFilters, $users, $api,
              $filter, datepickerPopupConfig,
              vxSession, $job, $payment, $products, $depot, $location, $customer) {



    });


