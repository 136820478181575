import app from "/src/app/app.module";
import Customer from "../models/customer";
import Job from "../models/job";
import Email from "../models/email";
import Event from "../models/email";
import CustomerType from "../models/customer-type";

var api = function ($resource, settings, $http, $filter, $localStorage, vox, $api) {

    var customers = $resource(settings.BASE_API_URL + 'v1/customers');
    var queryCustomers = function (callback, $skip, $limit) {
        var q = {};
        if(typeof($skip) === 'number' && typeof($limit) === 'number') {
            customers = $resource(settings.BASE_API_URL + 'v1/customers/:skip/:limit');
            q = { skip: $skip, limit: $limit };
        }
        customers.query(q, function (result) {
            var out = [];

            angular.forEach(result, function (value, key) {
                var cust = new Customer(value);
                out.push(cust);
            });
            callback(out);
        });
    };

    var events = $resource(settings.BASE_API_URL + 'v1/customers/events/:id/:type');
    var queryEvents = function (callback, $id, $type) {
        //use a callback instead of a promise
        events.query({id: $id, type: $type}, function (result) {
            var out = [];
            //Underscore's "each" method
            angular.forEach(result, function (value, key) {
                var event = null;

                if (value['Type'] === 'job')
                    event = new Job(value, $filter);
                else if (value['Type'] === 'email')
                    event = new Email(value);
                else
                    event = new Event(value);

                out.push(event);
            });

            callback(out);
        });
    };

    var postNote = function (callback, $note) {

        vox.post(settings.BASE_API_URL + 'v1/customers/' + $note.idClient + '/notes',
            $note, callback);
    };

    var deleteEvent = function (callback, id, type) {
        vox.delete(settings.BASE_API_URL + 'v1/events/' + id + '/' + type, callback);
    };

    var getStats = function (callback, $id) {
        vox.get(settings.BASE_API_URL + 'v1/customers/' + $id + '/stats', data => callback(data[0]));
    };

    var postCustomer = function (callback, $customer) {
        vox.post(settings.BASE_API_URL + 'v1/customers', $customer, data => callback(new Customer(data[0])));
    };

    var deleteCustomer = function (callback, $id) {
        vox.delete(settings.BASE_API_URL + 'v1/customers/' + $id, callback);
    };

    var getCustomer = function (callback, $id) {
        vox.get(settings.BASE_API_URL + 'v1/customers/' + $id, data => callback(new Customer(data[0])));
    };

    var postCustomerType = function (callback, $customerType) {
        vox.post(settings.BASE_API_URL + 'v1/customertypes', $customerType, data => callback(new CustomerType(data[0])));
    };

    var deleteCustomerType = function (callback, $id) {
        vox.delete(settings.BASE_API_URL + 'v1/customertypes/' + $id, callback);
    };

    var getCustomerType = function (callback, $id) {
        vox.get(settings.BASE_API_URL + 'v1/customertypes/' + $id, data => callback(new CustomerType(data[0])));
    };


    var getAllTypes = function (callback) {
        if ($localStorage.allCustomerTypes !== undefined) {
            if (callback)
                callback($localStorage.allCustomerTypes);

            return
        }
        vox.get(settings.BASE_API_URL + 'v1/customertypes', function (result) {
            angular.forEach(result, function (val, key) {
                val.client_type_name_org = val.client_type_name;
                val.client_type_name = $filter('relationType')(val.client_type_name);
            });

            $localStorage.allCustomerTypes = result;
            if (callback)
                callback($localStorage.allCustomerTypes);
        });
    };

    var getAllCustomerKinds = function (callback, nocache = false) {
        if ($localStorage.allCustomerKinds != null && !nocache) {
            if (callback)
                callback($localStorage.allCustomerKinds);

            return
        }
        $api.get($api.adress.customerKinds, function (result) {

            $localStorage.allCustomerKinds = result;
            if (callback)
                callback($localStorage.allCustomerKinds);
        });
    };

    return {
        getAllTypes: getAllTypes,
        getAll: queryCustomers,
        getEvents: queryEvents,
        postNote: postNote,
        newCustomer: postCustomer,
        deleteCustomer: deleteCustomer,
        getStats: getStats,
        getCustomer: getCustomer,
        deleteEvent: deleteEvent,
        getAllCustomerKinds:getAllCustomerKinds
    };
};

app.factory("$customer", api);
