import app from "/src/app/app.module";
import moment from "moment/moment";


app.controller('orders', function ($scope, settings, $location, $order, $filter,tabService,
                                   msgBus,selectionFilters,
                                   vxUtils, enums, $locale ,msgBusActions) {
    var tab = tabService.getCurrentTab();
    $scope.tab = tab;
    $scope.suspended = false;

    ////////OVERVIEW/////
    $scope.overviewCpi = {
        openItemPage: "/src/pages/orders/order-details.html",
        openItemIcon: "fa fa-clock-o",
        type: 'order',
        tabItems: $scope.openItems,
        filters: selectionFilters.orderStates,
        date: {startDate: moment(), endDate: moment().add('days', 29)},
        searchChanged: function (text) {
            $scope.overviewCpi.items = $filter('filter')($scope.overviewCpi.all, {$: text});
            $scope.overviewCpi.reload();
        },
        dateChanged: function (newDate) {
            $scope.lastdate = newDate;
            $scope.getItems(newDate);
        }, selectionChanged: function (data) {

            var fields = [];

            $scope.overviewCpi.items = selectionFilters.filterBySelection(
                $scope.overviewCpi.filters,
                $scope.overviewCpi.all,
                fields
            );
            $scope.overviewCpi.reload();

        }
    };

    $scope.columns = [
        {title: '#', field: 'id', visible: true},
        {title: vxUtils.loc('STATUS'), field: 'statusIcon', visible: true, icon: true},
        {title:  vxUtils.loc('ORDERDATE'), field: 'orderDate', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate},
        {title:  vxUtils.loc('DELIVERYDATE'), field: 'deliveryDate', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate},
        {title: vxUtils.loc('TITLE'), field: 'title', visible: true},
        {title: vxUtils.loc('CUSTOMER_NAME'), field: 'clientname', visible: true},
        {title: vxUtils.loc('ADRESS'), field: 'city', visible: true},
        {title: vxUtils.loc('CITY'), field: 'city', visible: true},
        {title: vxUtils.loc('AMOUNT'), field: 'amount', visible: true, filter: "currency", args: settings.currency}

    ];

    $scope.getItems = function (date) {
        $scope.lastdate = date;
        $order.getAllOrders(date.startDate, date.endDate, function (results) {
            $scope.overviewCpi.all = results;
            $scope.overviewCpi.selectionChanged(null);
        });
    };

    msgBus.onMsg(msgBusActions.overviewReload, function (sender, mssg) {
        if (mssg === 'order')
            $scope.getItems($scope.lastdate);
    }, $scope);
    //$scope.getItems();
    ////////OVERVIEW/////
});