/*!
 * Waves v0.4.1
 * https://publicis-indonesia.github.io/Waves
 *
 * Copyright 2014 Publicis Metro Indonesia, PT. and other contributors
 * Released under the BSD license
 * https://github.com/publicis-indonesia/Waves/blob/master/LICENSE
 */

;(function (window) {
    'use strict';

    var Waves = Waves || {};
    var $$ = document.querySelectorAll.bind(document);

    // Find exact position of element
    function position(obj) {

        var left = 0;
        var top = 0;

        if (obj.offsetParent) {
            do {
                left += obj.offsetLeft;
                top += obj.offsetTop;
            } while (obj = obj.offsetParent);
        }

        return {
            top: top,
            left: left
        };
    }

    function convertStyle(obj) {

        var style = '';

        for (var a in obj) {
            if (obj.hasOwnProperty(a)) {
                style += (a + ':' + obj[a] + ';');
            }
        }

        return style;
    }

    var Effect = {

        // Effect delay
        duration: 500,

        show: function (e, element) {

            var el = element;

            // Create ripple
            var ripple = document.createElement('div');
            ripple.className = ripple.className + 'waves-ripple';
            el.append(ripple);

            // Get click coordinate and element witdh
            var pos = position(el);
            var relativeY = (e.pageY - pos.top);
            var relativeX = (e.pageX - pos.left);
            var width = el.clientWidth * 1.4;

            // Attach data to element
            ripple.setAttribute('data-hold', Date.now());
            ripple.setAttribute('data-x', relativeX);
            ripple.setAttribute('data-y', relativeY);

            // Set ripple position
            var rippleStyle = {
                'top': relativeY + 'px',
                'left': relativeX + 'px'
            };

            ripple.className = ripple.className + ' waves-notransition';
            ripple.setAttribute('style', convertStyle(rippleStyle));
            ripple.offsetHeight;
            ripple.className = ripple.className.replace('waves-notransition', '');

            rippleStyle['border-width'] = width + 'px';
            rippleStyle['margin-top'] = '-' + width + 'px';
            rippleStyle['margin-left'] = '-' + width + 'px';
            rippleStyle['opacity'] = '1';

            rippleStyle['-webkit-transition-duration'] = Effect.duration + 'ms';
            rippleStyle['-moz-transition-duration'] = Effect.duration + 'ms';
            rippleStyle['-o-transition-duration'] = Effect.duration + 'ms';
            rippleStyle['transition-duration'] = Effect.duration + 'ms';

            ripple.setAttribute('style', convertStyle(rippleStyle));

        },

        hide: function (e, element) {

            var el = element;

            var width = el.clientWidth * 1.4;

            // Get first ripple
            var ripple = null;

            for (var a = 0; a < el.children.length; a++) {
                if (el.children[a].className.indexOf('waves-ripple') !== -1) {
                    ripple = el.children[a];
                    continue;
                }
            }


            if (!ripple) {
                return false;
            }

            var relativeX = ripple.getAttribute('data-x');
            var relativeY = ripple.getAttribute('data-y');

            // Get delay beetween mousedown and mouse leave
            var diff = Date.now() - Number(ripple.getAttribute('data-hold'));
            var delay = 500 - diff;

            if (delay < 0) {
                delay = 0;
            }

            // Fade out ripple after delay
            setTimeout(function () {

                var style = {
                    'top': relativeY + 'px',
                    'left': relativeX + 'px',
                    'border-width': width + 'px',
                    'margin-top': '-' + width + 'px',
                    'margin-left': '-' + width + 'px',
                    'opacity': '0',

                    // Duration
                    '-webkit-transition-duration': Effect.duration + 'ms',
                    '-moz-transition-duration': Effect.duration + 'ms',
                    '-o-transition-duration': Effect.duration + 'ms',
                    'transition-duration': Effect.duration + 'ms',
                };

                ripple.setAttribute('style', convertStyle(style));

                setTimeout(function () {

                    try {
                        el.removeChild(ripple);
                    } catch (e) {
                        return false;
                    }


                }, 300);

            }, delay);

        },

        // Little hack to make <input> can perform waves effect
        wrapInput: function (elements) {

            for (var a = 0; a < elements.length; a++) {

                var el = elements[a];

                if (el.tagName.toLowerCase() === 'input') {

                    var parent = el.parentNode;

                    // If input already have parent just pass through
                    if (parent.tagName.toLowerCase() === 'i' && parent.className.indexOf('waves-effect') !== -1) {
                        return false;
                    }

                    // Put element class and style to the specified parent
                    var wrapper = document.createElement('i');
                    wrapper.className = el.className + ' waves-input-wrapper';

                    var elementStyle = el.getAttribute('style');
                    var dimensionStyle = 'width:' + el.offsetWidth + 'px;height:' + el.clientHeight + 'px;';

                    if (!elementStyle) {
                        elementStyle = '';
                    }

                    wrapper.setAttribute('style', dimensionStyle + elementStyle);

                    el.className = 'waves-button-input';
                    el.removeAttribute('style');

                    // Put element as child
                    parent.replaceChild(wrapper, el);
                    wrapper.appendChild(el);

                }

            }
        }
    };

    Waves.displayEffect = function (options) {

        options = options || {};

        if ('duration' in options) {
            Effect.duration = options.duration;
        }
        var l = $$('.waves-effect');
        //Wrap input inside <i> tag
        Effect.wrapInput(l);

        Array.prototype.forEach.call(l, function (i) {

            if (window.Touch) {
                i.addEventListener('touchstart', Effect.show, false);
                i.addEventListener('touchend', Effect.hide, false);
            }

            i.addEventListener('mousedown', Effect.show, false);
            i.addEventListener('mouseup', Effect.hide, false);
            i.addEventListener('mouseleave', Effect.hide, false);

        });

    };
// Expose the show and hide functions on the Waves object
    Waves.show = Effect.show;
    Waves.hide = Effect.hide;
    window.Waves = Waves;

})(window);