import app from "/src/app/app.module";
import PaymentAdress from "../../models/payment-adress";
import {dateToUnixTimestamp} from "../../shared/utils";


app.controller('order-details',
    function ($scope, vxUtils, settings, $job, selectionFilters,
              $location, $filter, enums, $order, $customer, vxSession,
              toastr, $users, $products, tabService, $locale) {

        var tab = tabService.getCurrentTab();
        var id = tab.id;

        $scope.locale = $locale;
        $scope.cust = {};
        $scope.savetoserver = true;
        $scope.order = {};
        $scope.actionBarCpi = {};
        $scope.customerCpi = {};
        $scope.paymentCpi = {};
        $scope.paymentLocationCpi = {};
        $scope.orderCpi = {};
        $scope.adressCpi = {};
        $scope.paymentCustCpi = {};
        $scope.PageHash = vxUtils.generateQuickGuid();
        $scope.locationCpi = {};

        var added = false;
        $scope.timeLineCpi = {
            tabItems: $scope.openItems,
            filters: selectionFilters.eventTypes
        };



        $scope.init = function (id) {
            $scope.oldorder = id;



            $order.getOrder(id, function (result) {
                $scope.order = result;
                $scope.orderCpi.item = $scope.order;

                if (!$scope.order.idjob) {
                    if (!added) {
                        $scope.actionitems.push(
                            {label: vxUtils.loc('DELETE'), href: "/src/panel/delete-dialog.html", icon: "fa fa-trash-o fa-2x"}
                        );
                        added = true;
                    }
                }
               $scope.locationCpi.adress = result.location;
               $scope.paymentCpi.item = result.offerte;
               $scope.paymentCpi.showAdress = true;
               $scope.paymentLocationCpi.adress = result.offerte;
               $scope.paymentCustCpi.cust = result.offerte;





                $users.getUser(function (result) {
                    $scope.paymentcreatedBy = result[0];
                }, $scope.order.createdByUserFK);

                $customer.getCustomer(function (result) {
                    $scope.cust = result;
                    $scope.customerCpi.cust = $scope.cust;
                    tab.tabname = $scope.order.tabID + ': ' +  $scope.cust.tabDescription;
                   // $scope.actionBarCpi.mailobject = $scope.paymentCpi.item;
                }, $scope.order.clientFK);


                $order.getEvents($scope.order.id, function (results) {
                    $scope.timeLineCpi.all = results;
                    $scope.timeLine = $scope.timeLineCpi;
                    $scope.timeLineCpi.refresh();
                });

                $products.getProductsByOrderPaymentItem(function (result) {
                    $scope.products = result;
                }, $scope.order.paymentFK);

            }, id);


        };

        $scope.locationCpi = {};
        $scope.auth = function () {
            return parseInt(vxSession.userRole) > 1;
        };
        ///////////actionbar/////////////
        $scope.actionitems = [
            {label: vxUtils.loc('SEND_EMAIL'), href: "/src/panel/mail-dialog.html", icon: "fa fa-envelope fa-2x"},
            {label: vxUtils.loc('NEW_NOTE'), href: "/src/panel/paperclip-dialog.html", icon: "fa fa-paperclip fa-2x"},
            // {
            //     label: "IDEAL",
            //     href: "/src/panel/pay-dialog.html",
            //     icon: "fa fa-eur fa-2x",
            // },
            {
                label:  vxUtils.loc('REFRESH'), click: function () {
                    $scope.init($scope.oldorder);
                    toastr.info($filter('translate')('REFRESHING'), $filter('translate')('BUSY'))
                }, href: "", icon: "fa fa-refresh fa-2x"
            }
        ];

        $scope.actionBarCpi = {};
        $scope.actionBarCpi.pageHash = $scope.PageHash;

        $scope.scrollMethod = function ($event, $delta, $deltaX, $deltaY) {
            vxUtils.scrollHorzontal($event, $delta, $deltaX, $deltaY, $scope.PageHash);
        };
        $scope.init(id);
    });