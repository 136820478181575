import app from "/src/app/app.module";
import HourType from "../../models/hour-type";
import HourRate from "../../models/hour-rate";


app.controller('job_details', function ($scope, selectionFilters, $products, msgBusActions,
                                        vxUtils, $timeout, $api, $filter, enums, toastr, $users,
                                        $customer, $job, msgBus, $payment, tabService, vxSession, $locale) {
    var tab = tabService.getCurrentTab();
    $scope.tog = false;
    $scope.locale = $locale;
    msgBus.onMsg(msgBusActions.suspend + tab.hash, function (sender, mssg) {
        $scope.tog = mssg === 'true';
        $scope.toggler = function () {
            return $scope.tog
        };
    }, $scope);

    var id = tab.id;
    $scope.PageHash = vxUtils.generateQuickGuid();
    $scope.job = {};
    $scope.createdBy = {};
    $scope.customerCpi = {};
    $scope.locationCpi = {};
    $scope.adressCpi = {};
    $scope.jobCpi = {};
    $scope.savetoserver = true;
    $scope.paymentCpi = {};
    $scope.paymentCpi.showAdress = true;
    $scope.session = vxSession;

    $scope.option = {
        cursorcolor: "#bdbdbd",
        background: "#ffffff",
        cursorwidth: "7px",
        cursorborder: "none",
        cursorborderradius: "2px",
        zindex: 9998,
        autohidemode: false,
        bouncescroll: true,
        mousescrollstep: 20,
        touchbehavior: false,
        oneaxismousemode: true,
        rtlmode: true
    };

    msgBus.onMsg(msgBusActions.employeeReloadPanel, function (data, msg) {
        if (msg !== $scope.PageHash)
            return;
        $scope.empExpPanelCpi.refresh();

    }, $scope);

    msgBus.onMsg(msgBusActions.employeeReloadCrewPanel, function (data, msg) {
        if (msg !== $scope.PageHash)
            return;
        $job.getJobCrew(function (result) {
            $scope.empExpPanelCpi.planned = result;
            var flat = [];
            var len = result.length;
            _.forIn(result, function (v, k) {
                if (_.isArray(v))
                    flat = flat.concat(v);
            });
            var found = _.map(flat, function (item) {
                return item.idUser;
            });
            $api.post($api.adress.hourrate + '/batch',
                function (output) {
                    if (!_.isArray(output))
                        output = [output];

                    var groups = _.groupBy(output, 'user_id');

                    _.forEach(groups, function (v, k) {
                        _.forIn($scope.empExpPanelCpi.planned, function (v1, k1) {
                            if (_.isArray(v1)) {
                                var found = _.find(v1, {idUser: parseInt(k)});
                                if (found !== undefined) {
                                    found.hourrates = v;
                                    var l = _.find(v, {type_id: found.hour_rate_id.toString()});
                                    found.hour_rate_label = l._label;
                                }
                            }
                        });

                    })

                }, found, HourRate);
            // $scope.empExpPanelCpi.refresh();
        }, $scope.job.jobid);

        $job.getAvailableCrew(function (result) {
            $scope.empExpPanelCpi.crew = result;

        }, $scope.job.jobid);


    }, $scope);

    $scope.init = function (object) {

        $scope.job = object;
        if ($scope.employee())
            $scope.showCars = true;

        $scope.jobCpi.item = object;
        $scope.locationCpi.adress = object;

        $api.get($api.adress.hourtypes,
            function (output) {
                if (!_.isArray(output))
                    output = [output];

                $scope.empExpPanelCpi.hourrates = output;
                var job = $scope.job;
                var l = _.find($scope.empExpPanelCpi.hourrates,
                    {job_type_id: job.dbo['id_partykind']});
                if (l === undefined) {
                    l = _.find($scope.empExpPanelCpi.hourrates,
                        {id: '' + 1});
                }
                $scope.empExpPanelCpi.hour_rate_label = l.label;
                $scope.empExpPanelCpi.hour_rate_id = l.id;

            }, HourType);

        $users.getUser(function (result) {
            $scope.createdBy = result[0];
        }, $scope.job.createdbyID);

        $customer.getCustomer(function (result) {
            $scope.cust = result;
            $scope.customerCpi.cust = result;
            $scope.adressCpi.adress = result;
        }, object.idClient);

        $job.getJobCrew(function (result) {
            $scope.empExpPanelCpi.planned = result;
            var flat = [];
            var len = result.length;
            _.forIn(result, function (v, k) {
                if (_.isArray(v))
                    flat = flat.concat(v);
            });
            var found = _.map(flat, function (item) {
                return item.idUser;
            });
            $api.post($api.adress.hourrate + '/batch',
                function (output) {
                    if (!_.isArray(output))
                        output = [output];

                    var groups = _.groupBy(output, 'user_id');

                    _.forEach(groups, function (v, k) {
                        _.forIn($scope.empExpPanelCpi.planned, function (v1, k1) {
                            if (_.isArray(v1)) {
                                var found = _.find(v1, {idUser: parseInt(k)});
                                if (found !== undefined) {
                                    found.hourrates = v;
                                    var l = _.find(v, {type_id: found.hour_rate_id.toString()});
                                    found.hour_rate_label = l._label;
                                }
                            }
                        });

                    })

                }, found, HourRate);
        }, object.jobid);

        //$job.getAvailableCrew(function (result) {
        //    $scope.empExpPanelCpi.crew = result;
        //
        //}, object.jobid);

        $job.getEvents(function (results) {
            $scope.timeLineCpi.all = results;
            if ($scope.timeLineCpi.refresh)
                $scope.timeLineCpi.refresh();
        }, $scope.job.jobid, 'lol');

        $products.getProductsByJob(function (result) {
            $scope.products = result;
        }, $scope.job.jobid);

        $payment.getPaymentItemByJob(function (result) {
            $scope.paymentCpi.item = result;
            $scope.paymentCpi.hideNewData = isNaN(result.idPayment);

            $users.getUser(function (result) {
                $scope.paymentcreatedBy = isNaN(result[0].idUser) ? undefined : result[0];
            }, result.idUser);
        }, $scope.job.jobid);
    };

    $scope.timeLineCpi = {
        tabItems: $scope.openItems,
        filters: selectionFilters.eventTypes
    };

    $scope.scrollMethod = function ($event, $delta, $deltaX, $deltaY) {
        vxUtils.scrollHorzontal($event, $delta, $deltaX, $deltaY, $scope.PageHash);
    };

    ///////////actionbar/////////////
    $scope.actionitems = [
        {label: vxUtils.loc('DELETE'), href: "/src/panel/delete-dialog.html", icon: "fa fa-trash-o fa-2x"},
        {
            label: vxUtils.loc('SEND_EMAIL'),
            href: "/src/panel/mail-dialog.html",
            icon: "fa fa-envelope fa-2x"
        },
        {label: vxUtils.loc('CHANGES'), href: "/src/panel/changes-panel.html", icon: "fa fa-history fa-2x"},
        {
            label: vxUtils.loc('CREATE_DOCUMENT'),
            href: "/src/panel/document-dialog.html",
            icon: "fa fa-file-text-o fa-2x"
        },
        {label: vxUtils.loc('NEW_PHONE_CALL'), href: "/src/panel/call-dialog.html", icon: "fa fa-phone fa-2x"},
        {label: vxUtils.loc('NEW_NOTE'), href: "/src/panel/paperclip-dialog.html", icon: "fa fa-paperclip fa-2x",},
        {
            label: vxUtils.loc('CARS'),
            href: "/src/panel/car-panel.html",
            icon: "fa fa-car fa-2x",
        },
        // {
        //     label: vxUtils.loc('IDEAL'),
        //     href: "/src/panel/pay-dialog.html",
        //     icon: "fa fa-eur fa-2x",
        // },
        {
            label: vxUtils.loc('REFRESH'), click: function () {
            $scope.init($scope.job);
            toastr.info($filter('translate')('REFRESHING'), $filter('translate')('BUSY'))
        }, href: "", icon: "fa fa-refresh fa-2x"
        }
    ];

    $scope.actionBarCpi = {};
    $scope.actionBarCpi.pageHash = $scope.PageHash;

    $scope.empExpPanelCpi = {
        panels: [
// { label: vxUtils.loc('DELETE'), href: "", icon: "fa fa-trash-o fa-2x", guid: vxUtils.generateQuickGuid(), toggle: false },
            {
                label: vxUtils.loc('SCHEDULED_STAFF'), width: 400,
                href: "/src/panel/employee-overview.html", obj: 'planned', filter: true,
                id: "plannedEmpOverview" + $scope.PageHash,
                icon: "fa fa-group", toggle: true, guid: vxUtils.generateQuickGuid()
            }
        ]
    };
    $scope.auth = function () {
        return parseInt(vxSession.userRole) > 1;
    };

    $scope.employee = function () {
        return parseInt(vxSession.userRole) == 1;
    };

    if ($scope.auth())
        $scope.empExpPanelCpi.panels.push({
            label: vxUtils.loc('AVAILABLE_STAFF'), width: 400,
            href: "/src/panel/employee-overview.html", obj: 'crew', filter: true,
            icon: "fa fa-group", toggle: false, guid: vxUtils.generateQuickGuid(),
            id: "crewEmpOverview" + $scope.PageHash,
            init: function (scope) {
                scope.ignoreSuspention = true;
            },
            onOpen: function () {

                $job.getAvailableCrew(function (result) {
                    $scope.empExpPanelCpi.crew = result;
                }, $scope.job.jobid);
            }
        });

    $job.getJobByJobNr(function (item) {
        document.title = item.tabID + " " + item.tabDescription;
        tab.tabname = item.tabID + " " + item.tabDescription;
        $scope.init(item);
    }, parseInt(id));


    var timer = $timeout(function () {
        $scope.empExpPanelCpi.closeAll();
        msgBus.emitMsg("UpdateScroll", {});
    }, 350);

    $scope.$on(
        "$destroy",
        function (event) {

            $timeout.cancel(timer);

        }
    );


});