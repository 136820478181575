import app from "/src/app/app.module";

app.controller('mail-dialog',
    function ($scope, $templates, $window, $payment, settings, $job, Mailto, $filter,
              $voxilConnect, vxMailMerge, vxUtils) {

        var vm = this;
        vm.foreachAcceptedUser = false;
        vm.dialogGuid = vxUtils.generateQuickGuid();

        vm.job = vxUtils.findObjectOnParents($scope, 'job');
        vm.order = vxUtils.findObjectOnParents($scope, 'order');
        vm.cust = vxUtils.findObjectOnParents($scope, 'cust');
        vm.payment = vxUtils.findObjectOnParents($scope, 'paymentCpi');
        vm.proucts = vxUtils.findObjectOnParents($scope, 'products');
        vm.onlyCreate = false;
        vm.selectedTemplate = {};

        vm.init = function (c) {
            vm.onlyCreate = c;
            vm.actions.splice(0, 1, {label: 'Document aanmaken', value: 'create'});
            vm.selectedAction = vm.actions[0];
        };
        vm.actionChanged = function () {
            var type = vm.selectedAction.value;

            $payment.getPaymentItemsByClient(function (result) {
                vm.items = result;
            }, type, vm.cust.idClient);

        };

        vm.actions = [
            {label: vxUtils.loc('SEND_QUOTE_PANEL'), value: 'quotes'},
            {label: vxUtils.loc('SEND_INVOICE_PANEL'), value: 'invoices'},
            {label: vxUtils.loc('SEND_EMAIL_PANEL'), value: 'email'}
        ];
        vm.selectedAction = vm.actions[2];

        vm.closepanel = function () {
            $scope.toggleNav(false);
        };

        vm.getMessages = function () {
            $templates.getMessages(function (result) {
                vm.templates = result;
                if (vm.templates.length > 0)
                    vm.selectedTemplate = vm.templates[0];
            });
        };

        vm.getPdfTemplates = function () {
            $templates.getHTMLtemplates(function (result) {
                vm.HTMLtemplates = result ? result : [];
                $templates.getWordtemplates(function (result) {
                    $.merge(vm.HTMLtemplates, result);
                    if (vm.HTMLtemplates.length > 0)
                        vm.selectedDocument = vm.HTMLtemplates[0];
                });
            });
        };

        vm.openOutlook = function () {
            vm.job = vxUtils.findObjectOnParents($scope, 'job');
            vm.order = vxUtils.findObjectOnParents($scope, 'order');
            vm.cust = vxUtils.findObjectOnParents($scope, 'cust');
            vm.payment = vxUtils.findObjectOnParents($scope, 'paymentCpi');
            vm.products = vxUtils.findObjectOnParents($scope, 'products');
            vm.planned = vxUtils.findObjectOnParents($scope, 'empExpPanelCpi');


            var emailadres = vm.cust.idClient ? vm.cust.email : vm.job.email;
            vm.selectedTemplate.recipients = [emailadres];
            //vm.selectedTemplate.subject = vm.subject;

            if (vm.selectedItem) {
                $job.getJob(function (result) {
                    vm.job = result;
                    $payment.getPaymentItemByJob(function (result) {
                        vm.payment = result;
                        vm.openWord();
                        vm.job = -1;

                    }, vm.selectedItem.idjob);

                }, vm.selectedItem.idjob);
            }
            else {
                vm.openWord();
            }

        };
        //"Reiskosten Oesterman|€|2,25|x|300|=|€|500,00"
        vm.openWord = function () {
            var mergeListJob, mergeListCust, mergeListPayment, mergeListProducts = [];

            if (vm.cust !== -1)
                mergeListCust = vxMailMerge.createMailMergeList(vm.cust, 'customer');
            if (vm.payment !== -1)
                mergeListPayment = vxMailMerge.createMailMergeList(vm.payment.item, 'payment');
            if (vm.job !== -1)
                mergeListJob = vxMailMerge.createMailMergeList(vm.job, 'job');
            if (vm.products !== -1)
                mergeListProducts = vxMailMerge.createProductList(vm.products);

            var mergeList = [];
            mergeList = mergeList.concat(mergeListJob ? mergeListJob : []);
            mergeList = mergeList.concat(mergeListCust ? mergeListCust : []);
            mergeList = mergeList.concat(mergeListPayment ? mergeListPayment : []);
            mergeList = mergeList.concat(mergeListProducts ? mergeListProducts : []);

            var options = {
                subject: ' ',
                body: ' '
            };
            vm.Template = vm.selectedTemplate;
            if (vm.selectedAction.value !== "email") {

                const id =vm.job.jobid?vm.job.jobid:vm.order.id;
                let type =vm.job.jobid?'job':'order';

                const createForAcceptedUsers = vm.foreachAcceptedUser;

                if(createForAcceptedUsers ){
                    type = 'job|user';
                    for(const user of vm.planned.planned.accepted){
                        $voxilConnect.createWord(function (result) {

                        }, vm.selectedDocument.res_upload_id, type, `${id}|${user.idUser}`);
                        let content = decodeURIComponent(vm.Template.content);
                        content = $filter('plinebreakplain')(content);
                    }

                } else {

                    $voxilConnect.createWord(function (result) {

                    }, vm.selectedDocument.res_upload_id, type, id);
                    let content = decodeURIComponent(vm.Template.content);
                    content = $filter('plinebreakplain')(content);
                }

                if (vm.selectedAction.value === 'create')
                    return;

                vm.Template.content = vxMailMerge.fillTemplateFromObject(mergeList, vxUtils.strip_tags(content));
                vm.Template.subject = vxMailMerge.fillTemplateFromObject(mergeList, vm.Template.subject);
                options = {
                    subject: vm.Template.subject,
                    body: vm.Template.content
                };
            }
            var href = Mailto.url(vm.Template.recipients, options);

            $window.location = href;


        };

        vm.getMessages();
        vm.getPdfTemplates();
    });