'use strict';

var Event = function (event) {

    this.dbo = event;

    this.Title = function () {

        if (this.Type() === 'note' || this.Type() === 'planned' || this.Type() === 'incoming' || this.Type() === 'outgoing') {

            var prep = this.dbo['ctd_preposition'];
            if (prep !== "" && prep !== null)
                prep = " " + prep;
            else {
                prep ='';
            }

            return this.dbo['ctd_surname'] + prep + " " + this.dbo['ctd_lastname'];
        } else {
            return this.dbo['Name'];
        }

    };

    this.ID = function () {
        return this.dbo['ID'];
    };

    this.SecundairID = function () {
        return this.dbo['SecundairID'] ? this.dbo['SecundairID'] : 0;
    };


    this.Icon = function () {
        switch (this.Type()) {
            case 'note_quote':
                return 'fa fa-clock-o';
                break;
            case 'note_cancellation':
                return 'ionicons ion-android-cancel';
                break;
            case 'note_payment':
                return 'fa fa-eur';
                break;
            case 'email':
                return 'fa fa-envelope';
                break;
            case 'note':
                return 'fa fa-paperclip';
                break;
            case 'outgoing':
                return 'fa fa-phone';
                break;
            case 'incoming':
                return 'fa fa-phone';
                break;
            case 'planned':
                return 'fa fa-phone';
                break;
            case 'job':
                return 'fa fa-map-marker';
                break;
        }
    };

    this.Background = function () {
        switch (this.Type()) {
            case 'note_cancellation':
            case 'outgoing':
                return 'red-bg';
                break;
            case 'note_payment':
            case 'incoming':
                return 'green-bg';
                break;
            case 'note_quote':
            case 'planned':
                return 'yellow-bg';
                break;
            default:
                return 'vx-blue-bg';
                break;
        }
    };

    this.From = function () {
        return this.dbo['eFrom'];
    };

    this.To = function () {
        return this.dbo['eTo'];
    };

    this.Subject = function () {
        return this.dbo['Subject'];
    };

    this.Message = function () {
        return this.dbo['Message'];
    };

    this.Type = function () {
        return this.dbo['Type'];
    };

    this.Time = function () {
        return parseInt(this.dbo['Date'] + "000");
    };


    this.title = this.Title();
    this.id = this.ID();
    this.secundairid = this.SecundairID();
    this.type = this.Type();
    this.time = this.Time();
    this.message = this.Message();
    this.icon = this.Icon();
    this.background = this.Background();
    this.from = this.From();
    this.to = this.To();
    this.subject = this.Subject();
};

export default Event;

//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];