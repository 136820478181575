import app from "/src/app/app.module";
app.controller('add-products-form', function ($scope, msgBus) {
    if ($scope.wizard.products === undefined) {
        $scope.wizard.products = [];
    }

    $scope.sendValidation = function () {
        msgBus.emitMsg('valForm', {
            isValid: true,
            scope: $scope
        });
    };
    $scope.sendValidation();


    $scope.$on('itemsUpdated', function(event, updatedItems) {
        console.log('Received updated items in parent component:', updatedItems);
        // check if the idproductdetail are set so whe assume its saved
        const invalid = $scope.wizard.products.some(item=> item.idproductdetail == null);
        msgBus.emitMsg('valForm', {
            isValid: !invalid,
            scope: $scope
        });
    });


});