import app from "/src/app/app.module";
import EmailResource from "../../models/email-resource";


app.controller('notify_templates',
    function ($scope, $templates, msgBus, vxUtils,toastr) {
        var vm = this;

        vm.isHtml = $scope.panel.isHtml;
        vm.subjectPlaceholder = vm.isHtml ? vxUtils.loc('ADD_SUBJECT_PLACEHOLDER') : vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.messages = [];
        vm.selectedMessage = {content: ''};
        vm.notifications = [];

        vm.addNotifycation = function () {
            var m = new EmailResource();
            m.subject = '';
            m.type = 'notifybody';
            m.label = vm.title;

            vm.title = '';
            vm.notifications.splice(0, 0, m);
        };

        vm.deleteitem = function (item) {
            var i = vm.notifications.indexOf(item);
            vm.notifications.splice(i, 1);

            $templates.delete(function (i) {
                toastr.success(vxUtils.loc("SUCCES"), vxUtils.loc("SUCCESFULLDELETED"));
            }, item.id_email_resource);
        };

        vm.saveMessage = function () {
            $templates.saveBatch(function (i) {

            }, vm.notifications);
        };

        vm.getMessages = function () {
            $templates.getMessages(function (result) {
                vm.messages = result;
                $templates.getNotifytemplates(function (result) {
                    vm.notifications = result;
                });
            });
        };

        vm.getMessages();

    });