import app from "/src/app/app.module";


app.controller('products', function ($scope, $products, msgBus, msgBusActions,vxUtils) {

    ////////OVERVIEW/////
    $scope.overviewCpi = {
        type: 'product',
        openItemPage: "/src/pages/products/product-details.html",
        openItemIcon: "fa fa-clock-o",
        tabItems: $scope.openItems,
        selectionChanged: function (data) {

            $scope.overviewCpi.reload();
        }
    };

    $scope.headerButtons = [
        {
            tooltip:  vxUtils.loc('EXPORT_TO_EXCEL'),
            icon: 'fa fa-file-excel-o',
            action: function () {
                $scope.overviewCpi.export(vxUtils.loc('PRODUCTS'), $scope.columns,
                    $scope.overviewCpi.items);
            }
        },
        {
            tooltip:  vxUtils.loc('REFRESH'),
            icon: 'fa fa-refresh',
            action: function () {
                $scope.getItems();
            }
        }
    ];

    $scope.columns = [
        { title: '#', field: 'fullnumber', visible: true, filter: "fullproductnumber" },
        {
            titleicon: 'fa fa-calculator fa-05x', name: vxUtils.loc('COUNT_ME'), field: 'showincalcicon',
            altfield: 'showincalc',
            visible: true, icon: true
        },
        { title: vxUtils.loc('PRODUCTGROUP'), field: 'productgroupname', visible: true },
        { title: vxUtils.loc('PRODUCTNAME'), field: 'productname', visible: true },
        { title: vxUtils.loc('DESCRIPTION'), field: 'productdescr', visible: true }

    ];

    $scope.getItems = function () {
        $products.allProducts(function (results) {
            $scope.overviewCpi.items = results;
            $scope.overviewCpi.selectionChanged(null);
        }, true);
    };

    msgBus.onMsg(msgBusActions.overviewReload, function (sender, mssg) {
        if (mssg === 'product')
            $scope.getItems();
    }, $scope);

    $scope.getItems();
    ////////OVERVIEW/////
});