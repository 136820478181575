import app from "/src/app/app.module";


app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        FUTURE_JOBS: 'Klussen komende 7 dagen',
        INCOMING_ORDERS: 'Binnengekomen orders afgelopen 14 dagen',
        REVENUE_STATUS: "Omzet per job status inzichtelijk",
        REVENUE_STATUS_ORDERS: "Omzet per order status inzichtelijk",
        STATUS_JOBS_MONTHLY: "Status van jobs per maand",
        STATUS_ORDERS_MONTHLY: "Status van order per maand",
        STATUS_JOBS_THIS_MONTH: "Status van jobs deze maand",
        STATUS_ORDERS_THIS_MONTH: "Status van jobs deze maand",
        HOME: 'Home',
        DASHBOARD: 'Dashboard',
        NETHERLANDS: 'Nederland',
        BELGIUM: 'België',
        FRANCE: 'Frankrijk',
        GERMANY: 'Duitsland',
        LUXEMBOURG: 'Luxemburg',
        ITALY: 'Italië',
    });

    const translations = {
        FUTURE_JOBS: 'Jobs next 7 days',
        INCOMING_ORDERS: 'Received orders past 14 days',
        REVENUE_STATUS: "Revenue breakdown by job status",
        REVENUE_STATUS_ORDERS: "Revenue breakdown by order status",
        STATUS_JOBS_MONTHLY: "Status of jobs each month",
        STATUS_JOBS_THIS_MONTH: "Status of jobs this month",
        STATUS_ORDERS_MONTHLY: "Status of jobs each month",
        STATUS_ORDERS_THIS_MONTH: "Status of jobs this month",
        HOME: 'Home',
        DASHBOARD: 'Dashboard',
        NETHERLANDS: 'Netherlands',
        BELGIUM: 'Belgium',
        FRANCE: 'France',
        GERMANY: 'Germany',
        LUXEMBOURG: 'Luxembourg',
        ITALY: 'Italy',
    };

    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);

    $translateProvider.preferredLanguage('nl-nl');
}]);