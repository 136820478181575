import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        OVERVIEW: 'Overzicht',
        CUSTOMERS: 'Klanten',
        AREACODE: 'Postcode',
        FIRSTNAME: 'Voornaam',
        PREPOSITION: 'Tussenvoegsel',
        LASTNAME: 'Achternaam',
        ADRESS: 'Adres',
        CITY: 'Stad',
        COUNTRY: 'Land',
        FAX: 'Fax',
        EMAIL: 'E-mail',
        TELEPHONE: 'Telefoon',
        TELEPHONE2: 'Telefoon #2',
        COMPANYNAME: 'Bedrijfsnaam',
        BTW: 'Btw-nummer',
        KVK: 'Kvk-nummer',
        COMPANY: 'Bedrijf',
        INDIVIDUAL: 'Particulier',
        STREET: 'Straat',
        STREETNUMBER: 'Huisnummer',
        VENDOR: 'Verkoper/winkel',
        RESELLER: 'Reseller',
        PARTNER: 'Partner',
        AGENT: 'Tussenpersoon',
        CUSTOMER: 'Klant',
        SUPPLIER: 'Leverancier',
        RELATION: 'Relatie',
        RELATIONS: 'Relaties',
        NAME: 'Naam',
        CUSTOMER_NAME: "Klantnaam",
        KIND_OF_CUSTOMER: "Soort klant",
        STATE_PROVINCE: 'Provincie',
        CUSTOMER_TYPES:'Klantsoorten'
    });
    const translations = {
        OVERVIEW: 'Overview',
        CUSTOMERS: 'Customers',
        AREACODE: 'Areacode',
        FIRSTNAME: 'Firstname',
        PREPOSITION: 'Preposition',
        LASTNAME: 'Lastname',
        ADRESS: 'Address',
        CITY: 'City',
        COUNTRY: 'Country',
        FAX: 'Fax',
        EMAIL: 'E-mail',
        TELEPHONE: 'Telephone',
        TELEPHONE2: 'Telephone #2',
        COMPANYNAME: 'Companyname',
        BTW: 'VAT number',
        KVK: 'Commerce number',
        COMPANY: 'Company',
        INDIVIDUAL: 'Private',
        STREET: 'Street',
        STREETNUMBER: 'House number',
        VENDOR: 'Seller/Shop',
        RESELLER: 'Reseller',
        PARTNER: 'Partner',
        AGENT: 'Agent',
        CUSTOMER: 'Customer',
        SUPPLIER: 'Supplier',
        RELATION: 'Relation',
        RELATIONS: 'Relations',
        NAME: 'Name',
        CUSTOMER_NAME: 'Customer name',
        KIND_OF_CUSTOMER: 'Kind of customer',
        STATE_PROVINCE: 'State',
        CUSTOMER_TYPES:'Customer types'
    };

    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);

    $translateProvider.preferredLanguage('nl-nl');
}]);