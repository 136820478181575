import app from "/src/app/app.module";
import Depot from "../../models/depot";


app.controller('create_depot',
    function ($scope, $timeout, msgBus, $depot, vxUtils, sweet, toastr) {
        var vm = this;

        vm.subjectPlaceholder = vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.depots = [];
        vm.selectedDepot = {};
        vm.adressCpi = {};

        vm.addMessage = function () {
            var m = new Depot();
            m.name = vm.title;
            vm.title = '';
            vm.selectedDepot = m;
            vm.saveDepot(true);
        };

        vm.deleteDepot = function () {
            sweet.show({
                title: vxUtils.loc('CONFIRMDELETION'),
                text: vxUtils.loc('REMOVEDEPOT'),
                type: 'success',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: vxUtils.loc('YES'),
                closeOnConfirm: true
            }, function () {
                $depot.deleteDepot(function () {
                    var i = vm.depots.indexOf(vm.selectedDepot);
                    vm.depots.splice(i, 1);
                    vm.selectedDepot = vm.depots[vm.depots.length - 1];
                    vm.adressCpi.adress = vm.selectedDepot;
                }, vm.selectedDepot.idWarehouse);
            });
        };

        vm.saveDepot = function (isNew) {
            $depot.postDepot(function (result) {
                if (isNew) {
                    vm.depots.splice(0, 0, result);
                    vm.adressCpi.adress = result;
                }

                vm.selectedDepot = result;
                toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('CHANGESUCCESFULL'));
            }, vm.selectedDepot);
        };

        vm.getDepots = function () {
            $depot.getAllDepots(function (result) {

                vm.depots = result;
                if (vm.depots.length > 0) {
                    vm.selectedDepot = vm.depots[0];
                    vm.adressCpi.adress = vm.selectedDepot;
                }
            }, true);

        };

        vm.getDepots();
    });