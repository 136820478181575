import app from "/src/app/app.module";
app.controller('add-location-form', function ($scope, vxForms, vxCountries) {

    $scope.locationFields = vxForms.location;
    $scope.countries = vxCountries.countries;

    if ($scope.wizard.customer !== undefined && $scope.wizard.location === undefined) {
        $scope.wizard.location = {};
        $scope.wizard.location.country = $scope.wizard.customer.country;
        $scope.wizard.location.areacode = $scope.wizard.customer.areacode;
        $scope.wizard.location.streetnumber = $scope.wizard.customer.streetnumber;
        $scope.wizard.location.street = $scope.wizard.customer.street;
        $scope.wizard.location.state = $scope.wizard.customer.state;
        $scope.wizard.location.city = $scope.wizard.customer.city;
    }

    $scope.prefillLocation = function (){
        $scope.wizard.location = {};
        var compLoc = $scope.wizard.selectedTendant;
        $scope.wizard.location.spot = compLoc.name;
        $scope.wizard.location.country = compLoc.country;
        $scope.wizard.location.areacode = compLoc.areacode;
        $scope.wizard.location.streetnumber = compLoc.streetnumber;
        $scope.wizard.location.street = compLoc.street;
        $scope.wizard.location.state = compLoc.state;
        $scope.wizard.location.city = compLoc.city;
    }

    $scope.options = {
        validation: {
            enabled: true
        },
        layout: {
            type: 'horizontal',
            labelSize: 3,
            inputSize: 9
        }
    };


});