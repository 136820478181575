import app from "/src/app/app.module";
import Job from "../models/job";
import User from "../models/user";
import Email from "../models/email";
import Event from "../models/event";

var api = function ($resource, settings, $http, $filter, vox, $localStorage) {

    var convertToObj = function (values) {
        var temp = [];
        angular.forEach(values, function (value, key) {
            var obj = new Job(value, $filter);
            //angular.extend(obj, value);
            this.push(obj);
        }, temp);
        return temp;
    };

    var convertToUser = function (values) {
        var temp = [];
        angular.forEach(values, function (value, key) {
            var obj = new User(value);
            //angular.extend(obj, value);
            this.push(obj);
        }, temp);
        return temp;
    };

    var events = $resource(settings.BASE_API_URL + 'v1/jobs/events/:id/:type');
    var queryEvents = function (callback, $id, $type) {
        //use a callback instead of a promise
        events.query({id: $id, type: $type}, function (result) {
            var out = [];
            //Underscore's "each" method
            angular.forEach(result, function (value, key) {
                var event = null;

                if (value['Type'] === 'job')
                    event = new Job(value, $filter);
                else if (value['Type'] === 'email')
                    event = new Email(value);
                else
                    event = new Event(value);

                out.push(event);
            });

            callback(out);
        });
    };

    var getAllJobKinds = function (callback) {
        if ($localStorage.allJobKinds !== undefined) {
            if (callback)
                callback($localStorage.allJobKinds);
        }
        vox.get(settings.BASE_API_URL + 'v1/jobs/kindofparties', function (result) {
            $localStorage.allJobKinds = result;
            if (callback)
                callback($localStorage.allJobKinds);
        });
    };

    var getAllJobStatuses = function (callback) {
        if ($localStorage.JobStatuses !== undefined) {
            if (callback)
                callback($localStorage.JobStatuses);
        }
        vox.get(settings.BASE_API_URL + 'v1/jobs/jobstatuses', function (result) {
            $localStorage.JobStatuses = result;
            if (callback)
                callback($localStorage.JobStatuses);
        });
    };

    var getAllJobs = function (callback) {
        vox.get(settings.BASE_API_URL + 'v1/jobs', function (data, status, headers, config) {
            var out = convertToObj(data);
            callback(out);
        });
    };

    var getJobsBetween = function (callback, startDate, endDate) {
        vox.get(settings.BASE_API_URL + 'v1/jobs/period/' + startDate + '/' + endDate, function (data, status, headers, config) {
            var out = convertToObj(data);
            callback(out);
        });
    };

    var deleteJob = function (callback, id) {
        vox.delete(settings.BASE_API_URL + 'v1/jobs/' + id, callback);
    };
    var getJobByJobNr = function (callback, id) {
        if (id === undefined) {
            callback();
            return;
        }

        vox.get(settings.BASE_API_URL + 'v1/jobs/nr/' + id,
            function (data, status, headers, config) {
                var out = convertToObj(data);
                callback(out[0]);
            });
    };

    var getEmailConfirmations = function (callback, ids, param) {
        if (param === undefined) {
            param = 'group';
        }

        vox.post(settings.BASE_API_URL + 'v1/jobs/confirmation/' + param, ids,
            function (data, status, headers, config) {
                callback(data);
            });
    };

    var getJobCrewStatus = function (callback, jobs, users) {

        var obj = {
            users: users,
            jobs: jobs
        };

        vox.post(settings.BASE_API_URL + 'v1/jobcrew', obj,
            function (data, status, headers, config) {
                callback(data);
            });
    };

    var getJob = function (callback, id) {
        if (id === undefined) {
            callback();
            return;
        }

        vox.get(settings.BASE_API_URL + 'v1/jobs/' + id,
            function (data, status, headers, config) {
                var out = convertToObj(data);
                callback(out[0]);
            });
    };

    var getJobByPaymentID = function (callback, id) {
        vox.get(settings.BASE_API_URL + 'v1/jobs/payment/' + id,
            function (data, status, headers, config) {
                var out = convertToObj(data);
                callback(out[0]);
            });
    };

    var getJobCrew = function (callback, id) {
        var url = 'v1/jobs/crew/' + id;
        var handler = function (data, status, headers, config) {
            var outa = convertToUser(data['accepted']);
            var outp = convertToUser(data['declined']);
            var outo = convertToUser(data['awaiting']);
            var out = {
                accepted: outa,
                declined: outp,
                awaiting: outo,
                jobId:data['jobId']
            };
            callback(out);
        }
        if ($.isArray(id)) {
            url = '/v1/jobs/crewbatch';
            vox.post(settings.BASE_API_URL + url, id, function (data, status, headers, config) {
                var output = [];
                var keys = Object.keys(data);
                for (var i = 0; i < keys.length; i++) {
                    var item =data[keys[i]][0];
                    var outa = convertToUser(item['accepted']);
                    var outp = convertToUser(item['declined']);
                    var outo = convertToUser(item['awaiting']);
                    var out = {
                        accepted: outa,
                        declined: outp,
                        awaiting: outo,
                        jobId:item['jobId']
                    };
                    output.push(out);
                }
                callback(output);
            });
        } else {
            vox.get(settings.BASE_API_URL + url, handler);
        }

    };

    var getAvailableCrew = function (callback, id) {

        vox.get(settings.BASE_API_URL + 'v1/jobs/available/' + id,
            function (data, status, headers, config) {
                var outa = convertToUser(data['available']);
                var outp = convertToUser(data['planners']);
                var outo = convertToUser(data['occupied']);
                var out = {
                    available: outa,
                    planners: outp,
                    occupied: outo
                };

                callback(out);
            });
    };

    var updatePaymentId = function (callback, jid, pid) {
        vox.put('', settings.BASE_API_URL + 'v1/jobs/update/payment/' + jid + '/' + pid, callback);
    };

    var deleteProduct = function (callback, productid) {
        vox.delete(settings.BASE_API_URL + 'v1/products/' + productid, callback);
    };

    var postJob = function (callback, job) {
        vox.post(settings.BASE_API_URL + 'v1/jobs', job,
            function (data, status, headers, config) {
                var out = convertToObj(data);
                callback(out[0]);
            });
    };

    var postJobcrew = function (callback, crew, jobid, error) {
        vox.post(settings.BASE_API_URL + 'v1/jobs/crew/' + jobid, crew,
            function (data, status, headers, config) {
                callback(data[0]);
            }, error);
    };

    var updateJobcrew = function (callback, item, error) {
        vox.post(settings.BASE_API_URL + 'v1/jobs/crew/update', item,
            function (data, status, headers, config) {
                callback(data[0]);
            }, error);
    };

    var postLocation = function (callback, location) {
        vox.post(settings.BASE_API_URL + 'v1/jobs/location', location,
            function (data, status, headers, config) {
                callback(data[0]);
            });
    };

    return {
        updateJobcrew: updateJobcrew,
        getEmailConfirmations: getEmailConfirmations,
        allKindOfParties: getAllJobKinds,
        allJobStatuses: getAllJobStatuses,
        getAllJobs: getAllJobs,
        getJobByJobNr: getJobByJobNr,
        updatePaymentId: updatePaymentId,
        postJob: postJob,
        getJob: getJob,
        deleteJob: deleteJob,
        postLocation: postLocation,
        getJobCrew: getJobCrew,
        getAvailableCrew: getAvailableCrew,
        getJobsBetween: getJobsBetween,
        getEvents: queryEvents,
        postJobcrew: postJobcrew,
        getJobByPaymentID: getJobByPaymentID,
        getJobCrewStatus: getJobCrewStatus
    };
};

app.factory("$job", api);