'use strict';

class OrderProduct {
    constructor(orderProduct) {
        if (orderProduct === undefined) {
            return;
        }

        this.dbo = orderProduct;
        const prefix = '';

        this.id = this.dbo[`${prefix}id`];
        this.productFK = this.dbo[`${prefix}product_FK`];
        this.taxRateFK = this.dbo[`${prefix}tax_rate_FK`];
        this.offerteFK = this.dbo[`${prefix}offerte_FK`];
        this.tendantFK = this.dbo[`${prefix}tendant_FK`];
        this.ordersFK = this.dbo[`${prefix}orders_FK`];
        this.productquantity = this.dbo[`${prefix}productquantity`];
        this.productprice = this.dbo[`${prefix}productprice`];
    }

    /**
     *
     * @param wizard
     * @returns {OrderProduct}
     */
    static parseWizard(wizard) {

        // Create a new OrderProduct instance using the mapped object

        return new OrderProduct({
            product_FK: wizard.idproductdetail,
            tax_rate_FK: wizard.taxid,
            offerte_FK: wizard.offerteFK,
            tendant_FK: wizard.tendantFK,
            orders_FK: wizard.ordersFK,
            productquantity: wizard.productquantity,
            productprice: wizard.productprice
        });
    }
}

export default OrderProduct;
