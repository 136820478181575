import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {

        INV_COMPANYNAME: 'Bedrijfsnaam factuuradres',
        INV_FIRSTNAME: 'Voornaam factuuradres',
        INV_PREPOSITION: 'Tussenvoegsel factuuradres',
        INV_LASTNAME: 'Achternaam factuuradres',
        INV_STREET: 'Straat factuuradres',
        INV_STREETNUMBER: 'Huisnummer factuuradres',
        INV_AREACODE: 'Postcode factuuradres',
        INV_CITY: 'Stad factuuradres',
        INV_COUNTRY: 'Land factuuradres',
        CTD_COMPANYNAME: 'Bedrijfsnaam van klant',
        CTD_FIRSTNAME: 'Voornaam van klant',
        CTD_PREPOSITION: 'Tussenvoegsel van klant',
        CTD_LASTNAME: 'Achternaam van klant',
        CTD_STREET: 'Straat van klant',
        CTD_STREETNUMBER: 'Huisnummer van klant',
        CTD_AREACODE: 'Postcode van klant',
        CTD_CITY: 'Stad van klant',
        CTD_STATE: 'Provincie van klant',
        USR_STATE: 'Provincie van werknemer',
        LOC_STATE: 'Provincie van locatie',
        INV_STATE: 'Provincie van factuur adres',
        CTD_COUNTRY: 'Land van klant',
        CTD_EMAIL: 'E-mail van klant',
        CTD_TELEPHONE: 'Telefoonnr van klant',
        CTD_TEL: 'Telefoonnr van klant',
        CTD_KVK: 'KvK Nr klant',
        CTD_BTW: 'Btw Nr klant',
        USR_FIRSTNAME: 'Voornaam gebruiker',
        USR_PREPOSITION: 'Tussenvoegsel gebruiker',
        USR_LASTNAME: 'Achternaam gebruiker',
        USR_STREET: 'Straat gebruiker',
        USR_STREETNUMBER: 'Huisnummer gebruiker',
        USR_AREACODE: 'Postcode gebruiker',
        USR_CITY: 'Stad gebruiker',
        USR_COUNTRY: 'Land gebruiker',
        USR_EMAIL: 'E-mail gebruiker',
        USR_TELEPHONE: 'Telefoonnr gebruiker',
        USR_BIRTHDAY: 'Geboortedatum gebruiker',
        USR_DATEOFHIRE: 'Jubileumdatum gebruiker',
        USR_PASSRESETURL: 'Wachtwoord reset URL',
        LOC_LOCATION: 'Plaats van locatie',
        LOC_STREET: 'Straat van locatie',
        LOC_STREETNUMBER: 'Huisnummer van locatie',
        LOC_AREACODE: 'Postcode van locatie',
        LOC_CITY: 'Stad van locatie',
        LOC_COUNTRY: 'Land van locatie',
        JOB_TITLE: 'Jobnaam',
        JOB_NUMBER: 'Jobnummer',
        JOB_STARTDATE: 'Datum van job (03-05-2001)',
        JOB_STARTDATE_LONG: 'Datum van job (3 mei 2001)',
        JOB_ENDDATE: 'Einddatum van job',
        JOB_ENTRANCE: 'Aanvang van job',
        JOB_TIME: 'Begin en eindtijd van job',
        JOB_STARTTIME: 'Begintijd van job',
        JOB_ENDTIME: 'Eindtijd van job',
        JOB_AMOUNTGUESTS: 'Aantal gasten aanwezig',
        JOB_AMOUNTEMPLOYEES: 'Aantal medewerkers op de job',
        JOB_KINDOFPARTY: 'Soort job',
        JOB_STATE: 'Status van job',
        JOB_INTERNALREMARKS: 'Opmerkingen voor kantoor',
        JOB_EXTERNALREMARKS: 'Opmerkingen voor medewerkers',
        PAYMENT_JOBID: "",
        PAYMENT_NR: "Factuurnummer",
        PAYMENT_SENDDATE: "Datum Offerte/Factuur verzonden",
        PAYMENT_CREATIONDATE: "Datum Offerte/Factuur aangemaakt",
        PAYMENT_DUEDATE: "Datum Offerte/Factuur aangemaakt",
        PAYMENT_STATUS: "Offerte/Factuur status",
        PAYMENT_TYPE: "Offerte of Factuur"
    });

    const translation = {
        INV_COMPANYNAME: 'Company billing address',
        INV_FIRSTNAME: 'Firstname billing Address',
        INV_PREPOSITION: 'Preposition billing address',
        INV_LASTNAME: 'Lastname billing Address',
        INV_STREET: 'Street billing address',
        INV_STREETNUMBER: 'House Number Billing Address',
        INV_AREACODE: 'Areacode billing address',
        INV_CITY: 'City Billing Address',
        INV_COUNTRY: 'Country billing address',
        CTD_COMPANYNAME: 'Company of customer ',
        CTD_FIRSTNAME: 'Firstname of customer',
        CTD_PREPOSITION: 'Preposition customer ',
        CTD_LASTNAME: 'Lastname of customer',
        CTD_STREET: 'Street of customer ',
        CTD_STREETNUMBER: 'House Number of customer',
        CTD_AREACODE: 'Areacode customer ',
        CTD_CITY: 'City of customer ',
        CTD_STATE: 'State of customer',
        LOC_STATE: 'State of location',
        USR_STATE: 'State of employee',
        INV_STATE: 'State of Billing Address',
        CTD_COUNTRY: 'Country of customer ',
        CTD_EMAIL: 'Email Customer',
        CTD_TELEPHONE: 'Telephone customer ',
        CTD_TEL: 'Telephone customer ',
        CTD_KVK: 'Chamber No. customer ',
        CTD_BTW: 'VAT No customer ',
        USR_FIRSTNAME: 'Firstname user',
        USR_PREPOSITION: 'Preposition user ',
        USR_LASTNAME: 'Lastname user',
        USR_STREET: 'Street user',
        USR_STREETNUMBER: 'Housenumber user ',
        USR_AREACODE: 'Areacode user',
        USR_CITY: 'City user ',
        USR_COUNTRY: 'Country user',
        USR_EMAIL: 'Email user',
        USR_TELEPHONE: 'Telephone User ',
        USR_BIRTHDAY: 'birthday user',
        USR_DATEOFHIRE: 'Anniversary Date User ',
        USR_PASSRESETURL: 'Password reset URL',
        LOC_LOCATION: 'Location location',
        LOC_STREET: 'Street location ',
        LOC_STREETNUMBER: 'House Number of location',
        LOC_AREACODE: 'Areacode location ',
        LOC_CITY: 'City Location',
        LOC_COUNTRY: 'Country of location',
        JOB_TITLE: 'Job name',
        JOB_NUMBER: 'job number',
        JOB_STARTDATE: 'Date of job (12-29-2001) ',
        JOB_STARTDATE_LONG: 'Date of job (May 3, 2001) ',
        JOB_ENDDATE: 'End of Job',
        JOB_ENTRANCE: 'Start of Job',
        JOB_TIME: 'Beginning and end of job ',
        JOB_STARTTIME: 'Start of Job',
        JOB_ENDTIME: 'End of Job',
        JOB_AMOUNTGUESTS: 'Number of guests present',
        JOB_AMOUNTEMPLOYEES: 'Number of employees on the job',
        JOB_KINDOFPARTY: 'Job Type',
        JOB_STATE: 'Status of job ',
        JOB_INTERNALREMARKS: 'Remarks office ',
        JOB_EXTERNALREMARKS: 'Remarks for employees',
        PAYMENT_JOBID: '',
        PAYMENT_NR: 'Invoice Number',
        PAYMENT_SENDDATE: 'Date Quotation / Invoice sent',
        PAYMENT_CREATIONDATE: 'Date Quote / Invoice created',
        PAYMENT_DUEDATE: 'Date Quote / Invoice created',
        PAYMENT_STATUS: 'Quote / Invoice Status',
        PAYMENT_TYPE: 'Quotation or Invoice'
    };
    $translateProvider.translations('en-gb', translation);
    $translateProvider.translations('en-us', translation);

    $translateProvider.preferredLanguage('nl-nl');
}]);