import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {

    $translateProvider.translations('nl-nl', {
        NOTE: 'Notitie',
        MAIL: 'E-mail',
        HIGH: 'Hoog',
        NORMAL: 'Normaal',
        LOW: 'Laag',
        VERYIMPORTANT: 'Zeer belangrijk',
        IMPORTANT: 'Belangrijk',
        PRIORITY: 'Prioriteit',
        TEMPLATE: 'Template',
        SUBJECT: 'Onderwerp',
        CALL: 'Bellen',
        INCOMING: 'Inkomend gesprek',
        OUTGOING: 'Uitgaand gesprek',
        PLANNING: 'Inplannen van gesprek',
        EMAIL_MESSAGE: 'E-mail bericht',
        DEFAULT_MESSAGE: 'Standaard bericht',
        NEW_CUSTOMER: 'Nieuwe klant',
        TALKED_ABOUT: 'Besproken',
        SENDMESSAGE: 'Bericht verzenden',
        I_WANT_ACTION: 'Ik wil een',
        WHICH_ITEM: 'Betreft',
        DELETE: 'Verwijderen',
        WHICH_TEMPLATE: 'Welk template gebruiken?',
        REFRESHING: 'Gegevens worden vernieuwd',
        REFRESH: 'Vernieuwen',
        BUSY: 'Dom..ti..dom',
        CHANGES: 'Wijzigingen',
        CAR: "Auto",
        CARS: "Auto's",
        TAXES: "Belasting",
        OTHER:'Aangepast',
        DAYS:['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
        MONTHS:['Januari', 'Februari', 'Maart', 'April', 'Mei',
            'Juni', 'Juli', 'Augustus', 'September',
            'Oktober', 'November', 'December'
        ]
    });

    const translations = {
        TAXES: "Taxes",
        NOTE: 'Note',
        MAIL: 'E-mail',
        HIGH: 'High',
        NORMAL: 'Normal',
        LOW: 'Low',
        VERYIMPORTANT: 'Very important',
        IMPORTANT: 'Important',
        PRIORITY: 'Priority',
        TEMPLATE: 'Template',
        SUBJECT: 'Subject',
        CALL: 'Call',
        INCOMING: 'Incoming call',
        OUTGOING: 'Outgoing call',
        PLANNING: 'Plan a call',
        EMAIL_MESSAGE: 'E-mail message',
        DEFAULT_MESSAGE: 'Standard message',
        NEW_CUSTOMER: 'New customer',
        TALKED_ABOUT: 'Discussed',
        SENDMESSAGE: 'Sent message',
        I_WANT_ACTION: 'I want to',
        WHICH_ITEM: 'Subject',
        DELETE: 'Delete',
        WHICH_TEMPLATE: 'Which template to use?',
        REFRESHING: 'Data is refreshed',
        REFRESH: 'Refresh',
        BUSY: 'Dom..ti..dom',
        CHANGES: 'Changes',
        CARS: "Cars",
        CAR: "Car",
        OTHER:'Other',
        DAYS:['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        MONTHS:[
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    }

    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);

    $translateProvider.preferredLanguage('nl-nl');
}]);