import app from "/src/app/app.module";
import moment from "moment";
import Order from "../../models/order";
import Location from "../../models/location";
import OrderProduct from "../../models/order-product";
import PaymentItem from "../../models/payment-item";
import PaymentAdress from "../../models/payment-adress";
import OrderWizardResult from "../../models/order-wizard-result";
import {dateToUnixTimestamp} from "../../shared/utils";


app.controller('wizard', function ($scope, vxUtils, settings, $location, $routeParams,
                                   $filter, enums, $customer, $users, vxSession, sweet,
                                   $api, $timeout, $order,selectionFilters,
                                   $payment, $products, $job, tabService, msgBus) {
    $scope.PageHash = vxUtils.generateQuickGuid();
    var tab = tabService.getCurrentTab();


    var type = tab.id;
    $scope.init = function (object) {
        $scope.steps = object.steps;

        $scope.finish = object.finish;
    };

    $scope.openWizard = function (newItem) {

        var item = {};
        switch (newItem) {
            case 'customer':
                item = $scope.newCustomer(item);
                tab.tabname = vxUtils.loc('NEW_CUSTOMER');
                break;
            case 'quote':
                item = $scope.newPaymentItem(item, 'quote');
                tab.tabname =  vxUtils.loc('NEW_QUOTE');
                break;
            case 'invoice':
                item = $scope.newPaymentItem(item, 'invoice');
                tab.tabname =  vxUtils.loc('NEW_INVOICE');
                break;
            case 'job':
                item = $scope.newJob(item);
                tab.tabname =  vxUtils.loc('NEW_JOB');
                break;
            case 'product':
                item = $scope.newProduct(item);
                tab.tabname =  vxUtils.loc('NEW_PRODUCT');
                break;
            case 'employee':
                item = $scope.newEmployee(item);
                tab.tabname =  vxUtils.loc('NEW_EMPLOYEE');
                break;
         case 'order':
                item = $scope.newOrder(item);
                tab.tabname =  vxUtils.loc('NEW_ORDER');
                break;
        }

        $scope.init(item);
    };

    $scope.newCustomer = function (newItem) {
        newItem.steps = [
            {name: vxUtils.loc('CUSTOMER_DATA'), href: "/src/wizardpages/add-customer-form.html"}
        ];

        newItem.finish = function (wizard) {
            console.log(wizard);
            $customer.newCustomer(function (customer) {
                var type = 'customer';
                var id = customer.ClientNumber();
                $scope.openNewWindow(type, id, newItem);
            }, wizard.customer);
        };

        return newItem;
    };

    $scope.openNewWindow = function (type, id) {
        //var win = window.open('#/' + type + '/' + id, '_blank');
        //win.focus();
        //window.close();

        msgBus.emitMsg('shell.closeTabByGuid',
            {guid: tab.hash});
        msgBus.emitMsg('shell.openTabByType',
            {type: type, id: id});

    };

    $scope.newPaymentItem = function (newItem, type) {
        newItem.steps = [
            {name: vxUtils.loc('SEARCH_CUSTOMER'), href: "/src/wizardpages/search-customer-form.html"},
            {name: vxUtils.loc('QUOTE_DATA'), href: "/src/wizardpages/add-quote-form.html"},
            {name: vxUtils.loc('PRODUCTS'), href: "/src/wizardpages/add-products-form.html"}
        ];

        newItem.finish = function (wizard) {
            console.log(wizard);

            $payment.postInvoiceAdress(function (invoice) {
                var p = new PaymentItem();
                p.idPayment = 0;
                p.idClient = wizard.customer.idClient;
                p.idUser = vxSession.user.idUser;
                p.creationdate = Math.round(new Date().getTime() / 1000);
                p.duedate = Math.round(wizard.expirationDate / 1000);
                p.type = type;
                p.status = "new";
                p.senddate = null;
                p.idinvoiceadress = parseInt(invoice[0].idinvoiceadresses);

                $payment.postPaymentItem(function (quote) {
                    console.log(quote);

                    angular.forEach(wizard.products, function (value, key) {
                        value.idofferte = quote[0].idofferte;
                        value.idproductdetails = value.idproductdetail;

                        $products.postProduct(function () {
                            if (key + 1 === wizard.products.length) {
                                var type = 'payment';
                                var id = quote[0].idofferte;
                                $scope.openNewWindow(type, id);
                            }
                        }, value, 0);
                    });
                    if (wizard.products.length === 0) {
                        var type = 'payment';
                        var id = quote[0].idofferte;
                        $scope.openNewWindow(type, id);
                    }
                }, p);

            }, wizard.invoiceAdress);
        };

        return newItem;
    };

    $scope.newJob = function (newItem) {
        newItem.steps = [
            {name: vxUtils.loc('SEARCH_CUSTOMER'), href: "/src/wizardpages/search-customer-form.html"},
            {name: vxUtils.loc('JOB_DATA'), href: "/src/wizardpages/add-job-form.html"},
            {name: vxUtils.loc('LOCATIONDATA'), href: "/src/wizardpages/add-location-form.html"},
            {name: vxUtils.loc('QUOTE_DATA'), href: "/src/wizardpages/add-quote-form.html"},
            {name: vxUtils.loc('PRODUCTS'), href: "/src/wizardpages/add-products-form.html"}
        ];

        newItem.finish = function (wizard) {

            //job
            var days = moment.duration(wizard.jobData.enddate - wizard.jobData.startdate).asDays() | 0;
            wizard.jobData.createdbyID = vxSession.user.idUser;/////todo
            wizard.jobData.jobid = 0;
            wizard.jobData.idClient = wizard.customer.idClient;
            wizard.jobData.jobcreationdate = Math.round(new Date().getTime() / 1000.0);
            wizard.jobData.duedate = Math.round(wizard.jobData.duedate / 1000.0);
            wizard.jobData.entrance = Math.round(wizard.jobData.entrance / 1000.0);
            wizard.jobData.enddate = Math.round(wizard.jobData.enddate / 1000.0);
            wizard.jobData.startdate = Math.round(wizard.jobData.startdate / 1000.0);

            //wizard.jobData.idStatus = wizard.jobData.state.idjobstatuses;
            wizard.jobData.crewslots = wizard.jobData.employees;
            wizard.jobData.guestcount = wizard.jobData.guests;
            wizard.jobData.tendant = wizard.selectedTendant.idTendant;

            angular.forEach(wizard.kindofjobs, function (value, key) {
                if (value.type === wizard.jobData.kindofparty)
                    wizard.jobData.idPartykind = value.id_partykind;
                //set depot noord
                // if (wizard.jobData.idPartykind === '20') {
                //     wizard.jobData.tendant = 2;
                // }
            });

            function createMultipleJobs() {
                $api.post($api.adress.jobs + '/multi', function (result) {
                    angular.forEach(result, function (v, k) {
                        $timeout(function () {
                            $scope.openNewWindow('job', v);
                        }, 700);
                    })

                }, wizard);
            }

            var createJob2 = function () {
                $api.post($api.adress.jobs + '/create', function (result) {
                    $scope.openNewWindow('job', result['jobnummer']);
                }, wizard);
            };
            var createJob = function (object, index) {

                console.log(index + ' - ' + object.jobData.entrance);
                $job.postLocation(function (location) {
                    object.jobData.idLocation = location;

                    //invoice
                    $payment.postInvoiceAdress(function (invoice) {
                        object.jobData.invoiceadressid = invoice[0].idinvoiceadresses;
                        $job.postJob(function (job) {
                            //products
                            var p = new PaymentItem();
                            p.idPayment = 0;
                            p.idClient = object.customer.idClient;
                            p.idUser = vxSession.user.idUser;
                            p.creationdate = Math.round(Date.parse(Date()) / 1000);
                            p.duedate = Math.round(object.expirationDate / 1000);
                            p.type = "quote";
                            p.status = "new";
                            p.senddate = null;
                            p.idjob = job.idJob;
                            p.idinvoiceadress = object.jobData.invoiceadressid;

                            console.log(index + ' - ' + object.jobData.entrance);
                            $payment.postPaymentItem(function (quote) {
                                //   console.log(quote);
                                var jobId = job.idjob;
                                var jobNr = job.jobnumber;

                                $job.updatePaymentId(null, jobId, quote[0].idofferte);

                                if (object.products.length === 0) {
                                    var type = 'job';
                                    var id = jobNr;
                                    $scope.openNewWindow(type, id);
                                }
                                else {
                                    angular.forEach(object.products, function (value, key) {
                                        value.idofferte = quote[0].idofferte;
                                        value.idproductdetail = value.idproductdetail;
                                        value.idjob = job.idJob;

                                        $products.postProduct(function () {
                                            if (key === object.products.length - 1) {
                                                var type = 'job';
                                                var id = jobNr;
                                                $scope.openNewWindow(type, id);
                                            }
                                        }, value, 0);
                                    });
                                }
                            }, p);
                        }, object.jobData);
                    }, object.invoiceAdress);
                }, object.location);
            };


            if (days > 1) {
                sweet.show({
                    title: vxUtils.loc('MULTIPLE_DAYS'),
                    text: vxUtils.loc('JOB_TAKES_X') + days + vxUtils.loc('DAYS') +
                    vxUtils.loc('WOULD_YOU_CREATE'.replace('{days}', days)),
                    type: 'warning',
                    cancelButtonText: vxUtils.loc('NO'),
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: vxUtils.loc('YES'),
                    closeOnConfirm: true
                }, function () {
                    wizard.days = days;
                    createMultipleJobs();
                }, function () {
                    createJob2();
                });
            }
            else {
                createJob2();
            }
            //location

        };
        return newItem;
    };

    $scope.newOrder = function (newItem) {
        newItem.steps = [
            {name: vxUtils.loc('SEARCH_CUSTOMER'), href: "/src/wizardpages/search-customer-form.html"},
            {name: vxUtils.loc('ORDER_DATA'), href: "/src/wizardpages/add-order-form.html"},
            {name: vxUtils.loc('LOCATIONDATA'), href: "/src/wizardpages/add-location-form.html"},
            {name: vxUtils.loc('QUOTE_DATA'), href: "/src/wizardpages/add-quote-form.html"},
            {name: vxUtils.loc('PRODUCTS'), href: "/src/wizardpages/add-products-form.html"}
        ];

        newItem.finish = function (wizard) {
/*
    customer:
            orderData:
        invoiceAdress:
            location:
              products: (1) […]
 */
            const newOrder = Order.parseWizard(wizard.orderData);
            const deliveryLocation = Location.parseWizard(wizard.location)
            const invoiceAddress = PaymentAdress.parseWizard(wizard.invoiceAdress)
            const orderProducts = [];

            angular.forEach(wizard.products, (product, key) => {
                orderProducts.push(OrderProduct.parseWizard(product));
            });

            const orderWizardResult = new OrderWizardResult();

            orderWizardResult.deliveryLocation = deliveryLocation;
            orderWizardResult.invoiceAddress = invoiceAddress;
            orderWizardResult.orderProducts = orderProducts;
            orderWizardResult.order = newOrder;
            orderWizardResult.order.clientFK = wizard.customer.idClient;
            orderWizardResult.expirationDate = dateToUnixTimestamp(wizard.expirationDate);

            $order.createOrder(orderWizardResult, function (resultId){
                var type = 'order';

                $scope.openNewWindow(type, resultId);
            })





        };
        return newItem;
    };

    $scope.newProduct = function (newItem) {
        newItem.steps = [
            {name: vxUtils.loc('PRODUCT_DATA'), href: "/src/wizardpages/add-product-form.html"}
        ];

        newItem.finish = function (wizard) {

            var selected = $filter('filter')(wizard.groups, {
                productgroupname: wizard.product.productgroup.productgroupname
            })[0];
            wizard.product.idproductgroup = selected.productgroupid;

            $products.lastProductNumber(function (result) {
                wizard.product.productnumber = result + 1;
                $products.postProductDetail(function (result) {
                    var type = 'product';
                    var id = result.idproductdetail;
                    $scope.openNewWindow(type, id);
                }, wizard.product);
            }, wizard.product.idproductgroup);
        };
        return newItem;
    };
    $scope.newEmployee = function (newItem) {
        newItem.steps = [
            {name: vxUtils.loc('PERSONAL_DATA'), href: "/src/wizardpages/add-employee-form.html"}
        ];

        newItem.finish = function (wizard) {
            $users.postUser(wizard.employee, function (result) {
                var type = 'employee';
                var id = result[0];

                sweet.show(vxUtils.loc('SUCCES'),
                    vxUtils.loc('SUCCEFULL_NEW_USER'), 'success');

                setTimeout($scope.openNewWindow(type, id), 1500);
                selectionFilters.employeeGroupsAvail(null, true);
            });
        };
        return newItem;
    };

    $scope.openWizard(type);
});