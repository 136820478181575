import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        NOT:' niet ',
        REQUIRED: 'Dit is een verplicht veld',
        TEMPLATE: 'Template',
        CREATE: 'Creeër',
        CONFIRMDELETION: 'Weet u zeker dat u dit item wilt verwijderen?',
        UPLOADOFFICEWORD: 'Upload een Microsoft Word template',
        SUCCES: 'Jeej',
        UNFORTUNATLY: 'Helaas',
        NO_WORKNOTE_FOUND:'Ik heb geen werkbriefjes gevonden :(',
        CANCEL: 'Annuleren',
        UNSUCCESFULL: 'Oeps',
        CHANGESUCCESFULL: 'De wijziging is doorgevoerd',
        UPLOADSUCCESFULL: 'Dat was een succesvolle upload',
        CUSTOMERUDATESUCCESFULL: 'De klant is succesvol gewijzigd',
        SHAREWITHEMPLOYEES: 'Deel klusinfo met ingepland personeel',
        MAILALLEMPLOYEES: 'Maak een e-mail voor het ingepland personeel',
        ACCEPTALLCHANGES: 'Bevestig alle wijzigingen in ingepland personeel',
        ALLEMPLOYEESUCCEFULLPLANNED: 'Alle wijzigingen zijn doorgevoerd en ' +
        'het personeel is per e-mail op de hoogte gesteld',
        REVERTALLCHANGES: 'Alle wijzigingen zijn terug gedraaid',
        UNSUCCEFULLERRORMESSAGE: 'Er zijn geen wijzigingen doorgevoerd, ' +
        'als deze melding blijft, neem dan contact op met de systeembeheerder',
        SENDMAILJOBSTATUS: 'Wilt u het personeel op de hoogte stellen van deze wijziging?',
        SENDMAILSUCCESFULL: 'Het personeel is gemaild!',
        SENDMAILJOBSTATUSSHORT: 'Wijziging doormailen?',
        SUCCESFULLDELETED: 'Gelukt om te verwijderen',
        WHEN: 'Wanneer?',
        DONE: 'Gedaan',
        FILLFORMCORRECT: 'Formulier is nog niet correct ingevoerd',
        REMARKS_HELP: "Vertel ons over je klus..",
        SAVE: 'Opslaan',
        CREATEDOCUMENT: 'Maak en vul een document',
        CHOOSEPAYMENT: 'Kies een klus, offerte of factuur',
        CONFIRM: "Bevestig",
        REMOVEWORKNOTE: "U staat op het punt de gegevens van het " +
        "werkbriefje te verwijderen. Weet u het zeker?",
        REMOVECAR: "U staat op het punt om een auto uit te plannen " +
        "Weet u het zeker?",
        WHAT_ARE_YOU_DOING: "Wat ga je doen?",
        WHOLE_DAY: "Gehele dag",
        RECURRENT: "Periodiek",
        REMARKS_EXTRA: "Wil je ons meer vertellen?",
        CREATE_DEPOT: 'Maak depot aan',
        DEPOTS: 'Depots',
        IGNORE: 'Negeren',
        CALLAPPOINTMENT: "Je hebt een bel-afpraak",
        CALLAPPOINTMENTTEXT: 'Er dient gebeld te worden naar: $tel voor klus: $jobtitle om $time. \nHet betreft het volgende: $remarks',
        REMOVEPRODUCTGROUP: 'U staat op het punt een productgroep te verwijderen, als de' +
        ' productgroep gebruikt wordt, dan alle bijbehorende producten niet meer in het systeem verschijnen! Wil je doorgaan?'
        ,
        REMOVE_PARTY_KIND_REPLACE: 'U staat op het punt een feest type te verwijderen, als het' +
        ' feest type gebruikt wordt, dan worden de feest types voor alle jobs met dit feest type vervangen met {{jobKind}}! Wil je doorgaan?'
        ,
        REMOVE_PARTY_KIND: 'U staat op het punt een feest type te verwijderen. Wil je doorgaan?',
        REMOVEDEPOT: 'U staat op het punt een depot te verwijderen als dit' +
        ' depot gebruikt wordt in klussen dan verschijnen de klussen niet meer in het systeem! Wil je doorgaan?',
        REMOVECOMPANY: 'U staat op het punt een bedrijf te verwijderen als dit' +
            ' bedrijf gebruikt wordt in klussen dan verschijnen de klussen niet meer in het systeem! Wil je doorgaan?',
        CONFIRMPASSWORDREQUEST: 'Wachtwoord herstellen',
        PASSWORDRESETTEXT: 'Je vraagt een wachtwoord herstel aan voor: $email. Wil je verder gaan?',
        COMPANIES: 'Bedrijven',
        MANAGECOMPANIES: 'Beheer aangesloten bedrijven',
        REMOVEUSERGROUP: 'U staat op het punt een gebruikersgroep te verwijderen, als de' +
        ' gebruikersgroep gebruikt wordt, dan verschijnen alle gebruiker met deze groep niet meer in het systeem! Wil je doorgaan?',
        DELETEHOURRATE: "U staat op het punt een uurloon te verwijderen, weet u dit zeker?",
        FORGOT_PASSWORD: "Wachtwoord vergeten",
        MIN_6_CHARS: "Minimaal 6 characters",
        ENTER_PASSWORD: "Voer wachtwoord in",
        NOT_THE_SAME: "Niet hetzelfde",
        OPENING: 'Openen...',
        OYSTERPLANNING: 'Oesterplanning',
        NO_REMINDER_SEND: 'Geen herinnering verzonden',
        NEEDED: 'Nodig',
        SEARCHING: 'Zoeken..',
        SEARCH: 'Zoeken',
        NOBODY_FOUND: 'Niemand gevonden',
        EXPORT_TO_EXCEL: 'Exporteer overzicht naar excel',
        CHANGE: 'Wijzigen',
        DELETE_PRODUCT: 'Verwijder dit product',
        SEND_EMAIL: 'Verstuur e-mail',
        NEW_NOTE: 'Nieuwe notitie',
        NEW_PHONE_CALL: 'Nieuw telefoon gesprek',
        EMPTY_LIST: 'De lijst is leeg...',
        SEND_EMAIL_PANEL: 'E-mail versturen',
        SEND_INVOICE_PANEL: 'Factuur versturen',
        SEND_QUOTE_PANEL: 'Offerte versturen',
        PAYMENT_INFO: 'Indicatie netto uitbetaling op basis van alleen uren',
        PAYMENT_TRAVEL_EXPENSES: 'Indicatie, excl. onkosten en reiskosten',
        EMPTY_FORM: 'Maak formulier leeg',
        CHANGE_TEMPLATE: 'Wijzig template',
        NEW_TEMPLATE: 'Nieuw template',
        DOWNLOAD_TEMPLATE: 'Download template',
        HANDLED: 'Afgehandeld',
        TODAY: "Vandaag",
        CLOSE: 'Sluiten',
        NEVER_MIND: 'Laat maar',
        YESTERDAY: 'Gisteren',
        DAYS_7: '7 dagen geleden',
        DAYS_30: '30 dagen geleden',
        THIS_MONTH: 'Deze maand',
        LAST_MONTH: 'Vorige maand',
        NEXT_MONTH: 'Volgende maand',
        NEW_RELATION_DESCR: "Speciaal voor onze nieuw klant maakt maken onze Oestermannen en girls een oester op, het kan met citroen, peper of frambozen azijn, net wat de klant wilt. " +
        "Een intermezzo met een oesterman of girl zorgt er geregeld voor dat een nieuwe oesterliefhebber wordt geboren.",
        NEW_JOB_DESCR: "Speciaal voor de klant maken een nieuwe klus waar onze Oestermannen en girls een oester openen, het kan met citroen, peper of frambozen azijn, net wat de klant wilt. " +
        "Een intermezzo met een oesterman of girl zorgt er geregeld voor dat een nieuwe oesterliefhebber wordt geboren.",
        NEW_QUOTE_DESCR: 'Bij ons kunt u een tailor-made offerte aanvragen. U ontvangt binnen 48 uur een vrijblijvende optie/offerte. Het maakt niet uit wat voor soort feest of ' +
        'evenement u in gedachten heeft, wij vinden het een uitdaging u van het juiste aantal oesters, wijn en/of champagne te voorzien.',
        NEW_INVOICE_DESCR: 'Deze factuur dient voor de komst van de Oestercompagnie voldaan te zijn. Wij versturen de factuur digitaal naar de klant' +
        'Voor meer uitleg hierover, lees onze algemene voorwaarden. Na betaling kunnen wij de klant en zijn gasten een fantastische avond bezorgen.',
        NEW_EMPLOYEE_DESCR: 'Uitgerust met een oestermes en maliënkolder-handschoen mengt de nieuwe werknemer zich onder de gasten om exlusief voor hen, oesters te kraken. ' +
        'En niet alleen met oesters weet men raad, maar ook met Zeewierjenever, Oestercroquetten en champagne.',
        NEW_PRODUCT_DESCR: 'Onze oesters komen van verscheidene oesterkwekers. Wij werken met bijna alle oestersoorten die hierna genoemd worden, behalve met de Amerikaanse. ' +
        'Onze Bretonse creuses komen dagvers uit Trinité Sur Mer en worden onder onze eigen naam verpakt.'
, CLEAN_FEELING:"Een lekker opgeruimd gevoel :)",
        NEW_ORDER_DESCR: " Wanneer een klant een bestelling oester wilt laten leveren dan sturen wij deze op",
        HAS:'heeft een',
        ORDER:'Bestelling',
        TO_A:' aan een ',
        ACTION_CREATED:' aangemaakt',
        ACTION_DELETED:' verwijderd',
        ACTION_ADDED:' toegevoegd',
        ACTION_CHANGED:' gewijzigd',
        ACTION_SAVED:' opgeslagen',
        ACTION_HANDLED:' afgehandeld',
        EMAIL_ATTACHMENTS:'Email en bijlagen',
        JOBS_AND_AVAILABILITY: 'Klussen en beschikbaarheid',
        JOB_KIND_SAVED:'Nieuw soort feest toegevoegd',
        JOBKIND_IS_USED_BY :'Feest soort wordt gebruikt door {{amount}} jobs',
        JOBKIND_CHANGE_MESSAGE: 'Selecteer het nieuw feest type voor {{amount}} jobs wanneer de bovenstaand feest type wordt verwijdert',
        SELECT_A_JOB_KIND_FOR_REPLACEMENT: "Geen feesttype geselecteerd, selecteer alstublieft een feesttype.",
        NOT_SELECTED: "Niets geselecteerd",
        NO_ALLOWED_JOBKIND_REMOVAL: "Feest soort kan niet worden verwijdert",
        ADD_DESCRIPTION_PLACEHOLDER: "Vul een beschrijving in",
        ADD_SUBJECT_PLACEHOLDER: "Vul een onderwerp in voor het bericht",
        COLOR_JOBS_OVERVIEW: 'Kies kleur voor in job overzicht',
        SET_NO_COLOR: 'Herstel naar geen kleur',
        NO_COLOR_SET: 'Geen kleur geselecteerd, dit is optioneel',
        VALUE_MUST_BE_BETWEEN: 'Waarde moet tussen {{min}} en {{max}}',
        REQUIRED_VALUE: 'Veld is verplicht',
        DELETETAX: "U staat op het punt een belastingpercentage te verwijderen, alle producten die dit tarief gebruikten zullen worden gereset naar 0% BTW. Weet u het zeker?",
        REMOVE_ENTITY_REPLACE: 'U staat op het punt een {{entity}} te verwijderen, als het {{entity}} gebruikt wordt, dan worden alle verwijzingen naar {{currentEntity}} vervangen met {{replaceEntity}}! Wil je doorgaan?',
        REMOVE_ENTITY: 'U staat op het punt een {{entity}} te verwijderen. Wil je doorgaan?',
        CUSTOMER_KIND: 'klantsoort',
        NEW_ENTITY: "nieuw {{entity}}",
        ENTITY_IS_USED_BY :'{{entity}} wordt gebruikt door {{amount}} verwijzingen',
        ENTITY_CHANGE_MESSAGE: 'Selecteer het nieuw {{entity}} voor {{amount}} verwijzingen wanneer de bovenstaande {{entity}} wordt verwijdert',
        ENTITY_SAVED: "{{entity}} is opgeslagen",
        INVALID_TOKEN: 'Deze token is al gebruikt of is niet geldig, vraag een nieuwe token aan.',
        UNKOWN_ERROR: 'Onbekende foutmelding, probeer opnieuw',
        PASSWORD_RESET_SUCCESSFUL:'Uw wachtwoord is gewijzigd, ga verder naar het login scherm.',
        PROCEED_TO_LOGIN: 'Ga naar inloggen',
        HAS_ORDERS: "Heeft bestellingen",
        HAS_ORDERS_INFO: "Wanneer aangevinkt, toont het de bestellingen voor een bedrijf in het dashboard",
        CREATE_FOR_EACH_ACCEPTED_USER: 'Maak voor elke geaccepteerde medewerker',
        CREATE_FOR_EACH_ACCEPTED_USER_INFO: 'Voor elk geaccepteerde medewerker zal dit document aangemaakt worden, de naam van de medewerker komt dan ook in de bestandsnaam',
        USER_GROUP_SAVE:'Gebruikersgroep opgeslagen',
        SELECTED_IN_FILTER:'Standaard geslecteerd in filter',
        SELECTED_IN_FILTER_INFO: 'Wanneer geselecteerd zal deze group standaard geslecteerd zijn in de filter',
        USER_GROUP_SAVED: 'Gebruikersgroup opgeslagen',
        CREW:'Crew',
        TAKE_COMPANY_LOCATION: 'Vul geselecteerde franchise locatie in',

    });

    const translations ={
        NOT:' not ',
        HAS:'has ',
        TO_A:' to a ',
        REQUIRED: 'This is a required field',
        TEMPLATE: 'Template',
        CREATE: 'Create',
        CONFIRMDELETION: 'Are you sure you want to delete this item?',
        UPLOADOFFICEWORD: 'Upload a Microsoft Word template',
        NO_WORKNOTE_FOUND:'I did\'nt found any timesheets :(',
        SUCCES: 'Jeej',
        ORDER:'Order',
        UNFORTUNATLY: 'Unfortunately',
        JOBS_AND_AVAILABILITY: 'Jobs and availability',
        CANCEL: 'Cancel',
        UNSUCCESFULL: 'Oeps',
        CHANGESUCCESFULL: 'The change has been made',
        UPLOADSUCCESFULL: 'That was a successful upload',
        CUSTOMERUDATESUCCESFULL: 'The customer was successfully changed',
        SHAREWITHEMPLOYEES: 'Share job information with scheduled staff',
        MAILALLEMPLOYEES: 'Create an e-mail to the planned staff',
        ACCEPTALLCHANGES: 'Confirm any changes to planned staff',
        ALLEMPLOYEESUCCEFULLPLANNED: 'All the changes were made and ' +
            ' the staff is informed by e-mail',
        REVERTALLCHANGES: 'All changes are reversed',
        UNSUCCEFULLERRORMESSAGE: 'No changes have been made , ' +
            'If this message remains , please contact your system administrator ',
        SENDMAILJOBSTATUS: 'Update scheduled staff about these changes?',
        SENDMAILSUCCESFULL: 'Mail sent to staff!',
        SENDMAILJOBSTATUSSHORT: 'E-mail change?',
        SUCCESFULLDELETED: 'Successful deleted',
        WHEN: 'When?',
        DONE: 'Done',
        FILLFORMCORRECT: 'Form is not filled correctly',
        REMARKS_HELP: "Tell us about your job..",
        SAVE: 'Save',
        CREATEDOCUMENT: 'Create and fill a document',
        CHOOSEPAYMENT: 'Pick a job, invoice or quote',
        CONFIRM: "Confirm",
        REMOVEWORKNOTE: " You 're about to delete the details of the " +
            " time sheet . Are you sure? ",
        REMOVECAR: "You are about to remove a car from the schedule " +
            "Are you sure?",
        WHAT_ARE_YOU_DOING: "What are you going to do?",
        WHOLE_DAY: "Whole day",
        RECURRENT: "Recurrent",
        REMARKS_EXTRA: "Would you tell us more?",
        CREATE_DEPOT: 'Create depot',
        DEPOTS: 'Warehouses',
        TAKE_COMPANY_LOCATION: 'Take selected company location',
        IGNORE: 'Ignore',
        CALLAPPOINTMENT: "You have a scheduled phone call",
        CALLAPPOINTMENTTEXT: 'You need to call: $tel about job: $jobtitle at $time. \nIt relates to the following: $remarks',
        REMOVEPRODUCTGROUP: 'You are about to delete a productgroup, if the' +
            ' productgroup is in use, then all related products will not be shown in the system! Want to continue?'
        ,
        REMOVEDEPOT: 'You are about to delete a depot, if the' +
            ' depot  is in use then all related jobs will not be shown in the system! Want to continue?',
        REMOVECOMPANY: 'You are about to delete a company, if the' +
            ' company  is in use then all related jobs will not be shown in the system! Want to continue?',
        CONFIRMPASSWORDREQUEST: 'Restore password',
        PASSWORDRESETTEXT: 'You ask for a password reset for: $email. Want to continue?',
        COMPANIES: 'Companies',
        MANAGECOMPANIES: 'Management affiliates',
        REMOVEUSERGROUP: 'You are about to delete a usergroup, if the' +
            ' usergroup is in use, then all related users will not be shown in the system! Want to continue?',
        DELETEHOURRATE: "You are about to remove an hourly wage , are you sure?",
        DELETETAX: "You are about to remove an tax percentage, all products that were using this rate will be reset to 0% VAT. Are you sure?",
        FORGOT_PASSWORD: "Forgot password",
        MIN_6_CHARS: "Minimum of 6 characters",
        ENTER_PASSWORD: "Enter password",
        NOT_THE_SAME: "Not the same",
        OPENING: 'Opening...',
        OYSTERPLANNING: 'Oysterplanning',
        NO_REMINDER_SEND: 'No reminder send',
        NEEDED: 'Needed',
        SEARCHING: 'Searching...',
        SEARCH: 'Search',
        NOBODY_FOUND: 'Nobody found',
        EXPORT_TO_EXCEL: 'Export overview to Excel',
        CHANGE: 'Change',
        DELETE_PRODUCT: 'Remove this product',
        SEND_EMAIL: 'Sent e-mail',
        NEW_NOTE: 'New note',
        NEW_PHONE_CALL: 'New phone call',
        EMPTY_LIST: 'The list is empty...',
        SEND_EMAIL_PANEL: 'Send an e-mail',
        SEND_INVOICE_PANEL: 'Send invoice',
        SEND_QUOTE_PANEL: 'Send quote',
        PAYMENT_INFO: 'Indicative net payout based only on hours',
        PAYMENT_TRAVEL_EXPENSES: 'Indication, excl. costs and expenses',
        EMPTY_FORM: 'Empty form',
        CHANGE_TEMPLATE: 'Change template',
        NEW_TEMPLATE: 'New template',
        DOWNLOAD_TEMPLATE: 'Download template',
        HANDLED: 'Handled',
        TODAY: "Today",
        CLOSE: 'Close',
        NEVER_MIND: 'Never mind',
        YESTERDAY: 'Yesterday',
        DAYS_7: '7 days ago',
        DAYS_30: '30 days ago',
        THIS_MONTH: 'This month',
        LAST_MONTH: 'Last month',
        NEXT_MONTH: 'Next month',
        NEW_RELATION_DESCR: "Especially for our new customer makes our Oyster men and girls open an Oyster, it can with lemon, pepper or raspberry vinegar, just what the customer would. An interlude with a oesterman or girl will arranged for that a new Oyster lover is born.",
        NEW_JOB_DESCR:"Specially for the customer we create a new job where our Oyster men and girls open an Oyster, it can with lemon, pepper or raspberry vinegar, just what the customer would. An interlude with a oesterman or girl will arranged for that a new Oyster lover is born.",
        NEW_QUOTE_DESCR:'With us you can have a tailor-made quote. Within 48 hours you will receive a non-binding option/offer. No matter what type of party or event you have in mind, we find it challenging to find you the right amount of oysters, wine and/or champagne.',
        NEW_INVOICE_DESCR: 'This invoice must be for the arrival of the Oyster company complied with. We send the invoice digital to the customer. For more information, read our terms and conditions. After payment we can provide the customer and his guests a fantastic evening.',
        NEW_EMPLOYEE_DESCR: 'Equipped with an Oyster knife and chain mail glove the new employee mixes among the guests to exclusive to them, oysters to crack. And not just know our way with oysters, but also know oysters with Seaweed, Oyster croquettes Gin and champagne.',
        NEW_PRODUCT_DESCR: 'Our oysters come from several oyster farmers. We work with almost all Oyster species mentioned below, except with the American. Our Brittany Rock oysters come fresh from Trinité Sur Mer and are packaged under our own name.',
        NEW_ORDER_DESCR: "When a customer wants to have an order delivered, we will send it.",
        CLEAN_FEELING:"A nice tidy feeling :)",
        ACTION_CREATED:' created',
        ACTION_DELETED:' deleted',
        ACTION_ADDED:' added',
        ACTION_CHANGED:' changed',
        ACTION_SAVED:' saved',
        ACTION_HANDLED:' handled',
        EMAIL_ATTACHMENTS:'Email and attachments',
        JOB_KIND_SAVED:'New job kind is added',
        JOBKIND_IS_USED_BY :'Job kind is used by {{amount}} jobs',
        JOBKIND_CHANGE_MESSAGE: 'Select the new party type for {{amount}} jobs when the above party type is deleted',
        REMOVE_PARTY_KIND_REPLACE: "You are about to delete a party type. If this party type is being used, then the party types for all jobs with this party type will be replaced with {{jobKind}}! Do you want to continue?",
        REMOVE_PARTY_KIND: "You are about to delete a party type. Do you want to proceed?",
        SELECT_A_JOB_KIND_FOR_REPLACEMENT: "No party kind selected, please select a party kind.",
        NOT_SELECTED: "Nothing selected",
        NO_ALLOWED_JOBKIND_REMOVAL: "The job kind cannot be removed",
        ADD_DESCRIPTION_PLACEHOLDER: "Please add a description",
        ADD_SUBJECT_PLACEHOLDER: "Please add a subject for the message",
        COLOR_JOBS_OVERVIEW: 'Pick a color for in job overview',
        SET_NO_COLOR: 'Reset color to empty',
        NO_COLOR_SET: 'No color selected, this is optional',
        VALUE_MUST_BE_BETWEEN: 'Value must be between {{min}} and {{max}}',
        REQUIRED_VALUE: 'Field is required',
        REMOVE_ENTITY_REPLACE: "You are about to delete an {{entity}}. If this {{entity}} is in use, all references to it will be replaced with {{replaceEntity}}! Do you want to proceed?",
        REMOVE_ENTITY: "You are about to delete an {{entity}}. Do you want to proceed?",
        CUSTOMER_KIND: 'customer kind',
        NEW_ENTITY: "new {{entity}}",
        ENTITY_IS_USED_BY: '{{entity}} is used by {{amount}} references',
        ENTITY_CHANGE_MESSAGE: 'Select the new {{entity}} for {{amount}} references when the above {{entity}} is deleted',
        ENTITY_SAVED: '{{entity}} has been saved',
        INVALID_TOKEN: 'This token has been already used or is not valid, please request a new token.',
        UNKOWN_ERROR: 'Unkown error occurred. Please try again.',
        PASSWORD_RESET_SUCCESSFUL:'Your password has been changed, please go to login page',
        PROCEED_TO_LOGIN: 'Go to login',
        HAS_ORDERS: 'Has orders',
        HAS_ORDERS_INFO: 'When ticked it shows the orders for a company in the dashboard',
        CREATE_FOR_EACH_ACCEPTED_USER: 'Create foreach accepted employee',
        CREATE_FOR_EACH_ACCEPTED_USER_INFO: 'Tick this box so this document is creates for each accepted employee of the job',
        USER_GROUP_SAVE:'Usergroup saved',
        SELECTED_IN_FILTER:'Default selected in filter',
        SELECTED_IN_FILTER_INFO: 'When ticked this group is ticked in the filter',
        USER_GROUP_SAVED: 'Usergroup saved',
        CREW:'Crew'

    };
    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);

    $translateProvider.preferredLanguage('nl-nl');
}]);