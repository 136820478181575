import app from "/src/app/app.module";


app.controller('preview_mail_templates',
    function ($scope, msgBus, vxMailMerge) {

        var vm = this;
        vm.selectedMessage = {content: 'No template'};
        msgBus.onMsg('settings.templates.showpreview', function (msg, data) {

            if (data.content !== undefined &&
                data.content === "") {
                vm.selectedMessage = {content: 'No template'};
                return;
            }

            vm.selectedMessage.preview = vxMailMerge.fillTemplateFromObject([{hoitest: 'Alex Knijf'}], data.content);
        }, $scope);


    });