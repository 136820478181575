import app from "/src/app/app.module";
import moment from "moment/moment";


app.controller('add-order-form', function ($scope, vxForms, $job, $depot, $locale, $order, $auth) {
    $scope.locale = $locale;
    $scope.fields = vxForms.order;

    if($scope.wizard.orderData == null)
    $scope.wizard.orderData = {};

    $scope.options = {
        validation: {
            enabled: true
        },
        layout: {
            type: 'vertical',
            labelSize: 3,
            inputSize: 9
        }
    };

    $scope.today = function () {
        $scope.wizard.orderData.orderDate = new Date();

    };
    setTimeout(()=>$scope.today(), 1000)




    $depot.getAllDepots(function (result) {
        $scope.wizard.allDepots = result;
    });

    $order.getAllStatuses(function (result) {
        $scope.wizard.orderStatuses = result;
        
    });


    $scope.$watch('wizard.orderData.orderDate', function (newValue, oldValue) {
        if (!newValue)
            return;

       // $scope.wizard.orderData.deliveryDate = moment().add( 2,'days').toDate();

    });

});