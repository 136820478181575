import app from "/src/app/app.module";

app.config(['$routeProvider','$locationProvider',
    function ($routeProvider, $locationProvider) {
        $locationProvider.html5Mode(true);
        $routeProvider.
            //when('/create/:type', {
            //    templateUrl: 'pages/shell/shell.html',
            //    controller: 'shell',
            //    controllerAs: 'vm'
            //}).
            //when('/job/:id', {
            //    templateUrl: 'pages/shell/shell.html',
            //    controller: 'shell',
            //    controllerAs: 'vm'
            //}).
            //when('/product/:id', {
            //    templateUrl: 'pages/shell/shell.html',
            //    controller: 'shell',
            //    controllerAs: 'vm'
            //}).when('/employee/:id', {
            //    templateUrl: 'pages/shell/shell.html',
            //    controller: 'shell',
            //    controllerAs: 'vm'
            //}).
         
            when('/pssreset/:token/', {
                template: require('../pages/login/login.html'),
                controller: 'login',
                controllerAs: 'vm'
            }).
            when('/new/:token/', {
                template: require('../pages/login/login.html'),
                controller: 'login',
                controllerAs: 'vm'
            }).
            when('/newclient/:email/:name', {
                template: require('../pages/shell/shell.html'),
                controller: 'shell',
                controllerAs: 'vm'
            }).
            when('/customer/:id', {
                template: require('../pages/shell/shell.html'),
                controller: 'shell',
                controllerAs: 'vm'
            }).
            when('/login', {
                template: require('../pages/login/login.html'),
                controller: 'login',
                controllerAs: 'vm'
            }).
            when('/', {
                template: require('../pages/shell/shell.html'),
                controller: 'shell'
            }).
            otherwise({
                redirectTo: '/'
            });
    }]);