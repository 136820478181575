import app from "/src/app/app.module";
app.controller('add_employee_form', function ($scope, vxForms, $filter, vxCountries,vxUtils, $locale,
                                              $users) {

    $scope.locale = $locale;
    if ($scope.wizard.employee === undefined) {
        $scope.wizard.employee = {rating: 1};

    }
    $scope.employeeFields = vxForms.employee;
    $scope.countries = vxCountries.countries;

    $scope.max = 5;
    $scope.isReadonly = false;

    $scope.hoveringOver = function (value) {
        $scope.overStar = value;
        $scope.percent = 100 * (value / $scope.max);
        $scope.experience = $filter('vxExperience')(value);
    };

    $scope.options = {
        validation: {
            enabled: true
        },
        layout: {
            type: 'vertical',
            labelSize: 3,
            inputSize: 9,
            defaultOption: vxUtils.loc('PICK_AN_OPTION')
        }
    };

    $users.getGroups(function (result) {
        $scope.groups = result;

    });

    $users.getRoles(function (result) {
        $scope.roles = result;
    });
});