import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        PRODUCT: 'Product',
        PRODUCTS: 'Producten',
        PRODUCTNUMBER: 'Productnummer',
        STARTPRODUCTNUMBER: 'Productnummerreeks',
        PRODUCTNAME: 'Productnaam',
        PRODUCTGROUP: 'Productgroep',
        PRODUCTDESCRIPTION: 'Productbeschrijving',
        PRODUCTGROUPS: 'Productgroepen',
        COUNT_ME: 'Meetellen',
        PRODUCT_DATA: "Product gegevens",
        ADD_PRODUCT: 'Voeg product toe',
        TOTAL_WITHOUT_VAT:'Totaal ex. BTW'
    });


    const translations = {
        PRODUCT: 'Product',
        PRODUCTS: 'Products',
        PRODUCTNUMBER: 'Productnumber',
        STARTPRODUCTNUMBER: 'Product Number Range',
        PRODUCTNAME: 'Productname',
        PRODUCTGROUP: 'Productgroup',
        PRODUCTDESCRIPTION: 'Product description',
        PRODUCTGROUPS: 'Productgroups',
        COUNT_ME: 'Include',
        PRODUCT_DATA: "Product data",
        ADD_PRODUCT: 'Add product',
        TOTAL_WITHOUT_VAT:'Total excl. VAT'
    }

    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);


    $translateProvider.preferredLanguage('nl-nl');
}]);