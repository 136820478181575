import app from "/src/app/app.module";


app.controller('customers', function ($scope, settings, $location, $filter, enums,
                                      msgBus, msgBusActions,vxUtils,
                                      ngTableParams, $customer, selectionFilters) {

    $scope.checked = false;

    $scope.openCustomers = [];
    var data = [];

    $scope.overviewCpi = {
        type: 'customer',
        openItemPage: "/src/pages/customers/customer-details.html", // not used anymore?
        openItemIcon: "glyphicon glyphicon-user",
        tabItems: $scope.openItems,
        searchChanged: function (text) {
            var end = text.endsWith('%');
            var start = text.startsWith('%');
            var equals = text.startsWith('=');
            var between = text.includes('<>');
            var hasprop = text.includes(':');

            if (!text) {
                // If the search text is empty, return all items
                $scope.overviewCpi.items = $scope.overviewCpi.all;
                $scope.overviewCpi.reload();
                return;
            }

            var out = [];

            angular.forEach($scope.overviewCpi.all, function (v) {
                var expected = text.toLowerCase();

                for (var i = 0; i < $scope.columns.length; i++) {
                    var result = false;
                    var val;
                    if (hasprop) {
                        var field = expected.substring(0, expected.indexOf(':'));
                        val = v[field];
                        expected = expected.replace(field + ':', '');
                    } else {
                        val = v[$scope.columns[i].field];
                    }

                    if (!val) continue;

                    val = val.toString().toLowerCase();

                    if (end) {
                        result = val.endsWith(expected.replace('%', ''));
                    } else if (start) {
                        result = val.startsWith(expected.replace('%', ''));
                    } else if (equals) {
                        result = val === expected.replace('=', '');
                    } else if (between) {
                        var split = expected.split('<>');
                        if (split.length === 2 && split[1] !== '') {
                            var int = parseInt(val.toString().trim());
                            if (!isNaN(int)) {
                                result = int > parseInt(split[0].trim()) && int < parseInt(split[1].trim());
                            }
                        }
                    } else {
                        result = val.includes(expected);
                    }

                    if (result) {
                        out.push(v);
                        break; // Exit the loop early if a match is found
                    }
                }
            });

            $scope.overviewCpi.items = out;
            $scope.overviewCpi.reload();
        }
,
        selectionChanged: function (data) {

            var fields = ['IsCompany',
                'relationtype'];

            const first = selectionFilters.filterBySelection(
                $scope.overviewCpi.filters,
                $scope.overviewCpi.all,
                fields
            );

            $scope.overviewCpi.items = selectionFilters.filterBySelection(
                $scope.overviewCpi.filtersExtra,
                first,
                ['customerKindId']
            );
            $scope.overviewCpi.reload();
        }
    };
    selectionFilters.customerStates(function (result) {
        $scope.overviewCpi.filters = result;
    });

    selectionFilters.customerKinds(function (result) {
        $scope.overviewCpi.filtersExtra = result;
    });


    $scope.headerButtons = [
        {
            tooltip:  vxUtils.loc('EXPORT_TO_EXCEL'),
            icon: 'fa fa-file-excel-o',
            action: function () {
                $scope.overviewCpi.export(vxUtils.loc('CUSTOMERS'), $scope.columns, $scope.overviewCpi.items);
            }
        },
        {
            tooltip:  vxUtils.loc('REFRESH'),
            icon: 'fa fa-refresh',
            action: function () {
                $scope.getCustomers();
            }
        }
    ];

    $scope.columns = [
        {title: '', altfield: 'IsCompany', 
            name: vxUtils.loc("COMPANY"), field: 'customericon', visible: true, icon: true},
        {
            title: '#', field: 'idClient', visible: true, sortable: 'idClient',
            initalSort: 'desc'
        },
        {
            title: vxUtils.loc("RELATION"), field: 'relationtype', visible: true,
            sortable: 'relationtype'
            , filter: 'relationType'
        },
        {title: vxUtils.loc("NAME"), field: 'name', visible: true, sortable: 'name'},
        {title: vxUtils.loc("TELEPHONE"), field: 'telephone', visible: true},
        {title: vxUtils.loc("TELEPHONE2"), field: 'telephone2', visible: true},
        {title: vxUtils.loc("EMAIL"), field: 'email',  sortable: 'email', visible: true, link: true},
        {title: vxUtils.loc("CITY"), field: 'city',  visible: true, sortable: 'city'},
        {title: vxUtils.loc("COUNTRY"), field: 'country', visible: true, sortable: 'country'},
        {title: vxUtils.loc("STREET"), field: 'adress', visible: true},
        {title: vxUtils.loc("AREACODE"), field: 'areacode', visible: true, sortable: 'areacode'}
    ];

    $scope.getCustomers = function () {
        $customer.getAll(function (results) {
            $scope.overviewCpi.all = results;
            $scope.overviewCpi.selectionChanged();
        });
    };

    $scope.deleteCustomer = function () {
        $customer.deleteCustomer(function () {
        }, $scope.currentCustomer.ClientNumber());
    };

    msgBus.onMsg(msgBusActions.overviewReload, function (sender, mssg) {
        if (mssg === 'customer')
            $scope.getCustomers();
    }, $scope);

    $scope.getCustomers();
});
