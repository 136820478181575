'use strict';

import Location from "./location";
import {dateToUnixTimestamp, parseUnixWithMilliSeconds} from "../shared/utils";
import PaymentItem from "./payment-item";

class Order {
    constructor(order) {
        if (order === undefined) {
            return;
        }

        this.refresh(order);
    }

    refresh(order = this){
        this.dbo = order;
        const prefix = 'wsp_orders_';

        this.id = this.dbo[`${prefix}id`];
        this.offerteFK = this.dbo[`${prefix}offerte_FK`];

        this.orderStatusFK = this.dbo[`${prefix}order_status_FK`];
        this.clientFK = this.dbo[`${prefix}client_FK`];
        this.locationFK = this.dbo[`${prefix}location_FK`];
        this.createdByUserFK = this.dbo[`${prefix}created_by_user_FK`];
        this.paymentFK = this.dbo[`${prefix}offerte_FK`];
        this.warehouseFK = this.dbo[`${prefix}warehouse_FK`];
        this.tendantFK = this.dbo[`${prefix}tendant_FK`];
        this.orderDate = parseUnixWithMilliSeconds(this.dbo[`${prefix}order_date`]);
        this.creationDate = parseUnixWithMilliSeconds(this.dbo[`${prefix}creation_date`]);
        this.deliveryDate = parseUnixWithMilliSeconds(this.dbo[`${prefix}delivery_date`]);
        this.remarks = this.dbo[`${prefix}remarks`];
        this.title = this.dbo[`${prefix}title`];
        this.location = new Location(this.dbo);
        this.offerte = new PaymentItem(this.dbo);
        this.amount = this.dbo[`${prefix}amount`]
        this.status = this.dbo[`wsp_order_statuses_name`]
        this.statusIcon = this.getStatusIcon()
        this.offertename = this.offerte.offertename;
        this.clientname = this.ClientName();

        this.city = this.location.city;
        this.adress = this.location.Address();

        this.type = 'order';
        this.tabID = this.id;
        this.tabDescription = this.offerte.clientname;
    }
    ClientName () {
        if (this.dbo['invoice_companyname'] !== '')
            return this.dbo['invoice_companyname'];
        else {
            var prep = this.dbo['invoice_preposition'];
            if (prep !== "" && prep !== null)
                prep = " " + prep;
            else {
                prep ='';
            }

            return this.dbo['invoice_surname'] + prep + " " + this.dbo['invoice_lastname'];
        }
    };
    getStatusIcon (){

        var val = "fa fa-clock-o";

        switch (this.status) {
            case 'canceled':
                val = "fa fa-ban red fa-05x ";
                break;
            case 'received':
                val = "fa fa-envelope yellow fa-05x ";
                break;
            case 'in_progress':
                val = "fa fa-spinner yellow fa-05x";
                break;
            case 'ready_for_delivery':
                val = "fa fa-truck blue fa-05x";
                break;
            case 'delivered':
                val = "fa fa-check-circle green fa-05x";
                break;
        }
        return val;

    };

    /**
     *
     * @param wizard
     * @returns {Order}
     */
    static parseWizard(wizard) {

        const order = new Order();

        order.deliveryDate = dateToUnixTimestamp(wizard.deliveryDate);
        order.clientFK = wizard.idClient;
        order.orderDate = dateToUnixTimestamp(wizard.orderDate);
        order.warehouseFK = wizard.idWarehouse;
        order.orderStatusFK = wizard.orderStatusFK;
        order.remarks = wizard.remarks;
        order.title = wizard.title;

        return order;
    }
}
export default Order;
