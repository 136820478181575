import app from "/src/app/app.module";
import User from "../models/user";
import Job from "../models/job";
import Event from "../models/event";
import Availability from "../models/availability";
import Notification from "../models/notification";
import Worknote from "../models/worknote";

var api = function ($resource, settings, vox, $localStorage, $filter, $http) {

    var convertToObj = function (values) {

        var temp = [];
        angular.forEach(values, function (value, key) {
            var obj = new User(value);
            //angular.extend(obj, value);
            this.push(obj);
        }, temp);
        return temp;
    };

    var convertToObjGeneric = function (values, objectType) {
        var temp = [];
        angular.forEach(values, function (value, key) {
            var obj = new objectType(value, $filter);
            //angular.extend(obj, value);
            this.push(obj);
        }, temp);
        return temp;
    };


    var getUserRoles = function (callback, nocache) {
        if ($localStorage.allUserRoles !== undefined && !nocache) {
            if (callback)
                callback($localStorage.allUserRoles);
            return;
        }
        vox.get(settings.BASE_API_URL + 'v1/userroles', function (data) {
            if (callback)
                callback(data);

            $localStorage.allUserRoles = data;
        });
    };

    var getUserGroups = function (callback, nocache) {
        if ($localStorage.allUserGroups !== undefined && !nocache) {
            if (callback)
                callback($localStorage.allUserGroups);
            return;
        }
        vox.get(settings.BASE_API_URL + 'v1/usergroups', function (data) {
            $localStorage.allUserGroups = data;
            if (callback)
                callback(data);
        });
    };

    var getAllUsers = function (callback) {
        vox.get(settings.BASE_API_URL + 'v1/overview/users', function (data, status, headers, config) {
            callback(convertToObj(data));
        });
    };


    var getAllWithHoursBetween = function (callback, startdate, enddate) {
        vox.get(settings.BASE_API_URL + 'v1/overview/users/' + startdate + '/' + enddate, function (data, status, headers, config) {
            callback(convertToObj(data));
        });
    };

    var getWorkHoursBetween = function (callback, id, startdate, enddate, total, group) {
        vox.get(settings.BASE_API_URL + 'v1/overview/user/' + id + '/' + startdate + '/' + enddate + '/' + total + '/' + group,
            function (data, status, headers, config) {
                callback(convertToObjGeneric(data, Worknote));
            });
    };

    var getUserEvents = function (callback, id, startdate, enddate) {

        vox.get(settings.BASE_API_URL + 'v1/user/events/' + id + '/' + startdate + '/' + enddate,
            function (data, status, headers, config) {
                var out = [];
                //Underscore's "each" method
                angular.forEach(data, function (value, key) {
                    var event = null;

                    if (value['Type'] === 'job')
                        event = new Job(value, $filter);
                    else if (value['Type'] === 'availability')
                        event = new Availability(value);
                    else
                        event = new Event(value);

                    out.push(event);
                });

                callback(out);
            });
    };

    var getAllJobcrew = function (callback, id, all) {
        if (angular.isUndefined(all))
            all = 'hoi';

        vox.get(settings.BASE_API_URL + '/v1/user/crew/' + id + '/' + all,
            function (data, status, headers, config) {
                var out = [];
                //Underscore's "each" method
                angular.forEach(data, function (value, key) {
                    var event = new Job(value, $filter);
                    out.push(event);
                });

                callback(out);
            });
    };

    var postUsergroup = function (item, callback) {
        vox.post(settings.BASE_API_URL + 'v1/usersgroups', item, callback);
    };

    var postWorknote = function (item, callback) {
        vox.post(settings.BASE_API_URL + 'v1/users/worknote', item, callback);
    };

    var postAvailability = function (item, callback) {
        vox.post(settings.BASE_API_URL + 'v1/users/availability', item, callback);
    };


    var postUser = function (item, callback) {
        var u = item;
        u.birthday = moment(item.birthday).unix();
        u.hireddate = moment(item.hireddate).unix();

        vox.post(settings.BASE_API_URL + 'v1/users', u, callback);
    };

    var getUser = function (callback, id) {
        if (id === undefined) {
            callback(null);
            return;
        }
        vox.get(settings.BASE_API_URL + 'v1/users/' + id, function (data, status, headers, config) {
            callback(convertToObj(data));
        });
    };

    var getNotifications = function (callback, id) {
        vox.get(settings.BASE_API_URL + 'v1/notifications/' + id, function (data, status, headers, config) {
            callback(convertToObjGeneric(data, Notification));
        });
    };
    var getAvailability = function (callback, id) {
        vox.get(settings.BASE_API_URL + 'v1/users/availability/' + id, function (data, status, headers, config) {
            callback(convertToObjGeneric(data, Availability));
        });
    };
    var deleteUserEvent = function (id, callback, error) {
        vox.delete(settings.BASE_API_URL + 'v1/events/user/' + id, callback, error);
    };

    var deleteUser = function (id, callback, error) {
        vox.delete(settings.BASE_API_URL + 'v1/users/' + id, callback, error);
    };

    var deleteAvailability = function (id, callback, error) {
        vox.delete(settings.BASE_API_URL + 'v1/users/availability/' + id, callback, error);
    };

    var deleteWorknote = function (id, callback, error) {
        vox.delete(settings.BASE_API_URL + 'v1/worknotes/' + id, callback, error);
    };

    var getEventTypes = function (callback) {
        if ($localStorage.allEventTypes !== undefined) {
            if (callback)
                callback($localStorage.allEventTypes);
        }
        vox.get(settings.BASE_API_URL + 'v1/eventtypes', function (result) {
            $localStorage.allEventTypes = result;
            if (callback)
                callback($localStorage.allEventTypes);
        });
    };

    var uploadUserPicture = function (id, file, callback, errorCallback) {
        var formData = new FormData();
        formData.append('picture', file);

        $http.post(settings.BASE_API_URL + 'v1/users/' + id + '/picture', formData, {
            headers: { 'Content-Type': undefined }, // Let the browser set the Content-Type including the boundary
            transformRequest: angular.identity
        }).then(function (response) {
            if (callback) callback(response.data);
        }).catch(function (response) {
            if (errorCallback) errorCallback(response);
        });
    };

    return {
        postUsergroup: postUsergroup,
        getAvailability: getAvailability,
        deleteAvailability: deleteAvailability,
        postAvailability: postAvailability,
        getEventTypes: getEventTypes,
        postWorknote: postWorknote,
        postUser: postUser,
        getAll: getAllUsers,
        getRoles: getUserRoles,
        getGroups: getUserGroups,
        getUser: getUser,
        getNotifications: getNotifications,
        getAllWithHoursBetween: getAllWithHoursBetween,
        getWorkHoursBetween: getWorkHoursBetween,
        getUserEvents: getUserEvents,
        deleteUserEvent: deleteUserEvent,
        deleteUser: deleteUser,
        deleteWorknote: deleteWorknote,
        getAllJobcrew: getAllJobcrew,
        uploadUserPicture: uploadUserPicture
    };
};

app.factory("$users", api);