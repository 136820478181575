import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        JOBTITLE: 'Jobnaam',
        JOBNUMBER: 'Jobnummer',
        STARTDATE: 'Begindatum',
        DELIVERYDATE: 'Lever datum',
        ENDDATE: 'Einddatum',
        DATE: 'Datum',
        ENTRANCE: 'Aanvang',
        STARTTIME: 'Begintijd',
        STARTS_AT:'Duurt van:',
        ENDTIME: 'Eindtijd',
        AMOUNTGUESTS: 'Aantal gasten',
        AMOUNTEMPLOYEES: 'Aantal medewerkers',
        KINDOFPARTY: 'Soort',
        STATE: 'Status',
        INTERNALREMARKS: 'Opmerkingen kantoor',
        EXTERNALREMARKS: 'Opmerkingen medewerkers',
        JOBS: 'Jobs',
        JOB: 'Job',
        PICK_JOB: 'Kies job',
        LOCATION: 'Locatie',
        SPOT: 'Locatie',
        AVAILABLE: 'Beschikbaar',
        OCCUPIED: 'Bezet',
        PLANNERS: 'Planners',
        CANCELED: 'Geannuleerd',
        CONFIRMED: 'Bevestigd',
        OPTION: 'Optie',
        CREWFULL: 'Compleet',
        CREWEMPTY: 'Leeg',
        CREWNOTFULL: 'Niet compleet',
        CREWOVERBOOKED: 'Te veel ingepland',
        DEPOT: 'Depot',
        ADDJOB: 'Voeg job toe',
        NOJOBADDED: 'Geen job gekoppeld',
        NOTE_LABEL: 'Label',
        CANCELREASON: 'Annuleringsreden',
        NOTSEND: 'Niet verzonden',
        NOTREAD: 'Verzonden, niet gelezen',
        ISREAD: 'Gelezen',
        DESCRIPTION: 'Beschrijving',
        KINDOFPARTYHOURS: 'Gebruik uurtype voor dit soort klus',
        HAS_PRODUCTS: 'Heeft producten',
        HAS_NO_PRODUCTS: 'Heeft geen producten',
        NO_CAR_PLANNED: 'Geen auto ingepland',
        EMAIL_CONFIRMATION: 'E-mail bevestiging',
        DURATION: 'Duur',
        GOES: 'Gaat',
        NO_REACTION: 'Geen reactie',
        WONT_GO: 'Gaat niet',
        JOB_CREATED_BY: 'Job aangemaakt door ',
        ON: 'op',
        LOCATIONDATA: 'Locatiegegevens',
        CUSTOMER_DATA: 'Klantgegevens',
        CUSTOMER_ADDRESS_DATA: 'Adresgegevens klant',
        TIMELINE: 'Tijdlijn',
        CREATED_BY: 'Aangemaakt door ',
        MULTIPLE_DAYS: 'Meerdere dagen',
        JOB_TAKES_X: 'De job duurt ',
        DAYS: ' dagen, ',
        WOULD_YOU_CREATE: 'wil je daar {days} jobs van maken?',
        ACCEPT_JOB: 'Accepteer job',
        SCHEDULED_STAFF: 'Ingepland personeel',
        AVAILABLE_STAFF: 'Beschikbaar personeel',
        Annulering: 'Annulering',
        Optie: 'Optie',
        Bevestigd: 'Bevestigd',
        ADDRESS_DATA: 'Adres gegevens',
        COMPANY_DATA: 'Bedrijfsgegevens',
        NOTIFY_EMAIL: 'Notificatie e-mails',
        HTML_TEMPLATES: 'HTML templates',
        WORD_TEMPLATES: 'Microsoft Word templates',
        EMAIL_TEMPLATES: 'E-mail templates',
        BRAND: 'Merk',
        TYPE: 'Type',
        YEAR_OF_BUILD: 'Bouwjaar',
        ROUTE_PLANNER: 'Routeplanner',
        NOTIFICATION: 'Notificatie',
        STREET_VIEW: 'Streetview',
        FRANCHISE: 'Franchise',
        IN_PROGRESS:"Verwerken",
        RECEIVED:"Ontvangen",
        READY_FOR_DELIVERY:"Klaar voor verzending",
        DELIVERED:"Afgeleverd",
        TITLE: 'Titel',
        ORDERDATE: 'Besteldatum'
    });

    const translations = {
        FRANCHISE: 'Franchise',
        JOBTITLE: 'Jobtitle',
        JOBNUMBER: 'Jobnumber',
        STARTDATE: 'Startdate',
        STARTS_AT: 'Starts at:',
        ENDDATE: 'Enddate',
        DATE: 'Date',
        ENTRANCE: 'Arrival',
        STARTTIME: 'Start time',
        ENDTIME: 'End time',
        AMOUNTGUESTS: 'Number of guests',
        AMOUNTEMPLOYEES: 'Number of employees',
        KINDOFPARTY: 'Partykind',
        STATE: 'Status',
        INTERNALREMARKS: 'Remarks office',
        EXTERNALREMARKS: 'Remarks Employees',
        JOBS: 'Jobs',
        JOB: 'Job',
        PICK_JOB: 'Pick job',
        LOCATION: 'Location',
        SPOT: 'Location',
        AVAILABLE: 'Available',
        OCCUPIED: 'Occupied',
        PLANNERS: 'Planners',
        CANCELED: 'Canceled',
        CONFIRMED: 'Confirmed',
        OPTION: 'Option',
        CREWFULL: 'Complete',
        CREWEMPTY: 'Empty',
        CREWNOTFULL: 'Not full',
        CREWOVERBOOKED: 'Too many scheduled',
        DEPOT: 'Warehouse',
        ADDJOB: 'Add job',
        NOJOBADDED: 'No job linked',
        NOTE_LABEL: 'Label',
        CANCELREASON: 'Cancellation Reason',
        NOTSEND: 'Not sent',
        NOTREAD: 'Sent, but not read',
        ISREAD: 'Read',
        DESCRIPTION: 'Description',
        KINDOFPARTYHOURS: 'Use hourkind for this type of job',
        HAS_PRODUCTS: 'Has products',
        HAS_NO_PRODUCTS: 'Has no products',
        NO_CAR_PLANNED: 'No scheduled car',
        EMAIL_CONFIRMATION: 'E-mail confirmation',
        DURATION: 'Period',
        GOES: 'Goes',
        NO_REACTION: 'No response',
        WONT_GO: 'Doesn\'t go',
        JOB_CREATED_BY: 'Job created by ',
        CREATED_BY: 'Created by ',
        ON: 'on',
        DELIVERYDATE:'Delivery date',
        LOCATIONDATA: 'Location data',
        CUSTOMER_DATA: 'Customer data',
        CUSTOMER_ADDRESS_DATA: 'Address data customer',
        TIMELINE: 'Timeline',
        MULTIPLE_DAYS: 'Multiple days',
        JOB_TAKES_X: 'The job takes ',
        DAYS: 'days,',
        WOULD_YOU_CREATE: 'would you like to create {days} jobs?',
        ACCEPT_JOB: 'Accept job',
        SCHEDULED_STAFF: 'Scheduled staff',
        AVAILABLE_STAFF: 'Available staff',
        Annulering: 'Cancellation',
        Optie: 'Option',
        Bevestigd: 'Confirmed',
        ADDRESS_DATA: 'Address data',
        COMPANY_DATA: 'Company data',
        NOTIFY_EMAIL: 'Notification e-mails',
        HTML_TEMPLATES: 'HTML templates',
        WORD_TEMPLATES: 'Microsoft Word templates',
        EMAIL_TEMPLATES: 'E-mail templates',
        BRAND: 'Brand',
        TYPE: 'Type',
        YEAR_OF_BUILD: 'Year Built',
        ROUTE_PLANNER: 'Route planner',
        STREET_VIEW: 'Streetview',
        NOTIFICATION: 'Notification',
        IN_PROGRESS:"In Progress",
        RECEIVED:"Received",
        READY_FOR_DELIVERY:"Ready for delivery",
        DELIVERED:"Delivered",
        TITLE: 'Title',
        ORDERDATE: 'Order date'

    };
    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);

    $translateProvider.preferredLanguage('nl-nl');
}]);