import app from "/src/app/app.module";
import moment from "moment";
import HourType from "../models/hour-type";
import {value} from "lodash/seq";

app.controller('employeeOverview', function ($scope, $timeout, $job, toastr,
                                             $api,$voxilConnect,selectionFilters,
                                             $filter, vxUtils, msgBus, msgBusActions,
                                             vxSession) {
    var vm = this;
    vm.niceOptions = { horizrailenabled: false };
    vm.listCpi = {};
    vm.selectedType = '';
    vm.selectionValues = [];
    vm.searchStr = '';
    vm.pageHash = vxUtils.findObjectOnParents($scope, 'PageHash');

    vm.auth = function () {
        return parseInt(vxSession.userRole) > 1;
    };




    msgBus.onMsg(msgBusActions.saveEmployee, function (sender, mssg) {
        if (mssg[1] === vm.pageHash) {
            vm.saveAllChanges(true);
        }

    });

    vm.init = function (data, filter) {
        if (data === undefined) {
            return;
        }
        vm.data = data;
        vm.filter = filter;
        vm.hour_rate_label = '';
        vm.hourrates = [];

        if (vm.data === "crew") {
            selectionFilters.employeeGroupsAvail(function (groups) {
                vm.selectionValues = groups.map(x=>{
                    if(x.hasOwnProperty('multiSelectGroup')) {
                        x.label = x.name;
                        return x;
                    }

                    return{value:x.state,label:x.name,selected: x.ticked, field:x.field}});
            });

            vm.selectedType = 'available';

        }
        else if (vm.data === "planned") {
            vm.selectedType = 'accepted';



            vm.selectionValues = [{
                value: 'accepted',
                label: $filter('translate')('ACCEPTED'),
                selected: true
            }, {
                value: 'awaiting',
                label: $filter('translate')('AWAITING'),
                selected: vm.auth()
            }, {
                value: 'declined',
                label: $filter('translate')('DECLINED'),
                selected: false
            }];

         

        }

        vm.listCpi.items = $scope.cpi[data];
        vm.listCpi.allowedTypes = ['crew'];
        $scope.cpi.refresh = function () {
            vm.changeCrewList(true);
        };

        $scope.cpi.getCurrentItems = function () {
            return vm.listCpi.items;
        };

        $scope.$watchCollection('cpi.' + vm.data, function (newValue, oldValue) {
            if (newValue !== undefined) {
                if (vm.filter !== true) {
                    vm.orgList = $scope.cpi[vm.data];
                    vm.listCpi.items = vm.orgList;
                }
                else {
                    vm.changeCrewList(true);
                }
                vm.heightPanel = getHeight();
            }
        }, true);

    };

    vm.changeCrewList = function (ignoreNew) {
        var i = $scope.cpi[vm.data];

        var out = [];

        if(vm.data === "crew") {

            const selectionCrew = $filter('filter')(vm.selectionValues, {selected: true, field:'crew'});
            const selectionGroup = $filter('filter')(vm.selectionValues, {selected: true, field:'idGroup'});
            let temp = out.concat();
            angular.forEach(selectionCrew, function (value, key) {
                temp = temp.concat(i[value.value]);
            });

            angular.forEach(selectionGroup, function (value, key) {
                out.push(...temp.filter(user=>user[value.field].toString()===value.value));
            });
        } else if(vm.data === "planned") {
            const selection = $filter('filter')(vm.selectionValues, {selected: true});
            angular.forEach(selection, function (value, key) {
                out = out.concat(i[value.value]);
            });
        }

        vm.orgList = out;
        if (!ignoreNew) {
            var newPeople = $filter('filter')(vm.listCpi.items, {status: -1});
            vm.listCpi.items = vm.orgList.concat(newPeople);
        }
        else {
            vm.listCpi.items = vm.orgList;
        }
    };

    $scope.$watch('vm.searchStr', function (tmpStr) {
        if (!tmpStr || tmpStr.length == 0) {
            vm.listCpi.items = vm.orgList;
            return 0;
        }

        var timer = $timeout(function () {
            if (tmpStr === vm.searchStr) {
                vm.listCpi.items = $filter('filter')(vm.orgList, {name: vm.searchStr});
            }
        }, 350);

        $scope.$on(
            "$destroy",
            function (event) {

                $timeout.cancel(timer);

            }
        );
    });

    var timer2 = $timeout(function () {
        vm.heightPanel = getHeight();
    }, 650);

    $scope.$on(
        "$destroy",
        function (event) {

            $timeout.cancel(timer2);

        }
    );

    vm.MailAllEmployees = function () {

        var people = vm.listCpi.items;
        var mailto = "mailto:";
        angular.forEach(people, function (v, k) {
            mailto += v.email + ';';
        });

        window.location.href = mailto;
    }

    vm.CreateWorknotesAllEmployees = function () {

    }

    vm.UpdateHourRates =function(type){
        var result = $scope.cpi[vm.data];
        var flat = [];
        _.forIn(result, function (v, k) {
            if (_.isArray(v))
                flat = flat.concat(v);
        });
        var found = _.map(flat, function (item) {
            return item.idjobcrew;
        });
        $api.post($api.adress.hourtypes + '/batch/'+type.id,
            function (output) {
                toastr.success(vxUtils.loc('CHANGESUCCESFULL'), vxUtils.loc('SUCCES'));
                msgBus.emitMsg(msgBusActions.employeeReloadCrewPanel, vm.pageHash);
            }, found, HourType);


    };

    vm.saveAllChanges = function (checklocal) {

        vm.job = vxUtils.findObjectOnParents($scope, 'job');
        var newPeople = $filter('filter')(vm.listCpi.items, {status: -1});
        if (newPeople === undefined)
            newPeople = [];
        angular.forEach(newPeople, function (v, k) {
            v.idjob = vm.job.jobid;
            v.laststatus = v.status;
            v.status = 0;
            v.id_user = v.id_user ? v.id_user : v.dbo["id_user"];
            v.idUser = v.idUser ? v.idUser : v.dbo["id_user"];
            v.creationdate = moment().unix();
        });
        var deletedPeople = [];
        var siblingCpi = {};
        if (checklocal) {
            deletedPeople = $filter('filter')(vm.listCpi.items, {status: -2});
        }
        else {
            siblingCpi = vxUtils.findObjectOnParents($scope, 'empExpPanelCpi');
            deletedPeople = $filter('filter')(siblingCpi.getCurrentItems(), {status: -2});
        }
        if (deletedPeople === undefined)
            deletedPeople = [];

        if (newPeople.length === 0 && deletedPeople.length === 0)
            return;

        $job.postJobcrew(function () {
                toastr.success(vxUtils.loc('ALLEMPLOYEESUCCEFULLPLANNED'), vxUtils.loc('SUCCES'));
                angular.forEach(deletedPeople, function (v, k) {
                    v.status = null;
                });
                msgBus.emitMsg(msgBusActions.employeeReloadCrewPanel, vm.pageHash);
            }, newPeople.concat(deletedPeople), vm.job.jobid,
            function (e, g, j, l) {
                toastr.error(vxUtils.loc('UNSUCCEFULLERRORMESSAGE'), vxUtils.loc('UNSUCCESFULL'));
                angular.forEach(newPeople, function (v, k) {
                    v.status = v.laststatus;
                    v.creationdate = 0;
                });
                vm.cancelAllChanges();
            });
    };

    var getHeight = function () {
        var extra = (vm.data === 'planned' ? 37 : 0);
        var output = $scope.panelsize.height - $scope.headsize.height - 95 - extra;
        return output;
    };
    vm.cancelAllChanges = function () {
        vm.changeCrewList(true);
        msgBus.emitMsg(msgBusActions.employeeReloadPanel, vm.pageHash);
    };

    vm.listCpi.dropCallback = function (event, index, item, type) {

        if (vm.data === "planned" && item.status === -2 && item.laststatus > -1) {
            item.status = item.laststatus;

        }
        else if (vm.data === "planned") {
            item.status = -1;
        }
        else if (vm.data === "crew" && item.status === -1)
            item.status = null;
        else if (vm.data === "crew" && item.status > -1) {
            item.laststatus = item.status;
            item.status = -2;
        }

        if (external) {
            //TODO: check if external
        }
        return item;
    };


// vm.listCpi.items = [{
//      type: 'employee',
//      rating: 3,
//     status: 'Bevestigd',
//     name: 'Laurens van Beuningen'
//  }, {type: 'employee', rating: 3, status: 'Annulering', name: 'Robin van der Driessche'}
//     , {type: 'employee', rating: 3, status: 'Annulering', name: 'Robin van der Driessche'}
//    , {type: 'employee', rating: 3, status: 'Annulering', name: 'Robin van der Driessche'}];


})
;