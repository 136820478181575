// Application Dependencies
import '../models'
import '../modules/Voxmodules'
import '../directives/Voxponents'
import './vxFilters'
import '../assets/js';

// Application bootstrap
const appModule = angular.module('VoxilCRM',
    [
        'mgcrea.ngStrap.datepicker',
        'mgcrea.ngStrap.timepicker',
        "mgcrea.ngStrap.select",
        "mgcrea.ngStrap.tooltip",
        'mgcrea.ngStrap.helpers.parseOptions',
        'nsPopover',
        'ngTouch',
        'ui.bootstrap',
        'ngRoute',
        'ngSanitize',
        'chart.js',
        'ngTable',
        'ui.calendar',
        'ngCookies',
        'pascalprecht.translate',
        'ngResource',
        'ngAnimate',
        'Voxponents',
        'angular-velocity',
        'chieffancypants.loadingBar',
        'textAngular',
        'xeditable',
        'uiGmapgoogle-maps',
        'geocoder', //google maps
            'ui-leaflet',
        'ngStorage',
        'autofields',
        'multi-select',
        'toastr',
        'Voxmodules',
        'vxFilters',
        'monospaced.mousewheel',
        'dndLists',
        'daterangepicker',
        'angular.filter',
        'dcbClearInput',
        'angularFileUpload',
        'hSweetAlert',
        'angular-nicescroll',
        'uz.mailto'
    ]);



export default appModule;
