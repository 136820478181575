import app from "/src/app/app.module";

/**
 * @typedef {Object} HttpResponse
 * @property {*} data - The data returned from the request.
 * @property {number} status - HTTP status code of the response.
 * @property {function(string=): string} headers - Header getter function.
 * @property {Object} config - The configuration object that was used to generate the request.
 */

/**
 * Factory function to create API service.
 *
 * @param {$http} $http - Angular's $http service for making HTTP requests.
 * @returns An object containing methods for HTTP requests.
 */
var api = function ($http) {

    /**
     * Custom HTTP request.
     *
     * @param {string} method - The HTTP method to use.
     * @param {string} url - The URL for the request.
     * @param {string} responseType - The type of the response.
     * @param {function(HttpResponse):void} successCallback - Callback on success.
     * @param {function(HttpResponse):void} errorCallback - Callback on error.
     */
    var CUSTOM = function (method, url, responseType, successCallback, errorCallback) {
        $http({method: method, url: url, responseType: responseType})
            .then(function (response) {
                if (successCallback) successCallback(response.data, response.status, response.headers, response.config);
            })
            .catch(function (response) {
                if (errorCallback) errorCallback(response.data, response.status, response.headers, response.config);
            });
    };

    /**
     * Perform an HTTP GET request.
     *
     * @param {string} url - The URL to request.
     * @param {function(HttpResponse):void} successCallback - Callback on success.
     * @param {function(HttpResponse):void} errorCallback - Callback on error.
     */
    var GET = function (url, successCallback, errorCallback) {
        $http.get(url)
            .then(function (response) {
                if (successCallback) successCallback(response.data, response.status, response.headers, response.config);
            })
            .catch(function (response) {
                if (errorCallback) errorCallback(response.data, response.status, response.headers, response.config);
            });
    };

    /**
     * Perform an HTTP POST request.
     *
     * @param {string} url - The URL to request.
     * @param {*} item - The data to be sent as the POST request body.
     * @param {function(HttpResponse):void} successCallback - Callback on success.
     * @param {function(HttpResponse):void} errorCallback - Callback on error.
     */
    var POST = function (url, item, successCallback, errorCallback) {
        $http.post(url, item)
            .then(function (response) {
                if (successCallback) successCallback(response.data, response.status, response.headers, response.config);
            })
            .catch(function (response) {
                if (errorCallback) errorCallback(response.data, response.status, response.headers, response.config);
            });
    };

    /**
     * Perform an HTTP PUT request.
     *
     * @param {string} url - The URL to request.
     * @param {*} item - The data to be sent as the PUT request body.
     * @param {function(HttpResponse):void} successCallback - Callback on success.
     * @param {function(HttpResponse):void} errorCallback - Callback on error.
     */
    var PUT = function (url, item, successCallback, errorCallback) {
        $http.put(url, item)
            .then(function (response) {
                if (successCallback) successCallback(response.data, response.status, response.headers, response.config);
            })
            .catch(function (response) {
                if (errorCallback) errorCallback(response.data, response.status, response.headers, response.config);
            });
    };

    /**
     * Perform an HTTP DELETE request.
     *
     * @param {string} url - The URL to request.
     * @param {function(HttpResponse):void} successCallback - Callback on success.
     * @param {function(HttpResponse):void} errorCallback - Callback on error.
     */
    var DELETE = function (url, successCallback, errorCallback) {
        $http.delete(url)
            .then(function (response) {
                if (successCallback) successCallback(response.data, response.status, response.headers, response.config);
            })
            .catch(function (response) {
                if (errorCallback) errorCallback(response.data, response.status, response.headers, response.config);
            });
    };

    return {
        get: GET,
        post: POST,
        delete: DELETE,
        put: PUT,
        custom: CUSTOM
    };
};

app.factory("vox", api);
