import app from "/src/app/app.module";

app.controller('pay_dialog', function ($scope, $filter, vxUtils, msgBus, toastr, $api) {
    var vm = this;
    vm.dialogGuid = vxUtils.generateQuickGuid();

    vm.transation = {};

    vm.job = vxUtils.findObjectOnParents($scope, 'job');
    vm.cust = vxUtils.findObjectOnParents($scope, 'cust');
    function Get() {
        if (vm.job) {
            $api.get($api.adress.pay + '/' + vm.job.paymentid, function (result) {
                vm.hasIdeal = result.length > 0 ? result[0] : false;
            });
        }
    }

    vm.saveCall = function () {

        if (vm.job !== undefined) {
            vm.transation = {
                orderNumber: vm.job.jobid,
                description: "Betreft: " + vm.job.jobtitle + "\nDatum: " + moment(vm.job.startdate).format('DD-MM-YYYY'),
                amount: (vm.amount * 100) + vm.amountcents,
                object: vm.job.paymentid
            };
        }

        $api.post($api.adress.createpaytransaction, function (results) {
            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('PAYMENT') + vxUtils.loc('ACTION_ADDED'));
            //console.log(results);
            vm.amount = undefined;
            vm.amountcents = undefined;
            Get();
        }, vm.transation);
    };
    Get();
})
;