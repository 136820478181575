import app from "/src/app/app.module";
import Tendant from "../models/tendant";
import User from "../models/user";

var api = function ($resource, settings, vox, $http,
                    vxSession, $location, $localStorage) {

    var login = function (callback, login) {
        $http.post(settings.BASE_API_URL + 'v1/login', login)
            .then(function (response) {
                const data = response.data;
                vxSession.create(data.auth, data.user, data.userrole);
                $location.path('/');
                callback(data);
            }).catch(function (response) {
                alert('Login gegevens zijn niet gevonden');
            });
    };

    var requestReset = function (callback, email) {
        vox.post(settings.BASE_API_URL + 'v1/users/reset', email,
            function (data, status, headers, config) {
                callback(data);
            });
    };

    var reset = function (callback, login, errorCallback) {
        $http.post(settings.BASE_API_URL + 'v1/login/reset', login)
            .then(function (response) {
                $location.path('/');
                callback(response);
            }).catch(function (response) {
                errorCallback(response);
            });
    };

    var isAuthenticated = function () {
        return !!vxSession.user;
    };

    var allCompanies = function (callback, error, nocache) {
        if ($localStorage.allCompanies != null && !nocache) {
            if (callback)
                callback($localStorage.allCompanies);
            return;
        }

        $http.get(settings.BASE_API_URL + 'v1/companies')
            .then(function (response) {
                const data = response.data;
                var out = [];
                angular.forEach(data, function (v, k) {
                    var i = new Tendant(v);
                    out.push(i);
                });
                $localStorage.allCompanies = out;
                if (callback)
                    callback(out);
            }).catch(function (response) {
            const data = response.data;
                if (error)
                    error(data);
            });
    };

    var postCompany = function (callback, item, error) {
        $http.post(settings.BASE_API_URL + 'v1/companies', item)
            .then(function (response) {
                const data = response.data;
                var out = [];
                angular.forEach(data, function (v, k) {
                    var i = new Tendant(v);
                    out.push(i);
                });
                if (callback)
                    callback(out[0]);
            }).catch(function (response) {
            const data = response.data;
                if (error)
                    error(data);
            });
    };
    var deleteCompany = function (callback, item, error) {
        $http.delete(settings.BASE_API_URL + 'v1/companies/'+item)
            .then(function (response) {
                const data = response.data;
                callback(data);
            }).catch(function (response) {
            const data = response.data;
                if (error)
                    error(data);
            });
    };

    var isAuthorized = function (authorizedRoles) {
        if (!angular.isArray(authorizedRoles)) {
            authorizedRoles = [authorizedRoles];
        }
        return (isAuthenticated() &&
        authorizedRoles.indexOf(vxSession.userRole) !== -1);
    };

    return {
        deleteCompany: deleteCompany,
        getAllCompanies: allCompanies,
        requestReset: requestReset,
        reset: reset,
        login: login,
        isAuthenticated: isAuthenticated,
        isAuthorized: isAuthorized,
        postCompany: postCompany
    };
};

app.factory("$auth", api);

app.factory('ErrorResponseInterceptor', ['$q', '$location', 'vxSession', function ($q, $location, vxSession) {
    return {
        response: function (response) {
            if (response.status === 401) {
                console.log("Response 401");
            }
            else if (response.status === 403) {
                console.log("Response 403");
            }
            return response || $q.when(response);
        },
        responseError: function (rejection) {
            if (rejection.status === 401 && rejection.data === "expired") {
                console.log("Response Error 419", rejection);
                vxSession.destroy();
                $location.path('/login');
            }
            else if (rejection.status === 401 || rejection.status === 403) {
                console.log("Response Error 401", rejection);
                $location.path('/login');
            }
            return $q.reject(rejection);
        }
    }
}]);


app.service('vxSession', ['$localStorage', function ($localStorage) {

    if ($localStorage.token) this.token = $localStorage.token;
    if ($localStorage.user) this.user = $localStorage.user;
    if ($localStorage.userRole) this.userRole = $localStorage.userRole;

    this.create = function (token, user, userRole) {
        this.token = token;
        this.user = new User(user);
        this.userRole = userRole;
        $localStorage.token = token;
        $localStorage.user = this.user;
        $localStorage.userRole = userRole;
    };


    this.auth = function (level) {
        return !(parseInt(this.userRole) < level);
    };
    this.authEquals = function (level) {
        return (parseInt(this.userRole) === level);
    };

    this.sameUser = function (id) {
        return (parseInt(this.user.idUser) === id);
    }

    this.destroy = function () {
        this.token = null;
        this.user = null;
        this.userRole = null;
        $localStorage.token = null;
        $localStorage.user = null;
        $localStorage.userRole = null;
    };
    return this;
}]);

app.factory('AuthInterceptor', function (vxSession) {
    return {
        request: function (config) {
            config.headers = config.headers || {};
            if (vxSession.token !== undefined && vxSession.token !== null)
                config.headers.Authorization = vxSession.token;
            return config;
        }
    };
});

app.constant('AUTH_EVENTS', {
    loginSuccess: 'auth-login-success',
    loginFailed: 'auth-login-failed',
    logoutSuccess: 'auth-logout-success',
    sessionTimeout: 'auth-session-timeout',
    notAuthenticated: 'auth-not-authenticated',
    notAuthorized: 'auth-not-authorized'
})

app.constant('USER_ROLES', {
    all: '*',
    admin: 'admin',
    editor: 'editor',
    guest: 'guest'
});
