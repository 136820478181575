import app from "/src/app/app.module";

app.controller('emp_hour_rate_panel', function ($scope, vxUtils, vxSession, $api) {
    var vm = this;
    vm.listCpi = {};
    vm.user = vxUtils.findObjectOnParents($scope, 'user');
   

    vm.auth = function () {
        return parseInt(vxSession.userRole) > 1;
    };
    vm.items = [];

    vm.addItem = function () {

        $api.post($api.adress.hourrate,
            function (data) {
                vm.init(true);
            }, {
                _label: vm.selected.label,
                user_id: vm.user.idUser,
                type_id: vm.selected.id
            });


    };

   

    vm.save = function (item) {
      
    };

    vm.init = function (isNew) {
        var id = 0;
        var type = '';
        vm.listCpi.items = [];

        var items = [];
        var getChanges = function (id, type) {
            $api.get($api.adress.hourtypes,
                function (data) {
                    if (angular.isArray(data)) {
                        items = data.concat(items)
                    } else
                        items.push(data);

                    vm.items = items;
                    vm.selected = vm.items[0]
                }, HourType);

            $api.get($api.adress.hourrateuser + '/' + vm.user.idUser,
                function (data) {
                    var it = [];
                    if (angular.isArray(data)) {
                        it = data.concat(it)
                    } else
                        it.push(data);

                    vm.listCpi.items = it;

                }, HourRate);
        };

        if (vm.user !== -1) {
            id = vm.user.idUser;
            type = 'userHourRate';
            getChanges(id, type);
        }

    };
    vm.init();

})
;