import app from "/src/app/app.module";
import Customer from "../models/customer";

app.controller('add-customer-form', function ($scope, settings, $location,vxUtils,
                                              $filter, enums, $customer, $element,
                                              $routeParams,vxCountries) {

    if ($scope.wizard.customer === undefined) {
        $scope.wizard.customer = new Customer();
        $scope.wizard.customer.email = $routeParams.email;
        $scope.wizard.customer.companyname = $routeParams.name;
        $scope.wizard.customer.firstname = $routeParams.name;
        $scope.wizard.customer.IsCompany = false;
        $scope.countries = vxCountries.countries;
        $customer.getAllCustomerKinds(result => {
            $scope.customerKinds =result;
            $scope.wizard.customer.customerKindId = $scope.customerKinds[0].id_customerkind;
        });
    }
    $customer.getAllTypes(function (types) {
        $scope.customerTypes = types;
        $scope.wizard.customer.relationId = $scope.customerTypes[0].id_client_type;

    });

    $scope.currentForm = function () {
        return $scope.wizard.customer.IsCompany ? $scope.formC : $scope.formI;
    };

    $scope.schemaIndividual = [
        {
            property: 'firstname', type: 'text',
            attr: {autocomplete: 'off', required: true, maxlength: 45},
            label: $filter('translate')('FIRSTNAME'),
            msgs: {required: $filter('translate')('REQUIRED')}
        }, {
            property: 'preposition', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('PREPOSITION')
        }, {
            property: 'lastname', type: 'text',
            attr: {autocomplete: 'off', required: true, maxlength: 45},
            label: $filter('translate')('LASTNAME'),
            msgs: {required: $filter('translate')('REQUIRED')}
        }, {
            property: 'telephone', type: 'text',
            attr: {
                autocomplete: 'off', required: false, maxlength: 45
            },
            label: $filter('translate')('TELEPHONE')
        },
        {
            property: 'telephone2', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('TELEPHONE2')
        },
        {
            property: 'email', type: 'email',
            attr: {autocomplete: 'off', required: true, maxlength: 80},
            label: $filter('translate')('EMAIL')
        }, {
            property: 'street', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STREET')
        }, {
            property: 'streetnumber', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 10},
            label: $filter('translate')('STREETNUMBER')
        }, {
            property: 'areacode', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 10},
            label: $filter('translate')('AREACODE')
        },{
            property: 'state', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STATE_PROVINCE')
        }, {
            property: 'city', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 60},
            label: $filter('translate')('CITY')
        }, {
            property: 'country', type: 'typeahead', attr: {autocomplete: 'off', required: true},
            label: $filter('translate')('COUNTRY'),
            typeahead: 'country.name for country in countries',
            msgs: {required: $filter('translate')('REQUIRED')}
        }
    ]
    ;

    $scope.schemaCompany = [
        {
            property: 'name', type: 'text',
            attr: {autocomplete: 'off', required: true, maxlength: 100},
            label: $filter('translate')('COMPANYNAME'),
            msgs: {required: $filter('translate')('REQUIRED')}
        }, {
            property: 'kvk', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('KVK')
        }, {
            property: 'btw', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('BTW')
        }, {
            property: 'telephone', type: 'text',
            attr: {
                autocomplete: 'off', required: false, maxlength: 45
            },
            label: $filter('translate')('TELEPHONE')
        },
        {
            property: 'fax', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 45},
            label: $filter('translate')('FAX')
        },
        {
            property: 'email', type: 'email',
            attr: {autocomplete: 'off', required: true, maxlength: 100},
            label: $filter('translate')('EMAIL')
        }, {
            property: 'street', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STREET')
        }, {
            property: 'streetnumber', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 10},
            label: $filter('translate')('STREETNUMBER')
        }, {
            property: 'areacode', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 10},
            label: $filter('translate')('AREACODE')
        }, {
            property: 'city', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('CITY')
        },{
            property: 'state', type: 'text',
            attr: {autocomplete: 'off', required: false, maxlength: 100},
            label: $filter('translate')('STATE_PROVINCE')
        }, {
            property: 'country', type: 'typeahead', attr: {autocomplete: 'off', required: true},
            label: $filter('translate')('COUNTRY'),
            typeahead: 'country.name for country in countries',
            msgs: {required: $filter('translate')('REQUIRED')}
        }
    ];


    $scope.options = {
        validation: {
            enabled: true
        },
        layout: {
            type: 'horizontal',
            labelSize: 3,
            inputSize: 9
        }
    };

})
;