'use strict';

var Customer = function (cust) {

    // Dont change this, for some reason is used this to create a new customer :S AK
    if (cust === undefined) {
        return;
    }

    this.dbo = cust;
    this.IsCompany = this.dbo['id_contactdetail'] === '0' || this.dbo['id_contactdetail'] === null;

    var prefix = this.IsCompany ? "cpd" : "ctd";

    this.ClientNumber = function () {
        return parseInt(this.dbo['idClient']);
    };

    this.DetailID = function () {
        return this.IsCompany ? this.dbo['idCompanydetail'] : this.dbo['idContactdetail'];
    }

    //properties
    this.Name = function () {
        if (this.IsCompany)
            return this.dbo[prefix +'_name'];
        else {
            var prep = this.dbo['ctd_preposition'];
            if (prep !== "" && prep !== null)
                prep = " " + prep;
            else {
                prep ='';
            }

            return this.dbo['ctd_surname'] + prep + " " + this.dbo['ctd_lastname'];
        }
    };

    this.Adress = function () {
        return `${this.dbo[prefix + '_street']} ${this.dbo[prefix + '_number']}`
    };

    this.Street = function () {
        return this.dbo[prefix + '_street'];
    };

    this.StreetNumber = function () {
        return this.dbo[prefix + '_number'];
    };

    this.AreaCode = function () {
        return this.dbo[prefix + '_areacode'];
    };

    this.City = function () {
        return this.dbo[prefix + '_city'];
    };

    this.State = function () {
        return this.dbo[prefix + '_state'];
    };

    this.Country = function () {
        return this.dbo[prefix + '_country'];
    };

    this.Email = function () {
        return this.dbo[prefix + '_email'];
    };

    this.Telephone = function (n) {
        if (n === undefined)
            n = this.IsCompany ? "" : "1";

        return this.dbo[prefix + '_telephone' + n];
    };

    this.Fax = function () {

        return this.IsCompany ? this.dbo[prefix +'_fax'] : "-";
    };

    this.Btw = function () {
        return this.IsCompany ? this.dbo[prefix +'_btw'] : "-";
    };

    this.Kvk = function () {
        return this.IsCompany ? this.dbo[prefix +'_kvk'] : "-";
    };

    this.GetFirstName = function () {
        if (this.IsCompany)
            return null;
        else {

            return this.dbo[prefix +'_surname'];
        }
    };

    this.GetLastName = function () {
        if (this.IsCompany)
            return null;
        else {

            return  this.dbo[prefix +'_lastname'];
        }
    };
    //ICONS
    this.CustomerTypIcon = function () {

        var val = "fa fa-building-o";

        switch (this.IsCompany) {
            case true:
                val = "fa fa-building-o";
                break;
            case false:
                val = "glyphicon glyphicon-user";
        }
        return val;
    };

    this.Preposition = function () {
        return this.dbo[prefix +'_preposition'];
    };

    this.RelationType = function () {
        return this.dbo['client_type_name'];
    };

    this.RelationID = function () {
        return this.dbo['id_client_type'];
    };

    this.CustomerKindId = function () {
        return this.dbo['id_client_kind_FK'] || '1';
    };

    this.CustomerKind = function () {
        return this.dbo['type'];
    };

    //TAB interface
    this.tabID = this.ClientNumber();
    this.tabDescription = this.Name();

    //Client
    this.idClient = this.ClientNumber();
    this.iddetail = this.DetailID();

    this.customericon = this.CustomerTypIcon();

    this.relationtype = this.RelationType();
    this.relationId = this.RelationID();

    this.firstname = this.GetFirstName();
    this.prepostition = this.Preposition();
    this.lastname = this.GetLastName();
    this.adress = this.Adress();
    this.street = this.Street();
    this.streetnumber = this.StreetNumber();
    this.areacode = this.AreaCode();
    this.city = this.City();
    this.state = this.State();
    this.country = this.Country();
    this.email = this.Email();
    this.telephone = this.Telephone();
    this.telephone2 = this.Telephone(2);
    this.fax = this.Fax();
    this.events = [];
    this.name = this.Name();
    this.customerKindId = this.CustomerKindId();
    this.customerKind = this.CustomerKind();

    //Company
    if (this.IsCompany) {
        this.name = this.Name();
        this.kvk = this.Kvk();
        this.btw = this.Btw();
    }

};

export default Customer;
//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];