/**
 *  Function to detect and store the user's preferred language
 * @returns {string}
 */
export function detectAndStoreLanguage() {
    const userPreferredLanguage = navigator.language || navigator.userLanguage;

    // Extract the language code (e.g., 'en') from the user's preferred language
    const [languageCode, countryCode] = userPreferredLanguage.split(/[-_]/);

    // Check if the language code matches one of your supported languages
    // If it doesn't match, you can default to a specific language (e.g., Dutch)
    let preferedLanguage = languageCode.toLowerCase();
    let preferedCountry = countryCode ? countryCode.toLowerCase() : '';
    let selectedLanguage = 'nl-nl';

    // Check if the language code matches Dutch or Italian
    if (preferedLanguage === 'nl') {
        selectedLanguage = 'nl-nl'; // Dutch
    } else if (preferedLanguage === 'it') {
        selectedLanguage = 'it-it'; // Italian
    } else if (preferedLanguage === 'en' && preferedCountry === 'gb') {
        selectedLanguage = 'en-gb'; // British
    } else if (preferedLanguage === 'en' && preferedCountry === 'us') {
        selectedLanguage = 'en-us'; // US
    } else if (preferedLanguage === 'en') {
        selectedLanguage = 'en-us'; // US
    }

    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', selectedLanguage);

    return selectedLanguage;
}

/**
 * Function to get the selected language from localStorage
 */
export function getSelectedLanguage() {
    return localStorage.getItem('selectedLanguage');
}

/**
 * Function to set the selected language into localStorage
 * @param language the language to set
 */
export function setSelectedLanguage(language) {
    return localStorage.setItem('selectedLanguage', language.code);
}

export function getAvailableLanguages() {
    return [{
        code: 'nl-nl',
        label: 'DUTCH'
    },
        {
            code: 'en-us',
            label: 'AMERICAN'
        },
        {
            code: 'en-gb',
            label: 'BRITISH'
        }]
}


