import app from "/src/app/app.module";
import PaymentItem from "../models/payment-item";
import Tax from "../models/tax";

const api = (vox, settings, $localStorage) => {

    const createPaymentItems = (data) => {
        return data.map(value => new PaymentItem(value));
    };

    const getPaymentItems = (callback) => {
        vox.get(`${settings.BASE_API_URL}v1/payments/all`, (data) => {
            callback(createPaymentItems(data));
        });
    };

    const getPaymentItemsByClient = (callback, type, clientid) => {
        vox.get(`${settings.BASE_API_URL}v1/payments/${type}/${clientid}`, (data) => {
            callback(createPaymentItems(data));
        });
    };

    const getPaymentItemByID = (callback, jobid) => {
        vox.get(`${settings.BASE_API_URL}v1/payment/id/${jobid}`, (data) => {
            callback(new PaymentItem(data));
        });
    };

    const getPaymentItemByJob = (callback, jobid) => {
        vox.get(`${settings.BASE_API_URL}v1/payment/job/${jobid}`, (data) => {
            callback(new PaymentItem(data));
        });
    };

    const getAllPaymentsBetween = (callback, clientid, startdate, enddate) => {
        vox.get(`${settings.BASE_API_URL}v1/payments/date/all/${clientid}/${startdate}/${enddate}`, (data) => {
            callback(createPaymentItems(data));
        });
    };

    const getAllPayments = (callback) => {
        vox.get(`${settings.BASE_API_URL}v1/payments/all`, (data) => {
            callback(createPaymentItems(data));
        });
    };

    const getAllInvoices = (callback) => {
        vox.get(`${settings.BASE_API_URL}v1/payments/invoices`, (data) => {
            callback(createPaymentItems(data));
        });
    };

    const postPaymentItem = (callback, paymentItem) => {
        vox.post(`${settings.BASE_API_URL}v1/payments/${paymentItem.type}/${paymentItem.idPayment}`, paymentItem, (data) => {
            callback(data);
        });
    };

    const postInvoiceAdress = (callback, invoiceAdress) => {
        vox.post(`${settings.BASE_API_URL}v1/payments/invoiceadress`, invoiceAdress, (data) => {
            callback(data);
        });
    };

    const deletePayment = (callback, id) => {
        vox.delete(`${settings.BASE_API_URL}v1/payment/id/${id}`, (data) => {
            callback(data);
        });
    };

    const getTaxes = (callback, nocache) => {
        if ($localStorage.taxes != null && !nocache) {
            if (callback != null)
                callback($localStorage.taxes);
        } else {
            vox.get(`${settings.BASE_API_URL}v1/taxes`, (data) => {
                const out = data.map(value => new Tax(value));
                $localStorage.taxes = out;
                callback(out);
            });
        }
    };

    const postTax = (callback, tax) => {

            vox.post(`${settings.BASE_API_URL}v1/tax`,tax, (data) => {
               callback(new Tax(data[0]));
            });

    };

    const deleteTax = (id, callback) => {

            vox.delete(`${settings.BASE_API_URL}v1/tax/${id}`,(data) => {
               callback();
            });

    };

    return {
        deletePayment,
        getAllInvoices,
        getAll: getPaymentItems,
        getTaxes,
        postTax,
        deleteTax,
        postPaymentItem,
        postInvoiceAdress,
        getPaymentItemsByClient,
        getAllPayments,
        getAllPaymentsBetween,
        getPaymentItemByJob,
        getPaymentItemByID
    };
};

app.factory("$payment", api);
