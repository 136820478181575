import app from "/src/app/app.module";

var vxUtil = function (settings) {

    //strt = string  col is array
    var replaceValues = function (str, col) {

        col = typeof col === 'object' ? col : Array.prototype.slice.call(arguments, 1);

        return str.replace(/\{\{|\}\}|\{(\w+)\}/g, function (m, n) {
            if (m == "{{") {
                return "{";
            }
            if (m == "}}") {
                return "}";
            }
            return col[n];
        });

    };


    return {
        replaceValues: replaceValues
    };
}

app.factory("$vxUtil", vxUtil);