import app from "/src/app/app.module";
import Customer from "../models/customer";

app.controller('search-customer-form', function ($scope, $search, $customer, vxUtils, msgBus) {

    var step = vxUtils.findObjectOnParents($scope, "selectedstep");


    if ($scope.wizard.customer === undefined) {
        $scope.wizard.customer = new Customer();

        $scope.cust = $scope.wizard.customer;
    } else {
        $scope.cust = $scope.wizard.customer;
    }

    $scope.found = [];

    $scope.selectCustomer = function (item) {
        if ($scope.wizard.selectedCustomer != null) {
            $scope.wizard.selectedCustomer.$selected = false;
        }
        $scope.wizard.selectedCustomer = item;
        $customer.getCustomer(function (result) {
            $scope.wizard.customer = result;
            $scope.cust = result;
            msgBus.emitMsg('valForm', {
                isValid: true,
                scope: $scope
            });
        }, item.ID);
        $scope.wizard.selectedCustomer.$selected = true;
    };

    $scope.$watch('wizard.searchCustomer', function (tmpStr) {

        if (!tmpStr || tmpStr.length == 0)
            return 0;
        // if searchStr is still the same..
        // go ahead and retrieve the data
        if (tmpStr === $scope.wizard.searchCustomer) {
            $search.search(function (results) {
                $scope.wizard.found = results;
            }, $scope.wizard.searchCustomer, '["customer"]');
        }
        return 0;
    });



});