'use strict';

var HourRate = function (hourRate) {

    angular.extend(this, hourRate);

    this.dbo = hourRate;

    this._type = 'hourRate';
    this._amountwhole = parseInt(floorFigure(parseInt(this.amount) / 100, 0));
    var cents =( parseInt(this.amount) - (this._amountwhole * 100)  );
    this._amountcents = cents<10?'0'+cents:cents.toString();

    this._onlyNumbers = /^\d+$/;
    function floorFigure(figure, decimals) {
      //  if (!decimals) decimals = 2;
        var d = Math.pow(10, decimals);
        return (parseInt(figure * d) / d).toFixed(decimals);
    };
};

export default HourRate;
//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];