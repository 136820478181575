'use strict';

var Availability = function (event) {

    this.dbo = event;

    this.EventTitle = function () {
        return this.dbo['eventtitle'];
    };

    this.ID = function () {
        return this.dbo['idEvent'];
    };

    this.Icon = function () {
        return 'fa fa-beer small-negative-margin-left';
    };

    this.Background = function () {
        return 'vx-orange-bg';
    };

    this.Type = function () {
        return this.dbo['Type'];
    };
    this.WholeDay = function () {
        return this.dbo['wholeday'];
    };
    this.Remarks = function () {
        return this.dbo['remarks'];
    };

    this.Time = function () {
        return parseInt(this.dbo['start_date'] + "000");
    };
    this.StartDate = function () {
        return this.dbo['start_date'] === null ? '-' : parseInt(this.dbo['start_date'] + "000");
    };

    this.EndDate = function () {
        return this.dbo['end_date'] === null ? '-' : parseInt(this.dbo['end_date'] + "000");
    };

    this.eventtitle = this.EventTitle();
    this.id = this.ID();
    this.type = this.Type();
    this.time = this.Time();
    this.remarks = this.Remarks();
    this.icon = this.Icon();
    this.background = this.Background();
    this.startdate = this.StartDate();
    this.enddate = this.EndDate();

    //Calendar
    this.title = this.eventtitle;
    this.start = this.startdate;
    this.end = this.enddate;
    this.allDay =this.WholeDay();
    this.className=this.background;

};

export default Availability;

//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];