import app from "/src/app/app.module";


app.controller('user_groups',
    function ($scope, $timeout, msgBus, $api,
              $filter, $focus, sweet, vxUtils, toastr) {
        var vm = this;

        vm.subjectPlaceholder = vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.items = [];
        vm.selected = {content: ''};

        vm.add = function () {
            var m = {
                groupname: vm.title
            };

            vm.title = '';
            //vm.items.splice(0, 0, m);
            vm.selected = m;
            $focus('focusSubject');
            vm.save(true);
        };

        vm.delete = function () {
            sweet.show({
                title: vxUtils.loc('CONFIRMDELETION'),
                text: vxUtils.loc('REMOVEUSERGROUP'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: vxUtils.loc('YES'),
                closeOnConfirm: true
            }, function () {
                $api.del($api.adress.usergroups + '/' + vm.selected.idGroup, function () {
                    var i = $filter('removeWith')(vm.items, {idGroup: vm.selected.idGroup});
                    // vm.items.splice(i, 1);
                    vm.items = i;
                    vm.selected = vm.items[vm.items.length - 1];
                });
            });


        };

        vm.save = function (isNew) {
            $api.post($api.adress.usergroups, function (result) {

                if (isNew) {
                    vm.items.splice(vm.items.length, 0, result);
                    vm.items.push(result);
                    vm.selected = result;
                }
                else {
                    var i = vm.items.findIndex(x=>x.idGroup === vm.selected.idGroup);
                    vm.selected = vm.items[i];
                }



                toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('USER_GROUP_SAVED'));
            }, vm.selected);
        };

        vm.getItems = function () {
            $api.get($api.adress.usergroups, function (result) {
                vm.items = result.map(x=>{
                    x.default_ticked = x.default_ticked === '1'
                    return x;
                });

                if (vm.items.length > 0)
                    vm.selected = vm.items[0];
            });

        };

        vm.getItems();
    })
;