/**
 * Converts a Date object or a valid date string to a Unix timestamp in seconds.
 * It first checks if the input is neither null nor undefined, then verifies that
 * it can be converted to a valid Date.
 *
 * @param {Date|string} date - The date to convert.
 * @returns {number|null} The Unix timestamp in seconds, or null if the input is invalid.
 */
export function dateToUnixTimestamp(date) {
    if (date !== null && date !== undefined) {
        const dateObject = new Date(date);
        // Check if dateObject is a valid date
        if (!isNaN(dateObject.getTime())) {
            return Math.floor(dateObject.getTime() / 1000);
        }
    }
    return null; // Return null if the input is null, undefined, or invalid date
}

/**
 * Convert Unix timestamp from seconds to milliseconds.
 * @param {string | number} date - The Unix timestamp in seconds to convert.
 * @returns {number} The Unix timestamp in milliseconds.
 */
export function parseUnixWithMilliSeconds(date) {
    // Convert the input to a number in case it is passed as a string
    const timestampInSeconds = Number(date);

    // Check if the conversion to number failed or if the input was not a valid number
    if (isNaN(timestampInSeconds)) {
       return 0;
    }

    // Convert seconds to milliseconds
    return timestampInSeconds * 1000;
}
/**
 * Converts a Unix timestamp to a human-readable date string.
 *
 * @param {number} unixTimestamp - The Unix timestamp to convert.
 * @returns {string} The human-readable date string.
 */
export function parseUnixToDate(unixTimestamp) {
    // Create a new Date object using the Unix timestamp (in milliseconds)
    const date = new Date(parseUnixToSeconds(unixTimestamp) * 1000);
    // Combine the components into a human-readable string
    return date;
}

/**
 * Convert Unix timestamp from milliseconds to seconds if necessary.
 * @param {string | number} date - The Unix timestamp to convert.
 * @returns {number} The Unix timestamp in seconds.
 */
export function parseUnixToSeconds(date) {
    // Convert the input to a number in case it is passed as a string
    const timestamp = Number(date);

    // Check if the conversion to number failed or if the input was not a valid number
    if (isNaN(timestamp)) {
        return 0;
    }

    // Check if the timestamp is already in seconds (10 digits)
    return timestamp > 9999999999 ? Math.floor(timestamp / 1000) : timestamp;
}