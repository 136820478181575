import app from "/src/app/app.module";
app.controller('add_product_form', function ($scope, vxForms, $products, msgBus) {

    $scope.productFields = vxForms.product;

    if ($scope.wizard.product === undefined) {
        $scope.wizard.product = {};
        $products.allProductGroups(function (result) {
            $scope.wizard.groups = result;
        });
    }

    $scope.sendValidation = function () {
        msgBus.emitMsg('valForm', {
            isValid: true,
            scope: $scope
        });
    };

    $scope.sendValidation();
});