import app from "/src/app/app.module";


app.controller('new-item',
    function ($scope, msgBusActions, msgBus, $filter) {

        $scope.newItems = [
            {
                label: $filter('translate')('RELATION'),
                description: $filter("translate")('NEW_RELATION_DESCR'), icon: "fa fa-building-o", id: 'customer'
            },
            {
                label: $filter('translate')('JOB'), icon: "fa fa-map-marker", id: 'job',
                description: $filter("translate")('NEW_JOB_DESCR')},
            {
                label: $filter('translate')('QUOTE'), icon: "fa fa-clock-o ",
                description: $filter("translate")('NEW_QUOTE_DESCR'), id: 'quote'
            },
            {
                label: $filter('translate')('INVOICE'),
                icon: "fa fa-eur",
                id: 'invoice',
                description: $filter("translate")('NEW_INVOICE_DESCR')},
            {
                label: $filter('translate')('EMPLOYEE'),
                icon: "glyphicon glyphicon-user ",
                id: 'employee',
                description: $filter("translate")('NEW_EMPLOYEE_DESCR')
            },
            {
                label: $filter('translate')('PRODUCT'),
                icon: "fa fa-shopping-cart",
                id: 'product',
                description: $filter("translate")('NEW_PRODUCT_DESCR')
            },
            {
                label: $filter('translate')('ORDER'),
                icon: "fa fa-",
                id: 'order',
                description: $filter("translate")('NEW_ORDER_DESCR')
            }
        ];

        //msgBus.onMsg('show.wizard.page', function (sender, msg) {
        //    $scope.openWizard(msg);
        //}, $scope);


        $scope.openWizard = function (newItem) {
            //var win = window.open('#/create/' + newItem.id, '_blank');
            //win.focus();
            msgBus.emitMsg(msgBusActions.shellOpenTabByType, {
                type: 'create', id: newItem.id
            });
        };


    }
        );