'use strict';

var Depot = function (location) {

    if (location === undefined) {
        return;
    }

    this.dbo = location;
    this.prefix = 'loc';

    this.Name = function () {
        return this.dbo['name'];
    };

    this.Adress = function () {
        return `${this.dbo[this.prefix + '_street']} ${this.dbo[this.prefix + '_number']}`
    };

    this.Street = function () {
        return this.dbo[this.prefix + '_street'];
    };

    this.StreetNumber = function () {
        return this.dbo[this.prefix + '_number'];
    };

    this.AreaCode = function () {
        return this.dbo[this.prefix + '_areacode'];
    };

    this.City = function () {
        return this.dbo[this.prefix + '_city'];
    };

    this.State = function () {
        return this.dbo[this.prefix + '_state'];
    };

    this.Country = function () {
        return this.dbo[this.prefix + '_country'];
    };
    this.IDLocation = function () {
        return parseInt(this.dbo['idLocation']);
    };
    this.IDWarehouse = function () {
        return parseInt(this.dbo['idWarehouse']);
    };

    this.idWarehouse = this.IDWarehouse();
    this.idLocation = this.IDLocation();
    this.name = this.Name();
    this.adress = this.Adress();
    this.street = this.Street();
    this.streetnumber = this.StreetNumber();
    this.areacode = this.AreaCode();
    this.city = this.City();
    this.state = this.State();
    this.country = this.Country();
    this.lastUpdate = 0;
};

export default Depot;