'use strict';

class PaymentAddress {
    constructor(item) {
        if (item === undefined) {
            return; // Consider throwing an error or initializing default values instead
        }
        this.dbo = item;
        const prefix = 'invoice';

        // Initializing properties directly in the constructor
        this.id = this.ID();
        this.idinvoiceadress = this.ID();
        this.firstname = this.GetFirstName();
        this.prepostition = this.Preposition();
        this.lastname = this.GetLastName();
        this.adress = this.Adress();
        this.street = this.Street();
        this.streetnumber = this.StreetNumber();
        this.areacode = this.AreaCode();
        this.city = this.City();
        this.state = this.State();
        this.country = this.Country();
        this.email = this.Email();
        this.telephone = this.Telephone();
        this.telephone2 = this.Telephone2();
        this.name = this.Name();
    }

    ID() {
        return this.dbo['idinvoiceadresses'];
    }

    Name() {
        if (this.dbo['invoice_companyname'])
            return this.dbo['invoice_companyname'];
        else {
            let prep = this.dbo['invoice_preposition'];
            prep = prep ? ` ${prep}` : "";
            return `${this.dbo['invoice_surname']}${prep} ${this.dbo['invoice_lastname']}`;
        }
    }

    Adress() {
        return `${this.dbo['invoice_street']} ${this.dbo['invoice_number']}`;
    }

    Street() {
        return this.dbo['invoice_street'];
    }

    StreetNumber() {
        return this.dbo['invoice_number'];
    }

    AreaCode() {
        return this.dbo['invoice_areacode'];
    }

    City() {
        return this.dbo['invoice_city'];
    }

    State() {
        return this.dbo['invoice_state'];
    }

    Country() {
        return this.dbo['invoice_country'];
    }

    Email() {
        return this.dbo['invoice_email'];
    }

    Telephone() {
        return this.dbo['invoice_telephone1'];
    }

    Telephone2() {
        return this.dbo['invoice_telephone2'];
    }

    GetFirstName() {
        if (this.IsCompany)
            return null;
        else {
            return this.dbo['invoice_surname'];
        }
    }

    GetLastName() {
        if (this.IsCompany)
            return null;
        else {
            let prep = this.dbo['invoice_preposition'];
            prep = prep ? `${prep} ` : "";
            return `${prep}${this.dbo['invoice_lastname']}`;
        }
    }

    Preposition() {
        return this.dbo['invoice_preposition'];
    }

    /**
     *
     * @param wizard
     * @returns {PaymentAddress}
     */
    static parseWizard(wizard) {

        return new PaymentAddress({
            invoice_companyname: wizard.companyname,
            invoice_surname: wizard.firstName, // Assuming 'surname' meant 'firstname'
            invoice_preposition: wizard.preposition,
            invoice_lastname: wizard.lastName,
            invoice_street: wizard.street,
            invoice_number: wizard.streetNumber,
            invoice_areacode: wizard.areaCode,
            invoice_city: wizard.city,
            invoice_state: wizard.state,
            invoice_country: wizard.country,
            invoice_email: wizard.email,
            invoice_telephone1: wizard.telephone,
            invoice_telephone2: wizard.telephone2,
        });
    }
}

export default PaymentAddress;
