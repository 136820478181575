import app from "/src/app/app.module";
import Product from "../models/product";

var api = function ($resource, settings, vox, $localStorage) {

    var convertToObj = function (values) {
        var temp = [];
        angular.forEach(values, function (value, key) {
            var obj = new Product(value);
            this.push(obj);
        }, temp);
        return temp;
    };

    var getAllProducts = function (callback, nocache) {
        if ($localStorage.allProducts != null && !nocache) {
            if (callback)
                callback($localStorage.allProducts);
        }
        vox.get(settings.BASE_API_URL + 'v1/products', function (data) {
            var out = convertToObj(data);
            $localStorage.allProducts = out;

            if (callback)
                callback(out);
        });

    };

    var getProduct = function (id, callback) {
        vox.get(settings.BASE_API_URL + 'v1/products/' + id, function (data) {
            var out = convertToObj(data);
            if (callback)
                callback(out[0]);
        });

    };
    var getAllProductGroups = function (callback) {
        if ($localStorage.allProductGroups !== undefined) {
            if (callback)
                callback($localStorage.allProductGroups);
        }

        vox.get(settings.BASE_API_URL + 'v1/products/groups', function (data) {
            callback(data);
            $localStorage.allProductGroups = data;
        });
    };

    var getLastProductNr = function (callback, groupid) {
        vox.get(settings.BASE_API_URL + 'v1/products/groups/' + groupid + '/lastnr', function (data) {
            callback(parseInt(data[0].lastnr));
        });
    };

    var getProductsByPaymentItem = function (callback, paymentid) {
        vox.get(settings.BASE_API_URL + 'v1/products/payments/' + paymentid, function (data) {
            var out = convertToObj(data);
            callback(out);
        });
    }; var getProductsByOrderPaymentItem = function (callback, paymentid) {
        vox.get(settings.BASE_API_URL + 'v1/products/orderpayments/' + paymentid, function (data) {
            var out = convertToObj(data);
            callback(out);
        });
    };

    var getProductsByJob = function (callback, jobid) {
        vox.get(settings.BASE_API_URL + 'v1/products/job/' + jobid, function (data) {
            var out = convertToObj(data);
            callback(out);
        });
    };

    var deleteProduct = function (callback, productid) {
        vox.delete(settings.BASE_API_URL + 'v1/products/' + productid, function (data) {
            callback(data);
        });
    };
    var deleteProductFromJob = function (callback, productid) {
        vox.delete(settings.BASE_API_URL + 'v1/products/job/' + productid, function (data) {
            callback(data);
        });
    };

    var deleteProductFromOrder = function (callback, productid) {
        vox.delete(settings.BASE_API_URL + 'v1/products/order/' + productid, function (data) {
            callback(data);
        });
    };

    var deleteProductgroup = function (callback, productid) {
        vox.delete(settings.BASE_API_URL + 'v1/products/groups/' + productid, function (data) {
            callback(data);
        });
    };

    var postProduct = function (callback, product, productid) {
        vox.post(settings.BASE_API_URL + 'v1/products/' + productid, product, function (data) {
            callback(data);
        });
    };
    var postOrderProduct = function (callback, product, productid) {
        vox.post(settings.BASE_API_URL + '/v1/orders/'+productid+'/products', product, function (data) {
            callback(data);
        });
    };

    var postProductgroup = function (callback, product) {
        vox.post(settings.BASE_API_URL + 'v1/products/groups', product, function (data) {
            callback(data[0]);
        });
    };

    var postProductDetail = function (callback, product) {
        vox.post(settings.BASE_API_URL + 'v1/products/details', product, function (data) {
            callback(new Product(data[0]));
        });
    };

    return {
        deleteProductFromJob: deleteProductFromJob,
        deleteProductFromOrder: deleteProductFromOrder,
        deleteProductgroup: deleteProductgroup,
        postProductgroup: postProductgroup,
        postOrderProduct:postOrderProduct,
        allProducts: getAllProducts,
        allProductGroups: getAllProductGroups,
        postProduct: postProduct,
        getProductsByPaymentItem: getProductsByPaymentItem,
        getProductsByOrderPaymentItem: getProductsByOrderPaymentItem,
        deleteProduct: deleteProduct,
        lastProductNumber: getLastProductNr,
        postProductDetail: postProductDetail,
        getProductsByJob: getProductsByJob,
        getProduct: getProduct
    };
};

app.factory("$products", api);
