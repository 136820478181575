import app from "/src/app/app.module";
import moment from "moment";


app.controller('employees', function ($scope, $users, selectionFilters, $filter,
                                      vxSession,
                                      vxUtils, msgBus, msgBusActions, settings, $locale) {


    ////////OVERVIEW/////
    $scope.overviewCpi = {
        type: 'employee',
        openItemPage: "/src/pages/employees/employee-details.html",
        openItemIcon: "fa fa-user",
        canOpen: function () {
            return vxSession.auth(2);
        },
        tabItems: $scope.openItems,
        //  filters: selectionFilters.employeeGroups(),

        dateChanged: function (newDate) {
            $scope.lastdate = newDate;
            $scope.getItems(newDate);
        },
        selectionChanged: function (data) {
            $scope.overviewCpi.items = selectionFilters.filterBySelection(
                $scope.overviewCpi.filters,
                $scope.overviewCpi.all,
                $scope.overviewCpi.fields
            );

            $scope.overviewCpi.reload();
        }
    };
    $scope.getTime = function (item) {
        return $filter('date')(item.startdate, $locale.DATETIME_FORMATS.shortTime) + " - " + $filter('date')(item.enddate, $locale.DATETIME_FORMATS.shortTime);
    };
    $scope.headerButtons = [
        {
            tooltip: vxUtils.loc('EXPORT_TO_EXCEL'),
            icon: 'fa fa-file-excel-o',
            action: function () {
                var name = moment($scope.overviewCpi.date.startDate).format('DD-MM-YYYY') + ' - ' +
                    moment($scope.overviewCpi.date.endDate).format('DD-MM-YYYY');
                $scope.overviewCpi.export('Personeel ' + name, $scope.columns, $scope.overviewCpi.items);
            }
        },
        {
            tooltip: vxUtils.loc('REFRESH'),
            icon: 'fa fa-refresh',
            action: function () {
                $scope.getItems($scope.lastdate);
            }
        }
    ];
    if ($scope.tabitem.type === 'worknote') {
        $scope.headerTitle = vxUtils.loc('WORKNOTES');
        $scope.overviewCpi.fields = ['filled', 'filled'];
        $scope.overviewCpi.date = {startDate: moment().add( -29,'days'), endDate: moment()};
        $scope.columns = [
            {title: '#', field: 'idTimechart', visible: true, sortable: 'idTimechart'},

            {
                title: vxUtils.loc('FILLED'), field: 'filledIcon', visible: true, sortable: 'filledIcon',
                altfield: 'filled',
                showtitle: true,
                icon: true,
                hastooltip: false,
                hover: function (item, column) {
                    column.tooltipobj = item.filled ? vxUtils.loc('FILLED') : vxUtils.loc('NOTFILLED');
                },
                style: {'width': '10px'}
            },
            {title: vxUtils.loc('COLLEAGUE'), field: 'tabDescription', visible: true, sortable: 'tabDescription'},
            {title: vxUtils.loc('JOB_NUMBER'), field: 'jobnumber', visible: true, sortable: 'jobnumber'},
            {title: vxUtils.loc('JOB_TITLE'), field: 'jobtitle', visible: true},
            {title: vxUtils.loc('JOB_DATE'), field: 'startdate', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate},
            {
                title: vxUtils.loc('IN_RESPONSE'), field: 'createdWorknote',
                empty: vxUtils.loc('NOTFILLED'), visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate
            },
            {title: vxUtils.loc('ENTRANCE'), field: 'entrance', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortTime},
            {title: vxUtils.loc('DURATION'), value: $scope.getTime, visible: true},
            {title: vxUtils.loc('HOURS'), field: 'hours', visible: true, sortable: 'hours', type: 'number'},
            {title: vxUtils.loc('TRAVELHOURS'), field: 'traveltime', visible: true, sortable: 'traveltime', type: 'number'},
            {title: vxUtils.loc('TRAVELDISTANCE'), field: 'travelkm', visible: true, sortable: 'travelkm', type: 'number'},
            {
                title: vxUtils.loc('EXPENSES'),
                field: 'expenses',
                sortable: 'expenses',
                visible: true,
                filter: "currency",
                args: settings.currency, noFilterOnExport:true, type: 'number'
            }, {
                title: vxUtils.loc('PAYMENT_NET'),
                field: 'payout',
                sortable: 'payout',
                visible: true,
                filter: "currency",
                args: settings.currency, noFilterOnExport:true, type: 'number'
            }
            , {
                title: vxUtils.loc('REMARKS'), field: 'remarks', visible: true,
                style: {'max-width': '325px'}
            }];
        $scope.overviewCpi.filters = selectionFilters.worknoteTypes(true)

    } else {
        $scope.headerTitle = vxSession.auth(2) ? vxUtils.loc('EMPLOYEES') : vxUtils.loc('COLLEAGUES');
        $scope.overviewCpi.fields = ['groupname', 'level'];
        $scope.overviewCpi.date = {startDate: moment(), endDate: moment().add('days', 29)};
        selectionFilters.employeeGroups(function (groups) {
            $scope.overviewCpi.filters = groups;
        });
        $scope.columns = [
            {title: '#', field: 'idUser', visible: true, sortable: 'idUser'},
            {title: vxUtils.loc('NAME'), field: 'name', visible: true, sortable: 'name'},
            {title: vxUtils.loc('BIRTHDAY'), field: 'birthday', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate},
            {title: vxUtils.loc('EMAIL'), field: 'email', visible: true, link: true},
            {title: vxUtils.loc('TELEPHONE'), field: 'telephone1', visible: true},
            {title: vxUtils.loc('TELEPHONE2'), field: 'telephone2', visible: true}
        ];
        $scope.showSmall = true;
        if (vxSession.auth(2)) {
            $scope.showSmall = false;
            $scope.columns.push({
                    title: vxUtils.loc('DATEOFHIRE'),
                    field: 'hireddate',
                    visible: true,
                    filter: "date",
                    args: $locale.DATETIME_FORMATS.shortDate
                },
                {
                    title: vxUtils.loc('JOBS'),
                    initalSort: 'desc',
                    field: 'amountjobs',
                    visible: true,
                    sortable: 'amountjobs'
                },
                {title: vxUtils.loc('HOURS'), field: 'hours', visible: true, sortable: 'hours', type: 'number'},
                {title: vxUtils.loc('TRAVELHOURS'), field: 'traveltime', visible: true, sortable: 'traveltime'},
                {title: vxUtils.loc('TRAVELDISTANCE'), field: 'travelkm', visible: true, sortable: 'travelkm'},
                {
                    title: vxUtils.loc('EXPENSES'),
                    field: 'expenses',
                    sortable: 'expenses',
                    visible: true,
                    filter: "currency",
                    args: settings.currency
                }, {
                    title: vxUtils.loc('PAYMENT_NET'),
                    field: 'payout',
                    sortable: 'payout',
                    visible: true,
                    filter: "currency",
                    args: settings.currency
                }
            );
        }
    }
    msgBus.onMsg(msgBusActions.overviewReload, function (sender, mssg) {
        if (mssg === 'employee')
            $scope.getItems($scope.lastdate);
    }, $scope);

    $scope.getItems = function (date) {
        $scope.lastdate = date;
        if ($scope.tabitem.type === 'worknote') {

            $users.getWorkHoursBetween(function (results) {
                if (results.length === 0) {
                    //   toastr.info("Helaas!", "Ik heb geen werkbriefjes gevonden :(");
                }

                angular.forEach(results, function (v, k) {
                    if (v.remarks) {
                        var val = v.remarks;
                        v.remarks = vxUtils.strip_tags(val.replace(/(?:\\[rn]|[\r\n]+)+/g, " "));
                    }
                });

                $scope.overviewCpi.all = results;
                $scope.overviewCpi.selectionChanged(null);
            }, 0, date.startDate, date.endDate, false, false);
        }
        else {
            $users.getAllWithHoursBetween(function (results) {
                $scope.overviewCpi.all = results;
                $scope.overviewCpi.selectionChanged(null);
            }, date.startDate, date.endDate);
        }
    };

    // $scope.getItems();
    ////////OVERVIEW/////
});
