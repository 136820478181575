import app from "/src/app/app.module";
import Car from "../models/car";

app.controller('car_panel', function ($scope, vxUtils, vxSession, $api) {
    var vm = this;
    vm.listCpi = {};
    vm.job = vxUtils.findObjectOnParents($scope, 'job');

    vm.auth = function () {
        return parseInt(vxSession.userRole) > 1;
    };
    vm.items = [];

    vm.addCar = function () {

        $api.post($api.adress.plancar,
            function (data) {
                vm.init();
            }, {
                id_job: vm.job.jobid,
                id_car: vm.selected.id
            });


    };

    vm.init = function () {
        var id = 0;
        var type = '';
        vm.listCpi.items = [];

        var items = [];
        var getChanges = function (id, type) {
            $api.get($api.adress.cars,
                function (data) {
                    if (angular.isArray(data)) {
                        items = data.concat(items)
                    } else
                        items.push(data);

                    vm.items = items;
                }, Car);

            $api.get($api.adress.carsplanned + '/' + vm.job.jobid,
                function (data) {
                    var it = [];
                    if (angular.isArray(data)) {
                        it = data.concat(it)
                    } else
                        it.push(data);
                    vm.listCpi.items = it;
                }, Car);
        };

        if (vm.job !== -1) {
            id = vm.job.jobid;
            type = 'Job';
            getChanges(id, type);
        }

    };
    vm.init();

})
;