import app from "/src/app/app.module";

app.controller('taxes_settings',
    function ($scope, $timeout, msgBus, $api, $payment,
              $filter, $focus, sweet, vxUtils, toastr) {
        var vm = this;

        vm.subjectPlaceholder = vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.items = [];
        vm.selected = {};

        vm.add = function () {
            var m = {
                name: vm.title
            };

            vm.title = '';
            //vm.items.splice(0, 0, m);
            vm.selected = m;
            $focus('focusSubject');
            vm.save(true);
        };

        vm.delete = function () {
            sweet.show({
                title: vxUtils.loc('CONFIRMDELETION'),
                text: vxUtils.loc('DELETETAX'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: vxUtils.loc('YES'),
                closeOnConfirm: true
            }, function () {

                $payment.deleteTax(vm.selected.id, function () {
                    var i = $filter('removeWith')(vm.items, {id: parseInt(vm.selected.id)});
                    // vm.items.splice(i, 1);
                    vm.items = i;
                    $payment.getTaxes(null,true);
                    vm.selected = vm.items[vm.items.length - 1];
                });
            });


        };

        vm.save = function (isNew) {
            $payment.postTax(function (tax) {

                if (isNew) {
                    vm.items.splice(vm.items.length, 0, tax);
                }
                else {
                    const i = vm.items.findIndex(value => value.id === vm.selected.id);
                    vm.items[i] = tax;
                }

                vm.selected = tax;

                $payment.getTaxes(null,true);
                toastr.success(vxUtils.loc('SUCCES'),vxUtils.loc('CHANGESUCCESFULL'));
            }, vm.selected);
        };

        vm.getItems = function () {
            $payment.getTaxes(function (result) {
                vm.items = result;

                if (vm.items.length > 0)
                    vm.selected = vm.items[0];
            });

        };

        vm.getItems();
    })
;