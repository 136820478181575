import app from "/src/app/app.module";


app.controller('employee_details', function ($scope, vxUtils, msgBus,
                                             toastr, $timeout, $users,
                                             selectionFilters, tabService) {

    var tab = tabService.getCurrentTab();
    var id = tab.id;
    $scope.showPersonal = tab.type !== 'home';
    $scope.showRest = tab.type !== 'settings';

    $scope.PageHash = vxUtils.generateQuickGuid();
    $scope.userID = {};
    $scope.userCpi = {};
    $scope.savetoserver = true;
    $scope.actionBarCpi = {};
    $scope.locationCpi = {};

    $scope.auth = $scope.session.auth(2);

    $scope.actionBarCpi.pageHash = $scope.PageHash;

    $scope.init = function (id, reload) {

        $scope.userCpi.selectedFile = null;

        $scope.userCpi.uploadPicture = function() {
            const file = document.getElementById('fileInput').files[0];
            if (file) {
                const userId = $scope.user.idUser; // Replace with actual user ID
                $users.uploadUserPicture(userId, file, function(response) {
                    toastr.success(vxUtils.loc('PROFILE_PICTURE_CHANGED'), vxUtils.loc('SUCCES'));
                    $scope.user.userPictureUrl = $scope.user.UpdateProfilePicture();
                }, function(error) {
                    toastr.error(vxUtils.loc('PROFILE_PICTURE_NOT_CHANGED'), vxUtils.loc('ERROR'));
                });
            }
        };

        $scope.userCpi.triggerFileInput = function() {
            $timeout(function() {
                document.getElementById('fileInput').click();
            }, 0);
        };
        $users.getGroups(function (groups) {
            $scope.userCpi.usergroups = groups;
        });
        $users.getRoles(function (roles) {
            $scope.userCpi.userroles = roles;
        });
        $users.getUser(function (data) {
            var user = data[0];
            $scope.user = user;
            $scope.userCpi.user = user;
            $scope.locationCpi.adress = user;
            document.title = user.tabID + ': ' + user.tabDescription;
            tab.tabname = user.tabID + ': ' + user.tabDescription;

        }, id);

        if (reload) {
            var checkIfMoment = function (date) {
                var tsd = date.startDate;
                var ted = date.endDate;
                var s = typeof tsd.unix !== 'function' ? tsd.getTime() / 1000.0 : tsd.unix();
                var e = typeof ted.unix !== 'function' ? ted.getTime() / 1000.0 : ted.unix();
                var unixT = {
                    startDate: s,
                    endDate: e
                };
                return unixT;
            };

            $scope.timeLineCpi.dateChanged(checkIfMoment($scope.timeLineCpi.date));
            $scope.worknotesCpi.dateChanged(checkIfMoment($scope.worknotesCpi.date));
            return;
        }

        $scope.timeLineCpi = {
            fields: [{
                field: 'userjobcrewstatus', expression: function (value) {
                    return {
                        userjobcrewstatus: value.state
                    };
                }
            },
                {
                    field: 'type', expression: function (value) {
                    return {
                        type: value.state
                    };
                }
                }],
            filters: selectionFilters.employeeEventTypes(!(tab.type === 'home')),
            date: {
                endDate: moment().add('days', 14),
                startDate: moment().add('days', -14)
            },
            dateChanged: function (newDate) {
                if (tab.type === 'home') {
                    $users.getAllJobcrew(function (results) {

                        angular.forEach(results, function (value, key) {
                            value.accept = true;
                        });

                        $scope.timeLineCpi.all = results;
                        $scope.timeLineCpi.refresh();
                    }, id);
                }
                else {
                    $users.getUserEvents(function (results) {
                            $scope.timeLineCpi.all = results;
                            $scope.timeLineCpi.refresh();
                        }, id, newDate.startDate,
                        newDate.endDate);
                }
            }
        };
        $scope.worknotesCpi = {
            filters: selectionFilters.worknoteTypes(!(tab.type === 'home')),
            date: {
                endDate: moment().add('days', 1),
                startDate: moment().add('days', -30)

            },
            dateChanged: function (newDate) {
                $scope.lastDate = newDate;
                $users.getWorkHoursBetween(function (results) {
                        if (results.length === 0) {
                            toastr.info(vxUtils.loc('UNFORTUNATLY'),
                                vxUtils.loc('NO_WORKNOTE_FOUND'));
                        }
                        $scope.worknotesCpi.all = results;
                        $scope.worknotesCpi.refresh();
                    }, id, newDate.startDate,
                    newDate.endDate, false, false);
            }, userID: id
        };
        var random = false ? '' : '?i=' + Math.random();
        $scope.empExpPanelCpi = {
            panels: [
// { label: {{'DELETE'|translate}}, href: "", icon: "fa fa-trash-o fa-2x", guid: vxUtils.generateQuickGuid(), toggle: false },
                {
                    label: vxUtils.loc('JOBS_AND_AVAILABILITY'), width: 800,
                    href: "/src/pages/calender/calender.html",
                    icon: "fa fa-calender", toggle: true,
                    guid: vxUtils.generateQuickGuid(),
                    calendarType: {
                        item: 'employee',
                        id: id
                    }
                },
                // {
                //     label: 'Beschikbaarheid toevoegen', width: 350,
                //     href: "/src/panel/availability-panel.html",
                //     icon: "fa fa-calender", toggle: true,
                //     guid: vxUtils.generateQuickGuid(),
                //     overflow: true,
                //     availPanelBag: {
                //         id: id
                //     }
                // }
            ]
        };
    };


    $scope.scrollMethod = function ($event, $delta, $deltaX, $deltaY) {
        vxUtils.scrollHorzontal($event, $delta, $deltaX, $deltaY, $scope.PageHash);
    };

    ///////////actionbar/////////////
    $scope.actionitems = [
        {label: vxUtils.loc('DELETE'), href: "/src/panel/delete-dialog.html", icon: "fa fa-trash-o fa-2x"},
        {label: vxUtils.loc('SEND_EMAIL'), href: "/src/panel/mail-dialog.html", icon: "fa fa-envelope fa-2x"},
        {label:vxUtils.loc('HOURRATE'), href: "/src/panel/emp-hour-rate-panel.html", icon: "fa fa-eur fa-2x"},
        {
            label:  vxUtils.loc('REFRESH'), click: function () {
            $scope.init(id, true);
            toastr.info(vxUtils.loc('REFRESHING'), vxUtils.loc('BUSY'));
        }, href: "", icon: "fa fa-refresh fa-2x"
        }
    ];
    $scope.init(id);

    var timer = $timeout(function () {
        $scope.empExpPanelCpi.closeAll();
        msgBus.emitMsg("UpdateScroll", {});
    }, 550);

    $scope.$on(
        "$destroy",
        function (event) {

            $timeout.cancel(timer);

        }
    );
});