import app from "/src/app/app.module";
import EmailResource from "../../models/email-resource";


app.controller('upload_word_templates',
    function ($scope, $templates, $timeout, msgBus, $upload, $filter, $focus, settings, toastr, $locale) {
        var vm = this;

        vm.locale = $locale;
        vm.isHtml = $scope.panel.isHtml;
        vm.subjectPlaceholder = vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.messages = [];
        vm.selectedMessage = {content: ''};

        vm.addMessage = function () {
            var m = new EmailResource();
            m.label = vm.title;
            vm.title = '';

            vm.messages.splice(0, 0, m);
            vm.selectedMessage = m;
            $focus('focusSubject');

        };

        vm.download = function () {
            var ev = vm.selectedMessage;
            $templates.openTemplate(ev.up_guid, ev.up_name, ev.up_type,
                function (result) {

                });
        };

        vm.deleteMessage = function () {
            var i = vm.messages.indexOf(vm.selectedMessage);
            vm.messages.splice(i, 1);

            if (vm.selectedMessage.id_email_resource !== 0)
                $templates.delete(function (i) {
                    vm.selectedMessage = {};
                }, vm.selectedMessage.id_email_resource);
        };

        vm.saveMessage = function () {
            if (vm.files) {
                vm.upload(vm.files, function (data, status, headers, config) {
                    vm.selectedMessage.type = 'word';
                    vm.selectedMessage.res_upload_id = parseInt(data);
                    $templates.save(function (i) {
                        toastr.success($filter('translate')('UPLOADSUCCESFULL'), $filter('translate')('SUCCES'))
                        vm.getMessages();
                    }, vm.selectedMessage);
                });
            }else{
                $templates.save(function (i) {
                    toastr.success($filter('translate')('UPLOADSUCCESFULL'), $filter('translate')('SUCCES'));
                    vm.getMessages();
                }, vm.selectedMessage);
            }

        };

        vm.testPdf = function () {
            $templates.openAsPdf(function (i) {
                console.log(i);
            }, vm.selectedMessage);

        };

        vm.getMessages = function () {
            $templates.getWordtemplates(function (result) {
                vm.messages = result;
                if (vm.messages.length > 0)
                    vm.selectedMessage = vm.messages[0];
            });

        };
        //   $scope.$watch('vm.files', function () {
        //       vm.upload(vm.files);
        //    });
        vm.upload = function (files, succes) {
            if (files && files.length) {
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    $upload.upload({
                        url: settings.BASE_API_URL + 'v1/upload',
                        headers: {'Content-Type': file.type},
                        method: 'POST',
                        data: file,
                        file: file
                    }).progress(function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
                    }).then(function (response) {
                        if (response.status === 200)
                            succes(response.data, response.status, response.headers, response.config);

                        console.log('file ' + response.config.file.name + 'uploaded. Response: ' + response.data);
                    });
                }
            }
        };

        vm.getMessages();
    });