import app from "/src/app/app.module";
import moment from "moment";


app.controller('jobsctrl', function ($scope, $job, $filter, msgBus, selectionFilters, tabService,
                                     msgBusActions, vxUtils, settings, $locale, $auth) {
    ////////OVERVIEW/////
    var tab = tabService.getCurrentTab();
    $scope.tab = tab;
    $scope.suspended = false;
    $auth.getAllCompanies(result => $scope.companies = result);

    // msgBus.onMsg(msgBusActions.suspend + tab.hash, function (sender, mssg) {
    //     mssg = mssg === 'true';
    //     console.log($scope.tab);
    //     if ($scope.tab.suspended === mssg && typeof mssg != 'boolean')
    //         return;
    //
    //     $scope.suspended = mssg.toString();
    //
    // }, $scope);

    $scope.overviewCpi = {
        openItemPage: "/src/pages/jobs/job-details.html",
        openItemIcon: "fa fa-map-marker",
        type: 'job',
        tabItems: $scope.openItems,
        filters: selectionFilters.jobStates,
        date: {startDate: moment(), endDate: moment().add('days', 29)},
        searchChanged: function (text) {
            $scope.overviewCpi.items = $filter('filter')($scope.overviewCpi.all, {$: text});
            $scope.overviewCpi.reload();
        },
        dateChanged: function (newDate) {
            $scope.lastdate = newDate;
            $scope.getItems(newDate);
        },
        selectionChanged: function (data) {

            var fields = ['status',
                'crewstatus', 'tendant'];

            $scope.overviewCpi.items = selectionFilters.filterBySelection(
                $scope.overviewCpi.filters,
                $scope.overviewCpi.all,
                fields
            );
            $scope.overviewCpi.reload();


        }
    };


    $scope.headerButtons = [
        {
            tooltip: vxUtils.loc('EXPORT_TO_EXCEL'),
            icon: 'fa fa-file-excel-o',
            action: function () {
                var name = moment($scope.overviewCpi.date.startDate).format('DD-MM-YYYY') + ' - ' +
                    moment($scope.overviewCpi.date.endDate).format('DD-MM-YYYY');
                $scope.overviewCpi.export('Jobs ' + name, $scope.columns, $scope.overviewCpi.items);
            }
        },
        {
            tooltip:  vxUtils.loc('REFRESH'),
            icon: 'fa fa-refresh',
            action: function () {
                $scope.getItems($scope.lastdate);
            }
        }
    ];

    $scope.getTime = function (item) {
        return $filter('date')(item.startdate, $locale.DATETIME_FORMATS.shortTime) + " - " + $filter('date')(item.enddate, $locale.DATETIME_FORMATS.shortTime);
    };

    $scope.columns = [
        {
            title: '',
            name: vxUtils.loc("COMPANY"),
            altfield: 'tendant',
            field: 'tendant',
            backgroundField: 'tendantColor',
            visible: true,
            icon: true,
            hastooltip: true,
            hover: function (item, column) {
                const found = $scope.companies.find(comp=>item.tendant === comp.idTendant);
                if(!found)
                    console.warn(`no company found for ${item.tendant}`)
                column.tooltipobj = found.name;
            },
            //style: {'width': '10px'}
        }, {
            title: '',
            name: vxUtils.loc("REMARKS"),
            altfield: 'remarks',
            field: 'infoIcon',
            visible: true,
            icon: true,
            hastooltip: true,
            hover: function (item, column) {
                var val = '';
                if (item.remarks) {
                    column.tooltipobj = item.remarks;
                    column.tooltipopen = true;
                }


                // column.hover(item,column);

            },
            style: {'width': '10px'}
        },
        {title: '#', field: 'jobnumber', visible: true, sortable: 'jobnumber'},
        {
            title: '',
            name: vxUtils.loc('KIND_OF_CUSTOMER'),
            altfield: 'customertype',
            field: 'customericon',
            visible: true,
            icon: true,
            style: {'width': '10px'}
        },
        {
            title: vxUtils.loc('STATE'),
            altfield: 'status',
            showtitle: false,
            field: 'statusicon',
            visible: true,
            icon: true,
            hover: function (item, column) {
                var val = '';
                if (item.status) {
                    column.tooltipobj = item.status;
                    column.tooltipopen = true;
                }
            },
            hastooltip: true,
            style: {'width': '10px'}
        },
        {
            title: '', name: vxUtils.loc('PRODUCTS'),
            altfield: 'hasproducts',
            field: 'hasproductsicon',
            hover: function (item, column) {
                var val = '';
                if (item.hasproducts) {
                    column.tooltipobj = item.hasproducts === 'Ja' ? vxUtils.loc('HAS_PRODUCTS') : vxUtils.loc('HAS_NO_PRODUCTS');
                    column.tooltipopen = true;
                }
            },
            hastooltip: true,
            visible: true,
            icon: true,
            style: {'width': '10px'}
        },
        {
            title: '', name: vxUtils.loc('CAR'),
            altfield: 'hascars',
            field: 'hascarsicon',
            visible: true,
            icon: true,
            hastooltip: true,
            hover: function (item, column) {
                var val = '';
                if (item.hascars) {
                    column.tooltipobj = item.hascars;
                    column.tooltipopen = true;
                } else {
                    column.tooltipobj = vxUtils.loc('NO_CAR_PLANNED');
                    column.tooltipopen = true;
                }


                // column.hover(item,column);

            },
            style: {'width': '10px'}
        },
        {
            title: '',
            name: vxUtils.loc('PAYMENTSTATE'),
            hover: function (item, column) {
                var val = '';
                if (item.paymenttype) {
                    column.tooltipobj = $filter('paymentType')(item.paymenttype);
                    column.tooltipopen = true;
                } else {
                    column.tooltipobj = val;

                }
            },
            altfield: 'paymenttype',
            altfilter: 'paymentType',
            field: 'payementtypeicon',
            hastooltip: true,
            visible: true, icon: true, style: {'width': '10px'}
        }, {
            title: '',
            name: vxUtils.loc('PAYMENT_TYPE'),
            field: 'ispayedicon',
            hover: function (item, column) {
                var val = '';
                if (item.paymentstate) {
                    column.tooltipobj = $filter('invoiceType')(item.paymentstate);
                    column.tooltipopen = true;
                } else {
                    column.tooltipobj = vxUtils.loc('PAYMENT_UNKOWN');
                    column.tooltipopen = true;
                }
            },
            hastooltip: true,
            altfield: 'paymentstate',
            altfilter: 'invoiceType',
            visible: true, icon: true, style: {'width': '10px'}
        }, {
            title: '',
            name: vxUtils.loc('EMAIL_CONFIRMATION'),
            field: 'isconfirmedicon',
            hastooltip: true,
            hover: function (item, column) {
                var val = '';
                if (item.mail) {
                    var time = item.mail.vox_mail_logging_date_read ? item.mail.vox_mail_logging_date_read : item.mail.vox_mail_date_send;
                    var prefix = item.mail.vox_mail_logging_date_read ? vxUtils.loc('ISREAD')+' ' : vxUtils.loc('SENDDATE')+' ';
                    val = moment(parseInt(time + "000")).format('DD-MM-YYYY');
                } else {
                    val =  vxUtils.loc('NO_REMINDER_SEND');
                }
                if (val === undefined)
                    val = '';
                if (prefix === undefined)
                    prefix = '';
                column.tooltipobj = prefix + val;
                column.tooltipopen = true;
                // column.hover(item,column);

            },
            altfield: 'isconfirmedicon',
            altfilter: 'emailStatus',
            visible: true, icon: true, style: {'width': '10px'}
        },
        {
            title: vxUtils.loc('DATE'),
            field: 'startdate',
            sortable: 'startdate',
            visible: true,
            filter: "date",
            args: $locale.DATETIME_FORMATS.shortDate,
            initalSort: 'asc'
        },
        {title: vxUtils.loc('JOBTITLE'), field: 'jobtitle', visible: true},
        {title: vxUtils.loc('ENTRANCE'), field: 'entrance', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortTime},
        {title: vxUtils.loc('DURATION'), value: $scope.getTime, visible: true},
        //{ title: 'Eindtijd', field: 'enddate', visible: true, filter: "date", args: "HH:mm" },
        {title: vxUtils.loc('CUSTOMER_NAME'), field: 'clientname', visible: true},
        {title: vxUtils.loc('CITY'), field: 'city', sortable: 'city', visible: true},
        {title: vxUtils.loc('COUNTRY'), field: 'country', sortable: 'country', visible: true},
        {title: vxUtils.loc('AMOUNT'), field: 'amount', visible: true, filter: "currency", args: settings.currency},
        {
            title: vxUtils.loc('DUEDATE'),
            field: 'duedate',
            sortable: 'duedate',
            visible: true,
            filter: "date",
            args: $locale.DATETIME_FORMATS.shortDate
        },
        {
            title: '', name: vxUtils.loc('GOES'), field: 'crewaccepted',
            visible: true,
            iconlabel: 'fa fa-check-circle green',
            css: ['icon-label', 'hover-hand'],
            hastooltip: true,
            hover: function (row, column) {
                $scope.crewTooltip(row, column, 'accepted');
            },
            altfield: 'crew',
            altfilter: 'crewNames',
            args: 'accepted'
        },
        {
            title: '', name: vxUtils.loc('NO_REACTION'),
            field: 'crewwaiting',
            visible: true,
            iconlabel: 'fa fa fa-clock-o vx-light-gray',
            css: ['icon-label', 'hover-hand'],
            hastooltip: true,
            hover: function (row, column) {
                $scope.crewTooltip(row, column, 'awaiting');
            },
            altfield: 'crew',
            altfilter: 'crewNames',
            args: 'awaiting'
        },
        {
            title: '',
            name: vxUtils.loc('WONT_GO'),
            field: 'crewdeclined',
            visible: true,
            iconlabel: 'fa fa-times-circle red',
            css: ['icon-label', 'hover-hand'],
            hastooltip: true,
            hover: function (row, column) {
                column.tooltipopen = true;
                $scope.crewTooltip(row, column, 'declined');
            },
            altfield: 'crew',
            altfilter: 'crewNames',
            args: 'declined'
        },
        {title: '', name: vxUtils.loc('NEEDED'), field: 'crewslots', visible: true, iconlabel: 'fa fa-group', css: 'icon-label'}


    ]
    ;

    $scope.crewTooltip = function (row, column, type) {
        if (!column.hastooltip)
            return;
        column.tooltipobj = vxUtils.loc('SEARCHING');
        var fill = function () {
            var names = [];
            if (row.crew[type].length === 0) {
                column.tooltipobj = vxUtils.loc('NOBODY_FOUND');
            }
            angular.forEach(row.crew[type], function (value, key) {
                names.push(value.name);
            });
            column.tooltipobj = names.join();

        };

        if (row.crew !== undefined) {
            fill();
        }
        //else {
        //    $job.getJobCrew(function (result) {
        //        row.crew = result;
        //        fill();
        //    }, row.jobid);
        //}
    };

    $scope.getItems = function (date) {
        $scope.lastdate = date;
        $job.getJobsBetween(function (results) {
            $scope.overviewCpi.all = results;
            $scope.overviewCpi.selectionChanged(null);
            var ids = $filter('map')($scope.overviewCpi.all, 'jobid');
            $job.getEmailConfirmations(function (data) {
                for (var i = 0; i < $scope.overviewCpi.all.length; i++) {
                    var item = $scope.overviewCpi.all[i];
                    var mail = $filter('filter')(data, {
                        vox_mail_referenceID: item.jobid.toString()
                    })[0];
                    if (mail === undefined)
                        continue;

                    item.isread = mail.vox_mail_logging_date_read;
                    item.mail = mail;
                    item.isconfirmed = mail ? true : false;
                    item.isconfirmedicon = item.isConfirmedIcon();
                }

            }, ids);
            $job.getJobCrew(function (result) {
                for (var i = 0; i < $scope.overviewCpi.all.length; i++) {
                    var item = $scope.overviewCpi.all[i];
                    var crew = $filter('filter')(result, {
                        jobId: item.jobid
                    })[0];
                    if (crew === undefined)
                        continue;

                    item.crew = crew;
                }
            }, ids);
        }, date.startDate, date.endDate);
    };

    msgBus.onMsg(msgBusActions.overviewReload, function (sender, mssg) {
        if (mssg === 'job')
            $scope.getItems($scope.lastdate);
    }, $scope);

//$scope.getItems(dateInit);

////////OVERVIEW/////

})
;