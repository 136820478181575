import app from "/src/app/app.module";
import Order from "../models/order";
import OrderStatus from "../models/order-status";
import Event from "../models/event";

let api = function (settings, $api) {

    const baseUrl = 'v1/orders';

    const createOrder = function (orderDetails, callback) {
        // Assuming orderDetails contains all necessary data to create a new order
        $api.post(`${baseUrl}`, callback, orderDetails);
    };

    const getOrder = function (id, callback, errorCallback) {
        $api.get(`${baseUrl}/${id}`, callback, Order, errorCallback);
    };
    const getAllStatuses = function (callback, errorCallback) {
        $api.get(`${baseUrl}/statuses`, callback, OrderStatus, errorCallback);
    };

    const updateOrder = function (id, updatedDetails, callback, errorCallback) {
        // Assuming updatedDetails contains all the data to be updated for an existing order
        $api.post(`${baseUrl}/${id}`, callback, updatedDetails, Order, errorCallback);
    };

    const deleteOrder = function (id, successCallback, errorCallback) {
        $api.delete(`${baseUrl}/${id}`, successCallback, Order, errorCallback);
    };

    const getAllOrders = function (startDate, endDate, callback, errorCallback) {
        // Assuming the API supports filtering by date range
        $api.get(`${baseUrl}?start=${startDate}&end=${endDate}`, callback, Order, errorCallback, false);
    };

    const getEvents = function (id, callback, errorCallback) {
        // Assuming the API supports filtering by date range
        $api.get(`${baseUrl}/events/${id}`, callback, Event, errorCallback, false);
    };

    const postLocation = function (location, callback) {
        $api.post(`${baseUrl}/location`, callback, location);
    };

    return {
        createOrder: createOrder,
        getOrder: getOrder,
        getAllOrders: getAllOrders,
        updateOrder: updateOrder,
        deleteOrder: deleteOrder,
        getAllStatuses: getAllStatuses,
        getEvents:getEvents,
        postLocation:postLocation
    };
};

app.factory("$order", api);
