import app from "/src/app/app.module";
import EmailResource from "../models/email-resource";
import {saveAs} from "../assets/js/FileSaver";

var api = function (settings, $http, vox) {

    var convertToObj = function (values) {
        var temp = [];
        angular.forEach(values, function (value, key) {
            var obj = new EmailResource(value);
            angular.extend(obj, value);
            this.push(obj);
        }, temp);
        return temp;
    };

    var getMessages = function (callback) {
        vox.get(settings.BASE_API_URL + 'v1/templates/messages',function (data, status, headers, config) {
            var temp = convertToObj(data);

            callback(temp);
        })
    };

    var getHTMLtemplates = function (callback) {
        vox.get(settings.BASE_API_URL + 'v1/templates/html', function (data, status, headers, config) {
            var temp = convertToObj(data);

            callback(temp);
        });
    };
    var getNotifytemplates = function (callback) {
        vox.get(settings.BASE_API_URL + 'v1/templates/notify', function (data, status, headers, config) {
            var temp = convertToObj(data);
            callback(temp);
        });
    };

    var getWordtemplates = function (callback) {
        vox.get(settings.BASE_API_URL + 'v1/templates/word', function (data, status, headers, config) {
            var temp = convertToObj(data);

            callback(temp);
        });
    };

    var getResource = function (callback, $id) {
        vox.get(settings.BASE_API_URL + 'v1/templates/resource/' + $id, function (data, status, headers, config) {
            var temp = convertToObj(data);
            callback(temp);
        });
    };

    var postResource = function (callback, $resource) {
        vox.post(settings.BASE_API_URL + 'v1/templates',
            $resource, function (data, status, headers, config) {
                var temp = convertToObj(data);
                callback(temp);
            });
    };

    var postResources = function (callback, $resource) {
        vox.post(settings.BASE_API_URL + 'v1/templates/batch',
            $resource, callback);

    };

    function openSaveAsDialog(filename, content, mediaType) {
        var blob = new Blob([content], {type: mediaType});
        saveAs(blob, filename);
    }

    var deleteResource = function (callback, $id) {
        vox.delete(settings.BASE_API_URL + 'v1/templates/' + $id, callback)
    };
    var openTemplate = function (id, filename, mime, callback) {
        vox.custom("get", settings.BASE_API_URL + 'v1/templates/' + id, 'arraybuffer',
            function (result) {
                openSaveAsDialog(filename, result, mime);
            });
    };
    var openAsPdf = function (callback, emailResource) {
        $http.post(settings.BASE_API_URL + 'v1/templates/createPdf',
            emailResource, {responseType: 'arraybuffer'}).then(function (response) {
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch(function (data, status, headers, config) {

        });
    };

    return {
        openTemplate: openTemplate,
        save: postResource,
        saveBatch: postResources,
        delete: deleteResource,
        getMessages: getMessages,
        getResource: getResource,
        getHTMLtemplates: getHTMLtemplates,
        openAsPdf: openAsPdf,
        getWordtemplates: getWordtemplates,
        getNotifytemplates: getNotifytemplates
    };
}

app.factory("$templates", api);