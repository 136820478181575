import app from "/src/app/app.module";
import EmailResource from "../models/email-resource";
import {saveAs} from "../assets/js/FileSaver";

var api = function (settings, $http, toastr, vox) {

    var convertToObj = function (values) {
        var temp = [];
        angular.forEach(values, function (value, key) {
            var obj = new EmailResource();
            angular.extend(obj, value);
            this.push(obj);
        }, temp);
        return temp;
    };

    var getMessage = function (callback, $id) {
        $http.get(settings.BASE_API_URL + 'v1/templates/messages/' + $id)
            .then(function (data, status, headers, config) {
                var temp = convertToObj(data);
                callback(temp);
            }).catch(function (data, status, headers, config) {

            });
    };

    var postOutlook = function (callback, emailresource) {
        var j = angular.toJson(emailresource, false);
        $http.post(settings.VOXIL_CONNECT_API_URL + 'outlook/send',
            j).then(function (data, status, headers, config) {
                callback(data);
            }).catch(function (data, status, headers, config) {
                VoxilConnectNotFound();
            });
    };

    var VoxilConnectNotFound = function () {
        toastr.error("Controleer of Voxil Connect is gestart.", 'Kan geen verbinding maken');
    };

    function openSaveAsDialog(filename, content, mediaType) {
        var blob = new Blob([content], {type: mediaType});
        saveAs(blob, filename);
    }

    var createWord = function (callback, templateid, type, objectid) {
        vox.custom("get", settings.BASE_API_URL + 'v1/word/' + templateid + '/' + type + '/' + objectid,
            'arraybuffer',
            function (data, status, headers, config) {
                var name = headers('x-filename');
                openSaveAsDialog(name, data, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
            });
    };

    return {
        openOutlook: postOutlook,
        createWord: createWord
    };
};

app.factory("$voxilConnect", api);