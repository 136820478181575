import app from "/src/app/app.module";
import {saveAs} from "../assets/js/FileSaver";

var api = function (settings, vox) {

    var sendEvent = function (event, item, callback) {
        vox.post(settings.BASE_API_URL + 'v1/mail/send/type/' + event, item, callback);
    };

    function openSaveAsDialog(filename, content, mediaType) {
        var blob = new Blob([content], {type: mediaType});
        saveAs(blob, filename);
    }

    var getAttachment = function (id, filename, mime, callback) {
        vox.custom("get", settings.BASE_API_URL + 'v1/mail/attachment/' + id, 'arraybuffer',
            function (result) {
                openSaveAsDialog(filename, result, mime);
            });
    };

    var deleteEmail = function (id, succes, err) {
        vox.delete(settings.BASE_API_URL + 'v1/mail/delete/' + id,
            succes, err);
    };

    return {
        sendEvent: sendEvent,
        getAttachment: getAttachment,
        deleteEmail: deleteEmail
    };
}

app.factory("$email", api);