import app from "/src/app/app.module";


app.controller('job_kind',
    function ($scope, $timeout, msgBus, $api, $job,
              $filter, $focus, sweet, vxUtils, toastr) {
        var vm = this;

        vm.items = [];
        vm.selected = {type: '', usedBy: 0};
        vm.replaceKind = null;

        vm.add = function () {
            var m = {
                type: vm.newJobKind
            };

            vm.newJobKind = '';
            //vm.items.splice(0, 0, m);
            vm.selected = m;
            $focus('focusSubject');
            vm.save(true);
        };

        vm.delete = function () {
            if(vm.replaceKind === null && vm.selected.usedBy > 0)
            {
                sweet.show({
                    title: vxUtils.loc('NOT_SELECTED'),
                    text: vxUtils.loc('SELECT_A_JOB_KIND_FOR_REPLACEMENT'),
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: vxUtils.loc('OK'),
                    closeOnConfirm: true
                })

            } else {

                sweet.show({
                    title: vxUtils.loc('CONFIRMDELETION'),
                    text: vm.selected.usedBy > 0? vxUtils.loc('REMOVE_PARTY_KIND_REPLACE', {jobKind: vm.replaceKind.type}):vxUtils.loc('REMOVE_PARTY_KIND'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: vxUtils.loc('YES'),
                    closeOnConfirm: true
                }, function () {
                    $api.post($api.adress.kindofparties + '/' + vm.selected.id_partykind, function () {
                        var i = $filter('removeWith')(vm.items, {id_partykind: vm.replaceKind && vm.selected.usedBy > 0
                                ? vm.replaceKind.id_partykind
                                : vm.selected.id_partykind}).sort(function (a, b){
                                    return a.usedBy > b.usedBy;
                        });

                        // vm.items.splice(i, 1);
                        vm.items = i;
                        vm.selected = vm.items[0];
                        vm.replaceKind = null;
                    },vm.replaceKind, function (result, status) {
                        sweet.show({
                            title: vxUtils.loc('ERROR'),
                            text: vxUtils.loc('NO_ALLOWED_JOBKIND_REMOVAL'),
                            type: 'error',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: vxUtils.loc('YES'),
                            closeOnConfirm: true
                        })
                    });
                });

            }
        };

        vm.save = function (isNew) {
            $api.post($api.adress.kindofparties, function (result) {

                vm.getItems();


                toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('JOB_KIND_SAVED'));
            }, vm.selected);
        };

        vm.getItems = function () {
            $api.get($api.adress.kindofparties_counted, function (result) {
                vm.items = result.map(function(item){
                    item.usedBy = parseInt(item.usedBy);
                    return item;
                });

                if (vm.items.length > 0)
                    vm.selected = vm.items[0];
            });

        };

        vm.getItems();
    })
;