import app from "/src/app/app.module";
import Change from "../models/change";

app.controller('changes_panel', function ($scope, vxUtils, vxSession, $api) {
    var vm = this;
    vm.listCpi = {};
    vm.job = vxUtils.findObjectOnParents($scope, 'job');
    vm.cust = vxUtils.findObjectOnParents($scope, 'cust');
    vm.employee = vxUtils.findObjectOnParents($scope, 'user');
    vm.location = vxUtils.findObjectOnParents($scope, 'locationCpi');

    vm.auth = function () {
        return parseInt(vxSession.userRole) > 1;
    };


    vm.init = function () {
        var id = 0;
        var type = '';
        vm.listCpi.items = [];
        var items = [];
        var getChanges = function (id, type) {
            $api.get($api.adress.changes + '/' + id + "/" + type,
                function (data) {
                    if (angular.isArray(data)) {
                        items = data.concat(items)
                    } else
                        items.push(data);

                    vm.listCpi.items = items;
                }, Change);
        };

        if (vm.job !== -1) {
            id = vm.job.jobid;
            type = 'Job';
            getChanges(id, type);
        }
        if (vm.employee !== -1) {
            id = vm.employee.idUser;
            type = "User";
            getChanges(id, type);
        }
        if (vm.cust !== -1) {
            id = vm.cust.idClient;
            type = "Customer";
            getChanges(id, type);
        }
        if (vm.location !== -1) {
            id = vm.location.adress.idLocation;
            type = "Location";
            getChanges(id, type);
        }


    };
    vm.init();

})
;