import app from "/src/app/app.module";

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('nl-nl', {
        JUSTSTARTED: 'Pas begonnen',
        INTERMEDIATE: 'Junior',
        ADVANCED: 'Gevorderd',
        SENIOR: 'Senior',
        EXPERT: 'Expert',
        STARTDATEEMPLOYMENT: 'Data voor jubileum',
        BIRTHDAY: 'Geboortedatum',
        DATEOFHIRE: 'Indiensttreding',
        EXPERIENCE: 'Ervaring',
        USERLEVEL: 'Gebruikersrol',
        USERGROUP: 'Gebruikersgroep',
        USER_GROUPS: 'Gebruikersgroep',
        ACCEPTED: 'Geaccepteerd',
        DECLINED: 'Geweigerd',
        AWAITING: 'Afwachting',
        EMPLOYEES: 'Werknemers',
        EMPLOYEE: 'Werknemer',
        WORKNOTES_AND_PAYMENT: 'Stats en uitbetaling',
        FILLED: 'Ingevuld',
        NOTFILLED: 'Niet ingevuld',
        PAYMENT: 'Betaling',
        WORKNOTES: 'Werkbriefjes',
        WORKHOURS: 'Gewerkte uren',
        TRAVELHOURS: 'Reistijd',
        HOUR: 'uur',
        HOURS: 'uren',
        EXPENSES: 'Onkosten',
        TRAVELDISTANCE: 'Reisafstand',
        AVAILABILITY: 'Beschikbaarheid,',
        PLAN_AVAILABILITY: 'Plan beschikbaarheid',
        NEW_WORKNOTE: 'Nieuw werkbriefje',
        REMARKS: 'Opmerkingen',
        SUCCEFULL_NEW_USER: 'Succesvol een nieuwe gebruiker aangemaakt',
        HASCAR: 'In bezit van auto',
        HASLICENSE: 'In bezit van rijbewijs',
        NOLICENSE: 'Geen rijbewijs',
        NOCAR: 'Geen auto',
        PERIODEND: 'Loopt door tot',
        PLAN: "Inplannen",
        ACCEPT: 'Accepteren',
        DECLINE: 'Weigeren',
        HOURTYPES: 'Soort uren',
        HOURRATE: 'Uurloon',
        SUCCEFULL_PASS_RESET: 'Succesvol e-mail met wachtwoord-herstel verstuurd naar: $email'
        , ADDFIRSTHOURTYPE: "Open de werknemer en voeg een uurloon toe."
        , COLLEAGUES: 'Collega\'s'
        , COLLEAGUE: 'Collega'
        , PERSONAL_DATA: "Persoonsgegevens",
        UNSCHEDULE: 'Uitplannen',
        SCHEDULE: 'Inplannen',
        RECENT_PLANNED: 'Zojuist gepland',
        RECENT_UNPLANNED: 'Zojuist uitgepland',
        PRESENT: 'Aanwezig van ',
        FROM: ' van ',
        TILL: ' tot ',
        WORKS: 'Werkt',
        OTHER_TIME: 'Andere tijd',
        HOUR_WAGE_UNKNOWN: 'Uurloon onbekend',
        MADE_TRAVEL_DISTASTE: 'Gemaakte kilometers',
        JOB_DATE: 'Klusdatum',
        IN_RESPONSE: 'Reactie op',
        PAYMENT_NET: 'Netto betaling',
        DATES_TO_REMEMBER: 'Datums om te onthouden',
        CONTACTDATA: 'Contact gegevens',
        ACCOUNTDATA: 'Accountgegevens'
        , Admin: 'Admin'
        , Planner: 'Planner',
        Gebruiker: 'Gebruiker',
        Accountant: 'Accountant',
        NEW_PASSWORD: 'Nieuw wachtwoord',
        CONFIRM_PASSWORD: 'Nogmaals je wachtwoord',
        EMPLOYEE_DETAILS:'Personeel gegevens',
        CHANGE_PROFILE_PICTURE: 'Verander profiel foto',
        PROFILE_PICTURE_CHANGED: 'Profiel foto veranderd',
        PROFILE_PICTURE_NOT_CHANGED: 'Profiel foto niet veranderd',
        INVALID_TIMES:'Ongeldige tijden opgegeven: Zorg ervoor dat de ingangstijd vóór de starttijd ligt en de starttijd vóór de eindtijd.'
    });

    const translations = {
        JUSTSTARTED: 'Just begun',
        INTERMEDIATE: 'Junior',
        ADVANCED: 'Advanced',
        SENIOR: 'Senior',
        EXPERT: 'Expert',
        STARTDATEEMPLOYMENT: 'Date for anniversary',
        BIRTHDAY: 'Birthday',
        DATEOFHIRE: 'Enlistment',
        EXPERIENCE: 'Experience',
        USERLEVEL: 'User Role',
        USERGROUP: 'Users group',
        USER_GROUPS: 'Users groups',
        ACCEPTED: 'Accepted',
        DECLINED: 'Declined',
        AWAITING: 'Awaiting',
        EMPLOYEES: 'Employees',
        EMPLOYEE: 'Employee',
        WORKNOTES_AND_PAYMENT: 'Stats and payment',
        FILLED: 'completed',
        NOTFILLED: 'Not completed',
        PAYMENT: 'Payment',
        WORKNOTES: 'Time sheets',
        WORKNOTE: 'Time sheet',
        WORKHOURS: 'Worked hours',
        TRAVELHOURS: 'Travel time',
        HOUR: 'hour',
        HOURS: 'hours',
        EXPENSES: 'Expense',
        TRAVELDISTANCE: 'Commute',
        AVAILABILITY: 'Availability,',
        PLAN_AVAILABILITY: 'Plan Availability',
        NEW_WORKNOTE: 'New time sheet',
        REMARKS: 'Remarks',
        SUCCEFULL_NEW_USER: 'Successfully created a new user',
        HASCAR: 'Possession of car',
        HASLICENSE: 'Possession of driving Licence',
        NOLICENSE: 'Has no driving Licence',
        NOCAR: 'Has no car',
        PERIODEND: 'Will continue until',
        PLAN: "Schedule",
        ACCEPT: 'Accept',
        DECLINE: 'Decline',
        HOURTYPES: 'Hourtypes',
        HOURRATE: 'Hourly wage',
        SUCCEFULL_PASS_RESET: 'Successful email sent with password recovery to: $email',
        ADDFIRSTHOURTYPE: "Open the employee and add an hourly wage."
        , COLLEAGUES: 'Colleagues'
        , COLLEAGUE: 'Colleague'
        , PERSONAL_DATA: "Personal data",
        UNSCHEDULE: 'Unschedule',
        SCHEDULE: 'Schedule',
        RECENT_PLANNED: 'Recently scheduled',
        RECENT_UNPLANNED: 'Recently unscheduled',
        PRESENT: 'Present at ',
        FROM: ' from ',
        TILL: ' till ',
        WORKS: ' works ',
        OTHER_TIME: 'Other time',
        HOUR_WAGE_UNKNOWN: 'Hourly wage unknown',
        MADE_TRAVEL_DISTASTE: 'Made travel distance',
        JOB_DATE: 'Job date',
        IN_RESPONSE: 'Response',
        PAYMENT_NET: 'Net payment',
        DATES_TO_REMEMBER: 'Dates to remember',

        CONTACTDATA: 'Contact data',
        ACCOUNTDATA: 'Account data'
        , Admin: 'Admin'
        , Planner: 'Planner',
        Gebruiker: 'User',
        Accountant: 'Accountant',
        NEW_PASSWORD: 'New password',
        CONFIRM_PASSWORD: 'Confirm password',
        EMPLOYEE_DETAILS:'Employee details',
        CHANGE_PROFILE_PICTURE: 'Change profile picture',
        PROFILE_PICTURE_CHANGED: 'Profile picture updated successfully',
        PROFILE_PICTURE_NOT_CHANGED: 'Profile picture updated unsuccessfully',
        INVALID_TIMES:'Invalid times provided: Ensure entrancetime is before starttime, starttime is before endtime'

    };

    $translateProvider.translations('en-gb', translations);
    $translateProvider.translations('en-us', translations);

    $translateProvider.preferredLanguage('nl-nl');
}]);