import app from "/src/app/app.module";
import PaymentAdress from "../../models/payment-adress";


app.controller('payment-details',
    function ($scope, vxUtils, settings, $job, selectionFilters,
              $location, $filter, enums, $payment, $customer,
              toastr, $users, $products, tabService) {

        var tab = tabService.getCurrentTab();
        var id = tab.id;

        $scope.cust = {};
        $scope.savetoserver = true;
        $scope.offerte = {};
        $scope.actionBarCpi = {};
        $scope.customerCpi = {};
        $scope.adressCpi = {};
        $scope.PageHash = vxUtils.generateQuickGuid();
        $scope.paymentCpi = {};
        $scope.jobCpi = {};
        var added = false;
        $scope.timeLineCpi = {
            tabItems: $scope.openItems,
            filters: selectionFilters.eventTypes
        };

        $scope.init = function (id) {
            $scope.oldOfferte = id;
            $payment.getPaymentItemByID(function (result) {
                $scope.offerte = result;
                if (!$scope.offerte.idjob) {
                    if (!added) {
                        $scope.actionitems.push(
                            {label: vxUtils.loc('DELETE'), href: "/src/panel/delete-dialog.html", icon: "fa fa-trash-o fa-2x"}
                        );
                        added = true;
                    }
                }
                $scope.adressCpi.adress = new PaymentAdress(result.dbo);
                $scope.paymentCpi.item = result;


                tab.tabname = $scope.offerte.tabID + ': ' +  $scope.offerte.tabDescription;

                $job.getJob(function (result) {
                    $scope.job = result;
                    $scope.jobCpi.item = result;
                    if (result) {
                        $users.getUser(function (result) {
                            $scope.createdBy = result[0];
                        }, result.createdbyID);
                    }
                }, $scope.offerte.idjob);

                $scope.paymentCpi.showAdress = false;

                $users.getUser(function (result) {
                    $scope.paymentcreatedBy = result[0];
                }, $scope.offerte.idUser);

                $customer.getCustomer(function (result) {
                    $scope.cust = result;
                    $scope.customerCpi.cust = $scope.cust;

                    $scope.actionBarCpi.mailobject = $scope.paymentCpi.item;
                }, $scope.offerte.idClient);


                $customer.getEvents(function (results) {
                    $scope.timeLineCpi.all = results;
                    $scope.timeLineCpi.refresh();
                }, $scope.offerte.idClient, 'lol');
            }, id);

            $products.getProductsByPaymentItem(function (result) {
                $scope.products = result;
            }, id);
        };

        $scope.locationCpi = {};

        ///////////actionbar/////////////
        $scope.actionitems = [
            {label: vxUtils.loc('SEND_EMAIL'), href: "/src/panel/mail-dialog.html", icon: "fa fa-envelope fa-2x"},
            {label: vxUtils.loc('NEW_NOTE'), href: "/src/panel/paperclip-dialog.html", icon: "fa fa-paperclip fa-2x"},
            // {
            //     label: "IDEAL",
            //     href: "/src/panel/pay-dialog.html",
            //     icon: "fa fa-eur fa-2x",
            // },
            {
                label:  vxUtils.loc('REFRESH'), click: function () {
                $scope.init($scope.oldOfferte);
                toastr.info($filter('translate')('REFRESHING'), $filter('translate')('BUSY'))
            }, href: "", icon: "fa fa-refresh fa-2x"
            }
        ];

        $scope.actionBarCpi = {};
        $scope.actionBarCpi.pageHash = $scope.PageHash;

        $scope.scrollMethod = function ($event, $delta, $deltaX, $deltaY) {
            vxUtils.scrollHorzontal($event, $delta, $deltaX, $deltaY, $scope.PageHash);
        };
        $scope.init(id);
    });