'use strict';

var HourType = function (hourType) {

    angular.extend(this, hourType);

    this.dbo = hourType;

    this._type = 'hourType';
    this._amountwhole = parseInt(floorFigure(parseInt(this.default_amount) / 100, 0));
    var cents = ( parseInt(this.default_amount) - (this._amountwhole * 100)  );
    this._amountcents = cents < 10 ? '0' + cents : cents.toString();

    function floorFigure(figure, decimals) {
        //  if (!decimals) decimals = 2;
        var d = Math.pow(10, decimals);
        return (parseInt(figure * d) / d).toFixed(decimals);
    };

    this.calcAmount = function () {
        var out = '00';
        if (parseInt(this._amountcents) < 10 && this._amountcents !== '00')
            out = '0' + this._amountcents;

        out = ((this._amountwhole) + out);

        this.default_amount = out;
    }
};

export default HourType;

//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];