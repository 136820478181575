'use strict';

var EmailResource = function (emailres) {
    this.dbo = emailres;

    this.UploadTime = function () {
        if (this.dbo === undefined)
            return 0;

        var i = this.dbo['up_uploadedon'] ? this.dbo['up_uploadedon'] : "0";
        return parseInt(i + "000");
    };

    this.content = null;
    this.id_email_resource = 0;
    this.type = null;
    this.label = null;
    this.subject = null;
    this.selected = false;
    this.res_upload_id = 0;
    this.uploadtime = this.UploadTime();
};

export default EmailResource;