import app from "/src/app/app.module";
import Car from "../../models/car";


app.controller('cars',
    function ($scope, $timeout, msgBus, $api,
              $filter, $focus, sweet, vxUtils, toastr) {
        var vm = this;

        vm.subjectPlaceholder = vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.items = [];
        vm.selected = {content: ''};

        var getNewStartNr = function () {
            var result = $filter('max')(vm.items, 'productgroupstartnr');
            if (angular.isUndefined(result)) {
                return 100;
            } else {
                return result.productgroupstartnr + 100;
            }
        };

        vm.add = function () {
            var m = {
                number: vm.title
            };

            vm.title = '';
            vm.items.splice(0, 0, m);
            vm.selected = m;
            $focus('focusSubject');
            vm.save(true);
        };

        vm.delete = function () {
            sweet.show({
                title: vxUtils.loc('CONFIRMDELETION'),
                text: "Auto verwijderen? Weet u het zeker?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: vxUtils.loc('YES'),
                closeOnConfirm: true
            }, function () {
                $api.del($api.adress.cars + '/' + vm.selected.id, function () {
                    var i = $filter('removeWith')(vm.items, {productgroupid: vm.selected.productgroupid});
                    // vm.items.splice(i, 1);
                    vm.items = i;
                    vm.selected = vm.items[vm.items.length - 1];
                });
            });


        };

        vm.save = function (isNew) {
            $api.post($api.adress.cars, function (result) {
                vm.selected.id = parseInt(result);
                if (isNew) {
                //    vm.items.splice(0, 0, vm.selected);
                }
                var i = $filter('filter')(vm.items, {number: result.number});
                vm.selected = i;
                toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('CAR')+vxUtils.loc('ACTION_SAVED'));
            }, vm.selected);

        };

        vm.getItems = function () {
            var items = [];
            $api.get($api.adress.cars,
                function (data) {
                    if (angular.isArray(data)) {
                        items = data.concat(items)
                    } else
                        items.push(data);

                    vm.items = items;

                    if (vm.items.length > 0)
                        vm.selected = vm.items[0];
                }, Car);
        };

        vm.getItems();
    })
;