class OrderWizardResult{
    /**
     * @type Location
     */
    deliveryLocation;
    /**
     * @type PaymentAddress
     */
    invoiceAddress;
    /**
     * @type Array<OrderProduct>
     */
    products;
    /**
     * @type Order
     */
    order;

    /**
     * @type Date
     **/
    expirationDate;
}

export default OrderWizardResult;