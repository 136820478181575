(function(){var a=function(a){return"undefined"===typeof a},b=function(b){return a(b)||null===b},c=function(a,c){if(!a)throw Error("Wrong argument: ns.");for(var f=0<=a.indexOf(".")?a.split("."):[a],g=window,h=0;h<f.length;h++){var i=f[h];b(g[i])&&(g[i]={});g=g[i]}if(!b(c))for(var j in c)g[j]=c[j];return g};c("bob.utils",{isUndefined:a,isNull:function(a){return null===a},isUndefinedOrNull:b});c("bob.ns",{setNs:c})})();
bob.ns.setNs("bob.array.extensions",{contains:function(a,b){if(!bob.utils.isArray(a))throw Error("Wrong argument: array.");return 0<=a.indexOf(b)},remove:function(a,b,c){if(!bob.utils.isArray(a))throw Error("Wrong argument: array.");var d=a.indexOf(b);if(0<=d)return a.splice(d,1),!0;if(!c)throw Error(bob.string.formatString("Cannot find array element to remove: {0}.",b));return!1}});
bob.ns.setNs("bob.assert",{fail:function(a){if(1>=arguments.length)throw Error(bob.utils.ifNullOrWhiteSpace(a,"Assert failed."));var b=bob.collections.extensions.clone.call(arguments,1,arguments.length-1,[a]);throw Error(bob.string.formatString.apply(null,b));},equals:function(a,b,c){bob.utils.equals(a,b)||bob.assert.fail(bob.utils.ifUndefinedOrNull(c,"Assert equals failed. Actual: {0}. Expected: {1}."),b,a)},notEquals:function(a,b,c){bob.utils.equals(a,b)&&bob.assert.fail(bob.utils.ifUndefinedOrNull(c,
    "Assert not-equals failed. Actual: {0}. Comparison: {1}."),b,a)},isTrue:function(a,b){return bob.assert.equals(!0,a,bob.utils.ifUndefinedOrNull(b,"Assert is-true failed. Actual: {0}."))},isFalse:function(a,b){return bob.assert.equals(!1,a,bob.utils.ifUndefinedOrNull(b,"Assert is-false failed. Actual: {0}."))},throwsError:function(a,b){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnTest.");bException=!1;try{a()}catch(c){bException=!0}bException||bob.assert.fail(bob.utils.ifUndefinedOrNull(b,
    "Assert throws-error failed."))},isUndefined:function(a,b){return bob.assert.isTrue(bob.utils.isUndefined(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-undefined failed. Actual: {0}."),a))},isNotUndefined:function(a,b){return bob.assert.isFalse(bob.utils.isUndefined(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-undefined failed. Actual: {0}."),a))},isNull:function(a,b){return bob.assert.isTrue(bob.utils.isNull(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,
    "Assert is-null failed. Actual: {0}."),a))},isNotNull:function(a,b){return bob.assert.isFalse(bob.utils.isNull(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-null failed. Actual: {0}."),a))},isUndefinedOrNull:function(a,b){return bob.assert.isTrue(bob.utils.isUndefinedOrNull(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-undefined-or-null failed. Actual: {0}."),a))},isNotUndefinedOrNull:function(a,b){return bob.assert.isFalse(bob.utils.isUndefinedOrNull(a),
    bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-undefined-or-null failed. Actual: {0}."),a))},isNullOrWhiteSpace:function(a,b){return bob.assert.isTrue(bob.utils.isNullOrWhiteSpace(a,!0),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-null-or-whitespace failed. Actual: {0}."),a))},isNotNullOrWhiteSpace:function(a,b){return bob.assert.isFalse(bob.utils.isNullOrWhiteSpace(a,!0),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-null-or-whitespace failed. Actual: {0}."),
    a))},isNumber:function(a,b){return bob.assert.isTrue(bob.utils.isNumber(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-number failed. Actual: {0}."),a))},isNotNumber:function(a,b){return bob.assert.isFalse(bob.utils.isNumber(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-number failed. Actual: {0}."),a))},isBoolean:function(a,b){return bob.assert.isTrue(bob.utils.isBoolean(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-boolean failed. Actual: {0}."),
    a))},isNotBoolean:function(a,b){return bob.assert.isFalse(bob.utils.isBoolean(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-boolean failed. Actual: {0}."),a))},isString:function(a,b){return bob.assert.isTrue(bob.utils.isString(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-string failed. Actual: {0}."),a))},isNotString:function(a,b){return bob.assert.isFalse(bob.utils.isString(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-string failed. Actual: {0}."),
    a))},isDate:function(a,b){return bob.assert.isTrue(bob.utils.isDate(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-date failed. Actual: {0}."),a))},isNotDate:function(a,b){return bob.assert.isFalse(bob.utils.isDate(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-date failed. Actual: {0}."),a))},isFunction:function(a,b){return bob.assert.isTrue(bob.utils.isFunction(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-function failed. Actual: {0}."),
    a))},isNotFunction:function(a,b){return bob.assert.isFalse(bob.utils.isFunction(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-function failed. Actual: {0}."),a))},isArray:function(a,b){return bob.assert.isTrue(bob.utils.isArray(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-array failed. Actual: {0}."),a))},isNotArray:function(a,b){return bob.assert.isFalse(bob.utils.isArray(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-array failed. Actual: {0}."),
    a))},isObject:function(a,b){return bob.assert.isTrue(bob.utils.isObject(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-object failed. Actual: {0}."),a))},isNotObject:function(a,b){return bob.assert.isFalse(bob.utils.isObject(a),bob.string.formatString(bob.utils.ifUndefinedOrNull(b,"Assert is-not-object failed. Actual: {0}."),a))}});
bob.ns.setNs("bob.code.module",{ModuleObserver:function(){var a=this,b={},c=bob.event.namedEvent(this,"moduleLoaded");this.setLoaded=function(a,e){if(!bob.utils.isString(a)||bob.utils.isNullOrWhiteSpace(a))throw Error("Wrong argument: sModule.");if(!bob.utils.isUndefined(b[a]))throw Error(bob.string.formatString('Module "{0}" is already loaded.',a));e=bob.utils.ifUndefined(e,null);b[a]=e;c(a,b[a])};this.whenLoaded=function(c,e){if(!bob.utils.isString(c)||bob.utils.isNullOrWhiteSpace(c))throw Error("Wrong argument: sModule.");
    if(!bob.utils.isFunction(e))throw Error("Wrong argument: fnBody.");if(bob.utils.isUndefined(b[c]))var f=a.add_moduleLoaded(function(b,h){bob.utils.equals(b,c)&&(e(h),a.remove_moduleLoaded(f))});else e(b[c])}}});
bob.ns.setNs("bob.collections.extensions",{equals:function(a,b){if(bob.utils.isUndefinedOrNull(b))b=function(a,b){return bob.utils.equals(a,b)};else if(!bob.utils.isFunction(b))throw Error("Wrong argument: fnEquals.");for(var c=bob.collections.getEnumerator(this),d=bob.collections.getEnumerator(a),e=c.next(),f=d.next();e&&f;){if(!b.call(c.get_current(),c.get_current(),d.get_current(),c.get_index(),d.get_index()))return!1;e=c.next();f=d.next()}return e==f?!0:!1},any:function(a){if(bob.utils.isUndefinedOrNull(a))a=
    function(){return!0};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnAny.");for(var b=bob.collections.getEnumerator(this);b.next();)if(a.call(b.get_current(),b.get_index(),b.get_current()))return!0;return!1},all:function(a){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnAll.");return!bob.collections.extensions.any.call(this,function(b,c){return!a.call(c,b,c)})},forEach:function(a){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnForEach.");for(var b=bob.collections.getEnumerator(this);b.next();)a.call(b.get_current(),
    b.get_index(),b.get_current());return this},firstOrDefault:function(a,b){if(bob.utils.isUndefinedOrNull(a))a=function(){return!0};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnCondition.");var c=b;bob.collections.extensions.any.call(this,function(b,e){var f=a.call(e,b,e);f&&(c=e);return f});return c},lastOrDefault:function(a,b){if(bob.utils.isUndefinedOrNull(a))var c=bob.collections.extensions.count.call(this),d=0,a=function(){return++d==c};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnCondition.");
    var e=b;bob.collections.extensions.forEach.call(this,function(b,c){a.call(c,b,c)&&(e=c)});return e},select:function(a){if(bob.utils.isUndefinedOrNull(a))a=function(){return!0};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnMap.");var b=bob.collections.getCollectionCreator(this);bob.collections.extensions.forEach.call(this,function(c,d){b.addItem(c,a.call(d,c,d))});return b.getValue()},where:function(a){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnCondition.");var b=
    bob.collections.getCollectionCreator(this);bob.collections.extensions.forEach.call(this,function(c,d){a.call(d,c,d)&&b.addItem(c,d)});return b.getValue()},atIndex:function(a){var b=!1,c=bob.collections.extensions.firstOrDefault.call(this,function(c){return b=bob.utils.equals(a,c)});if(b)return c;throw Error(bob.string.formatString("Cannot find item at index {0}.",a));},combine:function(a){var b=bob.collections.extensions.clone.call(this);bob.collections.extensions.clone.call(a,null,b);return b},toObject:function(a,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          b){if(!bob.utils.isFunction(a))throw Error("Wrong agrument: fnProperty.");if(bob.utils.isUndefinedOrNull(b))b=function(a,b){return b};else if(!bob.utils.isFunction(b))throw Error("Wrong argument: fnValue.");var c={};bob.collections.extensions.forEach.call(this,function(d,e){var f=a.call(e,d,e);if(bob.utils.isUndefinedOrNull(f))throw Error(bob.string.formatString("Wrong property name: {0}.",f));c[f]=b.call(e,d,e)});return c},toArray:function(a){if(bob.utils.isUndefinedOrNull(a))a=function(a,b){return b};
else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnItem.");var b=[];bob.collections.extensions.forEach.call(this,function(c,d){b.push(a.call(d,c,d))});return b},groupBy:function(a,b){if(bob.utils.isUndefinedOrNull(a))a=function(a){return a};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnKey.");if(bob.utils.isUndefinedOrNull(b))b=function(a,b){return b};else if(!bob.utils.isFunction(b))throw Error("Wrong argument: fnItem.");var c={};bob.collections.extensions.forEach.call(this,
    function(d,e){var f=a.call(e,d,e);bob.utils.isUndefinedOrNull(c[f])&&(c[f]=[]);c[f].push(b.call(e,d,e))});return c},aggregate:function(a,b){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnForEach.");if(!bob.utils.isFunction(b))throw Error("Wrong argument: fnResult.");bob.collections.extensions.forEach.call(this,a);return b()},count:function(a){var b=0;if(bob.utils.isUndefinedOrNull(a))a=function(){return!0};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnCondition");return bob.collections.extensions.aggregate.call(this,
    function(c,d){a.call(d,c,d)&&b++},function(){return b})},sum:function(a){if(bob.utils.isUndefinedOrNull(a))a=function(a,b){return b};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnItem.");var b=0;return bob.collections.extensions.aggregate.call(this,function(c,d){b+=a.call(d,c,d)},function(){return b})},avg:function(a){if(bob.utils.isUndefinedOrNull(a))a=function(a,b){return b};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnItem.");var b=0,c=bob.collections.extensions.sum.call(this,
    function(c,e){b++;return a.call(e,c,e)});return 0==b?0:c/b},min:function(a){if(bob.utils.isUndefinedOrNull(a))a=function(a,b){return b};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnItem.");var b=!0,c=void 0;return bob.collections.extensions.aggregate.call(this,function(d,e){b?(c=a.call(e,d,e),b=!1):c=Math.min(c,a.call(e,d,e))},function(){if(!b)return c;throw Error("Cannot calculate min: collection is empty.");})},max:function(a){if(bob.utils.isUndefinedOrNull(a))a=function(a,b){return b};
else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnItem.");var b=!0,c=void 0;return bob.collections.extensions.aggregate.call(this,function(d,e){b?(c=a.call(e,d,e),b=!1):c=Math.max(c,a.call(e,d,e))},function(){if(!b)return c;throw Error("Cannot calculate max: collection is empty.");})}});
bob.ns.setNs("bob.collections",{Enumerator:function(a,b,c){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnHasNext.");if(!bob.utils.isFunction(b))throw Error("Wrong argument: fnGetNext.");if(!bob.utils.isFunction(c))throw Error("Wrong argument: fnGetIndex.");var d=bob.prop.namedProp(this,"current",void 0,!0),e=bob.prop.namedProp(this,"index",-1,!0);this.next=function(){return a()?(d(b()),e(c()),!0):!1}},arrayEnumerator:function(a){if(!bob.utils.isArray(a))throw Error("Wrong argument: array.");
    var b=-1;return new bob.collections.Enumerator(function(){return b<a.length-1},function(){return a[++b]},function(){return b})},stringEnumerator:function(a){if(!bob.utils.isString(a))throw Error("Wrog argument: str.");var b=-1;return new bob.collections.Enumerator(function(){return b<a.length-1},function(){return a[++b]},function(){return b})},objectEnumerator:function(a){if(!bob.utils.isObject(a))throw Error("Wrong argument: obj.");var b=[],c;for(c in a)b.push({name:c,value:a[c]});return bob.collections.arrayEnumerator(b)},
    objectIndexerEnumerator:function(a){if(!bob.utils.isObject(a))throw Error("Wrong argument: obj.");var b=[],c;for(c in a)b.push({name:c,value:a[c]});var d=-1;return new bob.collections.Enumerator(function(){return d<b.length-1},function(){return b[++d].value},function(){return b[d].name})},getEnumerator:function(a){if(bob.utils.isArray(a))return bob.collections.arrayEnumerator(a);if(bob.utils.isString(a))return bob.collections.stringEnumerator(a);if(bob.utils.isObject(a))return bob.collections.objectIndexerEnumerator(a);
        if(!bob.utils.isUndefinedOrNull(a)&&bob.utils.isFunction(a.getEnumerator))return a.getEnumerator();throw Error("Wrong argument: objAny.");},CollectionCreator:function(a,b){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnAddItem.");if(!bob.utils.isFunction(b))throw Error("Wrong argument: fnGetValue.");this.addItem=function(b,d){a(b,d)};this.getValue=function(){return b()}},arrayCreator:function(a){if(bob.utils.isUndefinedOrNull(a))a=[];else if(!bob.utils.isArray(a))throw Error("Wrong argument: arrValue.");
        return new bob.collections.CollectionCreator(function(b,c){a.push(c)},function(){return a})},stringCreator:function(a){if(bob.utils.isUndefinedOrNull(a))a="";else if(!bob.utils.isString(a))throw Error("Wrong argument: sValue.");var b=a.split("");return new bob.collections.CollectionCreator(function(a,d){b.push(d)},function(){return b.join("")})},objectCreator:function(a){var b=bob.utils.ifUndefinedOrNull(a,{});return new bob.collections.CollectionCreator(function(a,d){b[a]=d},function(){return b})},
    getCollectionCreator:function(a,b){if(bob.utils.isArray(a))return bob.collections.arrayCreator(b);if(bob.utils.isString(a))return bob.collections.stringCreator(b);if(bob.utils.isObject(a))return bob.collections.objectCreator(b);if(!bob.utils.isUndefinedOrNull(a)&&bob.utils.isFunction(a.getCreator))return a.getCreator(b);throw Error("Wrong argument: objSource.");},ExtendedCollection:function(a){var b=function(b){return function(){var c=b.apply(a,arguments);return new bob.collections.ExtendedCollection(c)}},
        c=function(b){return function(){return b.apply(a,arguments)}},d=bob.collections.extensions;this.equals=b(d.equals);this.clone=b(d.clone);this.any=c(d.any);this.all=c(d.all);this.forEach=b(d.forEach);this.firstOrDefault=c(d.firstOrDefault);this.lastOrDefault=c(d.lastOrDefault);this.select=b(d.select);this.where=b(d.where);this.except=b(d.except);this.atIndex=c(d.atIndex);this.combine=b(d.combine);this.count=c(d.count);this.toObject=b(d.toObject);this.toArray=b(d.toArray);this.groupBy=b(d.groupBy);
        this.aggregate=c(d.aggregate);this.sum=c(d.sum);this.avg=c(d.avg);this.min=c(d.min);this.max=c(d.max);this.getValue=function(){return a}},extend:function(a){return new bob.collections.ExtendedCollection(a)}});
bob.ns.setNs("bob.event",{Event:function(){var a=this,b=[];this.add_handler=function(a){if(!bob.utils.isFunction(a))throw Error("Invalid argument: fnHandler.");b.push(a);return a};this.remove_handler=function(a){bob.array.extensions.remove(b,a,!0)};var c=function(){var c=arguments,e=bob.collections.extensions.clone.call(b);bob.collections.extensions.forEach.call(e,function(b,e){e.apply(a,c)})};this.get_caller=function(b){b||delete a.get_caller;return c}},namedEvent:function(a,b){if(bob.utils.isUndefinedOrNull(a))throw Error("Argument null: objThis.");
    if(bob.utils.isNullOrWhiteSpace(b))throw Error("Wrong argument: sName");var c=new bob.event.Event;a["add_"+b]=c.add_handler;a["remove_"+b]=c.remove_handler;return c.get_caller()}});
bob.ns.setNs("bob.fn",{Overload:function(){var a=[];this.add=function(b,c){if(!bob.utils.isFunction(b))throw Error("Wrong argument: fnCondition.");if(!bob.utils.isFunction(c))throw Error("Wrong argument: fnOverload.");a.push({condition:b,overload:c})};this.call=function(){var b=arguments,c;a:{for(c=0;c<a.length;c++)if(a[c].condition.apply(this,b)){c=a[c];break a}c=null}if(!c)throw Error("No matching overload found.");return c.overload.apply(this,b)}},overloadFunction:function(a){if(!bob.utils.isArray(a))throw Error("Wrong argument: arrFunctionObjects.");
    if(!a.length)throw Error("Number of overloads must be more than zero.");for(var b=new bob.fn.Overload,c=0;c<a.length;c++)b.add(a[c].condition,a[c].overload);return b.call},getArgumentNames:function(a){if(!bob.utils.isFunction(a))throw Error("Wrong argument: func.");a=a.toString();a=a.slice(a.indexOf("(")+1,a.indexOf(")")).match(/([^\s,]+)/g);return bob.utils.isArray(a)?a:[]},namedArgFunction:function(a){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnOriginal.");var b=bob.fn.getArgumentNames(a);
    return function(c){var d=bob.utils.isUndefinedOrNull(c)?[]:bob.collections.extensions.select.call(b,function(a,b){return c[b]});return a.apply(this,d)}}});bob.ns.setNs("bob.ns",{});
bob.ns.setNs("bob.obj",{canExtractInterface:function(a,b){if(bob.utils.isUndefinedOrNull(a))throw Error("Wrong argument: objSource.");if(bob.utils.isUndefinedOrNull(b))throw Error("Wrong argument: objInterface.");return bob.collections.extensions.all.call(b,function(b,d){if(!bob.utils.isFunction(d))throw Error("Interface must contain only functions.");return bob.utils.isFunction(a[b])})},extractInterface:function(a,b,c){if(bob.utils.isUndefinedOrNull(a))throw Error("Wrong argument: objSource.");bob.utils.isUndefinedOrNull(b)&&
(b=bob.collections.extensions.where.call(a,function(a,b){return bob.utils.isFunction(b)}));return bob.collections.extensions.select.call(b,function(b,e){if(!bob.utils.isFunction(e))throw Error("Interface must contain only functions.");if(!bob.utils.isFunction(a[b]))throw Error(bob.string.formatString('Cannot extract interface: function "{0}" not found.',b));return c?a[b]:bob.fn.fixedObjectFunction(a,b)})}});
bob.ns.setNs("bob.prop.event",{NotifierProperty:function(a,b,c){var d=this,e=new bob.prop.Property(a,b,c);this.change=new bob.event.Event;var f=d.change.get_caller();bob.utils.isFunction(e.get_value)?d.get_value=function(){return e.get_value.apply(e,arguments)}:bob.utils.isFunction(e.get_getter)&&(d.get_getter=function(){try{return e.get_getter.apply(e,arguments)}finally{bob.utils.isUndefined(e.get_getter)&&delete d.get_getter}});var g=function(a,b){try{return a.apply(d,b)}finally{f.apply(d,b)}};
    bob.utils.isFunction(e.set_value)?d.set_value=function(){return g(e.set_value,arguments)}:bob.utils.isFunction(e.get_setter)&&(d.get_setter=function(){try{var a=e.get_setter.apply(e,arguments);return function(){return g(a,arguments)}}finally{bob.utils.isUndefined(e.get_setter)&&delete d.get_setter}})}});
bob.ns.setNs("bob.prop",{Property:function(a,b,c){var d=this,e=a,f=function(){return e},g=function(a){e=a};b?this.get_setter=function(a){a||delete d.get_setter;return g}:this.set_value=g;c?this.get_getter=function(a){a||delete d.get_getter;return f}:this.get_value=f}});
bob.ns.setNs("bob.serialization",{objectToString:function(a,b){var c=typeof a;if(bob.utils.isUndefinedOrNull(a)||0<=["number","boolean"].indexOf(c))return""+a;if("string"===c||bob.utils.isDate(a))return b?bob.utils.quote(a):""+a;if("function"===c)return"function() { }";if(bob.utils.isArray(a)){for(var c="[ ",d=0;d<a.length;d++)0<d&&(c+=", "),c+=bob.serialization.objectToString(a[d],!0);c+=" ]"}else{var c="{ ",e=0;for(d in a)0<e++&&(c+=", "),c+=bob.utils.quote(d)+": ",c+=bob.serialization.objectToString(a[d],
    !0);c+=" }"}return c}});bob.ns.setNs("bob.string",{formatString:function(a){if(!bob.utils.isString(a))throw Error("Wrong argument: sFormat.");var b=arguments;return a.replace(/({)(\d+)(})/gi,function(a,d,e){a=1*e+1;if(b.length<a+1)throw Error(bob.string.formatString("Cannot format string: argument at index {0} not found.",e));return b[a]})}});
bob.ns.setNs("bob.unitTest",{runTestObject:function(a){if(!bob.utils.isObject(a))throw Error("Wrong argument: objTest.");var b=!0,c;for(c in a){var d=a[c];if(bob.utils.isFunction(d))try{d(),console.log(bob.string.formatString('SUCCESS in Test Function "{0}".',c))}catch(e){b=!1,console.log(bob.string.formatString('FAILURE in Test Function "{0}": {1}.',c,e.message))}}return b}});
bob.ns.setNs("bob.utils",{isNullOrWhiteSpace:function(a,b){if(bob.utils.isUndefinedOrNull(a))return!0;if(!bob.utils.isString(a)){if(b)throw Error("Wrong argument: str.");return!1}return!a.trim()?!0:!1},clone:function(a,b){return bob.utils.isArray(a)||bob.utils.isObject(a)?bob.collections.extensions.select.call(a,function(a,d){return b?bob.utils.clone(d,b):d}):bob.utils.isDate(a)?new Date(a.valueOf()):a},extendObject:function(a,b,c){if(bob.utils.isUndefinedOrNull(a))throw Error("Argument null: source");
    if(!bob.utils.isUndefinedOrNull(b))for(var d in b)a[d]=c?bob.utils.clone(b[d],c):b[d];return a},ifUndefined:function(a,b){return bob.utils.isUndefined(a)?b:a},ifNull:function(a,b){return bob.utils.isNull(a)?b:a},ifUndefinedOrNull:function(a,b){return bob.utils.isUndefinedOrNull(a)?b:a},ifNullOrWhiteSpace:function(a,b){return bob.utils.isNullOrWhiteSpace(a)?b:a},isNumber:function(a){return"number"===typeof a},isBoolean:function(a){return"boolean"===typeof a},isString:function(a){return"string"===typeof a||
    0<Object.prototype.toString.call(a).indexOf("String")},isDate:function(a){return bob.utils.isUndefinedOrNull(a)?!1:0<Object.prototype.toString.call(a).indexOf("Date")},isFunction:function(a){return"function"===typeof a},isArray:function(a){if(bob.utils.isUndefinedOrNull(a))return!1;a=Object.prototype.toString.call(a);return 0<a.indexOf("Array")||0<a.indexOf("Arguments")},isObject:function(a,b,c){b=b?!1:bob.utils.isUndefined(a);c=c?!1:bob.utils.isNull(a);return!b&&!c&&!bob.utils.isNumber(a)&&!bob.utils.isBoolean(a)&&
    !bob.utils.isString(a)&&!bob.utils.isDate(a)&&!bob.utils.isFunction(a)&&!bob.utils.isArray(a)},equals:function(a,b){return 2>arguments.length||typeof a!==typeof b?!1:!bob.utils.isUndefinedOrNull(a)&&!bob.utils.isUndefinedOrNull(b)?a.valueOf()===b.valueOf():a==b},equalsAny:function(a,b){return 2>arguments.length?!1:bob.collections.extensions.any.call(b,function(b,d){return bob.utils.equals(a,d)})},Lazy:function(a){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnCreate.");var b=this,c=bob.prop.namedProp(this,
    "isValueCreated",!1,!0),d=void 0;this.getValue=function(){b.get_isValueCreated()||(d=a(),c(!0));return d}},lazyFunction:function(a){return(new bob.utils.Lazy(a)).getValue},quote:function(a){return'"'+a+'"'},executeUsing:function(a,b){if(!bob.utils.isObject(a)||!bob.utils.isFunction(a.dispose))throw Error("Wrong argument: disposable.");if(!bob.utils.isFunction(b))throw Error("Wrong argument: fnExecute.");try{return b(a)}finally{a.dispose()}}});
(function(){var a=function(a,c,d,e){if(!bob.utils.isBoolean(a)||!a)throw Error("Wrong argument: bUsePropObject.");if(bob.utils.isUndefinedOrNull(c))throw Error("Argument null: objThis.");if(bob.utils.isNullOrWhiteSpace(d))throw Error("Wrong argument: sName.");a=void 0;bob.utils.isFunction(e.get_value)?c["get_"+d]=e.get_value:bob.utils.isFunction(e.get_getter)&&(a=e.get_getter());bob.utils.isFunction(e.set_value)?c["set_"+d]=e.set_value:bob.utils.isFunction(e.get_setter)&&(a=e.get_setter());return a};
    bob.ns.setNs("bob.prop",{namedProp:bob.fn.overloadFunction([{condition:function(a){return bob.utils.isBoolean(a)&&a},overload:a},{condition:function(){return!0},overload:function(b,c,d,e,f){if(bob.utils.isUndefinedOrNull(b))throw Error("Argument null: objThis.");if(bob.utils.isNullOrWhiteSpace(c))throw Error("Wrong argument: sName.");d=new bob.prop.Property(d,e,f);return a(!0,b,c,d)}}])})})();
(function(){var a=function(a,c,d){if(!bob.utils.isBoolean(a)||!a)throw Error("Wrong argument: bUsePropObject.");if(bob.utils.isUndefinedOrNull(c))throw Error("Argument null: prop.");return function(){if(arguments.length)if(bob.utils.isFunction(c.set_value))c.set_value(arguments[0]);else{if(!d)throw Error("Cannot set value of read-only property.");}else{if(bob.utils.isFunction(c.get_value))return c.get_value();if(!d)throw Error("Cannot get value of write-only property.");}}};bob.ns.setNs("bob.prop",
    {propFunction:bob.fn.overloadFunction([{condition:function(a){return a?!0:!1},overload:a},{condition:function(){return!0},overload:function(b,c,d,e,f){if(!bob.utils.isBoolean(b)||b)throw Error("Wrong argument: bUsePropObject.");b=new bob.prop.Property(c,d,e);return a(!0,b,f)}}])})})();
(function(){var a=function(a,c,d,e,f){if(bob.utils.isUndefinedOrNull(c))throw Error("Argument null: objThis.");if(bob.utils.isNullOrWhiteSpace(d))throw Error("Wrong argument: sName.");c[d]=bob.prop.propFunction(!0,e,f);a=void 0;bob.utils.isFunction(e.get_getter)?a=e.get_getter():bob.utils.isFunction(e.get_setter)&&(a=e.get_setter());return a};bob.ns.setNs("bob.prop",{namedPropFunction:bob.fn.overloadFunction([{condition:function(a){return bob.utils.isBoolean(a)&&a},overload:a},{condition:function(){return!0},
    overload:function(b,c,d,e,f,g){if(bob.utils.isUndefinedOrNull(b))throw Error("Argument null: objThis.");if(bob.utils.isNullOrWhiteSpace(c))throw Error("Wrong argument: sName.");d=new bob.prop.Property(d,e,f);return a(!0,b,c,d,g)}}])})})();
(function(){var a=function(a,c){if(bob.utils.isUndefinedOrNull(a))a=function(){return!0};else if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnIndexCondition.");var d=bob.collections.getCollectionCreator(this,c);bob.collections.extensions.forEach.call(this,function(c,f){a.call(f,c,f)&&d.addItem(c,f)});return d.getValue()};bob.ns.setNs("bob.collections.extensions",{clone:bob.fn.overloadFunction([{condition:function(a,c,d){return bob.utils.isArray(this)&&(bob.utils.isUndefinedOrNull(a)||bob.utils.isNumber(a))&&
    (bob.utils.isUndefinedOrNull(c)||bob.utils.isNumber(c))&&(bob.utils.isUndefinedOrNull(d)||bob.utils.isArray(d))},overload:function(b,c,d){if(!bob.utils.isArray(this))throw Error("Wrong context: Array expected.");var d=bob.utils.ifUndefinedOrNull(d,[]),e=bob.utils.ifUndefinedOrNull(b,0),f=bob.utils.ifUndefinedOrNull(c,this.length-1);a.call(this,function(a){return a>=e&&a<=f},d);return d}},{condition:function(){return!0},overload:a}])})})();
(function(){var a=function(a){if(!bob.utils.isFunction(a))throw Error("Wrong argument: fnCondition.");var c=bob.collections.getCollectionCreator(this);bob.collections.extensions.forEach.call(this,function(d,e){a.call(e,d,e)||c.addItem(d,e)});return c.getValue()};bob.ns.setNs("bob.collections.extensions",{except:bob.fn.overloadFunction([{condition:function(a){return bob.utils.isFunction(a)},overload:a},{condition:function(){return!0},overload:function(b){return a.call(this,function(a){return bob.utils.equals(a,
    b)})}}])})})();
(function(){var a=function(a,c){if(bob.utils.isUndefinedOrNull(a))throw Error("Wrong argument: objSource.");if(!bob.utils.isFunction(c))throw Error("Wrong argument: func.");return function(){return c.apply(a,arguments)}};bob.ns.setNs("bob.fn",{fixedObjectFunction:bob.fn.overloadFunction([{overload:a,condition:function(a,c){return bob.utils.isFunction(c)}},{overload:function(b,c){if(bob.utils.isUndefinedOrNull(b))throw Error("Wrong argument: objSource.");if(!bob.utils.isString(c)||bob.utils.isNullOrWhiteSpace(c))throw Error("Wrong argument: fnName.");var d=
    b[c];if(!bob.utils.isFunction(d))throw Error(bob.string.formatString('Cannot find function "{0}".',c));return a(b,d)},condition:function(){return!0}}])})})();(function(){var a=new bob.code.module.ModuleObserver,b={setLoaded:function(){},whenLoaded:function(){}},b=bob.obj.extractInterface(a,b);bob.ns.setNs("bob.code.module",b)})();
