(function () {
    'use strict';

    angular
        .module('angular-nicescroll', [])
        .directive('ngNicescroll', ngNicescroll);

    ngNicescroll.$inject = ['$rootScope','$parse','msgBus','$timeout'];

    /* @ngInject */
    function ngNicescroll($rootScope,$parse,msgBus,$timeout) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            link: link
        };
        return directive;

        function link(scope, element, attrs, controller) {
            $timeout(function(){
                const niceOption = scope.$eval(attrs.niceOption);

                const niceScroll = $(element).niceScroll(niceOption);
                const nice = $(element).getNiceScroll();

                if (attrs.niceScrollObject)  $parse(attrs.niceScrollObject).assign(scope, nice);

                // on scroll end
                niceScroll.onscrollend = function (data) {
                    if (data.end.y >= this.page.maxh) {
                        if (attrs.niceScrollEnd) scope.$evalAsync(attrs.niceScrollEnd);

                    }
                    if (data.end.y <= 0) {
                        // at top
                        if (attrs.niceScrollTopEnd) scope.$evalAsync(attrs.niceScrollTopEnd);
                    }
                };

                msgBus.onMsg("UpdateScroll", function () {
                    niceScroll.resize();
                }, scope);

                scope.$on('$destroy', function () {
                    if (angular.isDefined(niceScroll)) {
                        niceScroll.remove();
                    }
                })
            },3000);



        }
    }


})();