import app from "/src/app/app.module";


app.controller('company-details', function ($timeout,vxCountries ,Geocoder, $scope, settings, $location, $filter, enums, $customer) {

    $scope.updateUser = function (data) {
        return $customer.newCustomer(function (result) {
            return true;
        }, $scope.cust);
    };

    $scope.countries = vxCountries.countries;

    angular.extend($scope, {
        map: {
            control: {},
            version: "uknown",
            showTraffic: false,
            showBicycling: false,
            showWeather: false,
            showHeat: false,
            center: {
                latitude: 45,
                longitude: -73
            },
            options: {
                streetViewControl: true,
                panControl: false,
                maxZoom: 20,
                minZoom: 3
            },
            zoom: 12,
            dragging: false,
            bounds: {},
            markers: [

            ]

        }
    });


    var c = $scope.cust;
    // Geocoder.geocodeAddress(c.country + ',' + c.city + ',' + c.adress).then(function (result) {
    //
    //     $scope.map.center = {
    //         latitude: result.lat,
    //         longitude: result.lng
    //     };
    //     $scope.map.markers.push({
    //         id: 2,
    //         latitude: result.lat,
    //         longitude: result.lng
    //     });
    //
    // }, function (error) { });

});