import app from "/src/app/app.module";
import EmailResource from "../../models/email-resource";


app.controller('default-messages', function ($scope, $focus, $templates, $rootScope, settings, $location, $filter, enums, $customer) {
    $scope.messages = [];
    $scope.selectedMessage = {};
    $scope.mergeitems = [{ name: 'hoi', merge_item: '{hoitest}' }];

    $scope.addMessage = function () {
        angular.forEach($scope.messages, function (value, key) {
            value.$selected = false;
        });

        var m = new EmailResource();
        m.$selected = true;
        $scope.messages.splice(0, 0, m);
        $scope.selectedMessage = m;
        $focus('focusSubject');
    };

    $scope.openMessage = function (message) {
        $scope.selectedMessage.$selected = false;

        $scope.selectedMessage = message;
        $scope.selectedMessage.$selected = true;
        $focus('focusSubject');

    };

    $scope.deleteMessage = function () {
        var i = $scope.messages.indexOf($scope.selectedMessage);
        $scope.messages.splice(i, 1);
        $scope.selectedMessage = {};

        if($scope.selectedMessage.id_email_resource !== 0)
            $templates.delete(function (i) { console.log(i) }, $scope.selectedMessage.id_email_resource);
    };

    $scope.saveMessage = function () {
        $scope.selectedMessage.type = 'message';

        $templates.save(function(i) { console.log(i); }, $scope.selectedMessage);
    };

    $scope.getMessages = function () {
        $templates.getMessages(function (result) { $scope.messages = result;});
    };

    $scope.getMessages();

});