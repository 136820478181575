import app from "/src/app/app.module";


app.controller('quotes', function ($scope, settings, $location, $payment, $filter,
                                   vxUtils, enums, $locale) {

    ////////OVERVIEW/////
    $scope.overviewCpi = {
        openItemPage: "/src/pages/quotes/quote-details.html",
        openItemIcon: "fa fa-clock-o",
        tabItems: $scope.openItems
    };

    $scope.columns = [
        {title: '#', field: 'idofferte', visible: true},
        {title: vxUtils.loc('STATUS'), field: 'statusIcon', visible: true, icon: true},
        {title:  vxUtils.loc('SENT_ON'), field: 'senddate', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate},
        {title: vxUtils.loc('PAYMENT_NAME'), field: 'offertename', visible: true},
        {title: vxUtils.loc('JOBTITLE'), field: 'jobtitle', visible: true},
        {title: vxUtils.loc('CUSTOMER_NAME'), field: 'clientname', visible: true},
        {title: vxUtils.loc('AMOUNT'), field: 'amount', visible: true, filter: "currency", args: settings.currency},
        {title:  vxUtils.loc('DUEDATE_ON'), field: 'duedate', visible: true, filter: "date", args: $locale.DATETIME_FORMATS.shortDate}

    ];

    $scope.getItems = function () {
        $payment.getAll(function (results) {
            $scope.overviewCpi.items = results;
            $scope.overviewCpi.reload();
        });
    };

    $scope.getItems();
    ////////OVERVIEW/////
});