import app from "/src/app/app.module";
import EmailResource from "../../models/email-resource";


app.controller('edit_mail_templates',
    function ($scope, $templates, $timeout, msgBus, vxMailMerge, $focus, vxUtils) {
        var vm = this;

        vm.isHtml = $scope.panel.isHtml;
        vm.subjectPlaceholder = vm.isHtml ? vxUtils.loc('ADD_SUBJECT_PLACEHOLDER') : vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.messages = [];
        vm.selectedMessage = {content: ''};
        vm.mergeitems = vxMailMerge.getMailMergeItems();

        vm.addMessage = function () {
            var m = new EmailResource();
            m.label = vm.title;
            vm.title = '';
            vm.messages.splice(0, 0, m);
            vm.selectedMessage = m;
            $focus('focusSubject');

        };

        vm.deleteMessage = function () {
            var i = vm.messages.indexOf(vm.selectedMessage);
            vm.messages.splice(i, 1);

            if (vm.selectedMessage.id_email_resource !== 0)
                $templates.delete(function (i) {
                    vm.selectedMessage = {};
                }, vm.selectedMessage.id_email_resource);
        };

        vm.saveMessage = function () {

            vm.selectedMessage.type = vm.isHtml ? 'html' : 'message';

            $templates.save(function (i) {
                console.log(i);
            }, vm.selectedMessage);
        };

        vm.testPdf = function(){
            $templates.openAsPdf(function (i) {
                console.log(i);
            }, vm.selectedMessage);

        };


        vm.getMessages = function () {
            if (vm.isHtml) {
                $templates.getHTMLtemplates(function (result) {
                    vm.messages = result;
                    if (vm.messages.length > 0)
                        vm.selectedMessage = vm.messages[0];
                });
            }
            else {
                $templates.getMessages(function (result) {
                    vm.messages = result;
                    if (vm.messages.length > 0)
                        vm.selectedMessage = vm.messages[0];
                });
            }
        };

        vm.updatePreview = function () {

            vm.updateTimer = $timeout(function () {
                msgBus.emitMsg('settings.templates.showpreview', vm.selectedMessage);
                if (vm.update)
                    vm.updatePreview();
            }, 2000);

        };

        vm.getMessages();

        vm.update = true;
        vm.updatePreview();

        $scope.$on('$destroy', function () {
            $timeout.cancel(vm.updateTimer);
        });

    });