'use strict';

var Note = function (note) {

    this.idnotes = null;
    this.message = null;
    this.idLabel = 0;
    this.time = Date.now();
    this.idUser = 0;
    this.idJob = 0;
    this.idClient = 0;
    this.priority = 'normal';
    this.id_order_FK = 0;
    this.executiondate = Date.now();
    this.type = null;
};
export default Note;

